import gql from "graphql-tag";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { tokenAddress } from "../../lib/contracts";
import { NetworkID, networkIdFromChainId } from "../../lib/network";
import { Token, UnderlyingToken, wrapUnderlyingToken } from "../../lib/tokens";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";

export interface Transaction {
  type: TransactionType;
  amount: BigNumber;
  token: Token;
  hash: string;
  time: number;
}

export enum TransactionType {
  DepositType = "Deposit",
  WithdrawType = "Withdraw",
  BorrowType = "Borrow",
}

interface PoolTracesResponse {
  liquidityPoolTraces: {
    id: string;
    borrowed: string;
    withdrawn: string;
    earned: string;
    deposited: string;
    updatedAt: string;
  }[];
}

export interface Tx {
  type: string;
  amount: string;
  token: string;
  id: string;
  time: string;
}

export interface DepositTx extends Tx {
  depositedAt: string;
}

export interface WithdrawalTx extends Tx {
  withdrewAt: string;
}

interface TransactionResponse {
  transactions: Tx[];
}

export interface HistoryResponse {
  deposits: DepositTx[];
  withdrawals: WithdrawalTx[];
}

interface Variables {
  id: string;
}

interface LiquidityPoolTrace {
  id: string;
}

interface TxVariables {
  sender: string;
  token: string;
}

export function useTransactions(token: UnderlyingToken) {
  const { chainId, account } = useWeb3React();
  const { data, loading, error } = useQuery<HistoryResponse, TxVariables>(
    gql`
      query getTransactions($sender: Bytes!, $token: Bytes!) {
        deposits: userDeposits(where: { user: $sender, underlyingToken: $token }) {
          amount
          depositedAt
          id
        }
        withdrawals: userWithdrawals(where: { user: $sender, underlyingToken: $token }) {
          amount
          withdrewAt
          id
        }
      }
    `,
    {
      variables: {
        sender: account?.toLowerCase() || "",
        token: tokenID(wrapUnderlyingToken(token), networkIdFromChainId(chainId)),
      },
      pollInterval: 6000,
    }
  );

  if (error) {
    console.log(error);
  }

  return { data, loading, error };
}

export function useLazyUserTransactions() {
  return useLazyQuery<HistoryResponse, TxVariables>(
    gql`
      query getTransactions($sender: Bytes!, $token: Bytes!) {
        deposits: userDeposits(where: { user: $sender, underlyingToken: $token }) {
          amount
          depositedAt
          id
        }
        withdrawals: userWithdrawals(where: { user: $sender, underlyingToken: $token }) {
          amount
          withdrewAt
          id
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      // onCompleted: (data) => {
      //     console.log('CALLED USER TRANSACTIONS');
      // },
    }
  );
}

export function getUserTransactionsVariables(
  account: string,
  token: UnderlyingToken,
  chainId: number
) {
  return {
    variables: {
      sender: account?.toLowerCase() || "",
      token: tokenID(wrapUnderlyingToken(token), networkIdFromChainId(chainId)),
    },
  };
}

export function useLiquidityPoolTraces(networkID: NetworkID, token: Token): any {
  const { chainId } = useWeb3React();
  const { data, loading, error } = useQuery<PoolTracesResponse, Variables>(
    gql`
      query getPoolBalances($id: Bytes!) {
        liquidityPoolTraces(where: { underlyingToken: $id }) {
          id
          updatedAt
          earned
          deposited
          borrowed
          withdrawn
        }
      }
    `,
    {
      variables: {
        id: tokenID(token, networkIdFromChainId(chainId)),
      },
      pollInterval: 30000,
    }
  );
  if (error) {
    return {
      data: {
        liquidityPoolTraces: [],
      },
      error,
    };
  }

  return { data, loading, error };
}

export function useLiquidityPoolTracesList(
  networkID: NetworkID,
  token: Token,
  blocks: number[]
): any {
  const { chainId } = useWeb3React();
  const blockQueries = blocks
    .map(
      (blockNumber, i) => `
        trace${i}: liquidityPoolTraces(where: { underlyingToken: $id }, block: { number: ${blockNumber} }) {
            id
            updatedAt
            earned
            deposited
            borrowed
            withdrawn
        }`
    )
    .join(`\n`);
  const { data, loading, error } = useQuery<any, LiquidityPoolTrace>(
    gql`query getLiquidityPoolTraces($id: Bytes!) { ${blockQueries} }`,
    {
      variables: {
        id: tokenID(token, networkIdFromChainId(chainId)),
      },
      pollInterval: 30000,
    }
  );
  if (error) {
    console.log(error);
    return {
      data: {
        liquidityPoolTraces: [],
      },
      error,
    };
  }

  return { data, loading, error };
}

export function tokenID(selectedToken: Token, network: NetworkID): string {
  return network && network !== NetworkID.Unsupported
    ? tokenAddress(network, selectedToken).toLowerCase()
    : "";
}
