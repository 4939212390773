import { useState, useEffect, useRef } from "react";
import { createContainer } from "unstated-next";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { useEagerConnect, useInactiveListener } from "../hooks";
import { networkConnector } from "../lib/connectors";
import { INFURA } from "../components/Web3Provider";
import { AbstractConnector } from "@web3-react/abstract-connector";

export interface Web3ReactManagerFunctions {
  activate: (
    connector: AbstractConnector,
    onError?: (error: Error) => void,
    throwErrors?: boolean
  ) => Promise<void>;
  setError: (error: Error) => void;
  deactivate: () => void;
}

export interface ExtendedContextInterface extends AbstractConnector {
  stop?: any;
  close?: any;
}

export interface Web3ReactManagerReturn extends Web3ReactManagerFunctions {
  connector?: ExtendedContextInterface;
  provider?: any;
  library?: any;
  chainId?: number;
  account?: null | string;
  active: boolean;
  stop?: void;
  error?: Error;
}

export enum WalletModalState {
  ACCOUNT,
  PENDING,
  SUCCESS,
  ERROR,
}

function useWallet() {
  const { connector, library, chainId, account, activate, deactivate, active, error } =
    useWeb3React<Web3>();
  const {
    active: networkActive,
    error: networkError,
    activate: activateNetwork,
  } = useWeb3React<Web3>(INFURA);
  const [modalState, setModalState] = useState(WalletModalState.ACCOUNT);
  const [modalOpen, setModalOpen] = useState(false);

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  useEffect(() => {
    if (triedEager && !networkActive && !networkError && !active) {
      console.log("Activating network provider");
      activateNetwork(networkConnector);
    }
  }, [triedEager, active, networkActive, networkError, activateNetwork]);

  useInactiveListener(!triedEager);

  return {
    triedEager,
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error,
    modalState,
    setModalState,
    modalOpen,
    setModalOpen,
  };
}

const Wallet = createContainer(useWallet);

export default Wallet;
