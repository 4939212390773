import { createContainer } from "unstated-next";
import { useTotalSupplyOfKTokenList, usePoolAssetsList } from "./subscriptions/pool";
import { useBalancesOfTokenList } from "./subscriptions/user";

function useLiquidity() {
  const { kethSupply, kwethSupply, kdaiSupply, kusdcSupply, kbtcSupply } =
    useTotalSupplyOfKTokenList();
  const { kethBalance, kwethBalance, kdaiBalance, kusdcBalance, kbtcBalance } =
    useBalancesOfTokenList();
  const { ethPoolBalance, wethPoolBalance, daiPoolBalance, usdcPoolBalance, btcPoolBalance } =
    usePoolAssetsList();
  return {
    kethSupply,
    kwethSupply,
    kdaiSupply,
    kusdcSupply,
    kethBalance,
    kwethBalance,
    kdaiBalance,
    kusdcBalance,
    ethPoolBalance,
    wethPoolBalance,
    daiPoolBalance,
    usdcPoolBalance,
    kbtcSupply,
    kbtcBalance,
    btcPoolBalance,
  };
}

const Liquidity = createContainer(useLiquidity);

export default Liquidity;
