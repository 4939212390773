import Web3 from "web3";
import { Token, Tokens } from "./tokens";
import { NetworkID } from "./network";

import Config from "../contracts/config.json";
import CompoundKovanContracts from "../contracts/compound-kovan.json";
import CompoundRopstenContracts from "../contracts/compound-ropsten.json";
import ERC20_TOKENS from "../contracts/erc20.json";
import DISTRIBUTORS from "../contracts/distributors.json";

/**
 * Enumerates all of the contracts supported by this frontend.
 */
export enum ContractType {
  LiquidityPool = "LiquidityPool",

  //Distributors
  Distributor = "Distributor",
  LPPremineDistributor = "LPPreDistributor",
  LPDistributor1 = "LPDistributor1",
  LPDistributor2 = "LPDistributor2",
  LPDistributor3 = "LPDistributor3",
  LPDistributor4 = "LPDistributor4",
  LPDistributor5 = "LPDistributor5",
  KeeperDistributor = "KeeperDistributor",
  TradeDistributor = "TradeDistributor",
  TradeDistributor2 = "TradeDistributor2",
  TradeDistributor3 = "TradeDistributor3",
  TradeDistributor4 = "TradeDistributor4",
  TradeDistributor5 = "TradeDistributor5",
  TradeDistributor6 = "TradeDistributor6",
  TradeDistributor7 = "TradeDistributor7",
  HidingVaultDistributor = "HVDistributor",
  HidingVaultDistributor2 = "HVDistributor2",
  HidingVaultDistributor3 = "HVDistributor3",
  HidingVaultDistributor4 = "HVDistributor4",

  //HidingVault
  CompoundGateway = "CompoundGateway",
  CompoundVars = "CompoundVars",
  CompoundPosition = "CompoundPosition",
  HidingVaultNFT = "HidingVaultNFT",

  //kCompound
  KCompound = "KCompound",
  JITUCompound = "JITUCompound",

  //Compound Protocol
  Comptroller = "Comptroller",
  UniswapAnchoredView = "UniswapAnchoredView",
  CompoundLens = "CompoundLens",

  //Compound
  cETH = "cETH",
  cBAT = "cBAT",
  cDAI = "cDAI",
  cUSDC = "cUSDC",
  cUSDT = "cUSDT",
  cZRX = "cZRX",
  cCOMP = "cCOMP",
  cLINK = "cLINK",
  cREP = "cREP",
  cSAI = "cSAI",
  cWBTC = "cWBTC2",
  cWBTC2 = "cWBTC2",
  cTUSD = "cTUSD",
  cUNI = "cUNI",
  cAAVE = "cAAVE",
  cMKR = "cMKR",
  cSUSHI = "cSUSHI",
  cYFI = "cYFI",
  cFEI = "cFEI",

  //0x
  ZrxExchange = "0xExchange",
  ZrxV4Exchange = "0xExchangeV4Proxy",

  //erc20
  KETH = "kEther",
  KWETH = "kWrappedEther",
  ETH = "ETH",
  WETH = "wETH",
  USDC = "USDC",
  KUSDC = "kUSDC",
  DAI = "DAI",
  KDAI = "kDAI",
  ROOK = "ROOK",
  BTC = "BTC",
  KBTC = "kBTC",
  REP = "REP",
  UNI = "UNI",
  BAT = "BAT",
  COMP = "COMP",
  WBTC = "WBTC",
  USDT = "USDT",
  ZRX = "ZRX",
}

export const cTokens = [
  ContractType.cUSDC,
  ContractType.cETH,
  ContractType.cBAT,
  ContractType.cDAI,
];

export const ERC20 = [
  ContractType.COMP,
  ContractType.ZRX,
  ContractType.WBTC,
  ContractType.USDT,
  ContractType.USDC,
  ContractType.REP,
  ContractType.DAI,
  ContractType.BAT,
  ContractType.UNI,
  ContractType.ROOK,
  ContractType.BTC,
];

export const CTOKEN = [
  ContractType.cBAT,
  ContractType.cDAI,
  ContractType.cETH,
  ContractType.cUSDT,
  ContractType.cZRX,
  ContractType.cUSDC,
];

export const COMPOUND_TESTNET_CONTRACTS = [
  ContractType.COMP,
  ContractType.ZRX,
  ContractType.WBTC,
  ContractType.USDT,
  ContractType.USDC,
  ContractType.REP,
  ContractType.DAI,
  ContractType.BAT,
  ContractType.WETH,
  ContractType.UNI,
];

export const DISTRIBUTOR_CONTRACTS = [
  ContractType.LPPremineDistributor,
  ContractType.LPDistributor1,
  ContractType.LPDistributor2,
  ContractType.LPDistributor3,
  ContractType.LPDistributor4,
  ContractType.LPDistributor5,
  ContractType.KeeperDistributor,
  ContractType.TradeDistributor,
  ContractType.TradeDistributor2,
  ContractType.TradeDistributor3,
  ContractType.TradeDistributor4,
  ContractType.TradeDistributor5,
  ContractType.TradeDistributor6,
  ContractType.TradeDistributor7,
  ContractType.HidingVaultDistributor,
  ContractType.HidingVaultDistributor2,
  ContractType.HidingVaultDistributor3,
  ContractType.HidingVaultDistributor4,
];

/**
 * Build a web3 contract object.
 *
 * @param web3 that will be used to build the contract object.
 * @param network that will be used to find the contract ABI and contract
 * address.
 * @param contract that will be built.
 * @param from address that will be used to call functions on the contract
 * object.
 *
 * @returns a web3 contract object that can be used to invoke functions on
 * Ethereum.
 */
export function contract(
  web3: Web3,
  network: NetworkID,
  contract: ContractType,
  from: string,
  address = ""
) {
  const cAddress = address ? address : contractAddress(network, contract);
  return new web3.eth.Contract(contractABI(network, contract), cAddress, {
    from,
  });
}

export function ERC20Contract(web3: Web3, network, address, from: string) {
  return new web3.eth.Contract(contractABI(network, ContractType.DAI), address, { from });
}

export function distibutorContract(
  web3: Web3,
  network: NetworkID,
  from: string,
  type: ContractType
) {
  if (DISTRIBUTOR_CONTRACTS.includes(type)) {
    const address = DISTRIBUTORS[network][type];
    return new web3.eth.Contract(contractABI(network, type), address, { from });
  }
  return null;
}

export function distibutorContractAddress(
  web3: Web3,
  network: NetworkID,
  from: string,
  type: ContractType
) {
  if (DISTRIBUTOR_CONTRACTS.includes(type)) {
    const address = DISTRIBUTORS[network][type];
    return address;
  }
  return null;
}

// /**
//  * Build a web3 contract object.
//  *
//  * @param web3 that will be used to build the contract object.
//  * @param network that will be used to find the contract ABI and contract
//  * address.
//  * @param contract that will be built.
//  * @param from address that will be used to call functions on the contract
//  * object.
//  *
//  * @returns a web3 contract object that can be used to invoke functions on
//  * Ethereum.
//  */
// export function simpleWalletContract(web3: Web3, network: NetworkID): { contract: any; bytecode: string } {
//     const contract = new web3.eth.Contract(contractABI(network, ContractType.CompoundWallet));
//     const bytecode = Config[network][ContractType.CompoundWallet].bytecode;
//     return {
//         contract,
//         bytecode,
//     }
// }

export function contractTypeFromToken(token: Token) {
  switch (token) {
    case Token.KETH:
      return ContractType.KETH;
    case Token.KWETH:
      return ContractType.KWETH;
    case Token.ETH:
      return ContractType.ETH;
    case Token.WETH:
      return ContractType.WETH;
    case Token.USDC:
      return ContractType.USDC;
    case Token.KUSDC:
      return ContractType.KUSDC;
    case Token.DAI:
      return ContractType.DAI;
    case Token.KDAI:
      return ContractType.KDAI;
    case Token.ROOK:
      return ContractType.ROOK;
    case Token.BTC:
      return ContractType.BTC;
    case Token.ZRX:
      return ContractType.ZRX;
    case Token.KBTC:
      return ContractType.KBTC;
    case Token.COMP:
      return ContractType.COMP;
    case Token.UNI:
      return ContractType.UNI;
    case Token.REP:
      return ContractType.REP;
    case Token.BAT:
      return ContractType.BAT;
    case Token.USDT:
      return ContractType.USDT;
    case Token.WBTC:
      return ContractType.WBTC;
    default:
      throw new TypeError(`non-exhaustive pattern: ${token}`);
  }
}

/**
 * This function returns the JSON ABI of a contract. This is mostly used when
 * building contract objects for invoking method calls.
 *
 * @param network to which the contract has been, or will be, deployed.
 * @param contract that has been, or will be, deployed.
 *
 * @returns the JSON ABI of the contract.
 */
export function contractABI(network: NetworkID, contract: ContractType) {
  if (ERC20.includes(contract)) {
    return ERC20_TOKENS.ABI;
  } else if (DISTRIBUTOR_CONTRACTS.includes(contract)) {
    return DISTRIBUTORS.ABI;
  } else if (Config[network][contract]) {
    return Config[network][contract].ABI;
  }
  throw new TypeError(`non-exhaustive pattern: ${contract}`);
}

/**
 * This function returns the Ethereum address of a contract. This is mostly used
 * when building contract objects for invoking method calls.
 *
 * @param network to which the contract has been deployed.
 * @param contract that has been deployed.
 *
 * @returns the Ethereum address of the contract.
 */
export function contractAddress(
  network: NetworkID,
  contract: ContractType,
  compound = false
): string {
  try {
    if (network === NetworkID.Unsupported) {
      return "";
    }

    if (contract === ContractType.ETH) {
      return "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";
    }
    if (network === NetworkID.Kovan && compound) {
      return CompoundKovanContracts[contract].Address;
    }
    if (network === NetworkID.Ropsten && compound) {
      return CompoundRopstenContracts[contract].Address;
    }
    if (ERC20.includes(contract)) {
      return ERC20_TOKENS[network][contract];
    } else if (Config[network][contract]) {
      return Config[network][contract].Address;
    }
    throw new TypeError(`Contract does ot exist: ${contract} on network: ${network}`);
  } catch (err) {
    console.error(err);
    return "";
  }
}

export function contractName(contract: ContractType): string {
  return ContractType[contract];
}

export function tokenSymbol(network: NetworkID, contractAddr: string): Token {
  for (const token of Tokens) {
    if (
      contractAddress(network, contractTypeFromToken(token)).toLowerCase() ===
      contractAddr.toLowerCase()
    ) {
      return token;
    }
  }
  throw new TypeError(`non-exhaustive pattern: ${contractAddr}`);
}

export function isSupported(network: NetworkID, token: string): boolean {
  switch (token) {
    case contractAddress(network, ContractType.ETH).toLowerCase():
    case contractAddress(network, ContractType.KETH).toLowerCase():
    case contractAddress(network, ContractType.WETH).toLowerCase():
    case contractAddress(network, ContractType.KWETH).toLowerCase():
    case contractAddress(network, ContractType.USDC).toLowerCase():
    case contractAddress(network, ContractType.KUSDC).toLowerCase():
    case contractAddress(network, ContractType.DAI).toLowerCase():
    case contractAddress(network, ContractType.KDAI).toLowerCase():
    case contractAddress(network, ContractType.BTC).toLowerCase():
    case contractAddress(network, ContractType.KBTC).toLowerCase():
      return true;
    default:
      return false;
  }
}

export function tokenAddress(network: NetworkID, token: Token): string {
  switch (token) {
    case Token.ETH:
      return contractAddress(network, ContractType.ETH);
    case Token.KETH:
      return contractAddress(network, ContractType.KETH);
    case Token.USDC:
      return contractAddress(network, ContractType.USDC);
    case Token.KUSDC:
      return contractAddress(network, ContractType.KUSDC);
    case Token.WETH:
      return contractAddress(network, ContractType.WETH);
    case Token.KWETH:
      return contractAddress(network, ContractType.KWETH);
    case Token.DAI:
      return contractAddress(network, ContractType.DAI);
    case Token.KDAI:
      return contractAddress(network, ContractType.KDAI);
    case Token.BTC:
      return contractAddress(network, ContractType.BTC);
    case Token.KBTC:
      return contractAddress(network, ContractType.KBTC);
    default:
      break;
  }
  throw new TypeError(`non-exhaustive pattern: ${token}`);
}
