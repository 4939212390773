import React from "react";
import { tokenSymbol } from "../../lib/tokens";

export interface ITokenAmountTooltipProps {
  active?: boolean;
  payload?: Array<{
    value: string;
    payload: { token: number };
  }>;
  label?: string;
}

const TokenAmountTooltip: React.FC<ITokenAmountTooltipProps> = ({ active, payload, label }) => {
  if (active && payload) {
    return (
      <div>
        <p style={{ margin: "0px", padding: "0px" }}>{label}</p>
        <p style={{ margin: "0px", padding: "0px" }}>
          {`${payload[0].value} ${tokenSymbol(payload[0].payload.token)}`}
        </p>
      </div>
    );
  }
  return null;
};

export default TokenAmountTooltip;
