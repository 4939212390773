import React from "react";
import { IProps } from "../../lib/types";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modalBackground: {
    display: "flex",
    position: "relative",
    width: "100%",
    height: "100%",
    borderRadius: "32px",
    background: "none",
  },
  modalOverflow: {
    display: "flex",
    background: "none",
    position: "relative",
    borderRadius: "32px",
    overflow: "hidden",
    width: "100%",
    height: "100%",
  },
  modalContainer: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    borderRadius: "32px",
    width: "100%",
    height: "100%",
    border: "1px solid transparent",
    backgroundClip: "padding-box",
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px",

    "&:before": {
      position: "absolute",
      top: "0px",
      bottom: "0px",
      left: "0px",
      right: "0px",
      background: theme.palette.background.default,
      content: '""',
      zIndex: -1,
      borderRadius: "32px",
    },

    "&:after": {
      position: "absolute",
      top: "-1px",
      bottom: "-1px",
      left: "-1px",
      right: "-1px",
      background:
        "linear-gradient(187.66deg, #09A7F3 -12.7%, rgba(79, 99, 223, 0) 10.49%, rgba(92, 85, 219, 0) 79.1%, #6C46D6 107.67%)",
      content: '""',
      zIndex: -2,
      borderRadius: "32px",
    },
  },
  modalContent: {
    display: "flex",
    background: "rgba(245, 245, 245, 0.04)",
    position: "relative",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    borderRadius: "32px",
    zIndex: 1,
  },
  modalGradient: {
    position: "absolute",
    filter: "blur(80px)",
    left: "0%",
    right: "-5%",
    top: "-5%",
    bottom: "74.68%",
    background:
      "linear-gradient(87.1deg, rgba(108, 70, 214, 0) 47.38%, rgba(79, 99, 223, 0.35) 74.79%, rgba(9, 167, 243, 0.6) 100%)",
    borderRadius: "32px 32px 0px 0px",
  },
}));

export function StyledModal({ children }: IProps) {
  const c = useStyles();

  return (
    <div className={c.modalBackground}>
      <div className={c.modalOverflow}>
        <div className={c.modalContainer}>
          <div className={c.modalGradient} />
          <div className={c.modalContent}>{children}</div>
        </div>
      </div>
    </div>
  );
}
