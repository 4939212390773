import React, { useMemo, useState } from "react";
import { HelpCircle } from "react-feather";
import { toChecksumAddress } from "web3-utils";
import tokens from "../contracts/erc20.json";
import { ReactComponent as EthIcon } from "../styles/images/eth-icon.svg";
import { ReactComponent as RookIcon } from "../styles/images/rook-token-icon.svg";

const ROOK_TOKEN = "0xfA5047c9c78B8877af97BDcb85Db743fD7313d4a".toLowerCase();

const getTokenLogoURL = (address: string) =>
  `${process.env.PUBLIC_URL}/assets/tokenIcons/${address.toLowerCase()}.png`;

export default function CurrencyLogo({
  chainId,
  address,
  size,
}: {
  chainId: number | undefined;
  address: string;
  size?: number;
}) {
  const tSize = size ? size : 20;
  const [error, setError] = useState<boolean>(false);

  const src: string = useMemo(() => {
    setError(false);
    if (
      address === "" ||
      address === "0x0000000000000000000000000000000000000000" ||
      address === "ETH" ||
      address === null
    ) {
      return "ETH";
    }

    if (address.length > 2 && address.length < 6) {
      const addr = toChecksumAddress(tokens.mainnet[address]);
      return getTokenLogoURL(addr);
    }
    const checkSumAddress = toChecksumAddress(address);
    const addr = getTokenLogoURL(checkSumAddress);
    return addr;
  }, [address]);

  if (address.toLowerCase() === ROOK_TOKEN) {
    return <RookIcon height={tSize} width={tSize} />;
  }

  if (
    address === "" ||
    address === "0x0000000000000000000000000000000000000000" ||
    address === "ETH" ||
    address === null ||
    src === "ETH"
  ) {
    return <EthIcon width={tSize} height={tSize} />;
  }

  if (!address || (chainId && chainId !== 1) || error) {
    return <HelpCircle size={tSize} />;
  }

  return (
    <img
      className="token-icon"
      alt={address}
      src={src}
      onError={() => setError(true)}
      style={{ width: `${tSize}px`, height: `${tSize}px` }}
    />
  );
}
