import { BigNumber } from "@0x/utils";
import { Backdrop, Button, Dialog, Grid, makeStyles, Typography } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import MoonLoader from "react-spinners/MoonLoader";
import { StyledModal } from "..";
import { WalletCreate, WalletCreateState } from "../../../containers/borrow";
import CompoundData from "../../../containers/compound";
import { cTokens } from "../../../lib/compound";
import { contract, ContractType, ERC20Contract } from "../../../lib/contracts";
import { networkIdFromChainId } from "../../../lib/network";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "none",
    border: "none",
    position: "absolute",
  },
  modal: {
    position: "relative",
    width: "375px",
    height: "363px",
  },
  modalContent: {
    position: "absolute",
    display: "flex",
    height: "100%",
    width: "100%",
    padding: "24px",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  createVaultContainer: {
    display: "flex",
    position: "relative",
    height: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "20px",
  },
  yesNoContainer: {
    display: "flex",
    position: "relative",
    width: "100%",
    justifyContent: "space-between",
  },
  button: {
    borderRadius: "8px",
  },
  loadingContainer: {
    display: "flex",
    position: "relative",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  tokenField: {
    display: "flex",
    position: "relative",
    width: "100%",
    borderRadius: "16px",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(245, 245, 245, 0.08)",
    padding: "20px",
    marginTop: "8px",
    marginBottom: "8px",
  },
  tokenInfo: {
    display: "flex",
    justifyContent: "space-between",
    //position: 'absolute',
    //width: '100%',
    //height: '100%',
  },
  tokenInfoLeft: {
    display: "flex",
    //position: 'absolute',
    justifyContent: "center",
    alignItems: "flex-start",
    //justifySelf: 'flex-start',
  },
  tokenInfoHeader: {
    display: "flex",
    //position: 'absolute',
    //justifyContent: 'flex-start',
    color: theme.palette.text.primary,
  },
  tokenInfoRight: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  tokenLogo: {
    display: "flex",
    //position: 'absolute',
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
  },
  tokenInfoBody: {
    color: theme.palette.text.primary,
    //textTransform: 'none',
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: "bold",
    borderRadius: "16px",
    marginLeft: "8px",
  },
  divider: {
    background: "linear-gradient(91.6deg, #6D47D7 0%, #D7475A 100%)",
    opacity: "0.24",
    marginTop: "8px",
    marginBottom: "8px",
  },
  tradeInfoField: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    width: "100%",
  },
  tradeInfoHeader: {
    color: theme.palette.text.primary,
  },
  tradeInfoContent: {
    color: theme.palette.text.secondary,
  },
  cancelButton: {
    borderRadius: "8px",
    width: "100%",
    height: "100%",
  },
  submitButton: {
    //marginTop: '20px',
    borderRadius: "8px",
    width: "100%",
    height: "100%",
    //height: '56px',
  },
}));

const delay = (time) => new Promise((res) => setTimeout(res, time));

function useWalletCreate() {
  // The Wallet is needed so that we can sign and submit transactions to
  // Ethereum.
  const wallet = useWeb3React();
  const { t } = useTranslation();

  const { compoundTokenInfo, compoundData } = CompoundData.useContainer();

  //console.log('COMPOUND DATA', compoundTokenInfo, compoundData);

  // create hook
  const createC = WalletCreate.useContainer();

  async function cTokensApprove() {
    try {
      if (!wallet.library || !wallet.chainId || !wallet.account || !createC.positionAddress) {
        createC.setState(WalletCreateState.MIGRATE_FAIL);
        return;
      }
      const web3 = wallet.library;
      const networkID = networkIdFromChainId(wallet.chainId);
      createC.setState(WalletCreateState.WAIT_FOR_APPROVE);
      // const tokensToApprove = createC.userCTokens.reduce((arr, tok) => {
      //     const tokAllowance = createC.tokenAllowances.find((token) => token.address === tok.address);
      //     if (!tokAllowance) return arr;
      //     if (tokAllowance.allowance?.lt(tok.balance)) {
      //         return arr.concat([tok]);
      //     }
      //     return arr;
      // }, [] as cTokensBalance[]);

      // if (!tokensToApprove.length && createC.userCTokens.length) {
      //     await migrate();
      //     return;
      // }

      // const tokensToApprove = createC.userCTokens.map()
      // const multicall = new MultiCall(library);
      // const hidingVaultABI = contractABI(network, ContractType.USDC);
      // const hidingVaultContract = contractAddress(network, ContractType.HidingVaultNFT);

      // const lengthArr = Array.from(Array(totalSupply), (x, i) => i);
      // const inputs = lengthArr.map((n, i) => {
      //     return {
      //         target: hidingVaultContract,
      //         function: 'tokenByIndex',
      //         args: [i],
      //     };
      // });

      // const [, positionAddresses] = await multicall.multiCall(hidingVaultABI, inputs);

      //console.log('USER CTOKENS: ', createC.userCTokens);

      for (let i = 0; i < createC.userCTokens.length; i++) {
        const token = createC.userCTokens[i];
        const cTokencontract = ERC20Contract(web3, networkID, token.address, wallet.account);
        console.log("checking approval");
        const allowance = await cTokencontract.methods
          .allowance(wallet.account, createC.positionAddress)
          .call();
        console.log(token.token, " ALLOWANCE: ", allowance);
        console.log("approving ", token.token, " for ", token.balance.toFixed(0));
        if (new BigNumber(allowance).gte(token.balance)) continue;
        await cTokencontract.methods
          .approve(createC.positionAddress, token.balance.toFixed(0))
          .send({ from: wallet.account });
      }

      await migrate();
    } catch (err) {
      console.log(err);
    }
  }

  async function create() {
    createC.setState(WalletCreateState.WAIT_FOR_WALLET_CREATE);
    if (!wallet.library || !wallet.chainId || !wallet.account) {
      return;
    }
    try {
      //await createNewPosition();
      await createNewHidingVault();
      createC.setState(WalletCreateState.CREATE_SUCCESS);
    } catch (err) {
      console.error(`cannot create position: ${err}`);
      createC.setState(WalletCreateState.CREATE_FAIL);
      return;
    }
  }

  function createNewPosition() {
    return new Promise(async (resolve, reject) => {
      try {
        if (!wallet.library || !wallet.chainId || !wallet.account) {
          return;
        }
        const web3 = wallet.library;
        const networkID = networkIdFromChainId(wallet.chainId);
        const compoundContract = contract(
          web3,
          networkID,
          ContractType.CompoundGateway,
          wallet.account
        );
        await compoundContract.methods
          .mintCompoundPostion()
          .send({ from: wallet.account })
          .on("receipt", async (receipt) => {
            resolve(receipt);
          })
          .on("error", (error, receipt) => {
            reject(error);
          });
      } catch (err) {
        reject(err);
      }
    });
  }

  function createNewHidingVault() {
    return new Promise(async (resolve, reject) => {
      try {
        if (!wallet.library || !wallet.chainId || !wallet.account) {
          return;
        }

        const web3 = wallet.library;
        const networkID = networkIdFromChainId(wallet.chainId);
        const hidingVaultContract = contract(
          web3,
          networkID,
          ContractType.HidingVaultNFT,
          wallet.account
        );
        const res = await hidingVaultContract.methods
          .mintHidingVault("0x0000000000000000000000000000000000000000000000000000000000000000")
          .send({ from: wallet.account })
          .on("receipt", async (receipt) => {
            resolve(receipt);
          })
          .on("error", (error, receipt) => {
            reject(error);
          });
        /*
                    .send({ from: wallet.account })
                    .on('transactionHash', (hash) => {
                        console.log('HIDING VAULT TX HASH => ', hash);
                    })
                    .on('receipt', async (receipt) => {
                        console.log('HIDING VAULT RECEIPT => ', receipt);
                    })
                    .on('error', (error, receipt) => {
                        console.log('HIDING VAULT ERROR => ', error);
                    });*/
      } catch (err) {
        reject(err);
      }
    });
  }

  async function migrate() {
    createC.setState(WalletCreateState.WAIT_FOR_WALLET_MIGRATE);
    if (!wallet.library || !wallet.chainId || !wallet.account || !createC.positionAddress) {
      createC.setState(WalletCreateState.MIGRATE_FAIL);
      return;
    }
    try {
      const web3 = wallet.library;
      const networkID = networkIdFromChainId(wallet.chainId);
      const ctokens = cTokens(wallet.chainId);
      const cTokensByName = ctokens.reduce((acc, elem) => {
        acc[elem.name] = elem;
        return acc;
      }, {});
      //_amount The amount that needs to be flash lent (should be greater than the value of the compund position).
      const amount = createC.jituBalance.toString();
      //const amount = '100000000000000000000';

      //console.log('JITU BALANCE: ', amount);
      //const amount = await balanceOf(web3, networkID, cTokensByName['cETH'], cTokensByName['cETH'].address);
      // const hidingVaultContract = contract(web3, networkID, ContractType.HidingVaultNFT, wallet.account);

      const comptroller = contract(web3, networkID, ContractType.Comptroller, wallet.account);
      const assetsIn = await comptroller.methods.getAssetsIn(wallet.account).call();

      const suppliedTokens: string[] = [cTokensByName["cETH"].address];
      const borrowedTokens: string[] = [];
      let userCompoundData;
      try {
        const CompoundURL = `https://api.compound.finance/api/v2/account?network=${networkID}&page_size=20&page_number=1&addresses[]=${wallet.account}`;
        userCompoundData = await axios.get(CompoundURL);
      } catch (err) {
        console.log("compound api error: ", err);
      }

      //console.log('USER COMPOUND DATA', userCompoundData);

      if (userCompoundData) {
        const account = userCompoundData.data.accounts[0];
        if (account) {
          const tokens = account.tokens;
          tokens.map((token) => {
            const address = token.address;
            const supply = new BigNumber(token.supply_balance_underlying.value);
            const borrow = new BigNumber(token.borrow_balance_underlying.value);
            if (supply.gt(0)) suppliedTokens.push(address);
            if (borrow.gt(0)) borrowedTokens.push(address);
          });
        }
      }

      const collateralMarkets = [
        cTokensByName["cETH"].address,
        ...createC.userCTokens.map((cToken) => cToken.address),
      ];
      const debtMarkets = assetsIn; //.filter((x) => !collateralMarkets.includes(x));

      /*
            console.log(
                'address',
                createC.positionAddress,
                'supply',
                collateralMarkets,
                'borrow',
                debtMarkets,
                'amount',
                amount.toString(),
            );

            console.log('supplied tokens: ', suppliedTokens, 'borrowedTokens: ', borrowedTokens);
            */

      const JITUCompoundContract = contract(
        web3,
        networkID,
        ContractType.JITUCompound,
        wallet.account
      );

      await JITUCompoundContract.methods
        .migrate(
          createC.positionAddress,
          wallet.account,
          amount,
          suppliedTokens,
          borrowedTokens
          //createC.compoundVars.cTokens,
        )
        .send({ from: wallet.account })
        .on("receipt", async (receipt) => {
          createC.setState(WalletCreateState.MIGRATE_SUCCESS);
        })
        .on("error", (error, receipt) => {
          createC.setState(WalletCreateState.MIGRATE_FAIL);
        });
    } catch (err) {
      console.log(err);
      createC.setState(WalletCreateState.MIGRATE_FAIL);
    }
  }

  async function createWalletForMigration() {
    createC.setState(WalletCreateState.WAIT_FOR_WALLET_CREATE);
    if (!wallet.library || !wallet.chainId || !wallet.account) {
      return;
    }
    const web3 = wallet.library;
    const networkID = networkIdFromChainId(wallet.chainId);
    const compoundContract = contract(web3, networkID, ContractType.HidingVaultNFT, wallet.account);

    try {
      const numberOfPositionsBefore = Number(
        await compoundContract.methods.balanceOf(wallet.account).call()
      );
      let numberOfPositionsAfter = numberOfPositionsBefore;
      await createNewHidingVault();
      while (true) {
        numberOfPositionsAfter = Number(
          await compoundContract.methods.balanceOf(wallet.account).call()
        );
        if (numberOfPositionsAfter > numberOfPositionsBefore) break;
        await delay(5000);
      }
      const kCompoundPosition = new BigNumber(
        await compoundContract.methods
          .tokenOfOwnerByIndex(wallet.account, numberOfPositionsAfter - 1)
          .call()
      );
      const compoundPositionAddress = `0x${kCompoundPosition.toString(16)}`;
      createC.setPositionAddress(compoundPositionAddress);

      // createC.setState(WalletCreateState.CHECKING_ALLOWANCES);
      // while (true) {
      //     if (createC.tokenAllowances.length) break;
      //     await delay(5000);
      // }

      // const tokensToApprove = createC.userCTokens.reduce((arr, tok) => {
      //     const tokAllowance = createC.tokenAllowances.find((token) => token.address === tok.address);
      //     if (!tokAllowance) return arr;
      //     if (tokAllowance.allowance?.lt(tok.balance)) {
      //         return arr.concat([tok]);
      //     }
      //     return arr;
      // }, [] as cTokensBalance[]);

      createC.setState(WalletCreateState.CONFIRM_APPROVE);
    } catch (err) {
      console.error(`cannot migrate: ${err}`);
      createC.setState(WalletCreateState.MIGRATE_FAIL);
      return;
    }
  }

  return {
    ...wallet,
    ...createC,
    t,
    create,
    createWalletForMigration,
    cTokensApprove,
  };
}

interface CreateModalProps {
  onClose: () => void;
}

function TransferModal(props: CreateModalProps) {
  const c = useStyles();
  const {
    state,
    setState,
    t,
    userCTokens,
    cTokensApprove,
    createWalletForMigration,
    create,
    positionAddress,
  } = useWalletCreate();
  const { onClose } = props;

  const Hidden = () => {
    // It is important that this component is explicitly rendered when the modal
    // is hidden, otherwise the order of the "useState" calls will change when
    // the model opens/closes, and this will cause all sorts of undefined
    // behaviour!
    return null;
  };

  const CreateVault = () => {
    return (
      <Grid container item className={c.createVaultContainer} direction="column">
        <Grid item>
          <Typography variant="h5">{t("borrow-new-vault")}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">{t("vault-create-header")}</Typography>
        </Grid>
        <Grid container item className={c.yesNoContainer}>
          <Grid item style={{ width: "48%" }}>
            <Button
              fullWidth
              variant="outlined"
              className={c.button}
              onClick={() => setState(WalletCreateState.HIDDEN)}
            >
              {t("borrow-no")}
            </Button>
          </Grid>
          <Grid item style={{ width: "48%" }}>
            <Button
              fullWidth
              variant="contained"
              className={c.button}
              onClick={async () => await create()}
            >
              Create Vault
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const MigrateVault = () => {
    async function checkCTokens() {
      if (userCTokens.length) {
        if (positionAddress !== "") {
          console.log("ADDRESS TO MIGRATE: ", positionAddress);
          await cTokensApprove();
        } else {
          await createWalletForMigration();
        }
      } else {
        setState(WalletCreateState.MIGRATE_NOTHING);
      }
    }

    return (
      <Grid container item className={c.createVaultContainer} direction="column">
        <Grid item>
          <Typography variant="h5">Import Position</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">{t("vault-create-and-import-header")}</Typography>
        </Grid>
        <Grid container item className={c.yesNoContainer}>
          <Grid item style={{ width: "48%" }}>
            <Button
              fullWidth
              variant="outlined"
              className={c.button}
              onClick={() => setState(WalletCreateState.HIDDEN)}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item style={{ width: "48%" }}>
            <Button
              fullWidth
              variant="contained"
              className={c.button}
              onClick={async () => await checkCTokens()}
            >
              Migrate
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const MigrateOnly = () => {
    async function checkCTokens() {
      if (userCTokens.length) {
        if (positionAddress !== "") {
          console.log("ADDRESS TO MIGRATE: ", positionAddress);
          await cTokensApprove();
        } else {
          await createWalletForMigration();
        }
      } else {
        setState(WalletCreateState.MIGRATE_NOTHING);
      }
    }

    return (
      <Grid container item className={c.createVaultContainer} direction="column">
        <Grid item>
          <Typography variant="h5">Import Position</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">{t("vault-import-header")}</Typography>
        </Grid>
        <Grid container item className={c.yesNoContainer}>
          <Grid item style={{ width: "48%" }}>
            <Button
              fullWidth
              variant="outlined"
              className={c.button}
              onClick={() => setState(WalletCreateState.HIDDEN)}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item style={{ width: "48%" }}>
            <Button
              fullWidth
              variant="contained"
              className={c.button}
              onClick={async () => await checkCTokens()}
            >
              Migrate
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const WaitNewPositionCreate = () => {
    return (
      <Grid container item className={c.loadingContainer} direction="column" spacing={4}>
        <Grid item>
          <Typography variant="h5">{t("borrow-new-vault")}</Typography>
        </Grid>
        <Grid container item justify="center">
          <Typography variant="body1">{t("vault-mint")}</Typography>
        </Grid>
        <Grid container item justifyContent="space-around">
          <MoonLoader color="#6D47D7" loading={true} size={50} />
        </Grid>
      </Grid>
    );
  };

  const CheckingAllowances = () => {
    return (
      <Grid container item className={c.loadingContainer} direction="column" spacing={4}>
        <Grid item>
          <Typography variant="h5">Import Position</Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <Typography variant="body1">Checking Allowances</Typography>
        </Grid>
        <Grid container item justifyContent="space-around">
          <MoonLoader color="#6D47D7" loading={true} size={50} />
        </Grid>
      </Grid>
    );
  };

  const WaitForTokensApprove = () => {
    return (
      <Grid container item className={c.loadingContainer} direction="column" spacing={4}>
        <Grid item>
          <Typography variant="h5">Import Position</Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <Typography variant="body1">Waiting for cToken Approval</Typography>
        </Grid>
        <Grid container item justifyContent="space-around">
          <MoonLoader color="#6D47D7" loading={true} size={50} />
        </Grid>
      </Grid>
    );
  };

  const WaitForCompoundMigrate = () => {
    return (
      <Grid container item className={c.loadingContainer} direction="column" spacing={4}>
        <Grid item>
          <Typography variant="h5">Import Position</Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <Typography variant="body1">Waiting for Compound Migration</Typography>
        </Grid>
        <Grid container item justifyContent="space-around">
          <MoonLoader color="#6D47D7" loading={true} size={50} />
        </Grid>
      </Grid>
    );
  };

  const WaitApproveConfirm = () => {
    const allowanceRequiredTokens = userCTokens.map((token) => token.token).join(", ");

    return (
      <Grid container item className={c.createVaultContainer} direction="column">
        <Grid item>
          <Typography variant="h5">Import Position</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">{t("wallet-create-modal.approve-tokens")}</Typography>
        </Grid>
        <Grid container item className={c.yesNoContainer}>
          <Grid item style={{ width: "48%" }}>
            <Button
              fullWidth
              variant="outlined"
              className={c.button}
              onClick={() => setState(WalletCreateState.HIDDEN)}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item style={{ width: "48%" }}>
            <Button
              fullWidth
              variant="contained"
              className={c.button}
              onClick={async () => cTokensApprove()}
            >
              Approve
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const NewPositionSuccess = () => {
    return (
      <Grid container item className={c.loadingContainer} direction="column" spacing={4}>
        <Grid item>
          <Typography variant="h5">{t("borrow-new-vault")}</Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <Typography variant="body1">{t("wallet-create-modal.successful-create")}</Typography>
        </Grid>
        <Grid container item justifyContent="space-around">
          <Grid item style={{ width: "48%" }}>
            <Button
              fullWidth
              variant="outlined"
              className={c.button}
              onClick={() => {
                setState(WalletCreateState.HIDDEN);
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const MigrateSuccess = () => {
    return (
      <Grid container item className={c.loadingContainer} direction="column" spacing={4}>
        <Grid item>
          <Typography variant="h5">Import Position</Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <Typography variant="body1">{t("wallet-create-modal.successful-migrate")}</Typography>
        </Grid>
        <Grid container item justifyContent="space-around">
          <Grid item style={{ width: "48%" }}>
            <Button
              fullWidth
              variant="outlined"
              className={c.button}
              onClick={() => {
                setState(WalletCreateState.HIDDEN);
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const Message = (message: string) => {
    return (
      <Grid container item className={c.loadingContainer} direction="column" spacing={4}>
        <Grid item>
          <Typography variant="h5">{t("borrow-new-vault")}</Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <Typography variant="body1">{message}</Typography>
        </Grid>
        <Grid container item justifyContent="space-around">
          <Grid item style={{ width: "48%" }}>
            <Button
              fullWidth
              variant="outlined"
              className={c.button}
              onClick={() => {
                setState(WalletCreateState.HIDDEN);
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const modalContentState = (state: WalletCreateState) => {
    switch (state) {
      case WalletCreateState.HIDDEN:
        return Hidden();
      case WalletCreateState.CREATE_VAULT:
        return CreateVault();
      case WalletCreateState.MIGRATE_VAULT:
        return MigrateVault();
      case WalletCreateState.MIGRATE_ONLY:
        return MigrateOnly();
      case WalletCreateState.WAIT_FOR_WALLET_CREATE:
        return WaitNewPositionCreate();
      case WalletCreateState.CHECKING_ALLOWANCES:
        return CheckingAllowances();
      case WalletCreateState.CONFIRM_APPROVE:
        return WaitApproveConfirm();
      case WalletCreateState.WAIT_FOR_APPROVE:
        return WaitForTokensApprove();
      case WalletCreateState.WAIT_FOR_WALLET_MIGRATE:
        return WaitForCompoundMigrate();
      case WalletCreateState.MIGRATE_NOTHING:
        return Message(t("wallet-create-modal.nothing-to-migrate"));
      case WalletCreateState.CREATE_SUCCESS:
        return NewPositionSuccess();
      case WalletCreateState.MIGRATE_SUCCESS:
        return MigrateSuccess();
      case WalletCreateState.MIGRATE_FAIL:
        return Message(t("wallet-create-modal.migrate-fail"));
      case WalletCreateState.CANCEL_CREATE:
        return Message(t("wallet-create-modal.create-cancel"));
      case WalletCreateState.CREATE_FAIL:
        return Message(t("wallet-create-modal.create-fail"));
      default:
        throw new TypeError(`non-exhaustive pattern: ${state}`);
    }
  };

  return (
    <Dialog
      onClose={() => {
        onClose();
        setState(WalletCreateState.HIDDEN);
      }}
      open={state !== WalletCreateState.HIDDEN}
      classes={{ paper: c.root }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: { zIndex: -3 },
        timeout: 500,
      }}
    >
      <div className={c.modal}>
        <StyledModal>
          <Grid container className={c.modalContent}>
            {modalContentState(state)}
          </Grid>
        </StyledModal>
      </div>
    </Dialog>
  );
}

export default TransferModal;
