import React from "react";
import { Web3ReactProvider, createWeb3ReactRoot } from "@web3-react/core";
import Web3 from "web3";

const getLibrary = (provider: any) => {
  const options = {
    transactionConfirmationBlocks: 2,
  };
  const library = new Web3(provider);
  return library;
};

interface Web3ProviderProps {
  children: React.ReactNode;
}
export const INFURA = "infura";

const Web3InfuraProvider = createWeb3ReactRoot(INFURA);

export const Web3ProviderNetwork: React.FC<Web3ProviderProps> = ({
  children,
}: Web3ProviderProps) => {
  return <Web3InfuraProvider getLibrary={getLibrary}>{children}</Web3InfuraProvider>;
};

export const Web3Provider: React.FC<Web3ProviderProps> = ({ children }: Web3ProviderProps) => {
  return <Web3ReactProvider getLibrary={getLibrary}>{children}</Web3ReactProvider>;
};
