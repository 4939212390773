// Enumerate all of the environments.
enum ENV {
  STAGING,
  PRODUCTION,
}

// Define all of the configurations for all of the environments. All global
// constants that change based on the environment should be defined here.
export const Configurations = {
  [ENV.STAGING]: {
    //SUBGRAPHS
    THEGRAPH_KOVAN_URL: "https://api.thegraph.com/subgraphs/name/keeperdao/keeperdao-staging-kovan",
    THEGRAPH_MAINNET_URL: "https://api.thegraph.com/subgraphs/name/keeperdao/keeperdao-staging",
    //rewards
    ROOK_REWARDS_PER_BLOCK: 0.00655364964,

    //indibo mainnet
    INDIBO_BALANCE_URL: "https://indibo-lp.herokuapp.com/balance_of/", // {contract}/{address}
    INDIBO_KTOKENS_SUPLY_URL: "https://indibo-lp.herokuapp.com/total_supply/", // {contract}

    //Single endpoint for all rewards
    INDIBO_ALL_REWARDS_URL: "https://indibo.herokuapp.com/rewards", //{address}

    // DEPRACATED -------------------------------------------

    //KEEPER REWARD MAINNET
    INDIBO_KEEPER_REWARD_URL: "https://indibo-keeper.herokuapp.com/reward_of_keeper/", // /{address}

    //LP REWARDS MAINNET
    INDIBO_PRE_LIQUIDITY_PROVIDER_REWARD_URL:
      "https://indibo-lp-pre.herokuapp.com/reward_of_liquidity_provider/", // /{address}
    INDIBO_LIQUIDITY_PROVIDER_REWARD_URL:
      "https://indibo-lp.herokuapp.com/reward_of_liquidity_provider/", // {contract}/{address}
    INDIBO_LP_Q2_REWARD_URL: "https://indibo-lpq2.herokuapp.com/reward_of_liquidity_provider/", // /{address}
    INDIBO_LP_Q3_REWARD_URL: "https://indibo-lpq3.herokuapp.com/reward_of_liquidity_provider/", // /{address}

    //TRADE REWARDS MAINNET
    INDIBO_TRADE_REWARD_URL: "https://indibo-hiding.herokuapp.com/reward_of_hiding_game/", // /{address}
    INDIBO_TRADE_REWARD2_URL: "https://indibo-hiding2.herokuapp.com/reward_of_hiding_game/", // /{address}
    INDIBO_TRADE_REWARD3_URL: "https://indibo-hiding3.herokuapp.com/reward_of_hiding_game/", // /{address}
    INDIBO_TRADE_REWARD4_URL: "https://indibo-hiding4.herokuapp.com/reward_of_hiding_game/", // /{address}

    // -------------------------------------------------------

    INDIBO_VAULT_REWARD_URL: "https://indibo-lender.herokuapp.com/reward_of_lender/", // /{address},
    INDIBO_VAULT_REWARD2_URL: "https://indibo-lender2.herokuapp.com/reward_of_lender/", // /{address},
    INDIBO_VAULT_REWARD3_URL: "https://indibo-lender3.herokuapp.com/reward_of_lender/", // /{address},
    INDIBO_VAULT_REWARD4_URL: "https://indibo-lender4.herokuapp.com/reward_of_lender/", // /{address},

    // indibo kovan
    INDIBO_KOVAN_BALANCE_URL: "https://indibo-lp-kovan.herokuapp.com/balance_of/", // {contract}/{address}
    INDIBO_KOVAN_KTOKENS_SUPLY_URL: "https://indibo-lp-kovan.herokuapp.com/total_supply/", // {contract}

    //KEEPER REWARD KOVAN
    INDIBO_KOVAN_KEEPER_REWARD_URL: "https://indibo-keeper-kovan.herokuapp.com/reward_of_keeper/", // /{address}

    //LP REWARDS KOVAN
    INDIBO_KOVAN_PRE_LIQUIDITY_PROVIDER_REWARD_URL:
      "https://indibo-lp-kovan-pre.herokuapp.com/reward_of_liquidity_provider/", // /{address}
    INDIBO_KOVAN_LIQUIDITY_PROVIDER_REWARD_URL:
      "https://indibo-lp-kovan.herokuapp.com/reward_of_liquidity_provider/", // /{address}
    INDIBO_KOVAN_LP_Q2_REWARD_URL:
      "https://indibo-lpq2.herokuapp.com/reward_of_liquidity_provider/", // /{address}
    INDIBO_KOVAN_LP_Q3_REWARD_URL:
      "https://indibo-lpq3.herokuapp.com/reward_of_liquidity_provider/", // /{address}
    INDIBO_KOVAN_TRADE_REWARD_URL: "https://indibo-hiding.herokuapp.com/reward_of_hiding_game/", // /{address}
    INDIBO_KOVAN_TRADE_REWARD2_URL: "https://indibo-hiding2.herokuapp.com/reward_of_hiding_game/", // /{address}
    INDIBO_KOVAN_TRADE_REWARD3_URL: "https://indibo-hiding3.herokuapp.com/reward_of_hiding_game/", // /{address}
    INDIBO_KOVAN_TRADE_REWARD4_URL: "https://indibo-hiding4.herokuapp.com/reward_of_hiding_game/", // /{address}

    // uniswap v2 mainnet subgraph
    UNISWAP_MAINNET_URL: "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2",

    // compound v2 mainnet subgraph
    COMPOUND_MAINNET_URL: "https://api.thegraph.com/subgraphs/name/graphprotocol/compound-v2",
    // connectors keys
    INFURA_KEY: "c21bb411d30b4c5f888c8117aa8fde1f",
    INFURA_KEY_DEV: "4bf032f2d38a4ed6bb975b80d6340847",
    FORTMATIC_KEY: "pk_live_5671CAF2B511B5F2",
    PORTIS_ID: "2ef8a729-6d65-4a26-bb4a-d68354043713",

    // 0x
    ZEROX_API: "https://api.0x.org/",
    ZEROX_API_KOVAN: "https://kovan.api.0x.org/",
    // google analytics
    GOOGLE_ANALYTICS_ID: "UA-181645015-1",
    MAINNET_DEPOSIT_FEE: 0.0064,
    ROOK_REWARD_END_BLOCK: 11780885,

    // HidingBook API
    HIDING_BOOK_URL: "https://hidingbook.keeperdao.com/api/v1", //'https://higapro.herokuapp.com/api/v1', //,

    // Etherscan API
    ETHPLORER_URL: "https://api.ethplorer.io",
  },
  [ENV.PRODUCTION]: {
    //SUBGRAPHS
    THEGRAPH_KOVAN_URL: "https://api.thegraph.com/subgraphs/name/keeperdao/keeperdao-staging-kovan",
    THEGRAPH_MAINNET_URL: "https://api.thegraph.com/subgraphs/name/keeperdao/keeperdao-staging",
    //rewards
    ROOK_REWARDS_PER_BLOCK: 0.00655364964,
    //indibo mainnet
    INDIBO_BALANCE_URL: "https://indibo-lp.herokuapp.com/balance_of/", // {contract}/{address}
    INDIBO_KTOKENS_SUPLY_URL: "https://indibo-lp.herokuapp.com/total_supply/", // {contract}

    //Single endpoint for all rewards
    INDIBO_ALL_REWARDS_URL: "https://indibo.herokuapp.com/rewards", //{address}

    //KEEPER REWARD MAINNET
    INDIBO_KEEPER_REWARD_URL: "https://indibo-keeper.herokuapp.com/reward_of_keeper/", // /{address}

    //LP REWARDS MAINNET
    INDIBO_PRE_LIQUIDITY_PROVIDER_REWARD_URL:
      "https://indibo-lp-pre.herokuapp.com/reward_of_liquidity_provider/", // /{address}
    INDIBO_LIQUIDITY_PROVIDER_REWARD_URL:
      "https://indibo-lp.herokuapp.com/reward_of_liquidity_provider/", // {contract}/{address}
    INDIBO_LP_Q2_REWARD_URL: "https://indibo-lpq2.herokuapp.com/reward_of_liquidity_provider/", // /{address}
    INDIBO_LP_Q3_REWARD_URL: "https://indibo-lpq3.herokuapp.com/reward_of_liquidity_provider/", // /{address}

    //TRADE REWARDS MAINNET
    INDIBO_TRADE_REWARD_URL: "https://indibo-hiding.herokuapp.com/reward_of_hiding_game/", // /{address}
    INDIBO_TRADE_REWARD2_URL: "https://indibo-hiding2.herokuapp.com/reward_of_hiding_game/", // /{address}
    INDIBO_TRADE_REWARD3_URL: "https://indibo-hiding3.herokuapp.com/reward_of_hiding_game/", // /{address}
    INDIBO_TRADE_REWARD4_URL: "https://indibo-hiding4.herokuapp.com/reward_of_hiding_game/", // /{address}
    INDIBO_VAULT_REWARD_URL: "https://indibo-lender.herokuapp.com/reward_of_lender/", // /{address},
    INDIBO_VAULT_REWARD2_URL: "https://indibo-lender2.herokuapp.com/reward_of_lender/", // /{address},
    INDIBO_VAULT_REWARD3_URL: "https://indibo-lender3.herokuapp.com/reward_of_lender/", // /{address},
    INDIBO_VAULT_REWARD4_URL: "https://indibo-lender4.herokuapp.com/reward_of_lender/", // /{address},

    // indibo kovan
    INDIBO_KOVAN_BALANCE_URL: "https://indibo-lp-kovan.herokuapp.com/balance_of/", // {contract}/{address}
    INDIBO_KOVAN_KTOKENS_SUPLY_URL: "https://indibo-lp-kovan.herokuapp.com/total_supply/", // {contract}

    //KEEPER REWARD KOVAN
    INDIBO_KOVAN_KEEPER_REWARD_URL: "https://indibo-keeper-kovan.herokuapp.com/reward_of_keeper/", // /{address}

    //LP REWARDS KOVAN
    INDIBO_KOVAN_PRE_LIQUIDITY_PROVIDER_REWARD_URL:
      "https://indibo-lp-kovan-pre.herokuapp.com/reward_of_liquidity_provider/", // /{address}
    INDIBO_KOVAN_LIQUIDITY_PROVIDER_REWARD_URL:
      "https://indibo-lp-kovan.herokuapp.com/reward_of_liquidity_provider/", // /{address}
    INDIBO_KOVAN_LP_Q2_REWARD_URL:
      "https://indibo-lpq2.herokuapp.com/reward_of_liquidity_provider/", // /{address}
    INDIBO_KOVAN_LP_Q3_REWARD_URL:
      "https://indibo-lpq3.herokuapp.com/reward_of_liquidity_provider/", // /{address}
    INDIBO_KOVAN_TRADE_REWARD_URL: "https://indibo-hiding.herokuapp.com/reward_of_hiding_game/", // /{address}
    INDIBO_KOVAN_TRADE_REWARD2_URL: "https://indibo-hiding2.herokuapp.com/reward_of_hiding_game/", // /{address}
    INDIBO_KOVAN_TRADE_REWARD3_URL: "https://indibo-hiding3.herokuapp.com/reward_of_hiding_game/", // /{address}
    INDIBO_KOVAN_TRADE_REWARD4_URL: "https://indibo-hiding4.herokuapp.com/reward_of_hiding_game/", // /{address}

    // uniswap v2 mainnet subgraph
    UNISWAP_MAINNET_URL: "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2",
    // compound v2 mainnet subgraph
    COMPOUND_MAINNET_URL: "https://api.thegraph.com/subgraphs/name/graphprotocol/compound-v2",

    // connectors keys
    INFURA_KEY: "c21bb411d30b4c5f888c8117aa8fde1f",
    INFURA_KEY_DEV: "4bf032f2d38a4ed6bb975b80d6340847",
    FORTMATIC_KEY: "pk_live_5671CAF2B511B5F2",
    PORTIS_ID: "2ef8a729-6d65-4a26-bb4a-d68354043713",

    // 0x
    ZEROX_API: "https://api.0x.org/",
    ZEROX_API_KOVAN: "https://kovan.api.0x.org/",
    // google analytics
    GOOGLE_ANALYTICS_ID: "UA-181645015-1",
    MAINNET_DEPOSIT_FEE: 0.0064,
    ROOK_REWARD_END_BLOCK: 11780885,

    // HidingBook API
    HIDING_BOOK_URL: "https://hidingbook.keeperdao.com/api/v1", //'https://higapro.herokuapp.com/api/v1', //,

    // Etherscan API
    ETHPLORER_URL: "https://api.ethplorer.io",
  },
};

// Do not modify this line. It is replaced at compile-time to be set to the
// appropriate environment.
export const Environment = ENV.PRODUCTION;

// Export the configuration for the current environment.
export default Configurations[Environment];
