import {
  Backdrop,
  Button,
  Dialog,
  Divider,
  Grid,
  Input,
  InputAdornment,
  LinearProgress,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MoonLoader from "react-spinners/MoonLoader";
import Balances from "../../../containers/balances";
import { SupplyState, SupplyWithdraw, WithdrawState } from "../../../containers/borrow";
import { MarketSupplyInfo } from "../../../lib/compound";
import { contract, ContractType, ERC20Contract } from "../../../lib/contracts";
import { networkIdFromChainId } from "../../../lib/network";
import TokenLogo from "../../TokenLogo";
import { GradientBox } from "../../ui-components/StyledBox";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "none",
    border: "none",
    position: "absolute",
  },
  modal: {
    position: "relative",
    display: "flex",
    flex: "1 1 0",
    width: "375px",
    maxHeight: "625px",
  },
  modalContent: {
    display: "flex",
    flex: "1 1 0",
    position: "relative",
    height: "100%",
    width: "100%",
    padding: "24px",
    justifyContent: "flex-start",
    alignItems: "center",
    alignSelf: "flex-start",
    overflow: "auto",
  },
  modalHeader: {
    padding: "0px 24px",
  },
  modalMessage: {
    marginTop: "8px",
    alignItems: "center",
  },
  tabContainer: {
    display: "flex",
    position: "relative",
    marginTop: "16px",
    justifyContent: "center",
  },
  inputContainer: {
    display: "flex",
    position: "relative",
    backgroundColor: "rgba(245,245,245,0.08)",
    padding: "24px",
    borderRadius: "16px",
  },
  inputHeader: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
  },
  inputField: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
  },
  supplyContent: {
    display: "flex",
    position: "relative",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px",
    marginTop: "16px",
  },
  supplyLimits: {
    marginTop: "20px",
    display: "flex",
    position: "relative",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px",
  },
  yesNoContainer: {
    display: "flex",
    position: "relative",
    width: "100%",
    justifyContent: "space-between",
  },
  button: {
    borderRadius: "8px",
  },
  loadingContainer: {
    display: "flex",
    position: "relative",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  divider: {
    position: "relative",
    width: "100%",
    background: "linear-gradient(to right, #6C46D6 0%, #09A7F3 100%)",
    opacity: "0.24",
    marginTop: "8px",
    marginBottom: "8px",
  },
  cancelButton: {
    borderRadius: "8px",
    width: "100%",
    height: "100%",
  },
  submitButton: {
    borderRadius: "8px",
    width: "100%",
    height: "100%",
  },
  maxButton: {
    color: theme.palette.text.primary,
    padding: 2,
    margin: 0,
    border: "1px solid",
    textTransform: "none",
    textSize: "10px",
    borderColor: "rgba(245,245,245,0.5)",
    justifySelf: "right",
  },
  limitBar: {
    backgroundColor: "#09A7F3",
  },
  tabItemRoot: {
    "&:hover": {
      opacity: 1,
    },
    minHeight: 44,
    minWidth: 96,
    [theme.breakpoints.up("md")]: {
      minWidth: 120,
    },
  },
  tabItemWrapper: {
    // zIndex: 2,
    // marginTop: spacing(0.5),
    color: theme.palette.text.primary,
    textTransform: "initial",
  },
  tabRoot: {
    backgroundColor: "rgba(245, 245, 245, 0.08)",
    borderRadius: 16,
    minHeight: 44,
  },
  tabFlexContainer: {
    display: "inline-flex",
    position: "relative",
    zIndex: 1,
  },
  tabScroller: {
    [theme.breakpoints.up("md")]: {
      padding: "0px 8px",
    },
  },
  tabIndicator: {
    top: 3,
    bottom: 3,
    right: 3,
    height: "auto",
    background: "none",
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 4,
      left: 4,
      right: 4,
      bottom: 4,
      borderRadius: 8,
      backgroundColor: theme.palette.action.hover,
      boxShadow: "0 4px 12px 0 rgba(0,0,0,0.16)",
    },
  },
}));

function useSupplyWithdrawModal() {
  // State variables use for inputting the deposit amount. We need an explicit
  // state variable because we need to do bignumber/decimal/string
  // conversions, and haveing an explicit state variable makes this much
  // easier.
  const [supplyInput, setSupplyInput] = useState("");
  const [withdrawInput, setWithdrawInput] = useState("");

  // The Wallet is needed so that we can sign and submit transactions to
  // Ethereum.
  const wallet = useWeb3React();

  // Balances is needed so that we know the maximum amount that can be
  // deposited. There is no point letting the user attempt to deposit more
  // than the maximum, since it would just revert.
  const { compoundBalances } = Balances.useContainer();
  //const exchangeRates = ExchangeRates.useContainer();

  //console.log('COMPOUND DATA', compoundTokenInfo, compoundData);

  const { t } = useTranslation();

  // The depositor is needed so that we can know the current state of the
  // depositor.
  const supplier = SupplyWithdraw.useContainer();
  const token = supplier.token;

  //update input on modal state change
  useEffect(() => {
    setSupplyInput("");
  }, [supplier.supplyState]);

  useEffect(() => {
    setWithdrawInput("");
  }, [supplier.withdrawState]);

  function calculateNewLimit(supply: boolean, supplyData: MarketSupplyInfo | undefined) {
    if (!supplier.vaultBalancesUSD || !supplier.vaultBalances || !supplyData)
      return { oldLimit: "0", oldBorrow: "0", newLimit: "0", newBorrow: "0" };
    const currentLimit = supplier.vaultBalancesUSD.limitUSD;
    const currentLeftToBorrow = supplier.vaultBalancesUSD.leftToBorrowUSD;

    if (supply && supplyInput) {
      const price = supplyData.priceUSD;
      const collateralFactor = supplyData.collateralFactor;
      const decimalScaling = new BigNumber(10).pow(supplier.token.underlyingToken.decimals);
      const limitToAdd = supplier.supplyAmount
        ? supplier.supplyAmount
            .dividedBy(decimalScaling)
            .multipliedBy(price)
            .multipliedBy(collateralFactor)
        : new BigNumber(0);
      const newLimit = currentLimit.plus(limitToAdd).toFixed(2);
      const newBorrow = currentLeftToBorrow.plus(limitToAdd).toFixed(2);
      return {
        oldLimit: currentLimit.toFixed(2),
        oldBorrow: currentLeftToBorrow.toFixed(2),
        newLimit: newLimit,
        newBorrow: newBorrow,
      };
    }

    if (
      !supply &&
      withdrawInput &&
      supplier.vaultBalances.assetsIn.includes(supplier.token.cToken.address)
    ) {
      const price = supplyData.priceUSD;
      const collateralFactor = supplyData.collateralFactor;
      const decimalScaling = new BigNumber(10).pow(supplier.token.underlyingToken.decimals);
      const limitToAdd = supplier.withdrawAmount
        ? supplier.withdrawAmount
            .dividedBy(decimalScaling)
            .multipliedBy(price)
            .multipliedBy(collateralFactor)
        : new BigNumber(0);
      const newLimit = currentLimit.minus(limitToAdd).toFixed(2);
      const newBorrow = currentLeftToBorrow.minus(limitToAdd).toFixed(2);
      return {
        oldLimit: currentLimit.toFixed(2),
        oldBorrow: currentLeftToBorrow.toFixed(2),
        newLimit: newLimit,
        newBorrow: newBorrow,
      };
    }

    return {
      oldLimit: currentLimit.toFixed(2),
      oldBorrow: currentLeftToBorrow.toFixed(2),
      newLimit: currentLimit.toFixed(2),
      newBorrow: currentLeftToBorrow.toFixed(2),
    };
  }

  async function deposit() {
    supplier.setSupplyState(SupplyState.WAIT_FOR_SUPPLY);
    if (!wallet.library || !wallet.chainId || !wallet.account) {
      return;
    }
    const web3 = wallet.library;
    const networkID = networkIdFromChainId(wallet.chainId);
    const kCompoundContract = contract(
      web3,
      networkID,
      ContractType.KCompound,
      wallet.account,
      supplier.positionAddress
    );
    const amount = supplier.supplyAmount || new BigNumber(0);
    const value = token.underlyingToken.name === "ETH" ? amount : new BigNumber(0);
    try {
      //await kCompoundContract.methods.compound_enterMarkets([token.cToken.address]).call();
      await kCompoundContract.methods
        .compound_deposit(token.cToken.address, amount.toFixed(0))
        .send({ from: wallet.account, value: value })
        .on("transactionHash", (hash) => {
          // console.log('Tx hash came => ', hash);
        })
        .on("receipt", async (receipt) => {
          // console.log('Tx receipt came => ', receipt);
          supplier.setSupplyState(SupplyState.SUCCESS);
        })
        .on("error", (error, receipt) => {
          supplier.setSupplyState(SupplyState.CANCEL_SUPPLY);
          return;
        });
    } catch (err) {
      console.error(`cannot deposit: ${err}`);
      supplier.setSupplyState(SupplyState.CANCEL_SUPPLY);
      return;
    }
  }

  async function withdraw() {
    supplier.setWithdrawState(WithdrawState.WAIT_FOR_WITHDRAW);
    if (!wallet.library || !wallet.chainId || !wallet.account || !supplier.withdrawAmount) {
      return;
    }
    const web3 = wallet.library;
    const networkID = networkIdFromChainId(wallet.chainId);
    const positionContract = contract(
      web3,
      networkID,
      ContractType.KCompound,
      wallet.account,
      supplier.positionAddress
    );
    const amount = supplier.withdrawAmount || new BigNumber(0);
    try {
      await positionContract.methods
        .compound_withdraw(wallet.account, token.cToken.address, amount.toFixed(0))
        .send({ from: wallet.account })
        .on("transactionHash", (hash) => {
          // console.log('Tx hash came => ', hash);
        })
        .on("receipt", async (receipt) => {
          // console.log('Tx receipt came => ', receipt);
          supplier.setWithdrawState(WithdrawState.SUCCESS);
        })
        .on("error", (error, receipt) => {
          supplier.setWithdrawState(WithdrawState.CANCEL_WITHDRAW);
          return;
        });
    } catch (err) {
      console.error(`cannot withdraw: ${err}`);
      supplier.setWithdrawState(WithdrawState.CANCEL_WITHDRAW);
      return;
    }
  }

  return {
    supplyInput,
    setSupplyInput,
    withdrawInput,
    setWithdrawInput,
    ...wallet,
    supplier,
    t,
    deposit,
    withdraw,
    compoundBalances,
    token,
    calculateNewLimit,
  };
}

function Hidden() {
  // It is important that this component is explicitly rendered when the modal
  // is hidden, otherwise the order of the "useState" calls will change when
  // the model opens/closes, and this will cause all sorts of undefined
  // behaviour!
  const _ = useSupplyWithdrawModal();
  return null;
}

function SetUnlimtedApproval() {
  const supplyModal = useSupplyWithdrawModal();
  const c = useStyles();

  async function depositWithApproval(unlimited = false) {
    supplyModal.supplier.setSupplyState(SupplyState.WAIT_FOR_APPROVE);

    if (!supplyModal.library || !supplyModal.chainId || !supplyModal.account) {
      return;
    }
    const web3 = supplyModal.library;
    const networkID = networkIdFromChainId(supplyModal.chainId);
    const underlyingTokenContract = ERC20Contract(
      web3,
      networkID,
      supplyModal.token.underlyingToken.address,
      supplyModal.account
    );

    const amount = supplyModal.supplier.supplyAmount
      ? new BigNumber(supplyModal.supplier.supplyAmount)
      : new BigNumber(0);

    const maxAllowance = unlimited ? new BigNumber(2).pow(256).minus(1) : amount;

    try {
      if (!maxAllowance || maxAllowance.isNaN()) {
        supplyModal.supplier.setSupplyState(SupplyState.CANCEL_APPROVE);
        return;
      }
      await underlyingTokenContract.methods
        .approve(supplyModal.supplier.positionAddress, maxAllowance.toFixed(0))
        .send({ from: supplyModal.account })
        .on("transactionHash", (hash) => {
          // console.log('Tx hash came => ', hash);
        })
        .on("receipt", async (receipt) => {
          console.log("Tx receipt came => ", receipt);
          await supplyModal.deposit();
        })
        .on("error", () => {
          supplyModal.supplier.setSupplyState(SupplyState.CANCEL_APPROVE);
          return;
        });
    } catch (err) {
      console.error(`cannot approve: ${err}`);
      supplyModal.supplier.setSupplyState(SupplyState.CANCEL_APPROVE);
      return;
    }
  }

  return (
    <Grid container item className={c.supplyContent} direction="column">
      <Grid container item>
        <Typography variant="h4">
          {supplyModal.t("deposit-modal.unlimited-approval-header")}
        </Typography>
      </Grid>
      <Grid container item>
        <Typography variant="body2">
          {supplyModal.t("deposit-modal.unlimited-approval-popup", {
            underlyingTokenSymbol: supplyModal.token.underlyingToken.name,
          })}
        </Typography>
      </Grid>
      <Grid container item className={c.yesNoContainer}>
        <Grid item style={{ width: "48%" }}>
          <Button
            fullWidth
            variant="outlined"
            className={c.button}
            onClick={async () => await depositWithApproval()}
          >
            No
          </Button>
        </Grid>
        <Grid item style={{ width: "48%" }}>
          <Button
            fullWidth
            variant="contained"
            className={c.button}
            onClick={async () => await depositWithApproval(true)}
          >
            Approve
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

function WithdrawWaitForInput() {
  const {
    token,
    supplier,
    t,
    withdrawInput,
    account,
    library,
    chainId,
    setWithdrawInput,
    compoundBalances,
    withdraw,
    calculateNewLimit,
  } = useSupplyWithdrawModal();
  const c = useStyles();

  const decimalScaling = new BigNumber(10).pow(token.underlyingToken.decimals);

  const [suppliedBalance, setSuppliedBalance] = useState(new BigNumber(0));
  const [isUnderwritten, setUnderwritten] = useState(false);

  useEffect(() => {
    if (supplier.vaultBalances) {
      setUnderwritten(supplier.vaultBalances.isUnderwritten);
      const supplyTokenPair = supplier.vaultBalances.suppliedTokens.find(
        (x) => x.underlyingToken.name === token.underlyingToken.name
      );
      if (supplyTokenPair)
        setSuppliedBalance(new BigNumber(supplyTokenPair.underlyingToken.balance)); //.multipliedBy(decimalScaling));
    }
  }, [supplier.vaultBalances]);

  // // If the input entered is empty,zero or more than the maximum deposit amount,
  // // then disable the deposit button. There is no point letting the user
  // // attempt a transaction that we know will revert.
  const disabled =
    !withdrawInput ||
    !(parseFloat(withdrawInput) > 0) ||
    (suppliedBalance ? supplier.withdrawAmount?.gt(suppliedBalance) : false) ||
    isUnderwritten;

  // // Decimal scaling is used to scale between the string based amount (input
  // // by the user) and the shared state value (a big number with no decimals
  // // that will be handed to the contracts).

  const supplyData = supplier.supplyData;
  const tokenData = supplyData.find((x) => x.underlyingSymbol === token.underlyingToken.name);
  const tokenRateUSD = tokenData ? new BigNumber(tokenData.priceUSD) : new BigNumber(0);
  const tokenRateString =
    supplier.withdrawAmount && withdrawInput
      ? `≈ $${tokenRateUSD
          .multipliedBy(supplier.withdrawAmount.dividedBy(decimalScaling))
          .toFixed(2)}`
      : "";

  const tokenRateETH = tokenData ? new BigNumber(tokenData.priceETH) : new BigNumber(0);

  const [leftToWithdraw, setLeftToWithdraw] = useState(new BigNumber(0));
  useEffect(() => {
    if (!supplier.vaultBalances || !supplier.vaultBalancesUSD || !tokenData) return;

    const suppliedBalanceETH = suppliedBalance.dividedBy(decimalScaling).multipliedBy(tokenRateETH);

    console.log(
      suppliedBalanceETH.multipliedBy(tokenData.collateralFactor).toString(),
      !supplier.vaultBalances.assetsIn.includes(token.cToken.address.toLowerCase()),
      supplier.vaultBalancesUSD.leftToBorrowETH.gt(
        suppliedBalanceETH.multipliedBy(tokenData.collateralFactor)
      ),
      supplier.vaultBalancesUSD.leftToBorrowETH
        .dividedBy(tokenData.collateralFactor)
        .dividedBy(tokenRateETH)
        .toString(),
      suppliedBalance.toString()
    );

    if (
      !supplier.vaultBalances.assetsIn.includes(token.cToken.address.toLowerCase()) ||
      supplier.vaultBalancesUSD.leftToBorrowETH.gt(
        suppliedBalanceETH.multipliedBy(tokenData.collateralFactor)
      )
    ) {
      setLeftToWithdraw(suppliedBalance);
    } else {
      setLeftToWithdraw(
        supplier.vaultBalancesUSD.leftToBorrowETH
          .dividedBy(tokenData.collateralFactor)
          .dividedBy(tokenRateETH)
          .multipliedBy(decimalScaling)
          .multipliedBy(0.999999)
      );
    }
  }, [supplier.vaultBalances, supplier.vaultBalancesUSD, suppliedBalance]);

  const [limits, setLimits] = useState({
    oldLimit: "0",
    oldBorrow: "0",
    newLimit: "0",
    newBorrow: "0",
  });
  useEffect(() => {
    const newLimits = calculateNewLimit(false, tokenData);
    setLimits(newLimits);
  }, [supplier.supplyData, supplier.vaultBalancesUSD, supplier.withdrawAmount]);

  return (
    <Grid container item className={c.supplyContent} direction="column">
      <Grid container item className={c.inputContainer}>
        <Grid container item className={c.inputHeader} direction="row">
          <Typography variant="body2">Withdrawing {token.underlyingToken.name}</Typography>
          <Typography variant="body2">{tokenRateString}</Typography>
        </Grid>
        <Grid container item className={c.inputField} direction="row">
          <Grid container item alignItems="center" xs={9}>
            <Input
              id="supply-input"
              placeholder="0"
              disableUnderline
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Inter",
                fontSize: "20px",
                fontWeight: "bold",
              }}
              onChange={(evt) => {
                // Remove spaces.
                let v = evt.currentTarget.value.replace(/\s/g, "");
                // Remove second occurrences of periods.
                v = v
                  .split("")
                  .filter((x, n, self) => self.indexOf(x) === n || x !== ".")
                  .join("");
                // Remove non-digits (unless it is a period).
                v = v.replace(/[^\d\.]/g, "");
                // Set the withdraw amount as a big number. (This is
                // the shared state value.)
                supplier.setWithdrawAmount(new BigNumber(v).multipliedBy(decimalScaling));
                // Set the amount as a string so that input is not
                // messed up when typing "." characters.
                setWithdrawInput(v);
              }}
              startAdornment={
                <InputAdornment position="start" style={{ alignItems: "center" }}>
                  <TokenLogo chainId={chainId} address={token.underlyingToken.address} size={28} />
                </InputAdornment>
              }
              value={withdrawInput}
            />
          </Grid>
          <Grid container item alignItems="center" xs={3}>
            <Button
              onClick={() => {
                // Set the withdraw amount as a big number. (This is the
                // shared state value.)
                supplier.setWithdrawAmount(leftToWithdraw);
                // Set the amount as a string so that input is not
                // messed up when typing "." characters.
                setWithdrawInput(leftToWithdraw.dividedBy(decimalScaling).toPrecision(9));
              }}
              classes={{ root: c.maxButton }}
            >
              Max
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item className={c.supplyLimits}>
        <Grid container item justifyContent="flex-start" style={{ marginBottom: "16px" }}>
          <Typography variant="body2" style={{ color: "#8F8F8F" }}>
            Supply Rates
          </Typography>
        </Grid>
        <Grid container item direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">Supply APY</Typography>
          <Typography variant="body1" style={{ color: "#8F8F8F" }}>
            {tokenData ? new BigNumber(tokenData.supplyRate).multipliedBy(100).toFixed(2) : ""}%
          </Typography>
        </Grid>
        <Grid
          container
          item
          style={{
            position: "relative",
            width: "100%",
            margin: "none",
          }}
        >
          <Divider className={c.divider} />
        </Grid>
        <Grid container item direction="row" justify="space-between" alignItems="center">
          <Typography variant="body1">{t("vault-comp-apy")}</Typography>
          <Typography variant="body1" style={{ color: "#8F8F8F" }}>
            {tokenData ? new BigNumber(tokenData.supplyCompAPY).toFixed(2) : ""}%
          </Typography>
        </Grid>
        <Grid
          container
          item
          justifyContent="flex-start"
          style={{ marginTop: "24px", marginBottom: "16px" }}
        >
          <Typography variant="body2" style={{ color: "#8F8F8F" }}>
            Limits
          </Typography>
        </Grid>
        <Grid container item direction="row" justify="space-between" alignItems="center">
          <Typography variant="body1">{t("vault-borrow-limit")}</Typography>
          <Typography variant="body1" style={{ color: "#8F8F8F" }}>
            ${limits.oldLimit} → ${limits.newLimit}
          </Typography>
        </Grid>
        <Grid
          container
          item
          style={{
            position: "relative",
            width: "100%",
            margin: "none",
          }}
        >
          <Divider className={c.divider} />
        </Grid>
        <Grid container item direction="row" justify="space-between" alignItems="center">
          <Typography variant="body1">{t("vault-left-to-borrow")}</Typography>
          <Typography variant="body1" style={{ color: "#8F8F8F" }}>
            ${limits.oldBorrow} → ${limits.newBorrow}
          </Typography>
        </Grid>
        <LinearProgress
          classes={{ barColorPrimary: c.limitBar }}
          style={{
            position: "relative",
            width: "100%",
            marginTop: "12px",
            marginBottom: "24px",
            borderRadius: "2px",
          }}
          variant="determinate"
          value={
            (100 * (parseFloat(limits.newLimit) - parseFloat(limits.newBorrow))) /
            parseFloat(limits.newLimit)
          }
        />
      </Grid>
      <Grid container item className={c.yesNoContainer}>
        <Grid item style={{ width: "48%" }}>
          <Button
            fullWidth
            variant="outlined"
            className={c.button}
            onClick={() => {
              supplier.setWithdrawState(WithdrawState.HIDDEN);
              supplier.setSupplyState(SupplyState.HIDDEN);
            }}
          >
            Go Back
          </Button>
        </Grid>
        <Grid item style={{ width: "48%" }}>
          <Button
            fullWidth
            variant="contained"
            disabled={disabled}
            className={c.button}
            onClick={async () => await withdraw()}
          >
            Withdraw
          </Button>
        </Grid>
      </Grid>
      {isUnderwritten ? (
        <Grid>
          <Typography variant="body2">Your position is currently underwritten by JITU</Typography>
        </Grid>
      ) : null}
    </Grid>
  );
}

function SupplyWaitForInput() {
  const {
    token,
    supplier,
    t,
    supplyInput,
    account,
    library,
    chainId,
    setSupplyInput,
    compoundBalances,
    deposit,
    calculateNewLimit,
  } = useSupplyWithdrawModal();
  const c = useStyles();

  // // Get the underlying balance of the current wallet.
  const underlyingTokenBalance = compoundBalances.get(token.underlyingToken.name);

  // // If the input entered is empty,zero or more than the maximum deposit amount,
  // // then disable the deposit button. There is no point letting the user
  // // attempt a transaction that we know will revert.
  const disabled =
    !supplyInput ||
    !(parseFloat(supplyInput) > 0) ||
    (underlyingTokenBalance ? supplier.supplyAmount?.gt(underlyingTokenBalance) : false);

  // // Decimal scaling is used to scale between the string based amount (input
  // // by the user) and the shared state value (a big number with no decimals
  // // that will be handed to the contracts).
  const decimalScaling = new BigNumber(10).pow(token.underlyingToken.decimals);

  const supplyData = supplier.supplyData;
  const tokenData = supplyData.find((x) => x.underlyingSymbol === token.underlyingToken.name);
  const tokenRate = tokenData ? new BigNumber(tokenData.priceUSD) : new BigNumber(0);
  const tokenRateString =
    supplier.supplyAmount && supplyInput
      ? `≈ $${tokenRate.multipliedBy(supplier.supplyAmount.dividedBy(decimalScaling)).toFixed(2)}`
      : "";

  const [limits, setLimits] = useState({
    oldLimit: "0",
    oldBorrow: "0",
    newLimit: "0",
    newBorrow: "0",
  });
  useEffect(() => {
    const newLimits = calculateNewLimit(true, tokenData);
    setLimits(newLimits);
  }, [supplier.supplyData, supplier.vaultBalancesUSD, supplier.supplyAmount]);

  async function checkApproval() {
    // ETH does not need to be approved before depositing.
    if (token.underlyingToken.name === "ETH") {
      await deposit();
      return;
    }
    if (!library || !chainId || !account) {
      return;
    }
    const web3 = library;
    const networkID = networkIdFromChainId(chainId);
    // const compoundGatewayAddress = contractAddress(networkID, ContractType.CompoundGateway);

    const underlyingTokenContract = ERC20Contract(
      web3,
      networkID,
      token.underlyingToken.address,
      account
    );
    const allowance = await underlyingTokenContract.methods
      .allowance(account, supplier.positionAddress)
      .call();
    const value = supplier.supplyAmount ? new BigNumber(supplier.supplyAmount) : new BigNumber(0);
    // console.log('ALLOWance =>', new BigNumber(allowance).toFormat(), value.toFormat());
    // if allowance is set to unlimited we skip to deposit
    if (new BigNumber(allowance).lt(value)) {
      //promt message to set allowance to unlimited
      supplier.setSupplyState(SupplyState.SET_UNLIMITED_APPROVAL);
    } else {
      await deposit();
      return;
    }
  }

  return (
    <Grid container item className={c.supplyContent} direction="column">
      <Grid container item className={c.inputContainer}>
        <Grid container item className={c.inputHeader} direction="row">
          <Typography variant="body2">Supplying {token.underlyingToken.name}</Typography>
          <Typography variant="body2">{tokenRateString}</Typography>
        </Grid>
        <Grid container item className={c.inputField} direction="row">
          <Grid container item alignItems="center" xs={9}>
            <Input
              id="supply-input"
              placeholder="0"
              disableUnderline
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Inter",
                fontSize: "20px",
                fontWeight: "bold",
              }}
              onChange={(evt) => {
                // Remove spaces.
                let v = evt.currentTarget.value.replace(/\s/g, "");
                // Remove second occurrences of periods.
                v = v
                  .split("")
                  .filter((x, n, self) => self.indexOf(x) === n || x !== ".")
                  .join("");
                // Remove non-digits (unless it is a period).
                v = v.replace(/[^\d\.]/g, "");
                // Set the withdraw amount as a big number. (This is
                // the shared state value.)
                supplier.setSupplyAmount(new BigNumber(v).multipliedBy(decimalScaling));
                // Set the amount as a string so that input is not
                // messed up when typing "." characters.
                setSupplyInput(v);
              }}
              startAdornment={
                <InputAdornment position="start" style={{ alignItems: "center" }}>
                  <TokenLogo chainId={chainId} address={token.underlyingToken.address} size={28} />
                </InputAdornment>
              }
              value={supplyInput}
            />
          </Grid>
          <Grid container item alignItems="center" xs={3}>
            <Button
              onClick={() => {
                // Set the withdraw amount as a big number. (This is the
                // shared state value.)
                supplier.setSupplyAmount(
                  underlyingTokenBalance ? underlyingTokenBalance : new BigNumber(0)
                );
                // Set the amount as a string so that input is not
                // messed up when typing "." characters.
                setSupplyInput(
                  underlyingTokenBalance
                    ? underlyingTokenBalance.dividedBy(decimalScaling).toFixed(6)
                    : "0"
                );
              }}
              classes={{ root: c.maxButton }}
            >
              Max
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item className={c.supplyLimits}>
        <Grid container item justifyContent="flex-start" style={{ marginBottom: "16px" }}>
          <Typography variant="body2" style={{ color: "#8F8F8F" }}>
            Supply Rates
          </Typography>
        </Grid>
        <Grid container item direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">Supply APY</Typography>
          <Typography variant="body1" style={{ color: "#8F8F8F" }}>
            {tokenData ? new BigNumber(tokenData.supplyRate).multipliedBy(100).toFixed(2) : ""}%
          </Typography>
        </Grid>
        <Grid
          container
          item
          style={{
            position: "relative",
            width: "100%",
            margin: "none",
          }}
        >
          <Divider className={c.divider} />
        </Grid>
        <Grid container item direction="row" justify="space-between" alignItems="center">
          <Typography variant="body1">{t("vault-comp-apy")}</Typography>
          <Typography variant="body1" style={{ color: "#8F8F8F" }}>
            {tokenData ? new BigNumber(tokenData.supplyCompAPY).toFixed(2) : ""}%
          </Typography>
        </Grid>
        <Grid
          container
          item
          justifyContent="flex-start"
          style={{ marginTop: "24px", marginBottom: "16px" }}
        >
          <Typography variant="body2" style={{ color: "#8F8F8F" }}>
            Limits
          </Typography>
        </Grid>
        <Grid container item direction="row" justify="space-between" alignItems="center">
          <Typography variant="body1">{t("vault-borrow-limit")}</Typography>
          <Typography variant="body1" style={{ color: "#8F8F8F" }}>
            ${limits.oldLimit} → ${limits.newLimit}
          </Typography>
        </Grid>
        <Grid
          container
          item
          style={{
            position: "relative",
            width: "100%",
            margin: "none",
          }}
        >
          <Divider className={c.divider} />
        </Grid>
        <Grid container item direction="row" justify="space-between" alignItems="center">
          <Typography variant="body1">{t("vault-left-to-borrow")}</Typography>
          <Typography variant="body1" style={{ color: "#8F8F8F" }}>
            ${limits.oldBorrow} → ${limits.newBorrow}
          </Typography>
        </Grid>
        <LinearProgress
          classes={{ barColorPrimary: c.limitBar }}
          style={{
            position: "relative",
            width: "100%",
            marginTop: "12px",
            marginBottom: "24px",
            borderRadius: "2px",
          }}
          variant="determinate"
          value={
            (100 * (parseFloat(limits.newLimit) - parseFloat(limits.newBorrow))) /
            parseFloat(limits.newLimit)
          }
        />
      </Grid>
      <Grid container item className={c.yesNoContainer}>
        <Grid item style={{ width: "48%" }}>
          <Button
            fullWidth
            variant="outlined"
            className={c.button}
            onClick={() => {
              supplier.setSupplyState(SupplyState.HIDDEN);
              supplier.setWithdrawState(WithdrawState.HIDDEN);
            }}
          >
            Go Back
          </Button>
        </Grid>
        <Grid item style={{ width: "48%" }}>
          <Button
            fullWidth
            variant="contained"
            disabled={disabled}
            className={c.button}
            onClick={async () => await checkApproval()}
          >
            Supply
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

function WaitForTx(props: { header: string; message: string }) {
  const c = useStyles();
  const { header, message } = props;

  return (
    <Grid container item className={c.supplyContent} direction="column" spacing={2}>
      <Grid container item justify="center">
        <Typography variant="h4">{header}</Typography>
      </Grid>
      <Grid container item justifyContent="center">
        <Typography variant="body1">{message}</Typography>
      </Grid>
      <Grid container item justifyContent="space-around">
        <MoonLoader color="#6D47D7" loading={true} size={50} />
      </Grid>
    </Grid>
  );
}

function Success(props: { message: string }) {
  const supplyModal = useSupplyWithdrawModal();
  const c = useStyles();
  const { message } = props;

  // Decimal scaling is used to scale between the string based amount (input
  // by the user) and the shared state value (a big number with no decimals
  // that will be handed to the contracts).
  const decimalScaling = new BigNumber(10).pow(supplyModal.token.underlyingToken.decimals);

  return (
    <Grid container item className={c.supplyContent} direction="column" spacing={2}>
      <Grid container item justifyContent="center">
        <Typography variant="body1">{message}</Typography>
      </Grid>
      <Grid container item justifyContent="space-around" xs={8}>
        <Button
          className={c.button}
          fullWidth
          variant="outlined"
          onClick={() => {
            supplyModal.setSupplyInput("");
            supplyModal.supplier.setSupplyAmount(null);
            supplyModal.supplier.setSupplyState(SupplyState.WAIT_FOR_INPUT);
            supplyModal.setWithdrawInput("");
            supplyModal.supplier.setWithdrawAmount(null);
            supplyModal.supplier.setWithdrawState(WithdrawState.WAIT_FOR_INPUT);
          }}
        >
          Go Back
        </Button>
      </Grid>
    </Grid>
  );
}

function Message(props: { message: string }) {
  const supplyModal = useSupplyWithdrawModal();
  const c = useStyles();
  const { message } = props;
  return (
    <Grid container item className={c.supplyContent} direction="column" spacing={2}>
      <Grid container item justifyContent="center">
        <Typography variant="body1">{message}</Typography>
      </Grid>
      <Grid container item justifyContent="space-around" xs={8}>
        <Button
          className={c.button}
          fullWidth
          variant="outlined"
          onClick={() => {
            supplyModal.setSupplyInput("");
            supplyModal.supplier.setSupplyAmount(null);
            supplyModal.supplier.setSupplyState(SupplyState.WAIT_FOR_INPUT);
            supplyModal.setWithdrawInput("");
            supplyModal.supplier.setWithdrawAmount(null);
            supplyModal.supplier.setWithdrawState(WithdrawState.WAIT_FOR_INPUT);
          }}
        >
          Go Back
        </Button>
      </Grid>
    </Grid>
  );
}

interface SupplyModalProps {
  onClose: () => void;
}

function KCompSupplyWithdrawModal(props: SupplyModalProps) {
  const c = useStyles();
  const { supplier, token, t } = useSupplyWithdrawModal();
  const { onClose } = props;

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, value: any) => {
    supplier.setWithdrawState(WithdrawState.WAIT_FOR_INPUT);
    supplier.setSupplyState(SupplyState.WAIT_FOR_INPUT);

    setTabIndex(value);
  };

  // Decimal scaling is used to scale between the string based amount (input
  // by the user) and the shared state value (a big number with no decimals
  // that will be handed to the contracts).
  const decimalScaling = new BigNumber(10).pow(token.underlyingToken.decimals);

  const withdrawState = (state: WithdrawState) => {
    switch (state) {
      case WithdrawState.HIDDEN:
        setTabIndex(0);
        return <Hidden />;
      case WithdrawState.WAIT_FOR_INPUT:
        return <WithdrawWaitForInput />;
      case WithdrawState.WAIT_FOR_WITHDRAW:
        return (
          <WaitForTx
            header={t("withdraws-modal.withdraw-header")}
            message={t("withdraws-modal.withdraw-wait", {
              amount: supplier.withdrawAmount?.dividedBy(decimalScaling).toFixed(6),
              token: token.underlyingToken.name,
            })}
          />
        );
      case WithdrawState.SUCCESS:
        return (
          <Success
            message={t("withdraws-modal.successful-withdraw", {
              amount: supplier.withdrawAmount?.dividedBy(decimalScaling).toFixed(6),
              token: token.underlyingToken.name,
            })}
          />
        );
      case WithdrawState.ERROR:
        return <Message message={t("withdraws-modal.withdraw-error")} />;
      case WithdrawState.CANCEL_APPROVE:
        return <Message message={t("withdraws-modal.withdraw-cancel")} />;
      case WithdrawState.CANCEL_WITHDRAW:
        return <Message message={t("withdraws-modal.withdraw-cancel")} />;
      default:
        throw new TypeError(`non-exhaustive pattern: ${state}`);
    }
  };

  const supplyState = (state: SupplyState) => {
    switch (state) {
      case SupplyState.HIDDEN:
        return <Hidden />;
      case SupplyState.WAIT_FOR_INPUT:
        return <SupplyWaitForInput />;
      case SupplyState.SET_UNLIMITED_APPROVAL:
        return <SetUnlimtedApproval />;
      case SupplyState.WAIT_FOR_APPROVE:
        return (
          <WaitForTx
            header={t("supply-modal.supply-header")}
            message={t("supply-modal.supply-wait-approval", {
              token: token.underlyingToken.name,
            })}
          />
        );
      case SupplyState.WAIT_FOR_SUPPLY:
        return (
          <WaitForTx
            header={t("supply-modal.supply-header")}
            message={t("supply-modal.supply-wait", {
              amount: supplier.supplyAmount?.dividedBy(decimalScaling).toFixed(6),
              token: token.underlyingToken.name,
            })}
          />
        );
      case SupplyState.SUCCESS:
        return (
          <Success
            message={t("supply-modal.successful-supply", {
              amount: supplier.supplyAmount?.dividedBy(decimalScaling).toFixed(6),
              token: token.underlyingToken.name,
            })}
          />
        );
      case SupplyState.ERROR:
        return <Message message={t("supply-modal.supply-error")} />;
      case SupplyState.CANCEL_APPROVE:
        return <Message message={t("supply-modal.supply-cancel")} />;
      case SupplyState.CANCEL_SUPPLY:
        return <Message message={t("supply-modal.supply-cancel")} />;
      default:
        throw new TypeError(`non-exhaustive pattern: ${state}`);
    }
  };

  return (
    <Dialog
      classes={{ paper: c.root }}
      open={
        supplier.supplyState !== SupplyState.HIDDEN ||
        supplier.withdrawState !== WithdrawState.HIDDEN
      }
      onClose={() => {
        onClose();
        setTabIndex(0);
        supplier.setSupplyState(SupplyState.HIDDEN);
        supplier.setWithdrawState(WithdrawState.HIDDEN);
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: { zIndex: -3 },
        timeout: 500,
      }}
    >
      <div className={c.modal}>
        <GradientBox>
          <Grid container className={c.modalContent} direction="column">
            <Grid container item className={c.tabContainer}>
              <Tabs
                classes={{
                  root: c.tabRoot,
                  indicator: c.tabIndicator,
                  flexContainer: c.tabFlexContainer,
                  scroller: c.tabScroller,
                }}
                value={tabIndex}
                onChange={handleTabChange}
              >
                <Tab
                  classes={{ root: c.tabItemRoot, wrapper: c.tabItemWrapper }}
                  disableRipple
                  label={"Supply"}
                />
                <Tab
                  classes={{ root: c.tabItemRoot, wrapper: c.tabItemWrapper }}
                  disableRipple
                  label={"Withdraw"}
                />
              </Tabs>
            </Grid>
            <Grid container className={c.modalMessage} item>
              {tabIndex === 0
                ? supplyState(supplier.supplyState)
                : withdrawState(supplier.withdrawState)}
            </Grid>
          </Grid>
        </GradientBox>
      </div>
    </Dialog>
  );
}

export default KCompSupplyWithdrawModal;
