import { useState, useEffect, useRef } from "react";
import { createContainer } from "unstated-next";
import { getHGTokenPrices, IExchangePrice } from "../lib/coingecko";
import BigNumber from "bignumber.js";

const EXCHANGE_DEFAULT: IExchangePrice<BigNumber> = {
  ETH: new BigNumber(0),
  WETH: new BigNumber(0),
  DAI: new BigNumber(0),
  USDC: new BigNumber(0),
  BTC: new BigNumber(0),
  ROOK: new BigNumber(0),
  COMP: new BigNumber(0),
  REP: new BigNumber(0),
  UNI: new BigNumber(0),
  BAT: new BigNumber(0),
  ZRX: new BigNumber(0),
  WBTC: new BigNumber(0),
  USDT: new BigNumber(0),
  KNC: new BigNumber(0),
  LINK: new BigNumber(0),
};

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

export function useRates() {
  const [rates, setRates] = useState(EXCHANGE_DEFAULT);

  const fetchHGTokenPrices = async () => {
    try {
      const exchangePrices = await getHGTokenPrices();
      setRates(exchangePrices);
    } catch (err) {
      if (process.env.NODE_ENV === "development") {
        console.log(err);
      }
      console.log("Unable to fetch exchange rates");
    }
  };

  // on initial mount
  useEffect(() => {
    fetchHGTokenPrices();
  }, []);

  // setInterval is buggy at times and useInterval is better over it. More at : https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  useInterval(() => {
    fetchHGTokenPrices();
  }, 60 * 1000);

  return rates;
}

const ExchangeRates = createContainer(useRates);

export default ExchangeRates;
