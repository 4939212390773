import { Signature } from "@0x/protocol-utils";
import BigNumber from "bignumber.js";

export enum TradeType {
  Limit = "Limit Order",
  Wrap = "Wrap",
  Unwrap = "Unwrap",
}

export enum OrderStatus {
  INVALID,
  FILLABLE,
  FILLED,
  CANCELLED,
  EXPIRED,
}

export enum OrderState {
  INVALID,
  INVALID_MAKER_ASSET_AMOUNT,
  INVALID_TAKER_ASSET_AMOUNT,
  FILLABLE,
  EXPIRED,
  FULLY_FILLED,
  CANCELLED,
}

export interface HidingBookOrder {
  signature: string;
  expirationTimeSeconds: string;
  makerAssetAmount: string;
  takerAssetAmount: string;
  makerFee: string;
  takerFee: string;
  salt: string;
  senderAddress: string;
  makerAddress: string;
  makerAssetData: string;
  takerAssetData: string;
  makerFeeAssetData: string;
  takerFeeAssetData: string;
  exchangeAddress: string;
  feeRecipientAddress: string;
  takerAddress: string;
  chainId: number;
}

export type HidingBookToken = {
  address: string;
  chainId: number;
  name: string;
  symbol: string;
  decimals: number;
  logoURI: string;
  balance?: BigNumber;
};

export type MakerOrder = {
  order: {
    chainId: number;
    expiry: number;
    maker: string;
    makerAmount: string;
    makerToken: string;
    pool: string;
    salt: string;
    signature: Signature;
    taker: string;
    takerAmount: string;
    takerToken: string;
    txOrigin: string;
    verifyingContract: string;
  };
  metaData: {
    creation: number;
    filledAmount_takerToken: number;
    orderHash: string;
    remainingFillableAmount_takerToken: number;
    status: OrderStatus;
  };
};

export interface ModalState {
  buy: boolean;
  sell: boolean;
  trade: boolean;
  hardCancelOrder: boolean;
  softCancelOrder: boolean;
}

export interface TradeState {
  tradeType: TradeType;
  sellToken: HidingBookToken;
  buyToken: HidingBookToken;
  sellAmount: string;
  buyAmount: string;
  expiry: number;
  isInversedRateDisplayed: boolean;
  exchangeRate: string;
  exchangeRateInversed: string;
  marketRate: string;
  marketRateInversed: string;
  marketRateNeedsFetching: boolean;
  isFetchingMarketRate: boolean;
}

export enum PriceType {
  spot,
  instant,
  manual,
}

export interface TradeReducerAction {
  type:
    | "sellTokenChange"
    | "buyTokenChange"
    | "changeSellAmount"
    | "changeBuyAmount"
    | "setLimitExpiry"
    | "swapSides"
    | "inverseRate"
    | "setExchangeRates"
    | "setLoadingRate";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}
