import { Backdrop, Button, Dialog, Grid, makeStyles, Typography } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MoonLoader from "react-spinners/MoonLoader";
import { PositionsState, UsdPositionState } from "../../../containers/borrow";
import { CompoundTokenInfo } from "../../../lib/compound";
import { contract, ContractType } from "../../../lib/contracts";
import { networkIdFromChainId } from "../../../lib/network";
import { GradientBox } from "../../ui-components/StyledBox";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "none",
    border: "none",
    position: "absolute",
  },
  modal: {
    position: "relative",
    display: "flex",
    flex: "1 1 0",
    width: "400px",
    minHeight: "325px",
  },
  modalContent: {
    display: "flex",
    flex: "1 1 0",
    position: "relative",
    height: "100%",
    width: "100%",
    padding: "24px",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-start",
    overflow: "auto",
  },
  createVaultContainer: {
    display: "flex",
    position: "relative",
    height: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
  },
  yesNoContainer: {
    display: "flex",
    position: "relative",
    width: "100%",
    justifyContent: "space-between",
  },
  button: {
    borderRadius: "8px",
  },
  loadingContainer: {
    display: "flex",
    position: "relative",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  divider: {
    background: "linear-gradient(91.6deg, #6D47D7 0%, #D7475A 100%)",
    opacity: "0.24",
    marginTop: "8px",
    marginBottom: "8px",
  },
  cancelButton: {
    borderRadius: "8px",
    width: "100%",
    height: "100%",
  },
  submitButton: {
    borderRadius: "8px",
    width: "100%",
    height: "100%",
  },
}));

export enum CollateralModalState {
  HIDDEN,
  WAIT_FOR_APPROVE,
  WAIT_FOR_TX,
  SUCCESS,
  ERROR,
}

interface CollateralModalProps {
  vaultAddress: string;
  vaultState: PositionsState;
  vaultStateUSD: UsdPositionState;
  active: boolean;
  setActive: (active: boolean) => void;
  onClose: () => void;
  token?: CompoundTokenInfo;
}

function KCompCollateralModal(props: CollateralModalProps) {
  const c = useStyles();
  const { t } = useTranslation();
  const { library, chainId, account } = useWeb3React();
  const { vaultAddress, vaultState, vaultStateUSD, token, active, setActive, onClose } = props;

  const [modalState, setState] = useState(CollateralModalState.HIDDEN);
  const [isCollateral, setIsCollateral] = useState(false);
  const [headerMsg, setHeaderMsg] = useState("");
  const [subtext, setSubtext] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (active && token) {
      setState(CollateralModalState.WAIT_FOR_APPROVE);
    }
  }, [active, token]);

  useEffect(() => {
    if (!token) return;

    setIsCollateral(vaultState.assetsIn.includes(token.cToken.address));
  }, [vaultState, token]);

  useEffect(() => {
    if (!token) return;

    if (isCollateral) {
      setHeaderMsg("Disable Collateral");

      setSubtext(
        "This asset will no longer be used towards your borrowing limit, and can’t be seized in liquidation."
      );
    } else {
      setHeaderMsg("Enable Collateral");
      setSubtext(
        "Each asset used as collateral increases your borrowing limit. Be careful, this can subject the asset to being seized in liquidation."
      );
    }
  }, [isCollateral, token]);

  const handleClose = () => {
    onClose();
    setActive(false);
    setState(CollateralModalState.HIDDEN);
  };

  async function enterMarket(
    vaultAddress: string,
    vaultState: PositionsState,
    asset: CompoundTokenInfo
  ) {
    if (!library || !chainId || !account) return;

    setState(CollateralModalState.WAIT_FOR_TX);

    const web3 = library;
    const networkID = networkIdFromChainId(chainId);
    const kCompoundContract = contract(
      web3,
      networkID,
      ContractType.KCompound,
      account,
      vaultAddress
    );
    try {
      await kCompoundContract.methods
        .compound_enterMarkets([asset.cToken.address])
        .send({ from: account })
        .on("transactionHash", (hash) => {
          // console.log('Tx hash came => ', hash);
        })
        .on("receipt", async (receipt) => {
          // console.log('Tx receipt came => ', receipt);
          setState(CollateralModalState.SUCCESS);
        })
        .on("error", (error, receipt) => {
          setErrorMsg(error.message);
          setState(CollateralModalState.ERROR);
          return;
        });
    } catch (err) {
      console.error(`enter market error: ${err}`);
      setState(CollateralModalState.ERROR);
      return;
    }
  }

  async function exitMarket(
    vaultAddress: string,
    vaultState: PositionsState,
    asset: CompoundTokenInfo
  ) {
    if (!library || !chainId || !account) return;

    setState(CollateralModalState.WAIT_FOR_TX);

    const web3 = library;
    const networkID = networkIdFromChainId(chainId);
    const kCompoundContract = contract(
      web3,
      networkID,
      ContractType.KCompound,
      account,
      vaultAddress
    );
    try {
      await kCompoundContract.methods
        .compound_exitMarket(asset.cToken.address)
        .send({ from: account })
        .on("transactionHash", (hash) => {
          // console.log('Tx hash came => ', hash);
        })
        .on("receipt", async (receipt) => {
          // console.log('Tx receipt came => ', receipt);
          setState(CollateralModalState.SUCCESS);
        })
        .on("error", (error, receipt) => {
          setErrorMsg(error.message);
          setState(CollateralModalState.ERROR);
          return;
        });
    } catch (err) {
      console.error(`enter market error: ${err}`);
      setState(CollateralModalState.ERROR);
      return;
    }
  }

  const handleApprove = async () => {
    if (!token) return;
    //if (isCollateral) await exitMarket(vaultAddress, vaultState, token);
    if (isCollateral) await exitMarket(vaultAddress, vaultState, token);
    else await enterMarket(vaultAddress, vaultState, token);
  };

  //const decimalScaling = new BigNumber(10).pow(token.underlyingToken.decimals);

  function Hidden() {
    return null;
  }

  function WaitForApprove() {
    if (!token || !active) return null;
    return (
      <Grid container item className={c.createVaultContainer} direction="column">
        <Grid item>
          <Typography variant="h5">{headerMsg}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">{subtext}</Typography>
        </Grid>
        <Grid container item className={c.yesNoContainer}>
          <Grid item style={{ width: "48%" }}>
            <Button fullWidth variant="outlined" className={c.button} onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item style={{ width: "48%" }}>
            <Button fullWidth variant="contained" className={c.button} onClick={handleApprove}>
              {isCollateral
                ? `Disable ${token.underlyingToken.name}`
                : `Enable ${token.underlyingToken.name}`}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function WaitForTx() {
    return (
      <Grid container item className={c.loadingContainer} direction="column" spacing={4}>
        <Grid item>
          <Typography variant="h5">{headerMsg}</Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <Typography variant="body1">Waiting for tx</Typography>
        </Grid>
        <Grid container item justifyContent="space-around">
          <MoonLoader color="#6D47D7" loading={true} size={50} />
        </Grid>
      </Grid>
    );
  }

  function Success() {
    return (
      <Grid container item className={c.createVaultContainer} direction="column">
        <Grid item>
          <Typography variant="h5">{headerMsg}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">Success! </Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <Grid container item justifyContent="center" style={{ width: "48%" }}>
            <Button fullWidth variant="outlined" className={c.button} onClick={handleClose}>
              Go Back
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function Error() {
    return (
      <Grid container item className={c.createVaultContainer} direction="column">
        <Grid item>
          <Typography variant="h5">{headerMsg}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">Error: {errorMsg} </Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <Grid container item justifyContent="center" style={{ width: "48%" }}>
            <Button fullWidth variant="outlined" className={c.button} onClick={handleClose}>
              Go Back
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const modalContentState = () => {
    switch (modalState) {
      case CollateralModalState.HIDDEN:
        return Hidden();
      case CollateralModalState.WAIT_FOR_APPROVE:
        return WaitForApprove();
      case CollateralModalState.WAIT_FOR_TX:
        return WaitForTx();
      case CollateralModalState.SUCCESS:
        return Success();
      case CollateralModalState.ERROR:
        return Error();

      default:
        throw new TypeError(`non-exhaustive pattern: ${modalState}`);
    }
  };

  return (
    <Dialog
      classes={{ paper: c.root }}
      open={active}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: { zIndex: -3 },
        timeout: 500,
      }}
    >
      <div className={c.modal}>
        <GradientBox>
          <Grid container className={c.modalContent}>
            {modalContentState()}
          </Grid>
        </GradientBox>
      </div>
    </Dialog>
  );
}

export default KCompCollateralModal;
