import LanguageSelector from "./LanguageSelector";
import React, { useState, useEffect } from "react";
import {
  UnderlyingToken,
  UnderlyingTokens,
  underlyingTokenSymbol,
  symbolToToken,
} from "../lib/tokens";
import WalletStatus from "./WalletStatus";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  withStyles,
  Grid,
  Hidden,
  Icon,
  Button,
  Menu,
  MenuItem,
  MenuProps,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { CaretDownIcon } from "./Icons";
import TokenSVG from "./TokenSVG";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    position: "relative",
    height: "60px",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerRight: {
    display: "flex",
    position: "relative",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  headerLeft: {
    display: "flex",
    position: "relative",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  tokenDropdownButton: {
    justifyContent: "center",
    alignItems: "center",
    border: "none",
  },
  tokenDropdown: {
    "&:focus": {
      backgroundColor: "black",
    },
  },
  headerCenter: {},
  backButton: {
    marginRight: "11px",
    backgroundColor: "black",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "none",
    borderRadius: "8px",
    background: "rgba(245,245,245,0.12)",
  },
  list: {
    textTransform: "none",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "bold",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export default function Header(): any {
  const c = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const [token, setToken] = useState("");
  const [poolInfo, setPoolInfo] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleDropdownOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleTokenChange = (event: React.MouseEvent<HTMLElement>, route: string) => {
    if (route === "renBTC") {
      route = "BTC";
    }
    setAnchorEl(null);
    history.push(`/pool/${route}`);
  };

  useEffect(() => {
    setPoolInfo(pathname.split("/")[1] === "pool");
    setToken(pathname.split("/")[2]);
  }, [pathname]);

  return (
    <Grid container className={c.headerContainer} direction="row">
      <Grid container item direction="row" className={c.headerLeft} xs={12} sm={6} spacing={2}>
        {poolInfo && token ? (
          <>
            <Hidden smDown>
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<ArrowBack />}
                  component={RouterLink}
                  to="/pool"
                >
                  {t("header-back-to-liquidity")}
                </Button>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Hidden only="xs">
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<ArrowBack />}
                    component={RouterLink}
                    to="/pool"
                  >
                    {t("pool-info.back-btn")}
                  </Button>
                </Grid>
              </Hidden>
            </Hidden>

            <Hidden only="xs">
              <Grid item>
                <Grid item xs={3}>
                  <Button
                    variant="outlined"
                    aria-controls="token-menu"
                    aria-haspopup="true"
                    className={c.tokenDropdownButton}
                    disableElevation
                    disableRipple
                    startIcon={
                      <Icon style={{ marginTop: "-2px", color: "inherit" }}>
                        <TokenSVG underlyingToken={symbolToToken(token)} viewBox={"0 0 48 48"} />
                      </Icon>
                    }
                    endIcon={<CaretDownIcon />}
                    onClick={handleDropdownOpen}
                  >
                    {token}
                  </Button>
                  <StyledMenu
                    id="expiry-menu"
                    anchorEl={anchorEl}
                    className={c.tokenDropdown}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleDropdownClose}
                  >
                    {UnderlyingTokens.map((token) => (
                      <MenuItem
                        key={token}
                        onClick={(event) => handleTokenChange(event, underlyingTokenSymbol(token))}
                      >
                        {underlyingTokenSymbol(token)}
                      </MenuItem>
                    ))}
                  </StyledMenu>
                </Grid>
              </Grid>
            </Hidden>
          </>
        ) : null}
      </Grid>

      {poolInfo && token ? (
        <Grid item>
          <Grid container item direction="row" className={c.headerRight} spacing={1}>
            <Hidden smDown>
              <Grid item>
                <WalletStatus />
              </Grid>
            </Hidden>
            <Grid item>
              <LanguageSelector />
            </Grid>
            <Hidden smUp>
              <Grid item className={c.backButton}>
                <Button
                  variant="outlined"
                  startIcon={<ArrowBack />}
                  component={RouterLink}
                  to="/pool"
                ></Button>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      ) : (
        <Grid container item direction="row" className={c.headerRight} xs={12} sm={6} spacing={2}>
          <Hidden smDown>
            <Grid item>
              <WalletStatus />
            </Grid>
          </Hidden>
          <Grid item>
            <LanguageSelector />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
