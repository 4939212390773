import { getContractAddressesForChainOrThrow } from "@0x/contract-addresses";
import { NULL_ADDRESS, Order, SignedOrder } from "@0x/order-utils";
import { RfqOrder, Signature } from "@0x/protocol-utils";
import axios from "axios";
import BigNumber from "bignumber.js";
import JSONBigInt from "json-bigint";
import config from "./config";

const ETHPLORER_KEYS = ["EK-moVLZ-nAoNWsb-NN5y9", "EK-6qoHP-9vW8SQY-SWGUC"]; //-> old key 'EK-6qoHP-9vW8SQY-SWGUC
export const DAI: HidingBookToken = {
  address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  chainId: 1,
  name: "DAI",
  symbol: "DAI",
  decimals: 18,
  logoURI: "",
};

export const WETH: HidingBookToken = {
  address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  chainId: 1,
  name: "WETH",
  symbol: "WETH",
  decimals: 18,
  logoURI: "",
};

export const ETH: HidingBookToken = {
  address: "",
  chainId: 1,
  name: "ETH",
  symbol: "ETH",
  decimals: 18,
  logoURI: "",
};

export type OrderDetails = {
  verifyingContract: string;
  chainId: number;
  txOrigin: string;
  taker: string;
  pool: string;
};

export type TradeOrder = {
  sellToken: HidingBookToken;
  buyToken: HidingBookToken;
  sellAmount: string;
  buyAmount: string;
  expiry: number;
};

export enum TradeType {
  Instant = "Instant",
  Limit = "Limit Order",
  Wrap = "Wrap",
  Unwrap = "Unwrap",
}

export enum LimitExpiry {
  ThreeMinutes = "3 Minutes",
  TenMinutes = "10 Minutes",
  OneHour = "1 Hour",
  Day = "24 Hours",
  ThreeDays = "3 Days",
  Week = "7 Days",
}

export enum OrderState {
  INVALID,
  INVALID_MAKER_ASSET_AMOUNT,
  INVALID_TAKER_ASSET_AMOUNT,
  FILLABLE,
  EXPIRED,
  FULLY_FILLED,
  CANCELLED,
}

export enum OrderStatus {
  INVALID,
  FILLABLE,
  FILLED,
  CANCELLED,
  EXPIRED,
}

export interface RfqOrderJson {
  maker: string;
  taker: string;
  makerToken: string;
  takerToken: string;
  makerAmount: string;
  takerAmount: string;
  txOrigin: string;
  pool: string;
  expiry: number;
  salt: string;
  chainId: number; // Ethereum Chain Id where the transaction is submitted.
  verifyingContract: string; // Address of the contract where the transaction should be sent.
  signature: {
    signatureType: number;
    v: number;
    s: string;
    r: string;
  };
}

export type MakerOrder = {
  order: HidingBookOrder;
  metaData: {
    orderHash: string;
    creation: number;
    makerAllowance_makerToken: number;
    makerBalance_makerToken: number;
    remainingFillableTakerAssetAmount: number;
    takerAmountFilled: number;
    state: OrderState;
  };
};

export type MakerOrderV4 = {
  order: {
    chainId: number;
    expiry: number;
    maker: string;
    makerAmount: string;
    makerToken: string;
    pool: string;
    salt: string;
    signature: Signature;
    taker: string;
    takerAmount: string;
    takerToken: string;
    txOrigin: string;
    verifyingContract: string;
  };
  metaData: {
    orderHash: string;
    makerAllowance_makerToken: number;
    makerBalance_makerToken: number;
    remainingFillableAmount_takerToken: number;
    status: OrderStatus;
    filledAmount_takerToken: number;
  };
};

export type HidingBookToken = {
  address: string;
  chainId: number;
  name: string;
  symbol: string;
  decimals: number;
  logoURI: string;
  balance?: BigNumber;
};

const TAKER_ADDRESS = "0x3d71d79c224998e608d03c5ec9b405e7a38505f0";

export interface HidingBookOrder {
  signature: string;
  expirationTimeSeconds: string;
  makerAssetAmount: string;
  takerAssetAmount: string;
  makerFee: string;
  takerFee: string;
  salt: string;
  senderAddress: string;
  makerAddress: string;
  makerAssetData: string;
  takerAssetData: string;
  makerFeeAssetData: string;
  takerFeeAssetData: string;
  exchangeAddress: string;
  feeRecipientAddress: string;
  takerAddress: string;
  chainId: number;
}

export const ExpiryLabels = {
  0: LimitExpiry.ThreeMinutes,
  1: LimitExpiry.TenMinutes,
  2: LimitExpiry.OneHour,
  25: LimitExpiry.Day,
  73: LimitExpiry.ThreeDays,
  169: LimitExpiry.Week,
};

export const expiryLabelSelected = (expiry: LimitExpiry) => {
  switch (expiry) {
    case LimitExpiry.ThreeMinutes:
      return "3m Expiry";
    case LimitExpiry.TenMinutes:
      return "10m Expiry";
    case LimitExpiry.OneHour:
      return "1h Expiry";
    case LimitExpiry.Day:
      return "1d Expiry";
    case LimitExpiry.ThreeDays:
      return "3d Expiry";
    case LimitExpiry.Week:
      return "1w Expiry";
    default:
      const err = new TypeError(`non-exhaustive pattern: ${expiry} not defined`);
      console.log(err);
      return "";
  }
};

export const expiryLabel = (step: number): string => {
  if (step === 0) return "3 Minutes";
  else if (step === 1) return "10 Minutes";
  // starting from 2 each step is equal to 1 hour, where step 2 = 1 hour, step 73 = 72 hours (3 Days),
  else if (step < 169) {
    const hoursT = step - 1;
    const days = Math.floor(hoursT / 24);
    const daysLabel = days > 1 ? "Days" : "Day";
    const remHours = hoursT - days * 24;
    const hoursLabel = remHours > 1 ? "Hours" : "Hour";
    if (!remHours) {
      return `${days} ${daysLabel}`;
    }
    return days ? `${days} ${daysLabel} ${remHours} ${hoursLabel}` : `${remHours} ${hoursLabel}`;
  } else if (step === 169) {
    return "1 Week";
  } else {
    throw new Error(`expiryLabel::Undefined step ${step}`);
  }
};

export const ExpiryToSeconds = {
  [LimitExpiry.ThreeMinutes]: new BigNumber(180),
  [LimitExpiry.TenMinutes]: new BigNumber(600),
  [LimitExpiry.OneHour]: new BigNumber(3600),
  [LimitExpiry.Day]: new BigNumber(86400),
  [LimitExpiry.ThreeDays]: new BigNumber(259200),
  [LimitExpiry.Week]: new BigNumber(604800),
};

export const expiryToSeconds = (step: number): BigNumber => {
  if (step === 0) {
    return new BigNumber(180); // 3 Min
  } else if (step === 1) {
    return new BigNumber(600); // 10 Min
  } else if (step <= 169) {
    const hoursT = step - 1;
    return new BigNumber(3600).multipliedBy(hoursT);
  } else {
    throw new Error("expiryToSeconds::Undefined step");
  }
};

export enum RateReturnType {
  market = "market",
  spot = "spot",
}

export interface SuggestedRateQuery {
  type: RateReturnType;
  tokenIn: string;
  tokenOut: string;
  amountIn?: string;
  amountOut?: string;
}

const axiosInstance = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Max-Age": 600,
    "Content-Type": "application/json; charset=utf-8",
  },
});

export async function submitSignedOrder(
  signedOrders: HidingBookOrder[] | RfqOrderJson[]
): Promise<any> {
  try {
    const { data } = await axiosInstance.post(`${config.HIDING_BOOK_URL}/orders`, signedOrders);
    return data;
  } catch (error) {
    let displayMsg = error.response.data.error;

    // If error has "message" field, concatenate to error message for error modal display
    if (error.response.data.message) {
      displayMsg = `${displayMsg}: ${error.response.data.message}`;
    }

    throw new Error(displayMsg);
  }
}

export async function submitSoftCancelOrder(cancelOrder: {
  orderHash: string;
  signature: string;
}): Promise<any> {
  try {
    console.log(cancelOrder);
    const { data } = await axiosInstance.delete(`${config.HIDING_BOOK_URL}/orders`, {
      data: [cancelOrder],
    });

    console.log(data);
    return data.message;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
}

export async function getUserBalances(
  address: string | null | undefined,
  tokenList: HidingBookToken[]
): Promise<HidingBookToken[]> {
  try {
    const { data } = await axiosInstance.get(`${config.HIDING_BOOK_URL}/balances`, {
      params: { address },
      transformResponse: [(data) => data],
    });

    const parsedData = JSONBigInt.parse(data);

    const list = parsedData.result;
    const ethBalance = list["0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"].balance;

    const ethB = {
      ...tokenList[0],
      balance: new BigNumber(ethBalance),
    };

    const tokensWithBalances = tokenList.slice(1).map((tokenInfo) => {
      const tokenAddress = tokenInfo.address;
      if (!list[tokenAddress]) {
        return {
          ...tokenInfo,
          balance: new BigNumber(0),
        };
      }

      return {
        ...tokenInfo,
        balance: new BigNumber(list[tokenAddress].balance),
      };
    });

    const sorted = tokensWithBalances.sort((a, b) => b.balance.toNumber() - a.balance.toNumber());
    return [ethB, ...sorted];
  } catch (err) {
    console.log(err);
    return tokenList;
  }
}

export async function getTokenInfo(address: string): Promise<HidingBookToken> {
  const key = ETHPLORER_KEYS[Math.floor(Math.random() * ETHPLORER_KEYS.length)];
  const { data } = await axios.get(
    `${config.ETHPLORER_URL}/getTokenInfo/${address.toLowerCase()}?apiKey=${key}`
  );

  return {
    address,
    chainId: 1,
    name: data.name,
    symbol: data.symbol,
    decimals: parseInt(data.decimals),
    logoURI: "",
  };
}

export async function getTokenListBalances(
  address: string | null | undefined,
  tokenList: HidingBookToken[]
): Promise<any> {
  if (!address) {
    return tokenList;
  }

  const key = ETHPLORER_KEYS[Math.floor(Math.random() * ETHPLORER_KEYS.length)];

  const { data } = await axios.get(
    `${config.ETHPLORER_URL}/getAddressInfo/${address.toLowerCase()}?apiKey=${key}`
  );
  const { ETH, tokens } = data;

  const ethBalance = {
    ...tokenList[0],
    balance: new BigNumber(ETH.balance).multipliedBy(new BigNumber(10).pow(18)),
  };
  const tokensWithBalances = tokenList.slice(1).map((tokenInfo) => {
    if (!tokens) {
      return {
        ...tokenInfo,
        balance: new BigNumber(0),
      };
    }
    const tFound = tokens.find(
      (t) => t.tokenInfo.address.toLowerCase() === tokenInfo.address.toLowerCase()
    );
    return {
      ...tokenInfo,
      balance: tFound ? new BigNumber(tFound.balance) : new BigNumber(0),
    };
  });

  const sorted = tokensWithBalances.sort((a, b) => b.balance.toNumber() - a.balance.toNumber());
  return [ethBalance, ...sorted];
}

export async function getHidingBookInfo(): Promise<any> {
  const { data } = await axiosInstance.get(`${config.HIDING_BOOK_URL}/info`);
  const result = data.result;
  if (result) {
    const patchedResult = result;
    patchedResult.tokenList.tokens = result.tokenList.tokens.map((token: HidingBookToken) =>
      token.symbol === "OHM" ? { ...token, symbol: "OHM(V1)" } : token
    );
    return patchedResult;
  }
  return result;
}

export async function getExchangeRate(params: SuggestedRateQuery): Promise<BigNumber> {
  const { data } = await axiosInstance.get(`${config.HIDING_BOOK_URL}/suggestedReturn`, { params });
  return new BigNumber(data.result);
}

export async function getUserOrders(address: string): Promise<any> {
  const params = {
    maker: address,
  };
  const { data } = await axiosInstance.get(`${config.HIDING_BOOK_URL}/orders`, {
    params,
    transformResponse: [(data) => data],
  });

  const parsedData = JSONBigInt.parse(data);

  const sorted = parsedData.orders
    ? parsedData.orders.sort(
        (ord1, ord2) => Number(ord2.metaData.creation) - Number(ord1.metaData.creation)
      )
    : [];
  return sorted;
}

export function normalizeSignedOrder(signedOrder: SignedOrder): HidingBookOrder {
  return {
    ...signedOrder,
    expirationTimeSeconds: signedOrder.expirationTimeSeconds.toFixed(),
    makerAssetAmount: signedOrder.makerAssetAmount.toFixed(),
    takerAssetAmount: signedOrder.takerAssetAmount.toFixed(),
    makerFee: signedOrder.makerFee.toFixed(),
    takerFee: signedOrder.takerFee.toFixed(),
    salt: signedOrder.salt.toFixed(),
  };
}

export function normalizeRFQOrder(rawOrder: RfqOrder, signature: Signature): RfqOrderJson {
  return {
    signature,
    makerToken: rawOrder.makerToken.toLowerCase(),
    takerToken: rawOrder.takerToken.toLowerCase(),
    txOrigin: rawOrder.txOrigin.toLowerCase(),
    maker: rawOrder.maker.toLowerCase(),
    taker: rawOrder.taker.toLowerCase(),
    makerAmount: rawOrder.makerAmount.toString(),
    takerAmount: rawOrder.takerAmount.toString(),
    pool: rawOrder.pool.toLowerCase(),
    expiry: rawOrder.expiry.toNumber(),
    salt: rawOrder.salt.toString(),
    chainId: rawOrder.chainId,
    verifyingContract: rawOrder.verifyingContract.toLowerCase(),
  };
}

export function createLimitOrder(chainId: number, address: string, orderState: TradeOrder): Order {
  const exchangeAddress = getContractAddressesForChainOrThrow(chainId).exchange;
  const { sellToken, sellAmount, buyToken, buyAmount, expiry } = orderState;
  const makerAmount = new BigNumber(10)
    .pow(sellToken.decimals)
    .multipliedBy(new BigNumber(sellAmount));
  const takerAmount = new BigNumber(10)
    .pow(buyToken.decimals)
    .multipliedBy(new BigNumber(buyAmount));
  const expiryInSeconds = getExpiryUnixTime(expiry);
  const makerTokenAddress = sellToken.address.slice(2);
  const makerData = `0xf47261b0000000000000000000000000${makerTokenAddress.toLowerCase()}`;
  const takerTokenAddress = buyToken.address.slice(2);
  const takerData = `0xf47261b0000000000000000000000000${takerTokenAddress.toLowerCase()}`;

  const order: Order = {
    chainId,
    exchangeAddress,
    makerAddress: address.toLowerCase(),
    takerAddress: TAKER_ADDRESS,
    feeRecipientAddress: NULL_ADDRESS,
    senderAddress: NULL_ADDRESS,
    makerAssetAmount: makerAmount,
    takerAssetAmount: takerAmount,
    makerFee: new BigNumber(0),
    takerFee: new BigNumber(0),
    expirationTimeSeconds: expiryInSeconds,
    salt: new BigNumber(Date.now()).multipliedBy(1000), //generatePseudoRandomSalt(), <- caused touble when cancel trade
    makerAssetData: makerData,
    takerAssetData: takerData,
    makerFeeAssetData: "0x",
    takerFeeAssetData: "0x",
  };

  return order;
}

export function createRFQOrder(
  address: string,
  orderState: TradeOrder,
  orderDetails: OrderDetails | undefined
): RfqOrder {
  const { txOrigin, taker, pool, verifyingContract, chainId } = orderDetails
    ? orderDetails
    : {
        txOrigin: "0xBd49A97300E10325c78D6b4EC864Af31623Bb5dD",
        pool: "0x0000000000000000000000000000000000000000000000000000000000000017",
        chainId: 1,
        taker: "0x0000000000000000000000000000000000000000",
        verifyingContract: "0xDef1C0ded9bec7F1a1670819833240f027b25EfF",
      };

  const { sellToken, sellAmount, buyToken, buyAmount, expiry } = orderState;
  const makerAmount = new BigNumber(
    new BigNumber(10).pow(sellToken.decimals).multipliedBy(new BigNumber(sellAmount)).toFixed(0)
  );

  const takerAmount = new BigNumber(
    new BigNumber(10).pow(buyToken.decimals).multipliedBy(new BigNumber(buyAmount)).toFixed(0)
  );
  const expiryInSeconds = getExpiryUnixTime(expiry);
  const makerTokenAddress = sellToken.address;
  const takerTokenAddress = buyToken.address;
  const salt = new BigNumber(Date.now()).multipliedBy(1000);

  const order = new RfqOrder({
    makerToken: makerTokenAddress.toLowerCase(),
    takerToken: takerTokenAddress.toLowerCase(),
    txOrigin: txOrigin.toLowerCase(),
    maker: address.toLowerCase(),
    taker: taker.toLowerCase(),
    makerAmount,
    takerAmount,
    pool: pool.toLowerCase(),
    expiry: expiryInSeconds,
    salt: salt,
    verifyingContract: verifyingContract.toLowerCase(),
    chainId: chainId,
  });
  return order;
}

export function getExpiryUnixTime(expiry: number): BigNumber {
  //const expiryInSeconds = expiryToSeconds(expiry);
  const expiryInSeconds = expiry;
  return new BigNumber(Math.floor(Date.now() / 1000)).plus(expiryInSeconds);
}
