import React from "react";
import { InjectedConnector } from "@web3-react/injected-connector";
import { NetworkConnector } from "@web3-react/network-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { FortmaticConnector } from "@web3-react/fortmatic-connector";
import { PortisConnector } from "@web3-react/portis-connector";
import {
  CoinbaseIcon,
  FortmaticIcon,
  MetaMaskIcon,
  PortisIcon,
  WalletConnectIcon,
} from "../components/Icons";
import config from "../lib/config";

export enum ConnectorNames {
  Injected = "Metamask",
  Network = "Network",
  WalletConnect = "WalletConnect",
  WalletLink = "WalletLink",
  Fortmatic = "Fortmatic",
  Portis = "Portis",
}

const POLLING_INTERVAL = 12000;

const INFURA_KEY =
  process.env.NODE_ENV === "development" ? config.INFURA_KEY_DEV : config.INFURA_KEY;

export const RPC_URLS: { [chainId: number]: string } = {
  1: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  3: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
  42: `https://kovan.infura.io/v3/${INFURA_KEY}`,
};

export const injectedConnector = new InjectedConnector({
  supportedChainIds: [1, 3, 42],
});

export const networkConnector = new NetworkConnector({
  urls: { 1: RPC_URLS[1], 3: RPC_URLS[3], 42: RPC_URLS[42] },
  defaultChainId: 1,
});

export const walletconnect = new WalletConnectConnector({
  rpc: { 1: RPC_URLS[1] },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
});

export const walletlink = new WalletLinkConnector({
  url: RPC_URLS[1],
  appName: "KeeperDao",
  appLogoUrl: "https://ibb.co/GCbJ0Gb",
});

export const fortmatic = new FortmaticConnector({
  apiKey: config.FORTMATIC_KEY,
  chainId: 1,
});

export const portis = new PortisConnector({
  dAppId: config.PORTIS_ID,
  networks: [1],
});

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injectedConnector,
  [ConnectorNames.Network]: networkConnector,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.WalletLink]: walletlink,
  [ConnectorNames.Fortmatic]: fortmatic,
  [ConnectorNames.Portis]: portis,
};
