import React from "react";
import { IProps } from "../../lib/types";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

export const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 44,
      height: 24,
      padding: 0,
      margin: theme.spacing(1),
      alignSelf: "center",
      alignContent: "center",
    },
    switchBase: {
      padding: 4,
      "&$checked": {
        transform: "translateX(20px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#09A7F3",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#09A7F3",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 16,
      height: 16,
    },
    track: {
      borderRadius: 26 / 2,
      //border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: "rgba(245,245,245,0.12)",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
