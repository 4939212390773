/* eslint-disable react/jsx-key */
import React from "react";
import { useTable } from "react-table";

import {
  makeStyles,
  withStyles,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Typography,
  Paper,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: theme.palette.background.default,
    padding: 0,
    //backgroundColor: '#123456',
  },
  tablePaper: {
    background: "none",
  },
  tableRow: {
    border: "none",
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      backgroundColor: "rgba(245,245,245,0.04)",
      cursor: "pointer",
    },
  },
}));

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      border: "none",
      backgroundColor: theme.palette.background.default,
      "&:hover": {
        backgroundColor: "rgba(245,245,245,0.04)",
      },
    },
  })
)(TableRow);

interface Props {
  columns: any;
  data: any;
  onRowClick: (row: any) => void;
  opacity?: boolean;
  history?: boolean;
  striped?: boolean;
  headerColor?: string;
  rowHighlighted?: boolean;
  pointer?: boolean;
  control?: boolean;
}

const CustomTable: React.FC<Props> = ({
  columns,
  data,
  onRowClick,
  history,
  opacity = false,
  striped = true,
  rowHighlighted = true,
  pointer = false,
  headerColor,
  control = false,
}: Props) => {
  const c = useStyles();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const hColor = headerColor ? headerColor : "#8594E0";
  const rowClass = rowHighlighted ? "pools-table-body-row" : "pools-table-body-row-high-off";
  const pointerClass = pointer ? "pointer-row" : "";

  const rowClickHandle = (row) => {
    if (control) {
      return;
    }
    onRowClick(row);
  };

  return (
    <TableContainer style={{ padding: "0px" }}>
      <Table className={c.table} aria-label="tx table">
        <TableHead>
          <TableRow>
            {columns.map((column, i) => (
              <TableCell style={{ borderBottom: "1px solid", borderColor: "#4F4F4F" }} key={i}>
                {column.Header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody style={{ background: "none" }}>
          {data.map((row, i) => {
            return (
              <TableRow
                classes={{ root: c.tableRow }}
                key={row.toString() + i.toString()}
                onClick={() => rowClickHandle(row)}
              >
                {columns.map((column, i) => (
                  <TableCell
                    key={row[column.accessor] + i.toString()}
                    style={{
                      color: "inherit",
                      background: "inherit",
                      backgroundColor: "inherit",
                    }}
                  >
                    {row[column.accessor]}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
  /*
        <table {...getTableProps()} className={`pools-table ${opacity ? 'alternative' : ''}`}>
            <thead className="pools-table-header">
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()} className="pools-table-head-row">
                        {headerGroup.headers.map((column) => (
                            <th
                                {...column.getHeaderProps()}
                                className={`pools-table-header-cell`}
                                style={{ color: hColor }}
                            >
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    const highlighted = i % 2 > 0;
                    const opacityClass = striped && highlighted ? 'opacity-highlighted' : 'opacity-plain';
                    return (
                        <tr
                            {...row.getRowProps()}
                            className={`${rowClass} ${striped && highlighted ? 'highlighted' : ''} ${
                                opacity ? opacityClass : ''
                            } ${pointerClass}  ${control ? 'control-cell' : ''}`}
                            onClick={() => rowClickHandle(row)}
                        >
                            {row.cells.map((cell, i) => {
                                const changeColumn = history && cell.column.id === 'col2';
                                if (changeColumn) {
                                    const changeSign = changeColumn && cell.value.slice(0, 1);
                                    const cellColorClass = changeSign === '+' ? 'history-deposit' : 'history-withdraw';
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            className={`pools-table-row-cell ${cellColorClass}`}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                }
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        className={`pools-table-row-cell ${
                                            control && i === row.cells.length - 1 ? 'control-cell' : ''
                                        }`}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );*/
};

export default CustomTable;
