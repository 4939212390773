import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import { Token, displayTokenBalance } from "../lib/tokens";
import {
  contractAddress,
  contractTypeFromToken,
  ContractType,
  contract,
  distibutorContract,
} from "../lib/contracts";
import { useWeb3React } from "@web3-react/core";
import { NetworkID, networkIdFromChainId } from "../lib/network";
import axios from "axios";
import config from "../lib/config";

const KOVAN = "KOVAN_";
const ROOK_SUPPLY_HARDCODED = new BigNumber("1E+6").multipliedBy("1E+18");

// All current rewards categories should be enumerated here
export enum rewardsCategory {
  Keeper,
  LiquidityProvider1,
  LiquidityProvider2,
  LiquidityProvider3,
  LiquidityProvider4,
  LiquidityProvider5,
  PremineLP,
  Trade,
  Trade2,
  Trade3,
  Trade4,
  Trade5,
  Trade6,
  Trade7,
  HidingVault,
  HidingVault2,
  HidingVault3,
  HidingVault4,
  // new rewards type here,
}

// Reward Balance struct for calling distributor contract
export interface RewardBalance {
  earnings: BigNumber;
  nonce: string;
  signature: string;
}

// Default Reward Balance
const DEFAULT_REWARD_BALANCE = {
  earnings: new BigNumber(0),
  nonce: "",
  signature: "",
};

// All metadata for a given rewards category
export interface RewardData {
  reward_type: rewardsCategory;
  contract: ContractType;
  indibo_key: string;
  display_name: string;
  claimable_amount: BigNumber;
  reward_balance: RewardBalance;
}

// All current rewards metadata should be defined here
const REWARDS_DATA = {
  LiquidityProvider1: {
    reward_type: rewardsCategory.LiquidityProvider1,
    contract: ContractType.LPDistributor1,
    indibo_key: "lp",
    display_name: "LP1 Rewards",
    claimable_amount: new BigNumber(0),
    reward_balance: DEFAULT_REWARD_BALANCE,
  },
  LiquidityProvider2: {
    reward_type: rewardsCategory.LiquidityProvider2,
    contract: ContractType.LPDistributor2,
    indibo_key: "lpq2",
    display_name: "LP2 Rewards",
    claimable_amount: new BigNumber(0),
  },
  LiquidityProvider3: {
    reward_type: rewardsCategory.LiquidityProvider3,
    contract: ContractType.LPDistributor3,
    indibo_key: "lpq3",
    display_name: "LP3 Rewards",
    claimable_amount: new BigNumber(0),
  },
  LiquidityProvider4: {
    reward_type: rewardsCategory.LiquidityProvider4,
    contract: ContractType.LPDistributor4,
    indibo_key: "lpq4",
    display_name: "LP4 Rewards",
    claimable_amount: new BigNumber(0),
  },
  LiquidityProvider5: {
    reward_type: rewardsCategory.LiquidityProvider5,
    contract: ContractType.LPDistributor5,
    indibo_key: "lpq5",
    display_name: "LP5 Rewards",
    claimable_amount: new BigNumber(0),
  },
  PremineLP: {
    reward_type: rewardsCategory.PremineLP,
    contract: ContractType.LPPremineDistributor,
    indibo_key: "lp-pre",
    display_name: "Early Rewards",
    claimable_amount: new BigNumber(0),
  },
  Trade: {
    reward_type: rewardsCategory.Trade,
    contract: ContractType.TradeDistributor,
    indibo_key: "hiding",
    display_name: "Hiding Game 1",
    claimable_amount: new BigNumber(0),
  },
  Trade2: {
    reward_type: rewardsCategory.Trade2,
    contract: ContractType.TradeDistributor2,
    indibo_key: "hiding2",
    display_name: "Hiding Game 2",
    claimable_amount: new BigNumber(0),
  },
  Trade3: {
    reward_type: rewardsCategory.Trade3,
    contract: ContractType.TradeDistributor3,
    indibo_key: "hiding3",
    display_name: "Hiding Game 3",
    claimable_amount: new BigNumber(0),
  },
  Trade4: {
    reward_type: rewardsCategory.Trade4,
    contract: ContractType.TradeDistributor4,
    indibo_key: "hiding4",
    display_name: "Hiding Game 4",
    claimable_amount: new BigNumber(0),
  },
  Trade5: {
    reward_type: rewardsCategory.Trade5,
    contract: ContractType.TradeDistributor5,
    indibo_key: "hiding5",
    display_name: "Hiding Game 5",
    claimable_amount: new BigNumber(0),
  },
  Trade6: {
    reward_type: rewardsCategory.Trade6,
    contract: ContractType.TradeDistributor6,
    indibo_key: "hiding6",
    display_name: "Hiding Game 6",
    claimable_amount: new BigNumber(0),
  },
  Trade7: {
    reward_type: rewardsCategory.Trade7,
    contract: ContractType.TradeDistributor7,
    indibo_key: "hiding7",
    display_name: "Hiding Game 7",
    claimable_amount: new BigNumber(0),
  },
  /* NewRewardsType: {
        reward_type: rewardsCategory.NewType,
        contract: ContractType.NewRewardsType,
        indibo_key: '',
        display_name: '',
        claimable_amount: new BigNumber(0),
    }, */
};

// State for all reward categories
export interface RewardsState {
  rewardsData: RewardData[];
  rookBalance: BigNumber;
  rookSupply: BigNumber;
  currentBLock: number;
}

const DEFAULT_REWARDS_STATE: RewardsState = {
  rewardsData: Object.keys(REWARDS_DATA).map((claimType) => REWARDS_DATA[claimType]),
  rookBalance: new BigNumber(0),
  rookSupply: new BigNumber(0),
  currentBLock: 0,
};

// Reward modal state
export enum ClaimRookState {
  HIDDEN,
  NOTHING_TO_CLAIM,
  WAIT_FOR_PROCEED_TO_CLAIMING,
  WAIT_FOR_APPROVE,
  WAIT_FOR_CLAIM,
  SUCCESS,
  ERROR,
  CANCEL_APPROVE,
  CANCEL_CLAIM,
}

// Fetches all reward balances from rewards server
export async function getRewardBalances(
  address: string,
  network: NetworkID,
  rewards: RewardsState
) {
  const rewards_url = config.INDIBO_ALL_REWARDS_URL;

  const reward_balances: {
    reward_type: rewardsCategory;
    reward_balance: RewardBalance;
  }[] = rewards.rewardsData.map((reward) => ({
    reward_type: reward.reward_type,
    reward_balance: DEFAULT_REWARD_BALANCE,
  }));

  let indibo_rewards_data;
  try {
    if (!address) {
      return reward_balances;
    }

    const url = `${rewards_url}?address=${address}`;
    const { data } = await axios.get(url);

    //console.log('ALL REWARDS: ', data);

    indibo_rewards_data = data;
  } catch (err) {
    console.error("Fetch Reward Balance Error", err);
    return reward_balances;
  }

  const balances = reward_balances.map((reward_balance) => {
    const reward_data = rewards.rewardsData.find(
      (reward) => reward.reward_type === reward_balance.reward_type
    );

    if (reward_data) {
      const indibo_reward_balance = indibo_rewards_data[reward_data.indibo_key];

      if (indibo_reward_balance) {
        return {
          reward_type: reward_balance.reward_type,
          reward_balance: {
            earnings: new BigNumber(indibo_reward_balance.earnings_to_date),
            nonce: indibo_reward_balance.nonce,
            signature: indibo_reward_balance.signature,
          },
        };
      }
    }

    return {
      reward_type: reward_balance.reward_type,
      reward_balance: DEFAULT_REWARD_BALANCE,
    };
  });

  /*
    balances.forEach((bal) =>
        console.log(
            rewardsCategory[bal.reward_type],
            ' indibo_reward_balance: ',
            new BigNumber(bal.reward_balance.earnings).dividedBy(new BigNumber(10).pow(18)).toNumber(),
        ),
    );
    */

  return balances;
}

// Fetches previously claimed amount for a given reward category
export async function getClaimedBalance(
  web3: Web3,
  network: NetworkID,
  address: string,
  reward_data: RewardData
) {
  const claimed_balance = {
    reward_type: reward_data.reward_type,
    claimed_balance: new BigNumber(0),
  };

  try {
    if (!web3 || !network || network === NetworkID.Unsupported || !address) {
      return claimed_balance;
    }

    const contractType = reward_data.contract;
    const DistributorContract = distibutorContract(web3, network, address, contractType);

    if (!DistributorContract) {
      return claimed_balance;
    }

    const claimed = await DistributorContract.methods.claimedAmount(address).call();
    claimed_balance.claimed_balance = new BigNumber(claimed);

    /*
        console.log(
            rewardsCategory[claimed_balance.reward_type],
            ' claimed_balance',
            claimed_balance.claimed_balance.dividedBy(new BigNumber(10).pow(18)).toNumber(),
        );
        */
  } catch (err) {
    console.error(err);
  }
  return claimed_balance;
}

// Fetches previously claimed amount for all reward categories
export async function getClaimedBalances(
  web3: Web3,
  network: NetworkID,
  address: string,
  rewards: RewardsState
) {
  const claimed_balances: {
    reward_type: rewardsCategory;
    claimed_balance: BigNumber;
  }[] = rewards.rewardsData.map((reward) => ({
    reward_type: reward.reward_type,
    claimed_balance: new BigNumber(0),
  }));

  if (!web3 || !network || network === NetworkID.Unsupported || !address) {
    return claimed_balances;
  }

  try {
    const balances = await Promise.all(
      rewards.rewardsData.map((reward) => getClaimedBalance(web3, network, address, reward))
    );
    return balances;
  } catch (err) {
    console.error("Fetch Claimed Balance Error", err);
    throw new Error("Fetch Claimed Balance Error: ");
  }
}

// Rewards hook
function useRewardsBalances() {
  const { library, chainId, account } = useWeb3React();

  const [rewardsState, setRewardsState] = useState<RewardsState>(DEFAULT_REWARDS_STATE);
  const [claimModalState, setClaimModalState] = useState(ClaimRookState.HIDDEN);
  const [amountToClaim, setAmountToClaim] = useState(new BigNumber(0));
  const [loading, setLoading] = useState(true);
  const [totalClaimable, setTotalClaimable] = useState(new BigNumber(0));

  // Fetch and calculate balances for all reward categories, and update state
  async function updateRewards() {
    if (!library || !chainId || !account) {
      return;
    }

    const networkID = networkIdFromChainId(chainId);

    try {
      const data = await Promise.all([
        getRookBalance(library, networkID, account),
        getRookSupply(library, networkID, account),
        library.eth.getBlockNumber(),
        getClaimedBalances(library, networkID, account, rewardsState),
        getRewardBalances(account, networkID, rewardsState),
      ]);

      const rookBalance = data[0];
      const rookSupply = data[1];
      const currentBLock = data[2];

      const claimedBalances = data[3];
      const rewardBalances = data[4];

      let totalClaimableAmount = new BigNumber(0);

      const rewardsData = rewardsState.rewardsData.map((reward) => {
        const claimed = claimedBalances.find((bal) => bal.reward_type === reward.reward_type);
        const rewardState = rewardBalances.find((bal) => bal.reward_type === reward.reward_type);

        const claimable =
          claimed && rewardState
            ? rewardState.reward_balance.earnings.minus(claimed.claimed_balance)
            : new BigNumber(0);

        totalClaimableAmount = totalClaimableAmount.plus(claimable);

        return {
          reward_type: reward.reward_type,
          contract: reward.contract,
          indibo_key: reward.indibo_key,
          display_name: reward.display_name,
          claimable_amount: claimable,
          reward_balance: rewardState ? rewardState.reward_balance : DEFAULT_REWARD_BALANCE,
        };
      });

      setRewardsState({
        rewardsData,
        rookBalance,
        rookSupply,
        currentBLock,
      });

      setTotalClaimable(totalClaimableAmount);
      setLoading(false);
    } catch (err) {
      console.error("Update Rewards error: ", err);
    }
  }

  // Update reward balances every 30 seconds
  useEffect(() => {
    updateRewards();
    const interval = setInterval(async () => {
      updateRewards();
    }, 30000);
    return () => clearInterval(interval);
  }, [library, chainId, account, claimModalState]);

  // Update reward balancess on successful claim
  useEffect(() => {
    if (claimModalState === ClaimRookState.SUCCESS) {
      updateRewards();
    }
  }, [claimModalState]);

  /*useEffect(() => {
        const decimal_scaling = new BigNumber(10).pow(18);

        console.log('----- REWARDS STATE -----');
        console.log('current block: ', rewardsState.currentBLock);
        console.log('rook balance: ', rewardsState.rookBalance);
        console.log('rook supply: ', rewardsState.rookSupply);
        rewardsState.rewardsData.forEach((reward) => {
            console.log('-- ', reward.display_name, ' --');
            console.log('claimable balance: ', reward.claimable_amount.dividedBy(decimal_scaling).toNumber());
            console.log('rewards struct: ', reward.reward_balance);
        });
    }, [rewardsState]);*/

  return {
    rewardsState,
    setRewardsState,
    claimModalState,
    setClaimModalState,
    amountToClaim,
    setAmountToClaim,
    loading,
    totalClaimable,
  };
}

export const RewardsBalances = createContainer(useRewardsBalances);

// ------- OLD CODE --------
// The following code here is left over from the old rewards server paradigm. It is still necessary for fetching Vault reward balances
// We can change this in the future

// Fetches the ROOK balance of a given address from the chain
export async function getRookBalance(
  web3: Web3,
  network: NetworkID,
  address: string
): Promise<BigNumber> {
  try {
    if (!address) {
      return new BigNumber(0);
    }
    return new BigNumber(
      await web3.eth.call({
        to: contractAddress(network, contractTypeFromToken(Token.ROOK)),
        data: `0x70a08231000000000000000000000000${address.slice(-40)}`,
      })
    );
  } catch (err) {
    console.log(err);
    return new BigNumber(0);
  }
}

// Fetches reward balance for a single category (Only used for vault rewards)
export async function getRewards(type: rewardsCategory, address: string, network: NetworkID) {
  let rewards: RewardBalance = {
    earnings: new BigNumber(0),
    nonce: "",
    signature: "",
  };

  try {
    if (!address) {
      return rewards;
    }
    const base_url = getIndiboURL(type, network);
    const url = `${base_url}${address}`;
    const { data } = await axios.get(url);
    rewards = {
      earnings: data.earnings_to_date ? new BigNumber(data.earnings_to_date) : new BigNumber(0),
      nonce: data.nonce,
      signature: data.signature,
    };

    return rewards;
  } catch (err) {
    console.error(err);
    return rewards;
  }
}

export async function getClaimedROOKBalance(
  web3: Web3,
  network: NetworkID,
  address: string,
  distContract:
    | ContractType.LPDistributor1
    | ContractType.LPDistributor2
    | ContractType.LPDistributor3
    | ContractType.KeeperDistributor
    | ContractType.LPPremineDistributor
    | ContractType.TradeDistributor
    | ContractType.TradeDistributor2
    | ContractType.TradeDistributor3
    | ContractType.TradeDistributor4
    | ContractType.HidingVaultDistributor
    | ContractType.HidingVaultDistributor2
    | ContractType.HidingVaultDistributor3
    | ContractType.HidingVaultDistributor4
): Promise<BigNumber | null> {
  try {
    if (!web3 || !network || network === NetworkID.Unsupported || !address) {
      return null;
    }
    const DistributeContract = distibutorContract(web3, network, address, distContract);

    if (!DistributeContract) {
      return null;
    }

    const claimed = await DistributeContract.methods.claimedAmount(address).call();
    return claimed;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function getRookSupply(
  web3: Web3,
  network: NetworkID,
  address: string
): Promise<BigNumber> {
  try {
    const rookContract = contract(web3, network, ContractType.ROOK, address);
    const balance = await rookContract.methods.totalSupply().call();

    return balance ? new BigNumber(balance) : ROOK_SUPPLY_HARDCODED;
  } catch (err) {
    console.error(err);
    return new BigNumber(0);
  }
}

function getIndiboURL(type: rewardsCategory, network: NetworkID): string {
  switch (type) {
    case rewardsCategory.Keeper:
      return config[`INDIBO_${network === NetworkID.Kovan ? KOVAN : ""}KEEPER_REWARD_URL`];
    case rewardsCategory.LiquidityProvider1:
      return config[
        `INDIBO_${network === NetworkID.Kovan ? KOVAN : ""}LIQUIDITY_PROVIDER_REWARD_URL`
      ];
    case rewardsCategory.LiquidityProvider2:
      return config[`INDIBO_${network === NetworkID.Kovan ? KOVAN : ""}LP_Q2_REWARD_URL`];
    case rewardsCategory.LiquidityProvider3:
      return config[`INDIBO_${network === NetworkID.Kovan ? KOVAN : ""}LP_Q3_REWARD_URL`];
    case rewardsCategory.PremineLP:
      return config[
        `INDIBO_${network === NetworkID.Kovan ? KOVAN : ""}PRE_LIQUIDITY_PROVIDER_REWARD_URL`
      ];
    case rewardsCategory.Trade:
      return config[`INDIBO_${network === NetworkID.Kovan ? KOVAN : ""}TRADE_REWARD_URL`];
    case rewardsCategory.Trade2:
      return config[`INDIBO_${network === NetworkID.Kovan ? KOVAN : ""}TRADE_REWARD2_URL`];
    case rewardsCategory.Trade3:
      return config[`INDIBO_${network === NetworkID.Kovan ? KOVAN : ""}TRADE_REWARD3_URL`];
    case rewardsCategory.Trade4:
      return config[`INDIBO_${network === NetworkID.Kovan ? KOVAN : ""}TRADE_REWARD4_URL`];
    case rewardsCategory.HidingVault:
      return config[`INDIBO_${network === NetworkID.Kovan ? KOVAN : ""}VAULT_REWARD_URL`];
    case rewardsCategory.HidingVault2:
      return config[`INDIBO_${network === NetworkID.Kovan ? KOVAN : ""}VAULT_REWARD2_URL`];
    case rewardsCategory.HidingVault3:
      return config[`INDIBO_${network === NetworkID.Kovan ? KOVAN : ""}VAULT_REWARD3_URL`];
    case rewardsCategory.HidingVault4:
      return config[`INDIBO_${network === NetworkID.Kovan ? KOVAN : ""}VAULT_REWARD4_URL`];
    default:
      return "";
  }
}
