/* eslint-disable react/jsx-key */
import { Card, CardActionArea, CardContent, Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { UnderlyingToken, underlyingTokenSymbol } from "../lib/tokens";
import { TokenGradientSVG } from "./TokenSVG";

// Styles exported from figma
const useStyles = makeStyles((theme) => ({
  root: { minWidth: 250 },
  grid_container: {
    display: "flex",
    position: "relative",
    // width: "100%",
    // height: "100%",
  },
  poolTileWrapper: {},
  pool_tile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxSizing: "border-box",
    borderRadius: "24px",
    //minHeight: '200px',
    //minWidth: '263px',
    background:
      "linear-gradient(197.01deg, rgba(9, 167, 243, 0.2) 2.59%, rgba(108, 70, 214, 0.2) 103.03%), #141118",
    padding: "0%",
    margin: "0%",
  },
  pool_tile_content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    borderRadius: "24px",
    //minHeight: '200px',
  },
  token_logo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  token_stats: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    //alignItems: 'bottom',
  },
  pool_field_value: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  pool_field_name: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    color: theme.palette.text.secondary,
  },
  token_label: {
    textTransform: "none",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "26.5951px",
    lineHeight: "32px",
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
  divider: {
    background: "rgba(115, 115, 115, 0.2)",
  },
}));

interface GridProps {
  fields: any;
  data: any;
}

interface ItemProps {
  pool: any;
  fields: any;
}

const PoolsGridItem: React.FC<ItemProps> = ({ pool, fields }: ItemProps) => {
  const c = useStyles();
  return (
    <Grid item sm={12} md={6} lg={4}>
      <Card className={c.pool_tile}>
        <CardActionArea component={RouterLink} to={`/pool/${UnderlyingToken[pool.token]}`}>
          <CardContent className={c.pool_tile_content}>
            <Grid container item className={c.token_logo}>
              <Grid item>
                <TokenGradientSVG underlyingToken={pool.token} />
              </Grid>
              <Grid item>
                <Typography variant="h3" classes={{ h3: c.token_label }}>
                  {underlyingTokenSymbol(pool.token)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item className={c.token_stats} xs={12}>
              <Grid container item justifyContent="space-around" sm={3}>
                <Grid item direction="column" justifyContent="center">
                  <Typography align="center" className={c.pool_field_name}>
                    {fields[0].fieldName}
                  </Typography>

                  <Typography align="center" className={c.pool_field_value}>
                    {pool[fields[0].accessor]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item direction="column" justifyContent="center">
                <Divider orientation="vertical" className={c.divider} />
              </Grid>
              <Grid container item direction="row" justifyContent="space-around" sm={4} xs={12}>
                <Grid item direction="column" justifyContent="center">
                  <Typography align="center" className={c.pool_field_name}>
                    {fields[1].fieldName}
                  </Typography>

                  <Typography align="center" className={c.pool_field_value}>
                    {pool[fields[1].accessor]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item direction="column" justifyContent="center">
                <Divider orientation="vertical" className={c.divider} />
              </Grid>
              <Grid container item direction="row" justifyContent="space-around" sm={3}>
                <Grid item direction="column" justifyContent="center">
                  <Typography align="center" className={c.pool_field_name}>
                    {fields[2].fieldName}
                  </Typography>

                  <Typography align="center" className={c.pool_field_value}>
                    {pool[fields[2].accessor]}
                  </Typography>
                </Grid>
              </Grid>
              {/*}
                                    {idx < fields.length - 1 ? (
                                        <Grid item direction="column" justifyContent="center">
                                            <Divider orientation="vertical" className={c.divider} />
                                        </Grid>
                                    ) : null}*/}
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const PoolsGrid: React.FC<GridProps> = ({ fields, data }: GridProps) => {
  const c = useStyles();
  return (
    <Grid container className={c.grid_container} spacing={3}>
      {data.map((pool) => {
        return <PoolsGridItem key={pool.token} pool={pool} fields={fields}></PoolsGridItem>;
      })}
    </Grid>
  );
};

export default PoolsGrid;
