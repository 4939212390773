import axios from "axios";
import { Token } from "./tokens";
import BigNumber from "bignumber.js";
import { HidingBookToken } from "./trade";
import chunk from "lodash/chunk";
export type IExchangePrice<T> = { [key in keyof typeof Token | string]: T };
export enum GeckoId {
  USD = "usd",
}

const COINGECKO_ERC20_TOKEN_PRICES_URL =
  "https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=";
const HG_TOKENS_URL = "https://hidingbook.keeperdao.com/api/v1/tokenList";

export type HidingGameToken = Omit<HidingBookToken, "name" | "balance" | "logoURI">;

export async function getHGTokens(): Promise<Array<HidingGameToken>> {
  const result = await axios.get(`${HG_TOKENS_URL}`);
  return result.data.result.tokens as HidingGameToken[];
}

export async function getHGTokenPrices(): Promise<IExchangePrice<BigNumber>> {
  const hgTokens = await getHGTokens();

  const hgTokensMap = new Map(hgTokens.map((hgToken) => [hgToken.address, hgToken.symbol]));
  const hgTokenAddressesList = Array.from(hgTokensMap.keys());
  const hgTokenAddressChunks = chunk(hgTokenAddressesList, 40); // breaking the list to chunks for 40 each to make sure url length doesn't overflow

  let hgTokenPrices: IExchangePrice<BigNumber> = {};

  const promises = hgTokenAddressChunks.map((hgTokenAddressChunk) =>
    fetch(
      `${COINGECKO_ERC20_TOKEN_PRICES_URL}${hgTokenAddressChunk.join(",")}&vs_currencies=${
        GeckoId.USD
      }`
    )
  );
  const results = await Promise.all(promises);
  const jsonResults = await Promise.all(results.map((result) => result.json()));

  for (const result of jsonResults) {
    const hgTokenChunkPrices = Object.keys(result).reduce((obj, key) => {
      const symbol = hgTokensMap.get(key);
      return {
        ...obj,
        [symbol as string]: new BigNumber(result[key]["usd"]),
        [key as string]: new BigNumber(result[key]["usd"]),
      };
    }, {} as IExchangePrice<BigNumber>);
    hgTokenPrices = { ...hgTokenPrices, ...hgTokenChunkPrices };
  }

  //map kTokens and ETH,BTC prices to underlying erc20 token prices from CG API
  const allHgTokenPrices = {
    ...hgTokenPrices,
    KETH: hgTokenPrices.WETH,
    KWETH: hgTokenPrices.WETH,
    KUSDC: hgTokenPrices.USDC,
    KDAI: hgTokenPrices.DAI,
    KBTC: hgTokenPrices.WBTC,
    ETH: hgTokenPrices.WETH,
    BTC: hgTokenPrices.WBTC,
  };

  return allHgTokenPrices;
}
