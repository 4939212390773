import React, { Suspense } from "react";
import * as ReactDOM from "react-dom";

import App from "./components/App";
import PageLoader from "./components/PageLoader";
import "./styles/index.css";
import "./i18n";

ReactDOM.render(
  <Suspense fallback={<PageLoader />}>
    <App />
  </Suspense>,
  document.getElementById("root") as HTMLElement
);
