import { Backdrop, Button, Dialog, Grid, makeStyles, Typography } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MoonLoader from "react-spinners/MoonLoader";
import { StyledModal } from ".";
import Balances from "../../containers/balances";
import Depositor, { DepositorState } from "../../containers/depositor";
import {
  contract,
  contractAddress,
  ContractType,
  contractTypeFromToken,
} from "../../lib/contracts";
import { networkIdFromChainId } from "../../lib/network";
import {
  tokenDecimals,
  UnderlyingToken,
  underlyingTokenSymbol,
  wrapUnderlyingToken,
} from "../../lib/tokens";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "none",
    border: "none",
    position: "absolute",
  },
  modal: {
    position: "relative",
    display: "flex",
    flex: "1 1 0",
    width: "375px",
    maxHeight: "575px",
  },
  modalContent: {
    display: "flex",
    flex: "1 1 0",
    position: "relative",
    height: "100%",
    width: "100%",
    padding: "24px",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  reviewContainer: {
    display: "flex",
    position: "relative",
    height: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  loadingContainer: {
    display: "flex",
    flex: "1 1 0",
    position: "relative",
    height: "100%",
    width: "100%",
    padding: "8px",
    justifyContent: "center",
    alignItems: "center",
  },
  messageRow: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "8px",
    marginBottom: "8px",
  },
  buttonRow: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "8px",
    marginBottom: "8px",
  },
  cancelButton: {
    borderRadius: "8px",
    //width: '100%',
    //height: '100%',
  },
  submitButton: {
    //marginTop: '20px',
    borderRadius: "8px",
    width: "100%",
    height: "100%",
    //height: '56px',
  },
}));

function useDepositModal(underlyingToken: UnderlyingToken) {
  // State variables use for inputting the deposit amount. We need an explicit
  // state variable because we need to do bignumber/decimal/string
  // conversions, and haveing an explicit state variable makes this much
  // easier.
  const [input, setInput] = useState("");

  // The Wallet is needed so that we can sign and submit transactions to
  // Ethereum.
  const wallet = useWeb3React();
  const balances = Balances.useContainer();

  const { t } = useTranslation();

  // The depositor is needed so that we can know the current state of the
  // depositor.
  const depositor = Depositor.useContainer();

  //update input on modal state change
  useEffect(() => {
    setInput("");
  }, [depositor.state]);

  async function deposit() {
    depositor.setState(DepositorState.WAIT_FOR_DEPOSIT);
    if (!wallet.library || !wallet.chainId || !wallet.account) {
      return;
    }
    const web3 = wallet.library;
    const networkID = networkIdFromChainId(wallet.chainId);
    const liquidityPoolContract = contract(
      web3,
      networkID,
      ContractType.LiquidityPool,
      wallet.account
    );
    const underlyingTokenAddress = contractAddress(
      networkID,
      contractTypeFromToken(wrapUnderlyingToken(underlyingToken))
    );
    const amount = depositor.amount || new BigNumber(0);
    const value = underlyingToken === UnderlyingToken.ETH ? amount : new BigNumber(0);

    try {
      await liquidityPoolContract.methods
        .deposit(underlyingTokenAddress, depositor.amount?.toFixed(0))
        .send({ from: wallet.account, value: value?.toFixed() })
        .on("transactionHash", (hash) => {
          // console.log('Tx hash came => ', hash);
        })
        .on("receipt", async (receipt) => {
          // console.log('Tx receipt came => ', receipt);
          depositor.setState(DepositorState.SUCCESS);
        })
        .on("error", (error, receipt) => {
          depositor.setState(DepositorState.CANCEL_DEPOSIT);
          return;
        });
    } catch (err) {
      console.error(`cannot deposit: ${err}`);
      depositor.setState(DepositorState.CANCEL_DEPOSIT);
      return;
    }
  }

  return {
    input,
    setInput,
    ...wallet,
    ...balances,
    ...depositor,
    t,
    deposit,
  };
}

interface Props {
  underlyingToken: UnderlyingToken;
  depositAmount: BigNumber;
}

function DepositModal({ underlyingToken, depositAmount }: Props) {
  const c = useStyles();
  const depositModal = useDepositModal(underlyingToken);
  depositModal.setAmount(depositAmount);

  // Decimal scaling is used to scale between the string based amount (input
  // by the user) and the shared state value (a big number with no decimals
  // that will be handed to the contracts).
  const decimalScaling = new BigNumber(10).pow(tokenDecimals(wrapUnderlyingToken(underlyingToken)));

  function AcceptFee() {
    //const depositModal = useDepositModal(underlyingToken);
    return (
      <Grid container item className={c.loadingContainer} direction="column">
        <Grid item className={c.messageRow}>
          <Typography align="center" variant="h5">
            {depositModal.t("deposit-modal.accept-fee-header")}?
          </Typography>
        </Grid>
        <Grid item className={c.messageRow}>
          <Typography align="center" variant="body1">
            {depositModal.t("deposit-modal.accept-fee-msg")}
          </Typography>
        </Grid>
        <Grid container item className={c.buttonRow} justifyContent="space-between">
          <Grid item sm={5}>
            <Button
              className={c.cancelButton}
              variant="outlined"
              fullWidth
              size="large"
              onClick={() => depositModal.setState(DepositorState.HIDDEN)}
            >
              {depositModal.t("deposit-modal.cancel-btn")}
            </Button>
          </Grid>
          <Grid item sm={5}>
            <Button
              variant="contained"
              className={c.submitButton}
              fullWidth
              onClick={() => depositModal.setState(DepositorState.APPROVE)}
            >
              {depositModal.t("deposit-modal.ok-btn")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function Hidden() {
    // It is important that this component is explicitly rendered when the modal
    // is hidden, otherwise the order of the "useState" calls will change when
    // the model opens/closes, and this will cause all sorts of undefined
    // behaviour!
    //const _ = useDepositModal(underlyingToken);
    return null;
  }

  function SetUnlimtedApproval() {
    //const c = useStyles();
    //const depositModal = useDepositModal(underlyingToken);

    async function depositWithApproval(unlimited = false) {
      depositModal.setState(DepositorState.WAIT_FOR_APPROVE);

      if (!depositModal.library || !depositModal.chainId || !depositModal.account) {
        return;
      }
      const web3 = depositModal.library;
      const networkID = networkIdFromChainId(depositModal.chainId);
      const liquidityPoolAddress = contractAddress(networkID, ContractType.LiquidityPool);
      const underlyingTokenContract = contract(
        web3,
        networkID,
        contractTypeFromToken(wrapUnderlyingToken(underlyingToken)),
        depositModal.account
      );

      const amount = depositModal.amount ? new BigNumber(depositModal.amount) : new BigNumber(0);

      const maxAllowance = unlimited ? new BigNumber(2).pow(256).minus(1) : amount;

      try {
        if (!maxAllowance || maxAllowance.isNaN()) {
          depositModal.setState(DepositorState.CANCEL_APPROVE);
          return;
        }
        await underlyingTokenContract.methods
          .approve(liquidityPoolAddress, maxAllowance.toFixed(0))
          .send({ from: depositModal.account })
          .on("transactionHash", (hash) => {
            // console.log('Tx hash came => ', hash);
          })
          .on("receipt", async (receipt) => {
            // console.log('Tx receipt came => ', receipt);
            await depositModal.deposit();
          })
          .on("error", (error) => {
            depositModal.setState(DepositorState.CANCEL_APPROVE);
            return;
          });
      } catch (err) {
        console.error(`cannot approve: ${err}`);
        depositModal.setState(DepositorState.CANCEL_APPROVE);
        return;
      }
    }

    return (
      <Grid container item className={c.loadingContainer} direction="column">
        <Grid item className={c.messageRow}>
          <Typography align="center" variant="h5">
            {depositModal.t("deposit-modal.unlimited-approval-header")}
          </Typography>
        </Grid>
        <Grid item className={c.messageRow}>
          <Typography align="center" variant="body1">
            {depositModal.t("deposit-modal.unlimited-approval-popup", {
              underlyingTokenSymbol: underlyingTokenSymbol(underlyingToken),
            })}
          </Typography>
        </Grid>
        <Grid container item className={c.buttonRow} justifyContent="space-between">
          <Grid item sm={5}>
            <Button
              className={c.cancelButton}
              variant="outlined"
              fullWidth
              size="large"
              onClick={() => depositModal.setState(DepositorState.HIDDEN)}
            >
              {depositModal.t("deposit-modal.cancel-btn")}
            </Button>
          </Grid>
          <Grid item sm={5}>
            <Button
              variant="contained"
              className={c.submitButton}
              fullWidth
              onClick={async () => await depositWithApproval()}
            >
              {depositModal.t("deposit-modal.ok-btn")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function Approve() {
    //const c = useStyles();
    //const depositModal = useDepositModal(underlyingToken);

    // Get the kToken associated with the underlying token.
    // const kToken = underlyingTokenToKToken(underlyingToken);

    // Get the kToken balance of the current wallet.
    // const kTokenBalance = depositModal.balances.get(wrapKToken(kToken));
    // Get the underlying balance of the current wallet.
    const depositAmount = depositModal.amount;

    // Decimal scaling is used to scale between the string based amount (input
    // by the user) and the shared state value (a big number with no decimals
    // that will be handed to the contracts).
    const decimalScaling = new BigNumber(10).pow(
      tokenDecimals(wrapUnderlyingToken(underlyingToken))
    );

    async function checkApproval() {
      // ETH does not need to be approved before depositing.
      if (underlyingToken === UnderlyingToken.ETH) {
        await depositModal.deposit();
        return;
      }
      if (!depositModal.library || !depositModal.chainId || !depositModal.account) {
        return;
      }
      const web3 = depositModal.library;
      const networkID = networkIdFromChainId(depositModal.chainId);
      const liquidityPoolAddress = contractAddress(networkID, ContractType.LiquidityPool);

      const underlyingTokenContract = contract(
        web3,
        networkID,
        contractTypeFromToken(wrapUnderlyingToken(underlyingToken)),
        depositModal.account
      );
      const allowance = await underlyingTokenContract.methods
        .allowance(depositModal.account, liquidityPoolAddress)
        .call();

      const depositAmount = depositModal.amount ? depositModal.amount : new BigNumber(0);

      // console.log('Current allowance = ', new BigNumber(allowance).toString());
      // console.log('Deposit amount = ', depositAmount.toString());
      // if allowance is suffienct we skip to deposit
      if (new BigNumber(allowance).gte(depositAmount)) {
        // console.log('sufficient allowance');
        await depositModal.deposit();
        return;
      } else {
        depositModal.setState(DepositorState.SET_UNLIMITED_APPROVAL);
        return;
      }
    }

    return (
      <Grid container item className={c.loadingContainer} direction="column">
        <Grid item className={c.messageRow}>
          <Typography align="center" variant="h5">
            {depositModal.t("deposit-modal.deposit-header")}
          </Typography>
        </Grid>
        <Grid item className={c.messageRow}>
          <Typography align="center" variant="body1">
            {`Deposit ${
              depositAmount
                ? depositAmount.dividedBy(decimalScaling).toNumber().toPrecision(9)
                : null
            } ${underlyingTokenSymbol(underlyingToken)}?`}
          </Typography>
        </Grid>
        <Grid container item className={c.buttonRow} justifyContent="space-between">
          <Grid item sm={5}>
            <Button
              className={c.cancelButton}
              variant="outlined"
              fullWidth
              size="large"
              onClick={() => depositModal.setState(DepositorState.HIDDEN)}
            >
              {depositModal.t("deposit-modal.cancel-btn")}
            </Button>
          </Grid>
          <Grid item sm={5}>
            <Button
              variant="contained"
              className={c.submitButton}
              fullWidth
              onClick={async () => {
                // Begin with checking allowances.
                await checkApproval();
              }}
            >
              {depositModal.t("deposit-modal.deposit-btn")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function WaitForTx(message: string) {
    //const c = useStyles();
    //const depositModal = useDepositModal(underlyingToken);
    return (
      <Grid container item className={c.loadingContainer} direction="column">
        <Grid item className={c.messageRow}>
          <Typography align="center" variant="h5">
            {depositModal.t("deposit-modal.deposit-header")}
          </Typography>
        </Grid>
        <Grid item className={c.messageRow}>
          <Typography align="center" variant="body1">
            {message}
          </Typography>
        </Grid>
        <Grid container item className={c.messageRow} justifyContent="space-around">
          <MoonLoader color="#6D47D7" loading={true} size={50} />
        </Grid>
      </Grid>
    );
  }

  function Success() {
    //const c = useStyles();
    //const depositModal = useDepositModal(underlyingToken);

    // Decimal scaling is used to scale between the string based amount (input
    // by the user) and the shared state value (a big number with no decimals
    // that will be handed to the contracts).
    //const decimalScaling = new BigNumber(10).pow(tokenDecimals(wrapUnderlyingToken(underlyingToken)));

    return (
      <Grid container item className={c.loadingContainer} direction="column">
        <Grid item className={c.messageRow}>
          <Typography align="center" variant="h5">
            {depositModal.t("deposit-modal.deposit-header")}
          </Typography>
        </Grid>
        <Grid item className={c.messageRow}>
          <Typography align="center" variant="body1">
            {depositModal.t("deposit-modal.successful-deposit", {
              amount: depositModal.amount?.dividedBy(decimalScaling).toFixed(),
              token: underlyingTokenSymbol(underlyingToken),
            })}
          </Typography>
        </Grid>
        <Grid container item className={c.messageRow} justifyContent="space-between">
          <Button
            className={c.cancelButton}
            variant="contained"
            fullWidth
            onClick={() => {
              depositModal.setInput("");
              depositModal.setAmount(null);
              depositModal.setState(DepositorState.HIDDEN);
            }}
          >
            Go Back
          </Button>
        </Grid>
      </Grid>
    );
  }

  function Message(message: string) {
    //const c = useStyles();
    //const depositModal = useDepositModal(underlyingToken);
    return (
      <Grid container item className={c.loadingContainer} direction="column">
        <Grid item className={c.messageRow}>
          <Typography align="center" variant="h5">
            {depositModal.t("deposit-modal.deposit-header")}
          </Typography>
        </Grid>
        <Grid item className={c.messageRow}>
          <Typography align="center" variant="body1">
            {message}
          </Typography>
        </Grid>
        <Grid container item className={c.messageRow} justifyContent="space-between">
          <Button
            className={c.cancelButton}
            variant="contained"
            fullWidth
            size="large"
            onClick={() => depositModal.setState(DepositorState.HIDDEN)}
          >
            Ok
          </Button>
        </Grid>
      </Grid>
    );
  }

  const modalContentState = (state: DepositorState) => {
    switch (state) {
      case DepositorState.HIDDEN:
        return Hidden();
      case DepositorState.ACCEPT_FEE:
        return AcceptFee();
      case DepositorState.APPROVE:
        return Approve();
      case DepositorState.SET_UNLIMITED_APPROVAL:
        return SetUnlimtedApproval();
      case DepositorState.WAIT_FOR_APPROVE:
        return WaitForTx(
          depositModal.t("deposit-modal.deposit-wait-approval", {
            token: underlyingTokenSymbol(underlyingToken),
          })
        );
      case DepositorState.WAIT_FOR_DEPOSIT:
        return WaitForTx(
          depositModal.t("deposit-modal.deposit-wait", {
            amount: depositModal.amount?.dividedBy(decimalScaling).toFixed(),
            token: underlyingTokenSymbol(underlyingToken),
          })
        );
      case DepositorState.SUCCESS:
        return Success();
      case DepositorState.ERROR:
        return Message(depositModal.t("deposit-modal.deposit-error"));
      case DepositorState.CANCEL_APPROVE:
        return Message(depositModal.t("deposit-modal.approval-cancel"));
      case DepositorState.CANCEL_DEPOSIT:
        return Message(depositModal.t("deposit-modal.deposit-cancel"));
      default:
        return null;
    }
  };

  if (depositModal.state === DepositorState.HIDDEN) {
    return null;
  }

  return (
    <Dialog
      onClose={() => depositModal.setState(DepositorState.HIDDEN)}
      open={depositModal.state ? true : false}
      classes={{ paper: c.root }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: { zIndex: -3 },
        timeout: 500,
      }}
    >
      <div className={c.modal}>
        <StyledModal>
          <Grid container className={c.modalContent}>
            {modalContentState(depositModal.state)}
          </Grid>
        </StyledModal>
      </div>
    </Dialog>
  );
}

export default DepositModal;
