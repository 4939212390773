/* 
We use Fathom analytics.
*/

/*
URLs are automatically tracked, but extra "page" views can be manually triggered
*/
export enum Pages {
  Orders = "Trade orders",
}

/*
Create a Goal on the Fathom app to generate these codes.
*/
export enum Goals {
  ReviewOrder = "G4B1S7C8",
  TradeSuccess = "ZZEO7TEW",
  AllowanceSuccess = "3HV7MFMD",
}
