import {
  Button,
  Collapse,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { SwapVert } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { trackGoal } from "fathom-client";
import React, { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import TextButton from "../../components/common/TextButton";
import TooltipColored from "../../components/common/Tooltip";
import { ExternalLinkIcon } from "../../components/Icons";
import TradeModal from "../../components/modals/dex/TradeModal";
import { GradientBox } from "../../components/ui-components/StyledBox";
import ExchangeRates from "../../containers/exchangeRates";
import {
  ETH,
  getHidingBookInfo,
  getUserBalances,
  HidingBookToken,
  OrderDetails,
  RateReturnType,
} from "../../lib/trade";
import theme from "../../styles/theme";
import { Goals } from "./analytics";
import { ALL_MODALS_CLOSED, INITIAL_TRADE_STATE, MAX_TOKEN_NAME_LENGTH } from "./config";
import ExpiryField from "./ExpiryField";
import { FieldLabel, Row } from "./layoutComponents";
import PriceField from "./PriceField";
import reducer from "./reducer";
import TradeField from "./TradeField";
import { ModalState, TradeType } from "./types";
import {
  fetchSuggestedRate,
  getAmountInUSD,
  getTokenBalanceFromTokenList,
  getTokenBalanceInUSD,
  shouldWarnAboutPrice,
  shouldWarnAboutSellAmount,
} from "./util";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "absolute",
    width: "100%",
    height: "100%",
    paddingTop: "20px",
    flexDirection: "column",
    overflow: "auto",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flex: "1 1 auto",
    position: "absolute",
    minHeight: "200px",
    minWidth: "300px",
    maxWidth: "558px",
    maxHeight: "624px",
    borderRadius: "32px",
  },
  tradeContent: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    padding: "32px",
    borderRadius: "32px",
    justifyContent: "space-between",
  },
  tradeHeader: {
    display: "flex",
    flex: "1 1 0",
    position: "relative",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      margin: "8px 8px 8px 8px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "6px 6px 6px 6px",
    },
  },
  tradeHeaderText: {
    color: theme.palette.text.primary,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    [theme.breakpoints.up("md")]: {
      fontSize: "32px",
      lineHeight: "39px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "30px",
    },
  },
  tradeField: {
    display: "flex",
    flex: "1 1 0",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(245, 245, 245, 0.08)",
    padding: "20px",
    margin: "8px",
    borderRadius: "16px",
  },
  tradeInput: {
    display: "flex",
    flex: "1 1 auto",
    position: "relative",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
  },
  tokenInput: {
    display: "flex",
    justifyContent: "center",
  },
  tokenLabel: {
    display: "flex",
    justifyContent: "flex-start",
    color: theme.palette.text.primary,
  },
  tokenSelector: {
    marginLeft: "-6px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
    color: theme.palette.text.primary,
    fontFamily: "Inter",
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
      borderRadius: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      borderRadius: "12px",
    },
    fontWeight: "bold",
  },
  tokenAmount: {
    display: "flex",
    justifyContent: "center",
  },
  usdValue: {
    display: "flex",
    justifyContent: "flex-end",
    color: theme.palette.text.secondary,
  },
  maxButton: {
    color: theme.palette.text.primary,
    border: "1px solid",
    textTransform: "none",
    textSize: "10%",
    borderColor: "rgba(245,245,245,0.5)",
    justifySelf: "right",
    right: "0%",
    left: "0%",
  },
  reviewOrderButton: {
    [theme.breakpoints.up("md")]: {
      margin: "28px 8px 8px 8px",
      borderRadius: "16px",
      height: "56px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "21px 6px 6px 6px",
      borderRadius: "12px",
      height: "42px",
    },
  },
  tooltipIcon: {
    fontSize: 14,
    padding: "0px 5px",
    margin: "0px",
    color: theme.palette.text.secondary,
    "&:hover": {
      background: "none",
    },
  },
  docsLink: {
    textTransform: "none",
    alignContent: "center",
    padding: "0px 0px 0px 8px",
    "&:hover": {
      background: "none",
      textDecoration: "underline",
    },
  },
  alert: {
    position: "relative",
    marginBottom: "50px",
    width: "51%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

BigNumber.set({ ROUNDING_MODE: BigNumber.ROUND_DOWN });

const Trade: React.FC = () => {
  const c = useStyles();
  // const { t } = useTranslation();
  // const { account } = useWeb3React();
  //
  // const usdPrices = ExchangeRates.useContainer();
  //
  // const [state, dispatch] = useReducer(reducer, INITIAL_TRADE_STATE);
  //
  // const [modalState, setModalState] = useState<ModalState>(ALL_MODALS_CLOSED);
  // const [orderDetails, setOrderdetails] = useState<OrderDetails | undefined>(undefined);
  // const [tokenListWithBalances, setTokenListWithBalances] = useState<HidingBookToken[]>([]);

  const [alertOpen, setAlertOpen] = useState(true);
  //
  // // Dump state to local storage every time it changes
  // localStorage.setItem("tradeState", JSON.stringify(state));
  //
  // if (process.env.NODE_ENV == "development") {
  //   console.debug(state);
  // }
  //
  // // Analytics
  // if (modalState.trade) {
  //   trackGoal(Goals.ReviewOrder, 25);
  // }
  //
  // /* Data fetching */
  //
  // // Get token list and user balances
  // useEffect(() => {
  //   // TODO: replace with SWR hook
  //   async function fetchTokenListAndBalances() {
  //     const { orderDetails, tokenList } = await getHidingBookInfo();
  //     setOrderdetails(orderDetails);
  //     let tokenBalances = await getUserBalances(account, [ETH, ...tokenList.tokens]);
  //
  //     tokenBalances = tokenBalances.sort((a, b) => {
  //       try {
  //         // sort by USD value of balance if possible
  //         const diff = getTokenBalanceInUSD(b, usdPrices) - getTokenBalanceInUSD(a, usdPrices);
  //         // if both balances are the same put ROOK first (lol)
  //         if (!diff && [a.symbol, b.symbol].includes("ROOK")) {
  //           return a.symbol === "ROOK" ? -1 : 1;
  //         }
  //         return diff;
  //       } catch (error) {
  //         // if we can't get the USD price for some reason we sort by balance
  //         return (b.balance?.toNumber() ?? 0) - (a.balance?.toNumber() ?? 0);
  //       }
  //     });
  //
  //     setTokenListWithBalances(tokenBalances);
  //   }
  //   fetchTokenListAndBalances();
  // }, [account, usdPrices]);
  //
  // useEffect(() => {
  //   // TODO: replace with SWR hook
  //   const setInitialExchangeRate = async () => {
  //     //const uniswapExchangePrice = await fetchUniswapPrice(state.sellToken, state.buyToken);
  //
  //     const sellTokenAddress = state.sellToken.address.toLowerCase();
  //     const buyTokenAddress = state.buyToken.address.toLowerCase();
  //
  //     const sellTokenUSD = usdPrices[sellTokenAddress] ? usdPrices[sellTokenAddress] : 0;
  //     const buyTokenUSD = usdPrices[buyTokenAddress] ? usdPrices[buyTokenAddress] : 0;
  //
  //     let exchangeRate = "1";
  //     // If exchangeRates is done fetching, use those, else just call suggestedReturn (defaults sell amount to 1 of sellToken)
  //     if (sellTokenUSD && buyTokenUSD) {
  //       exchangeRate = sellTokenUSD.dividedBy(buyTokenUSD).toString();
  //     } else {
  //       exchangeRate = await fetchSuggestedRate(
  //         state.sellToken,
  //         state.buyToken,
  //         state.sellAmount,
  //         RateReturnType.market
  //       );
  //     }
  //
  //     // To actally wait for the fetch we have to include the dispatch in this fetching function
  //     // For some reason, React won't let you have async useEffects
  //     dispatch({
  //       type: "setExchangeRates",
  //       payload: {
  //         rate: exchangeRate,
  //         isMarket: true,
  //         isInverse: false,
  //       },
  //     });
  //   };
  //   if (state.marketRateNeedsFetching) {
  //     if ([TradeType.Wrap, TradeType.Unwrap].includes(state.tradeType)) {
  //       // Set exchange rate to 1 for wrap/unwrap
  //       dispatch({
  //         type: "setExchangeRates",
  //         payload: { rate: "1", isMarket: true, isInverse: false },
  //       });
  //     } else {
  //       dispatch({ type: "setLoadingRate" });
  //       // Run the async fetch-and-set function
  //       setInitialExchangeRate();
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state.marketRateNeedsFetching]);
  //
  // /* User action handlers */
  //
  // const handleClickMarket = async () => {
  //   dispatch({ type: "setLoadingRate" });
  //   const currentMarketPrice = await fetchSuggestedRate(
  //     state.sellToken,
  //     state.buyToken,
  //     state.sellAmount,
  //     RateReturnType.market
  //   );
  //   if (currentMarketPrice !== null) {
  //     dispatch({
  //       type: "setExchangeRates",
  //       payload: { rate: currentMarketPrice, isMarket: true, isInverse: false },
  //     });
  //   }
  // };
  //
  // const handleChangeSellToken = (token: HidingBookToken) => {
  //   if (token.address === state.buyToken.address) {
  //     dispatch({ type: "swapSides" });
  //   } else {
  //     dispatch({ type: "sellTokenChange", payload: token });
  //   }
  //   setModalState(ALL_MODALS_CLOSED);
  // };
  //
  // const handleChangeBuyToken = (token: HidingBookToken) => {
  //   if (token.address === state.sellToken.address) {
  //     dispatch({ type: "swapSides" });
  //   } else {
  //     dispatch({ type: "buyTokenChange", payload: token });
  //   }
  //   setModalState(ALL_MODALS_CLOSED);
  // };
  //
  // const handleClickSwapSides = () => {
  //   dispatch({ type: "swapSides" });
  // };
  //
  // const handleClickInverseRate = () => {
  //   if (!parseFloat(state.exchangeRate)) {
  //     return; // avoid dividing by zero if there's no exchange rate
  //   }
  //   dispatch({ type: "inverseRate" });
  // };
  //
  // const handleChangeSellAmount = (amount: string) => {
  //   dispatch({ type: "changeSellAmount", payload: amount });
  // };
  //
  // const handleChangeBuyAmount = (amount: string) => {
  //   dispatch({ type: "changeBuyAmount", payload: amount });
  // };
  //
  // /* Utility variables */
  //
  // const sellTokenBalance = getTokenBalanceFromTokenList(state.sellToken, tokenListWithBalances);
  // const sellTokenList = account
  //   ? tokenListWithBalances.filter((token) => token.balance?.gt(0))
  //   : tokenListWithBalances;
  //
  // const buyTokenBalance = getTokenBalanceFromTokenList(state.buyToken, tokenListWithBalances);
  // const buyTokenList = tokenListWithBalances;
  //
  // const warnAboutPrice = shouldWarnAboutPrice(state.marketRate, state.exchangeRate);
  // const warnAboutSellAmount = shouldWarnAboutSellAmount(
  //   getAmountInUSD(state.sellToken, state.sellAmount, usdPrices)
  // );
  //
  // // Format submit button text
  // let reviewOrderButtonText = t("trade-review-order");
  //
  // if (!account) {
  //   reviewOrderButtonText = t("header.connect-wallet-btn");
  // } else if (parseFloat(state.sellAmount) > sellTokenBalance.toNumber()) {
  //   reviewOrderButtonText = t("trade-insufficient-balance");
  // } else if (state.tradeType === TradeType.Wrap) {
  //   reviewOrderButtonText = t("trade-wrap-eth");
  // } else if (state.tradeType === TradeType.Unwrap) {
  //   reviewOrderButtonText = t("trade-unwrap-weth");
  // }

  return (
    <Grid container item className={c.root} justifyContent="center">
      <div className={c.alert}>
        {/*<Collapse in={alertOpen}>*/}
          <Alert
            severity="warning"
            // action={
            //   <IconButton
            //     aria-label="close"
            //     color="inherit"
            //     size="small"
            //     onClick={() => {
            //       setAlertOpen(false);
            //     }}
            //   >
            //     <CloseIcon fontSize="inherit" />
            //   </IconButton>
            // }
          >
            This is a legacy app. Use it only to withdraw your funds from Pool or Borrow.
            To use our new trading product that leverages the Rook Protocol, go to{" "}
            <a
              href="https://app.rook.fi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://app.rook.fi/
            </a>
            .
          </Alert>
        {/*</Collapse>*/}
      </div>

      {/*<div className={c.container} style={{ marginTop: `${alertOpen ? "70px" : "0px"}` }}>*/}
      {/*  <GradientBox>*/}
      {/*    /!* <HelpOutline style={{ position: "absolute", right: "15px", top: "15px", zIndex: 100 }} /> *!/*/}
      {/*    <Grid container item className={c.tradeContent}>*/}
      {/*      <Row>*/}
      {/*        <Grid container item className={c.tradeHeader}>*/}
      {/*          <Typography variant="h4" classes={{ h4: c.tradeHeaderText }}>*/}
      {/*            {`${t("trade-header")}`}*/}
      {/*          </Typography>*/}
      {/*        </Grid>*/}
      {/*      </Row>*/}
      {/*      <Row>*/}
      {/*        <Button*/}
      {/*          className={c.docsLink}*/}
      {/*          endIcon={*/}
      {/*            <Icon fontSize="large">*/}
      {/*              <ExternalLinkIcon />*/}
      {/*            </Icon>*/}
      {/*          }*/}
      {/*          href="https://blog.keeperdao.com/how-to-protect-yourself-from-mev-and-get-paid-for-it/"*/}
      {/*          target="_blank"*/}
      {/*          rel="noopener noreferrer"*/}
      {/*        >*/}
      {/*          {t("trade-protect")}*/}
      {/*        </Button>*/}
      {/*      </Row>*/}

      {/*      <Row>*/}
      {/*        <TradeField*/}
      {/*          token={state.sellToken}*/}
      {/*          pairToken={state.buyToken}*/}
      {/*          amount={state.sellAmount}*/}
      {/*          tokenBalance={sellTokenBalance}*/}
      {/*          setAmount={handleChangeSellAmount}*/}
      {/*          tokenListModalOn={modalState.sell}*/}
      {/*          tokenList={sellTokenList}*/}
      {/*          setModalState={setModalState}*/}
      {/*          onClickToken={() => setModalState({ ...modalState, sell: true })}*/}
      {/*          onClickTokenFromList={handleChangeSellToken}*/}
      {/*          onClickTokenBalance={() => {*/}
      {/*            handleChangeSellAmount(sellTokenBalance.toString());*/}
      {/*          }}*/}
      {/*          fillWarn={state.tradeType === TradeType.Limit && warnAboutSellAmount}*/}
      {/*        >*/}
      {/*          <FieldLabel>*/}
      {/*            <Grid item>*/}
      {/*              {t("trade-you-send")} /!* state.sellToken.name *!/*/}
      {/*              /!* Note: Hiding Book API doesn't currently return full names for tokens*/}
      {/*                    so the above code is commented out. *!/*/}
      {/*            </Grid>*/}
      {/*          </FieldLabel>*/}
      {/*        </TradeField>*/}
      {/*      </Row>*/}
      {/*      {state.tradeType === TradeType.Limit && (*/}
      {/*        <Row>*/}
      {/*          <Grid item xs={12} sm={8}>*/}
      {/*            <PriceField*/}
      {/*              pairExchangePrice={*/}
      {/*                state.isInversedRateDisplayed*/}
      {/*                  ? state.exchangeRateInversed*/}
      {/*                  : state.exchangeRate*/}
      {/*              }*/}
      {/*              buyToken={state.buyToken}*/}
      {/*              sellToken={state.sellToken}*/}
      {/*              isLoading={state.isFetchingMarketRate}*/}
      {/*              isRateInversed={state.isInversedRateDisplayed}*/}
      {/*              warn={warnAboutPrice}*/}
      {/*              dispatchNewExchangeRate={(rate) =>*/}
      {/*                dispatch({*/}
      {/*                  type: "setExchangeRates",*/}
      {/*                  payload: {*/}
      {/*                    rate,*/}
      {/*                    isMarket: false,*/}
      {/*                    isInverse: state.isInversedRateDisplayed,*/}
      {/*                  },*/}
      {/*                })*/}
      {/*              }*/}
      {/*            >*/}
      {/*              <FieldLabel>*/}
      {/*                <Grid item>*/}
      {/*                  <TextButton*/}
      {/*                    onClick={handleClickInverseRate}*/}
      {/*                    color={theme.palette.text.secondary}*/}
      {/*                  >*/}
      {/*                    {t("trade-price-per")}{" "}*/}
      {/*                    {state.isInversedRateDisplayed*/}
      {/*                      ? state.buyToken.symbol.slice(0, MAX_TOKEN_NAME_LENGTH)*/}
      {/*                      : state.sellToken.symbol.slice(0, MAX_TOKEN_NAME_LENGTH)}*/}
      {/*                  </TextButton>*/}
      {/*                </Grid>*/}
      {/*                <Grid item style={{ padding: "0px 5px" }}>*/}
      {/*                  •*/}
      {/*                </Grid>*/}
      {/*                <Grid item>*/}
      {/*                  <TooltipColored title={t("trade-mkt-tooltip")} color="#525252" delay={1000}>*/}
      {/*                    <div>*/}
      {/*                      <TextButton onClick={handleClickMarket}>*/}
      {/*                        {t("trade-set-to-mkt")}*/}
      {/*                      </TextButton>*/}
      {/*                    </div>*/}
      {/*                  </TooltipColored>*/}
      {/*                </Grid>*/}
      {/*              </FieldLabel>*/}
      {/*            </PriceField>*/}
      {/*          </Grid>*/}
      {/*          <Grid item xs={12} sm={4}>*/}
      {/*            <ExpiryField*/}
      {/*              setSecondsBeforeExpiry={(s) => dispatch({ type: "setLimitExpiry", payload: s })}*/}
      {/*            >*/}
      {/*              <FieldLabel>*/}
      {/*                <Grid item>{t("trade-tx-will-expire-in")}</Grid>*/}
      {/*              </FieldLabel>*/}
      {/*            </ExpiryField>*/}
      {/*          </Grid>*/}
      {/*        </Row>*/}
      {/*      )}*/}
      {/*      <Row>*/}
      {/*        <TradeField*/}
      {/*          token={state.buyToken}*/}
      {/*          pairToken={state.sellToken}*/}
      {/*          amount={state.buyAmount}*/}
      {/*          tokenBalance={buyTokenBalance}*/}
      {/*          setAmount={handleChangeBuyAmount}*/}
      {/*          tokenListModalOn={modalState.buy}*/}
      {/*          isLoading={!!state.buyAmount && state.isFetchingMarketRate}*/}
      {/*          tokenList={buyTokenList}*/}
      {/*          setModalState={setModalState}*/}
      {/*          onClickToken={() => setModalState({ ...modalState, buy: true })}*/}
      {/*          onClickTokenFromList={handleChangeBuyToken}*/}
      {/*        >*/}
      {/*          <FieldLabel>*/}
      {/*            <Grid item>*/}
      {/*              {t("trade-you-get")} /!* state.buyToken.name *!/*/}
      {/*              /!* Note: Hiding Book API doesn't currently return full names for tokens*/}
      {/*                    so the above code is commented out. *!/*/}
      {/*            </Grid>*/}
      {/*            <IconButton*/}
      {/*              style={{*/}
      {/*                marginLeft: "10px",*/}
      {/*                padding: "none",*/}
      {/*                color: "#f5f5f5",*/}
      {/*                height: "14px",*/}
      {/*                width: "14px",*/}
      {/*              }}*/}
      {/*              onClick={handleClickSwapSides}*/}
      {/*            >*/}
      {/*              <SwapVert />*/}
      {/*            </IconButton>*/}
      {/*          </FieldLabel>*/}
      {/*        </TradeField>*/}
      {/*      </Row>*/}

      {/*      <Row>*/}
      {/*        <Button*/}
      {/*          disabled={*/}
      {/*            parseFloat(state.sellAmount) > sellTokenBalance.toNumber() ||*/}
      {/*            !(parseFloat(state.sellAmount) > 0) ||*/}
      {/*            !state.expiry*/}
      {/*          }*/}
      {/*          variant="contained"*/}
      {/*          className={c.reviewOrderButton}*/}
      {/*          onClick={() => setModalState({ ...modalState, trade: true })}*/}
      {/*          fullWidth*/}
      {/*        >*/}
      {/*          {reviewOrderButtonText}*/}
      {/*        </Button>*/}
      {/*      </Row>*/}
      {/*    </Grid>*/}
      {/*  </GradientBox>*/}
      {/*</div>*/}

      {/*<TradeModal*/}
      {/*  tradeState={state}*/}
      {/*  active={modalState.trade}*/}
      {/*  orderDetails={orderDetails}*/}
      {/*  closeModal={() => {*/}
      {/*    setModalState(ALL_MODALS_CLOSED);*/}
      {/*  }}*/}
      {/*  warn={warnAboutPrice}*/}
      {/*  fillWarn={warnAboutSellAmount}*/}
      {/*/>*/}
    </Grid>
  );
};

export default Trade;
