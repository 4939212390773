import { Backdrop, Button, Dialog, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MoonLoader from "react-spinners/MoonLoader";
import { StyledModal } from ".";
import {
  ClaimRookState,
  RewardBalance,
  RewardData,
  RewardsBalances,
} from "../../containers/rookBalances";
import { distibutorContract } from "../../lib/contracts";
import { networkIdFromChainId } from "../../lib/network";
import { displayTokenBalance, Token, tokenDecimals } from "../../lib/tokens";
import { IOSSwitch } from "../ui-components/IOSSwitch";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "none",
    border: "none",
    position: "absolute",
  },
  modal: {
    position: "relative",
    display: "flex",
    flex: "1 1 0",
    minWidth: "575px",
    maxHeight: "675px",
  },
  modalContent: {
    display: "flex",
    flex: "1 1 0",
    position: "relative",
    height: "100%",
    width: "100%",
    padding: "24px",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  reviewContainer: {
    display: "flex",
    position: "relative",
    height: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  loadingContainer: {
    display: "flex",
    flex: "1 1 0",
    position: "relative",
    height: "100%",
    width: "100%",
    padding: "8px",
    justifyContent: "center",
    alignItems: "center",
  },
  messageRow: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "8px",
    marginBottom: "8px",
  },
  buttonRow: {
    display: "flex",
    position: "relative",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    marginTop: "8px",
    marginBottom: "8px",
  },
  cancelButton: {
    borderRadius: "8px",
    //width: '100%',
    //height: '100%',
  },
  submitButton: {
    //marginTop: '20px',
    borderRadius: "8px",
    width: "100%",
    height: "100%",
    //height: '56px',
  },
  claimablesList: {
    display: "flex",
    position: "relative",
    width: "100%",
  },
  divider: {
    position: "relative",
    width: "100%",
    background: "linear-gradient(to right, #6C46D6 0%, #09A7F3 100%)",
    opacity: "0.24",
    marginBottom: "8px",
    marginTop: "8px",
  },
}));

const ROOK_DECIMAL_SCALING = new BigNumber(10).pow(tokenDecimals(Token.ROOK));

function useClaimModal() {
  const { t } = useTranslation();
  const { library, chainId, account } = useWeb3React();
  const {
    rewardsState,
    claimModalState,
    setClaimModalState,
    amountToClaim,
    setAmountToClaim,
    loading,
  } = RewardsBalances.useContainer();

  // State variables to keep track of which rewards categories can be claimed, and which have been selected to claim
  const [claimableCategories, setClaimableCategories] = useState<RewardData[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<boolean[]>([]);

  useEffect(() => {
    if (!library || !chainId || !account) return;

    // Filter out rewards categories with zero balance
    const claimable = rewardsState.rewardsData.filter((reward) => reward.claimable_amount.gt(0));

    // Initialize array for selected categories
    const selected = claimable.map((x) => false);

    // If user has zero claimable balance set modal state accordingly
    if (claimable.length === 0 && claimModalState == ClaimRookState.WAIT_FOR_PROCEED_TO_CLAIMING) {
      setClaimModalState(ClaimRookState.NOTHING_TO_CLAIM);
    }

    setClaimableCategories(claimable);
    setSelectedCategories(selected);
  }, [library, chainId, account]);

  return {
    t,
    library,
    account,
    chainId,
    claimModalState,
    setClaimModalState,
    amountToClaim,
    setAmountToClaim,
    selectedCategories,
    setSelectedCategories,
    claimableCategories,
  };
}

function Hidden() {
  // It is important that this component is explicitly rendered when the modal
  // is hidden, otherwise the order of the "useState" calls will change when
  // the model opens/closes, and this will cause all sorts of undefined
  // behaviour!
  const _ = useClaimModal();
  return null;
}

function WaitForProceed() {
  const c = useStyles();
  const claimModal = useClaimModal();

  const {
    library,
    account,
    chainId,
    claimableCategories,
    selectedCategories,
    setSelectedCategories,
    amountToClaim,
    setAmountToClaim,
    setClaimModalState,
  } = claimModal;

  const [disabled, setDisabled] = useState(true);

  // Calculate total amount to be claimed when selection changes
  useEffect(() => {
    let total = new BigNumber(0);
    selectedCategories.map((selected, idx) => {
      if (selected) {
        total = total.plus(claimableCategories[idx].claimable_amount);
      }
    });
    setAmountToClaim(total);
    if (total.gt(0)) {
      setDisabled(false);
    }
  }, [selectedCategories, claimableCategories]);

  // Update which categories are selected for claim
  function handleSelectCategory(idx: number) {
    const selected = [...selectedCategories];
    selected[idx] = !selected[idx];
    console.log("setting selected categories to ", selected);
    setSelectedCategories(selected);
  }

  function claimableList() {
    return (
      <Grid item className={c.claimablesList}>
        <Grid container direction="column">
          {claimableCategories.map((reward, idx) => {
            const claimableBalance = reward.claimable_amount;
            return (
              <Grid item style={{ width: "100%" }}>
                <Grid
                  key={reward.indibo_key}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  direction="row"
                >
                  <Grid
                    container
                    item
                    justifyContent="flex-start"
                    direction="row"
                    alignItems="center"
                    xs={3}
                  >
                    <Typography variant="body1">{reward.display_name}</Typography>
                  </Grid>

                  <Grid
                    container
                    item
                    justifyContent="flex-end"
                    direction="row"
                    alignItems="center"
                    xs={8}
                  >
                    <Typography variant="body1">
                      {displayTokenBalance(Token.ROOK, claimableBalance)} ROOK
                    </Typography>
                    <IOSSwitch
                      checked={selectedCategories[idx]}
                      onChange={() => handleSelectCategory(idx)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  }

  async function claimAll() {
    setClaimModalState(ClaimRookState.WAIT_FOR_CLAIM);
    if (!library || !chainId || !account) {
      return;
    }

    try {
      for (let i = 0; i < claimableCategories.length; i += 1) {
        const claimable = claimableCategories[i];
        const selected = selectedCategories[i];

        if (!selected) {
          continue;
        }

        const contractType = claimable.contract;
        const distContract = distibutorContract(
          library,
          networkIdFromChainId(chainId),
          account,
          contractType
        );

        if (!distContract) {
          throw new Error(`Distributor contract for ${contractType} does not exist!`);
        }
        await contractClaim(distContract, account, claimable.reward_balance);
      }
      setClaimModalState(ClaimRookState.SUCCESS);
    } catch (err) {
      console.error(`cannot claim: ${err}`);
      setClaimModalState(ClaimRookState.ERROR);
    }
  }

  return (
    <Grid container item className={c.loadingContainer} direction="column">
      <Grid item className={c.messageRow}>
        <Typography align="center" variant="h5">
          {claimModal.t("claim-modal.claim-header")}
        </Typography>
      </Grid>
      <Divider className={c.divider} />
      {claimableList()}
      <Divider className={c.divider} />
      <Grid item className={c.messageRow}>
        <Typography align="center" variant="h6">
          {claimModal.t("claim-modal.claim-total-pending")}:{" "}
          {displayTokenBalance(Token.ROOK, amountToClaim)} ROOK
        </Typography>
      </Grid>
      <Grid container item className={c.buttonRow} justifyContent="space-between">
        <Grid item sm={5}>
          <Button
            className={c.cancelButton}
            variant="outlined"
            fullWidth
            size="large"
            onClick={() => claimModal.setClaimModalState(ClaimRookState.HIDDEN)}
          >
            {claimModal.t("claim-modal.cancel-btn")}
          </Button>
        </Grid>
        <Grid item sm={5}>
          <Button
            variant="contained"
            disabled={!amountToClaim.gt(0)}
            className={c.submitButton}
            fullWidth
            onClick={async () => {
              // Do nothing if the button is disabled.
              if (disabled) {
                return;
              }
              // Begin claiming.
              await claimAll();
            }}
          >
            {claimModal.t("claim-modal.claim-btn")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

function WaitForTx(props: { message: string }) {
  const c = useStyles();
  const claimModal = useClaimModal();
  const { message } = props;
  return (
    <Grid container item className={c.loadingContainer} direction="column">
      <Grid item className={c.messageRow}>
        <Typography align="center" variant="h5">
          {claimModal.t("claim-modal.claim-header")}
        </Typography>
      </Grid>
      <Grid item className={c.messageRow}>
        <Typography align="center" variant="body1">
          {message}
        </Typography>
      </Grid>
      <Grid container item className={c.messageRow} justifyContent="space-around">
        <MoonLoader color="#6D47D7" loading={true} size={50} />
      </Grid>
    </Grid>
  );
}

function Success() {
  const c = useStyles();
  const claimModal = useClaimModal();
  return (
    <Grid container item className={c.loadingContainer} direction="column">
      <Grid item className={c.messageRow}>
        <Typography align="center" variant="h5">
          {claimModal.t("claim-modal.claim-header")}
        </Typography>
      </Grid>
      <Grid item className={c.messageRow}>
        <Typography align="center" variant="body1">
          {claimModal.t("claim-modal.successful-claim", {
            amount: claimModal.amountToClaim.dividedBy(ROOK_DECIMAL_SCALING).toFixed(),
          })}
        </Typography>
      </Grid>
      <Grid container item className={c.messageRow} justifyContent="space-around">
        <Grid item xs={7}>
          <Button
            className={c.cancelButton}
            variant="outlined"
            fullWidth
            size="large"
            onClick={() => {
              claimModal.setAmountToClaim(new BigNumber(0));
              claimModal.setClaimModalState(ClaimRookState.HIDDEN);
            }}
          >
            {claimModal.t("claim-modal.ok-btn")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

function Message(props: { message: string }) {
  const c = useStyles();
  const claimModal = useClaimModal();
  const { message } = props;
  return (
    <Grid container item className={c.loadingContainer} direction="column">
      <Grid item className={c.messageRow}>
        <Typography align="center" variant="h5">
          {claimModal.t("claim-modal.claim-header")}
        </Typography>
      </Grid>
      <Grid item className={c.messageRow}>
        <Typography align="center" variant="body1">
          {message}
        </Typography>
      </Grid>
      <Grid container item className={c.messageRow} justifyContent="space-around">
        <Grid item xs={7}>
          <Button
            className={c.cancelButton}
            variant="outlined"
            fullWidth
            size="large"
            onClick={() => claimModal.setClaimModalState(ClaimRookState.HIDDEN)}
          >
            {claimModal.t("claim-modal.ok-btn")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

function ClaimRookModal() {
  const c = useStyles();
  const { t } = useTranslation();
  const claimModal = useClaimModal();

  const modalContentState = (state: ClaimRookState) => {
    switch (state) {
      case ClaimRookState.HIDDEN:
        return <Hidden />;
      case ClaimRookState.NOTHING_TO_CLAIM:
        return <Message message={"Nothing to claim"} />;
      case ClaimRookState.WAIT_FOR_PROCEED_TO_CLAIMING:
        return <WaitForProceed />;
      case ClaimRookState.WAIT_FOR_CLAIM:
        return <WaitForTx message={t("claim-model")} />;
      // t('claim-modal.claim-wait', {
      //     amount: displayTokenBalance(Token.ROOK, calculateClaimable(claimable).totalRewardsPending),
      // }),
      case ClaimRookState.SUCCESS:
        return <Success />;
      case ClaimRookState.ERROR:
        return <Message message={claimModal.t("claim-modal.claim-error")} />;
      case ClaimRookState.CANCEL_CLAIM:
        return <Message message={claimModal.t("claim-modal.claim-cancel")} />;
      default:
        throw new TypeError(`non-exhaustive pattern: ${state}`);
    }
  };

  if (claimModal.claimModalState === ClaimRookState.HIDDEN) {
    return null;
  }

  return (
    <Dialog
      onClose={() => claimModal.setClaimModalState(ClaimRookState.HIDDEN)}
      open={claimModal.claimModalState ? true : false}
      classes={{ paper: c.root }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: { zIndex: -3 },
        timeout: 500,
      }}
    >
      <div className={c.modal}>
        <StyledModal>
          <Grid container className={c.modalContent}>
            {modalContentState(claimModal.claimModalState)}
          </Grid>
        </StyledModal>
      </div>
    </Dialog>
  );
}

function contractClaim(contract: any, account: string, claimable: RewardBalance) {
  return new Promise((resolve, reject) => {
    const tx = contract.methods
      .claim(account, claimable.earnings.toFixed(0), claimable.nonce, claimable.signature)
      .send({ from: account })
      .on("transactionHash", (hash) => {
        // console.log('Tx hash came => ', hash);
      })
      .on("receipt", async (receipt) => {
        resolve(receipt);
      })
      .on("error", (error, receipt) => {
        reject(error);
      });
  });
}
export default ClaimRookModal;
