import React, { useState, useEffect } from "react";
import Select, { ValueType, components, ControlProps } from "react-select";
import AppSettings from "../containers/appSettings";
import { ReactComponent as Glob } from "../styles/images/glob.svg";

import { useTranslation } from "react-i18next";
import { relative } from "path";
import { CaretDownIcon } from "./Icons";

import {
  makeStyles,
  withStyles,
  Grid,
  Typography,
  Divider,
  Hidden,
  Icon,
  Button,
  Menu,
  MenuItem,
  MenuProps,
  ListItemIcon,
} from "@material-ui/core";

type OptionType = { label: string; value: string };

const options: OptionType[] = [
  { value: "en", label: "EN" }, //label: 'ENGLISH ',
  { value: "ch", label: "CN" }, //label: '简体中文'
  { value: "kr", label: "KR" }, //label: '한국어'
  { value: "ru", label: "RU" }, //label: 'Русский'
  { value: "ge", label: "GE" }, //label: 'Deutsch'
];

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    position: "relative",
    height: "60px",
    width: "95%",
    alignItems: "center",
  },
  headerRight: {
    display: "flex",
    position: "relative",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  headerLeft: {
    display: "flex",
    position: "relative",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  langDropdownButton: {
    justifyContent: "center",
    alignItems: "center",
    border: "none",
  },
  langDropdown: {
    "&:focus": {
      backgroundColor: "black",
    },
  },
  headerCenter: {},
}));

const StyledMenu = withStyles({
  paper: {
    border: "none",
    borderRadius: "8px",
    background: "rgba(245,245,245,0.12)",
  },
  list: {
    textTransform: "none",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "bold",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const LanguageSelector = () => {
  const c = useStyles();
  const { i18n } = useTranslation();
  const [value, setValue] = useState(options[0]);
  const { isMobile } = AppSettings.useContainer();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    // Update the state when the language has changed.
    const valuesList = options.filter((option) => option.value === i18n.language);
    if (valuesList.length > 0) {
      setValue(valuesList[0]);
    }
  }, [i18n.language]);

  const changeLanguage = (event: ValueType<OptionType, boolean>) => {
    i18n.changeLanguage((event as OptionType).value);
  };

  const handleDropdownOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    i18n.changeLanguage(value);
  };

  return (
    <Grid item xs={3}>
      <Button
        variant="outlined"
        aria-controls="token-menu"
        aria-haspopup="true"
        className={c.langDropdownButton}
        disableElevation
        disableRipple
        fullWidth
        endIcon={<CaretDownIcon />}
        onClick={handleDropdownOpen}
      >
        {value.label}
      </Button>
      <StyledMenu
        id="language-menu"
        anchorEl={anchorEl}
        className={c.langDropdown}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        BackdropProps={{ style: { backgroundColor: "black", opacity: 0.95 } }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={(event) => handleLanguageChange(event, option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </StyledMenu>
    </Grid>
  );
};

const customStyles = {
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    justifyContent: "center",
    padding: "0",
    // 'padding-left': '10px',
    height: "40px",
    width: "45px",
    "font-size": "20px",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "rgba(0,0,0,0)",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: "white",
    backgroundColor: state.isSelected ? "grey" : "rgba(0,0,0,0)",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0)",
    },
  }),
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "rgba(0,0,0,0)",
    height: "40px",
    cursor: "pointer",
    color: "white",
    border: "0",
    dispay: "flex",
    width: "72px",
    boxShadow: "none",
    "justify-content": "center",
    // 'padding-left': '5px',
    // '&:hover': {
    //     border: '1px solid white',
    // },
  }),

  singleValue: (provided: any) => ({
    ...provided,
    color: "white",
    // margin: 0,
    // position: 'relative',
    // display: 'flex',
    // justifyContent: 'center',
    // 'align-items': 'center',
  }),
};

export default LanguageSelector;
