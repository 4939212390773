import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import {
  UnderlyingToken,
  wrapUnderlyingToken,
  underlyingTokenToKToken,
  wrapKToken,
} from "../../lib/tokens";
import BigNumber from "bignumber.js";
import { networkIdFromChainId } from "../../lib/network";
import { useWeb3React } from "@web3-react/core";
import { tokenID } from "../../lib/graphql";

interface TokenResponse {
  ktoken: IToken;
}

interface PoolAssetsResponse {
  liquidityPoolSupplies: { supply: string }[];
}
interface ListPoolAssetsResponse {
  ethPoolBalance: { supply: string }[];
  wethPoolBalance: { supply: string }[];
  daiPoolBalance: { supply: string }[];
  usdcPoolBalance: { supply: string }[];
  btcPoolBalance: { supply: string }[];
}

interface PoolTracesResponse {
  liquidityPoolTraces: {
    earned: string;
    deposited: string;
    updatedAt: string;
  }[];
}

interface IToken {
  totalSupply: string;
}

interface Variables {
  id: string;
}

interface ListVariables {
  id1: string;
  id2: string;
  id3: string;
  id4: string;
  id5: string;
}

interface TokenTotalSupplyListResponse {
  kethSupply: IToken;
  kwethSupply: IToken;
  kdaiSupply: IToken;
  kusdcSupply: IToken;
  kbtcSupply: IToken;
}

interface PoolBalanceListResponse {
  kethSupply: IToken;
  kwethSupply: IToken;
  kdaiSupply: IToken;
  kusdcSupply: IToken;
  kbtcSupply: IToken;
}

interface APYVariables {
  id: string;
  block: number;
}

export function useTotalSupplyOfKTokenList() {
  const { chainId } = useWeb3React();
  const { chainId: networkChainId } = useWeb3React("infura");
  const chain = chainId ? chainId : networkChainId;

  const { data, loading, error } = useQuery<TokenTotalSupplyListResponse, ListVariables>(
    gql`
      query getKTokenSupply($id1: ID!, $id2: ID!, $id3: ID!, $id4: ID!, $id5: ID!) {
        kethSupply: ktoken(id: $id1) {
          totalSupply
        }
        kwethSupply: ktoken(id: $id2) {
          totalSupply
        }
        kdaiSupply: ktoken(id: $id3) {
          totalSupply
        }
        kusdcSupply: ktoken(id: $id4) {
          totalSupply
        }
        kbtcSupply: ktoken(id: $id5) {
          totalSupply
        }
      }
    `,
    {
      variables: {
        id1: tokenID(
          wrapKToken(underlyingTokenToKToken(UnderlyingToken.ETH)),
          networkIdFromChainId(chain)
        ),
        id2: tokenID(
          wrapKToken(underlyingTokenToKToken(UnderlyingToken.WETH)),
          networkIdFromChainId(chain)
        ),
        id3: tokenID(
          wrapKToken(underlyingTokenToKToken(UnderlyingToken.DAI)),
          networkIdFromChainId(chain)
        ),
        id4: tokenID(
          wrapKToken(underlyingTokenToKToken(UnderlyingToken.USDC)),
          networkIdFromChainId(chain)
        ),
        id5: tokenID(
          wrapKToken(underlyingTokenToKToken(UnderlyingToken.BTC)),
          networkIdFromChainId(chain)
        ),
      },
      pollInterval: 20000,
    }
  );

  if (error) {
    return {
      kethSupply: new BigNumber(0),
      kwethSupply: new BigNumber(0),
      kdaiSupply: new BigNumber(0),
      kusdcSupply: new BigNumber(0),
      kbtcSupply: new BigNumber(0),
    };
  }

  return {
    kethSupply:
      data && data.kethSupply ? new BigNumber(data.kethSupply.totalSupply) : new BigNumber(0),
    kwethSupply:
      data && data.kwethSupply ? new BigNumber(data.kwethSupply.totalSupply) : new BigNumber(0),
    kdaiSupply:
      data && data.kdaiSupply ? new BigNumber(data.kdaiSupply.totalSupply) : new BigNumber(0),
    kusdcSupply:
      data && data.kusdcSupply ? new BigNumber(data.kusdcSupply.totalSupply) : new BigNumber(0),
    kbtcSupply:
      data && data.kbtcSupply ? new BigNumber(data.kbtcSupply.totalSupply) : new BigNumber(0),
  };
}

export function usePoolAssetsList() {
  const { chainId } = useWeb3React();
  const { chainId: networkChainId } = useWeb3React("infura");
  const chain = chainId ? chainId : networkChainId;

  const { data, error, loading } = useQuery<ListPoolAssetsResponse, ListVariables>(
    gql`
      query getListPoolAssets($id1: ID!, $id2: ID!, $id3: ID!, $id4: ID!, $id5: ID!) {
        ethPoolBalance: liquidityPoolSupplies(where: { underlyingToken: $id1 }) {
          supply
          updatedAt
        }
        wethPoolBalance: liquidityPoolSupplies(where: { underlyingToken: $id2 }) {
          supply
          updatedAt
        }
        daiPoolBalance: liquidityPoolSupplies(where: { underlyingToken: $id3 }) {
          supply
          updatedAt
        }
        usdcPoolBalance: liquidityPoolSupplies(where: { underlyingToken: $id4 }) {
          supply
          updatedAt
        }
        btcPoolBalance: liquidityPoolSupplies(where: { underlyingToken: $id5 }) {
          supply
          updatedAt
        }
      }
    `,
    {
      variables: {
        id1: tokenID(wrapUnderlyingToken(UnderlyingToken.ETH), networkIdFromChainId(chain)),
        id2: tokenID(wrapUnderlyingToken(UnderlyingToken.WETH), networkIdFromChainId(chain)),
        id3: tokenID(wrapUnderlyingToken(UnderlyingToken.DAI), networkIdFromChainId(chain)),
        id4: tokenID(wrapUnderlyingToken(UnderlyingToken.USDC), networkIdFromChainId(chain)),
        id5: tokenID(wrapUnderlyingToken(UnderlyingToken.BTC), networkIdFromChainId(chain)),
      },
      pollInterval: 20000,
    }
  );

  if (error) {
    return {
      ethPoolBalance: new BigNumber(0),
      wethPoolBalance: new BigNumber(0),
      daiPoolBalance: new BigNumber(0),
      usdcPoolBalance: new BigNumber(0),
      btcPoolBalance: new BigNumber(0),
    };
  }

  return {
    ethPoolBalance:
      data && data.ethPoolBalance.length
        ? new BigNumber(data.ethPoolBalance[0].supply)
        : new BigNumber(0),
    wethPoolBalance:
      data && data.wethPoolBalance.length
        ? new BigNumber(data.wethPoolBalance[0].supply)
        : new BigNumber(0),
    daiPoolBalance:
      data && data.daiPoolBalance.length
        ? new BigNumber(data.daiPoolBalance[0].supply)
        : new BigNumber(0),
    usdcPoolBalance:
      data && data.usdcPoolBalance.length
        ? new BigNumber(data.usdcPoolBalance[0].supply)
        : new BigNumber(0),
    btcPoolBalance:
      data && data.btcPoolBalance.length
        ? new BigNumber(data.btcPoolBalance[0].supply)
        : new BigNumber(0),
  };
}

export function useKTokenSupply(selectedToken: UnderlyingToken): {
  totalSupply: BigNumber;
} {
  const { chainId } = useWeb3React();
  const { chainId: networkChainId } = useWeb3React("infura");
  const chain = chainId ? chainId : networkChainId;
  const { data, loading, error } = useQuery<TokenResponse, Variables>(
    gql`
      query getKTokenSupply($id: ID!) {
        ktoken(id: $id) {
          totalSupply
        }
      }
    `,
    {
      variables: {
        id: tokenID(
          wrapKToken(underlyingTokenToKToken(selectedToken)),
          networkIdFromChainId(chain)
        ),
      },
      pollInterval: 10000,
    }
  );

  if (error) {
    return {
      totalSupply: new BigNumber(0),
    };
  }

  return {
    totalSupply: data && data.ktoken ? new BigNumber(data.ktoken.totalSupply) : new BigNumber(0),
  };
}

export function usePoolAssets(selectedToken: UnderlyingToken): {
  poolAssets: BigNumber;
} {
  const { chainId } = useWeb3React();
  const { chainId: networkChainId } = useWeb3React("infura");
  const chain = chainId ? chainId : networkChainId;

  const { data, error, loading } = useQuery<PoolAssetsResponse, Variables>(
    gql`
      query getPoolAssets($id: ID!) {
        liquidityPoolSupplies(where: { underlyingToken: $id }) {
          supply
          updatedAt
        }
      }
    `,
    {
      variables: {
        id: tokenID(wrapUnderlyingToken(selectedToken), networkIdFromChainId(chain)),
      },
      pollInterval: 10000,
    }
  );

  if (error) {
    return {
      poolAssets: new BigNumber(0),
    };
  }

  return {
    poolAssets:
      data && data.liquidityPoolSupplies.length
        ? new BigNumber(data.liquidityPoolSupplies[0].supply)
        : new BigNumber(0),
  };
}

export function usePoolEarnings(selectedToken: UnderlyingToken): {
  earnings: BigNumber;
  loading?: boolean;
} {
  const { chainId } = useWeb3React();
  const { chainId: networkChainId } = useWeb3React("infura");
  const chain = chainId ? chainId : networkChainId;
  const { data, loading, error } = useQuery<PoolTracesResponse, Variables>(
    gql`
      query getEarnings($id: ID!) {
        liquidityPoolTraces(first: 1, where: { underlyingToken: $id }) {
          id
          updatedAt
          earned
          deposited
        }
      }
    `,
    {
      variables: {
        id: tokenID(wrapUnderlyingToken(selectedToken), networkIdFromChainId(chain)),
      },
      pollInterval: 30000,
    }
  );

  if (error) {
    console.log(error);
    return {
      earnings: new BigNumber(0),
    };
  }

  return {
    earnings:
      data && data.liquidityPoolTraces.length
        ? new BigNumber(data.liquidityPoolTraces[0].earned)
        : new BigNumber(0),
    loading,
  };
}
