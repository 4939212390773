import {
  Drawer,
  Grid,
  Hidden,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Omit } from "@material-ui/types";
import React, { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, LinkProps as RouterLinkProps, useLocation } from "react-router-dom";
import { ReactComponent as LogoInline } from "../styles/images/KeeperDAO_Logo_Inline_White.svg";
import { ReactComponent as Logo } from "../styles/images/KeeperDAO_Logo_Monogram_White.svg";
import { ReactComponent as ExternalLink } from "../styles/images/Menu Icons/external-link.svg";
import {
  DiscordIcon,
  GhostIcon,
  GitHubIcon,
  PoolIcon,
  TradeIcon,
  TwitterIcon,
  VaultIcon,
  VoteIcon,
  OhmIcon,
  ExternalLinkIcon,
} from "./Icons";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      width: "18%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },
    //minWidth: '210px',
    height: "100%",
    background: "rgba(245, 245, 245, 0.04)",
  },
  drawerGradient: {
    position: "absolute",
    left: "10.67%",
    right: "10.67%",
    top: "3.12%",
    bottom: "3.12%",

    background:
      "linear-gradient(137.97deg, rgba(109, 71, 215, 0.04) 23.7%, rgba(151, 71, 215, 0.04) 66.98%, rgba(215, 71, 90, 0.04) 76.3%)",
    filter: "blur(80px)",
  },
  drawerContent: {
    position: "relative",
    height: "100%",
    width: "100%",
    padding: "3%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    //alignItems: 'flex-start',
  },
  toolbarIcon: {
    display: "flex",
    width: "100%",
  },
  topIconButton: {
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      background: "none",
    },
  },
  listItemSelected: {
    color: theme.palette.action.hover,
    background: "none",
    //left: '4px',
    //borderLeft: '4px solid',
    //borderColor: theme.palette.action.hover,
  },
  listItemIcon: {},
  primaryList: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  topContent: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "space-around",
  },
  bottomContent: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  secondaryList: {
    //display: 'flex',
    //textAlign: 'center',
  },
  externalLinks: {
    display: "flex",
    justifyContent: "space-around",
  },
  iconButton: {
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.action.selected,
    },
  },
  listItemText: {
    fontFamily: "Inter",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  bottomListText: {
    fontFamily: "Inter",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "15px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  openDrawerButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

enum Icons {
  Trade,
  Borrow,
  Pool,
  Vote,
  Ohm,
}

const smallIcons = {
  [Icons.Trade]: <TradeIcon />,
  [Icons.Borrow]: <VaultIcon />,
  [Icons.Pool]: <PoolIcon />,
  [Icons.Vote]: <VoteIcon />,
  [Icons.Ohm]: <OhmIcon />,
};

const largeIcons = {
  [Icons.Trade]: <TradeIcon height="48px" width="48px" viewBox="0 0 28 28" />,
  [Icons.Borrow]: <VaultIcon height="48px" width="48px" viewBox="0 0 28 28" />,
  [Icons.Pool]: <PoolIcon height="48px" width="48px" viewBox="0 0 28 28" />,
  [Icons.Vote]: <VoteIcon height="48px" width="48px" viewBox="0 0 28 28" />,
  [Icons.Ohm]: <OhmIcon height="48px" width="48px" />,
};

interface ListItemLinkProps {
  icon: Icons;
  primary: string;
  to: string;
}

function ListItemLink(props: ListItemLinkProps) {
  const c = useStyles();
  const { icon, primary, to } = props;
  const { pathname } = useLocation();

  const selected = "/" + pathname.split("/")[1] === to;

  const renderLink = useMemo(
    () =>
      forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <ListItem
      button
      selected={selected}
      component={renderLink}
      classes={{ selected: c.listItemSelected }}
      style={{
        background: "none",
        justifyContent: "flex-start",
        display: "flex",
      }}
    >
      {/*<Hidden smDown>*/}
      <ListItemIcon
        style={{
          color: "inherit",
          justifyContent: "flex-start",
          marginRight: "-14px",
        }}
      >
        {smallIcons[icon]}
      </ListItemIcon>
      <ListItemText classes={{ primary: c.listItemText }} primary={primary} />
      {/*</Hidden>*/}
      {/*
      <Hidden mdUp>
        <ListItemIcon
          style={{
            position: "relative",
            display: "flex",
            height: "50px",
            width: "100%",
            color: "inherit",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {largeIcons[icon]}
        </ListItemIcon>
      </Hidden>
      */}
    </ListItem>
  );
}

interface drawerProps {
  mobileOpen: boolean;
  buttonToggle: boolean;
}

export default function Sidedrawer(drawerProps): any {
  const c = useStyles();
  const { t } = useTranslation();

  const roadmapLink = forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
    <RouterLink to={"/roadmap"} ref={ref} {...itemProps} />
  ));

  const drawer = (
    <div className={c.drawerContent}>
      <div className={c.topContent}>
        {/*<Hidden smDown>*/}
        <div className={c.toolbarIcon}>
          <IconButton
            className={c.topIconButton}
            style={{ width: "80%" }}
            href="https://keeperdao.com/#/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LogoInline />
          </IconButton>
        </div>
        {/*</Hidden>*/}
        {/*
        <Hidden mdUp>
          <div className={c.topIconButton}>
            <IconButton
              style={{
                width: "100%",
                maxWidth: "56px",
              }}
              href="https://keeperdao.com/#/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Logo />
            </IconButton>
          </div>
        </Hidden>
        */}

        <List className={c.primaryList}>
          <ListItemLink
            to="/trade"
            primary={t("sidedrawer-trade")}
            icon={Icons.Trade}
          ></ListItemLink>
          <ListItemLink
            to="/vaults"
            primary={t("sidedrawer-borrow")}
            icon={Icons.Borrow}
          ></ListItemLink>
          <ListItemLink to="/pool" primary={t("sidedrawer-pool")} icon={Icons.Pool}></ListItemLink>
          <ListItem
            button
            component="a"
            classes={{ selected: c.listItemSelected }}
            style={{
              background: "none",
              justifyContent: "flex-start",
              display: "flex",
            }}
            href="https://pro.olympusdao.finance/?utm_source=keeperdao&utm_medium=affiliate&utm_campaign=op-affiliate"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/*<Hidden smDown>*/}
            <ListItemIcon
              style={{
                color: "inherit",
                justifyContent: "flex-start",
                marginRight: "-14px",
              }}
            >
              {smallIcons[4]}
            </ListItemIcon>
            <ListItemText
              classes={{ primary: c.listItemText }}
              primary={
                <span style={{ display: "flex", alignItems: "center" }}>
                  {"Bond"}{" "}
                  <SvgIcon
                    style={{ marginLeft: "8px", height: "12px", width: "12px" }}
                    component={ExternalLink}
                    viewBox="0 0 15 15"
                  />
                </span>
              }
            />
            {/*</Hidden>*/}
            {/*
            <Hidden mdUp>
              <ListItemIcon
                style={{
                  position: "relative",
                  display: "flex",
                  height: "50px",
                  width: "100%",
                  color: "inherit",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {largeIcons[4]}
              </ListItemIcon>
            </Hidden>
            */}
          </ListItem>
        </List>
      </div>

      <div className={c.bottomContent}>
        {/* Secondary page links */}
        <List className={c.secondaryList}>
          <div>
            {/* <Hidden smDown>

                      <Tooltip title="Coming Soon!" placement="top-start">
                        <ListItem button>
                          <ListItemText
                            classes={{ primary: c.bottomListText }}
                            primary={t("sidedrawer-metrics")}
                          />
                        </ListItem>
                      </Tooltip>

                      <ListItem button component={roadmapLink}>
                        <ListItemText
                          classes={{ primary: c.bottomListText }}
                          primary={t("sidedrawer-roadmap")}
                        />
                      </ListItem>

                      <Tooltip title="Coming Soon!" placement="left">
                                          <ListItem button>
                                              <ListItemText classes={{primary: c.bottomListText}} primary="Documentation" />
                                          </ListItem>
                              </Tooltip>
                    </Hidden>*/}
          </div>
        </List>

        {/* External links */}
        <Grid container className={c.externalLinks} direction="row">
          <Grid container item justifyContent="center" xs={3} sm={3} md={3}>
            <IconButton
              className={c.iconButton}
              href="https://twitter.com/Keeper_DAO"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon />
            </IconButton>
          </Grid>
          <Grid container item justifyContent="center" xs={3} sm={3} md={3}>
            <IconButton
              className={c.iconButton}
              href="https://discord.gg/3JUgvyyNhA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <DiscordIcon />
            </IconButton>
          </Grid>
          <Grid container item justifyContent="center" xs={3} sm={3} md={3}>
            <IconButton
              className={c.iconButton}
              href="https://blog.keeperdao.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GhostIcon />
            </IconButton>
          </Grid>
          <Grid container item justifyContent="center" xs={3} sm={3} md={3}>
            <IconButton
              className={c.iconButton}
              href="https://github.com/keeperdao"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitHubIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    </div>
  );

  return (
    <div>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          open={drawerProps.mobileOpen}
          onClose={drawerProps.buttonToggle}
          classes={{ paper: c.drawerPaper }}
          ModalProps={{
            keepMounted: true,
          }}
          BackdropProps={{ style: { backgroundColor: "black", opacity: 0.95 } }}
        >
          <div className={c.drawerGradient}></div>
          {drawer}
        </Drawer>
      </Hidden>

      <Hidden smDown>
        <Drawer variant="permanent" open classes={{ paper: c.drawerPaper }}>
          <div className={c.drawerGradient}></div>
          {drawer}
        </Drawer>
      </Hidden>
    </div>
  );
}
