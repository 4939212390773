import BigNumber from "bignumber.js";
import { Token, tokenBalanceWithDecimals, tokenDecimals } from "../lib/tokens";
import { NetworkID } from "../lib/network";
import config from "./config";
import axios from "axios";
import Web3 from "web3";

const DAILY_HOURS = 24;
const YEAR = 365;
const WEEK = 7;
const BLOCKS_PER_YEAR = 2425790;

const ACT_IV_START_BLOCK = new BigNumber(13617668);
const ACT_IV_END_BLOCK = new BigNumber(13720196);
const ACT_IV_LP_REWARDS = new BigNumber(1293.6);

const ACT_IV_LP_REWARDS_PER_BLOCK = ACT_IV_LP_REWARDS.dividedBy(
  ACT_IV_END_BLOCK.minus(ACT_IV_START_BLOCK)
);

// hard coded unix timestamp for act iv end block
const ACT_IV_END_TIMESTAMP = new BigNumber(1638304726000);

interface APY {
  percent: string;
  number: BigNumber;
}

export enum BlocksPeriod {
  MINUTE,
  HOUR,
  DAY,
  WEEK,
  YEAR,
}

export function calculateBlocksPerPeriod(network: NetworkID, length: BlocksPeriod): number {
  const speed = network === NetworkID.Mainnet ? 12 : 4;
  const perMinute = 60 / speed;
  const perHour = Math.round(perMinute * 60);

  switch (length) {
    case BlocksPeriod.MINUTE:
      return Math.round(perMinute);
    case BlocksPeriod.HOUR:
      return perHour;
    case BlocksPeriod.DAY:
      return perHour * DAILY_HOURS;
    case BlocksPeriod.WEEK:
      return perHour * DAILY_HOURS * WEEK;
    case BlocksPeriod.YEAR:
      return perHour * DAILY_HOURS * YEAR;
    default:
      return perHour;
  }
}

/* The formula for calculating user balance 
        balance = kTokenBalance * TokensSupplied / kTokenSupply
    */
export function calculateUserBalance(
  token: Token,
  kBalance: BigNumber,
  poolBalance: BigNumber,
  kSupply: BigNumber
): BigNumber {
  const balance = kBalance.multipliedBy(poolBalance).dividedBy(kSupply);
  return tokenBalanceWithDecimals(token, balance);
}

export function calculatePoolAPY(data: { last: any[]; weekAgo: any[] }): APY {
  const last = data.last[0];
  const weekAgo = data.weekAgo[0];
  if (!last || !weekAgo) return { percent: "0.0%", number: new BigNumber(0) };
  const weeklyProfit = new BigNumber(last.earned).minus(new BigNumber(weekAgo.earned));
  const depositDelta = new BigNumber(last.deposited).minus(new BigNumber(last.withdrawn));
  if (weeklyProfit.lte(new BigNumber(0)) || depositDelta.lte(new BigNumber(0)))
    return { percent: "0.0%", number: new BigNumber(0) };
  const apy = weeklyProfit.dividedBy(depositDelta).multipliedBy(100);
  if (apy.lte(new BigNumber(0.01))) return { percent: "0.0%", number: new BigNumber(0) };
  return { percent: `${apy.toFormat(2)}%`, number: apy };
}

// Get estimate of time remaining in act from etherscan's block countdown
export async function getRemainingTimeInAct() {
  const endBlock = ACT_IV_END_BLOCK.toString();
  try {
    const result = await axios.get(
      `https://api.etherscan.io/api?module=block&action=getblockcountdown&blockno=${endBlock}`
    );
    if (result.data.status === "1") {
      const seconds = parseFloat(result.data.result.EstimateTimeInSec);
      return new BigNumber(seconds);
    }
    // If the etherscan call is rate limited, fall back hard-coded time estimate for end block
    else {
      const seconds = ACT_IV_END_TIMESTAMP.minus(new BigNumber(Date.now())).dividedBy(1000);
      return seconds;
    }
  } catch (err) {
    console.log("ETHERSCAN ERR: ", err);
    return new BigNumber(0);
  }
}

export function getRemainingBlocksInAct(currentBlock: number) {
  return ACT_IV_END_BLOCK.minus(new BigNumber(currentBlock));
}

// Calculates the current APY for a given liquidity pool
export function calculateRookAPY(
  network: NetworkID,
  blocksRemaining: BigNumber,
  secondsRemaining: BigNumber,
  poolLiquidity: BigNumber,
  tokenPrice: BigNumber,
  rookPrice: BigNumber,
  token: Token
): APY {
  const decimals = tokenDecimals(token);

  // Rewards remaining in act for this pool
  const rewardsRemaining = blocksRemaining
    .multipliedBy(ACT_IV_LP_REWARDS_PER_BLOCK)
    .multipliedBy(0.2);

  // Annual rewards for this pool in USD at *CURRENT EMISSIONS RATE*
  const rewardsPerYearUSD = rewardsRemaining
    .dividedBy(secondsRemaining)
    .multipliedBy(60 * 60 * 24 * 365)
    .multipliedBy(rookPrice);

  // Current pool liquidity in USD
  const poolLiquidityUSD = poolLiquidity
    .multipliedBy(tokenPrice)
    .div(new BigNumber(10).pow(decimals));

  // Current APY for this pool as a %
  const apy = rewardsPerYearUSD.dividedBy(poolLiquidityUSD).multipliedBy(100);

  // Not sure what's going on here
  if (apy.lt(new BigNumber(0.1)) || !isFinite(apy.toNumber()))
    return { percent: "0.0%", number: new BigNumber(0) };
  return { percent: `${apy.toFormat(2)}%`, number: apy };
}
