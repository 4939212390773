import { BigNumber } from "@0x/utils";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import {
  CompData,
  CompoundToken,
  CompoundTokenInfo,
  MarketBorrowInfo,
  MarketSupplyInfo,
  tokenDecimals,
} from "../lib/compound";
import { NetworkID, networkIdFromChainId } from "../lib/network";
import ExchangeRates from "./exchangeRates";

function useCompoundData() {
  const [compoundData, setCompoundData] = useState<{
    supply: MarketSupplyInfo[];
    borrow: MarketBorrowInfo[];
  }>({
    supply: [],
    borrow: [],
  });
  const [compoundTokenInfo, setCompoundTokenInfo] = useState<Map<string, any>>(new Map());
  const [compoundTokenPairs, setCompoundTokenPairs] = useState<CompoundTokenInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const wallet = useWeb3React();
  const network = networkIdFromChainId(wallet.chainId);
  const rates = ExchangeRates.useContainer();

  async function getCompoundMarkets() {
    if (!wallet.account || !wallet.chainId) return;

    //console.log('Calling compound api');

    setLoading(true);

    let compMarkets;
    let ethPriceUSD;
    if (network == NetworkID.Mainnet) {
      while (!rates.ETH.gt(0)) await new Promise((r) => setTimeout(r, 200));
      ethPriceUSD = rates.ETH;
      compMarkets = await axios.get(
        `https://api.compound.finance/api/v2/ctoken?meta=true&network=mainnet`
      );
    } else if (network == NetworkID.Kovan) {
      ethPriceUSD = rates.ETH;
      compMarkets = await axios.get(
        `https://api.compound.finance/api/v2/ctoken?meta=true&network=kovan`
      );
    }

    if (compMarkets) {
      //console.log('COMP MARKETS: ', compMarkets);
      const borrow: MarketBorrowInfo[] = [];
      const supply: MarketSupplyInfo[] = [];
      compMarkets.data.cToken.map((cToken) => {
        if (new BigNumber(cToken.supply_rate.value).gt(0)) {
          const priceUSD = new BigNumber(cToken.underlying_price.value)
            .multipliedBy(ethPriceUSD)
            .toString();

          const borrowData = {
            name: cToken.underlying_name,
            symbol: cToken.symbol,
            id: cToken.token_address,
            borrowRate: cToken.borrow_rate.value,
            borrowCompAPY: cToken.comp_borrow_apy.value,
            totalBorrows: cToken.total_borrows.value,
            underlyingSymbol: cToken.underlying_symbol,
            address: cToken.underlying_address,
            collateralFactor: cToken.collateral_factor.value,
            priceUSD: priceUSD,
            priceETH: cToken.underlying_price.value,
            liquidity: cToken.cash.value,
          };
          const supplyData = {
            name: cToken.underlying_name,
            symbol: cToken.symbol,
            id: cToken.token_address,
            supplyRate: cToken.supply_rate.value,
            supplyCompAPY: cToken.comp_supply_apy.value,
            totalSupply: cToken.total_supply.value,
            underlyingSymbol: cToken.underlying_symbol,
            address: cToken.underlying_address,
            collateralFactor: cToken.collateral_factor.value,
            priceUSD: priceUSD,
            priceETH: cToken.underlying_price.value,
          };
          borrow.push(borrowData);
          supply.push(supplyData);
        }
      });

      const supplySorted = supply.sort((a, b) =>
        a.underlyingSymbol > b.underlyingSymbol ? 1 : -1
      );
      const borrowSorted = borrow.sort((a, b) =>
        a.underlyingSymbol > b.underlyingSymbol ? 1 : -1
      );
      const data = {
        supply: supplySorted,
        borrow: borrowSorted,
      };
      const compDataMapped: CompData = data;
      const newCompTokenMap = new Map();
      compDataMapped.supply.forEach((token) => {
        newCompTokenMap.set(token.underlyingSymbol, {
          price: token.priceUSD,
          collateralFactor: token.collateralFactor,
        });
      });

      const pairs: CompoundTokenInfo[] = compDataMapped.supply.map((token) => {
        const cToken: CompoundToken = {
          name: token.symbol,
          address: token.id,
          decimals: 8,
        };
        const underlying: CompoundToken = {
          name: token.underlyingSymbol,
          address:
            token.underlyingSymbol === "ETH"
              ? "0x0000000000000000000000000000000000000000"
              : token.address,
          decimals: tokenDecimals(token.underlyingSymbol),
        };
        return { underlyingToken: underlying, cToken: cToken };
      });

      setCompoundTokenPairs(pairs);
      setCompoundData(compDataMapped);
      setCompoundTokenInfo(newCompTokenMap);
      setLoading(false);
    }

    setLoading(false);
    return;
  }

  // on initial mount
  useEffect(() => {
    function updateCompData() {
      getCompoundMarkets();
    }

    if (!wallet.account) return;

    updateCompData();
  }, [wallet, rates]);

  useEffect(() => {
    const interval = setInterval(async () => {
      //console.log('re fetching markets');
      getCompoundMarkets();
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  return { compoundData, compoundTokenInfo, compoundTokenPairs, loading };
}

const CompoundData = createContainer(useCompoundData);

export default CompoundData;
