import {
  Backdrop,
  Button,
  Dialog,
  Divider,
  Grid,
  Input,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { utils } from "ethers";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import MoonLoader from "react-spinners/MoonLoader";
import { StyledModal } from "..";
// import { EVENT_CATEGORIES, ACTIONS } from '../../../lib/analytics';
import {
  ClaimCompState,
  ClaimRookState,
  TransferPosition,
  TransferState,
} from "../../../containers/borrow";
import ExchangeRates from "../../../containers/exchangeRates";
import {
  contract,
  contractAddress,
  ContractType,
  distibutorContract,
} from "../../../lib/contracts";
import { networkIdFromChainId } from "../../../lib/network";
import theme from "../../../styles/theme";
import TooltipColored from "../../common/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "none",
    border: "none",
    position: "absolute",
  },
  modal: {
    position: "relative",
    display: "flex",
    flex: "1 1 0",
    width: "400px",
    maxHeight: "775px",
  },
  modalContent: {
    display: "flex",
    flex: "1 1 0",
    position: "relative",
    height: "100%",
    width: "100%",
    padding: "32px",
    justifyContent: "flex-start",
    alignItems: "center",
    alignSelf: "flex-start",
    overflow: "auto",
  },
  modalHeader: {
    display: "flex",
    position: "relative",
    //height: '150px',
    alignSelf: "flex-start",
    justifyContent: "flex-start",
  },
  createVaultContainer: {
    display: "flex",
    position: "relative",
    height: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "20px",
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    position: "relative",
    width: "100%",
    //backgroundColor: '#123456',
  },
  claimContainer: {
    display: "flex",
    position: "relative",
    width: "100%",
    borderRadius: "16px",
    justifyContent: "space-between",
    alignItems: "center",
    background: "rgba(245, 245, 245, 0.08)",
    padding: "20px",
    marginTop: "8px",
    marginBottom: "8px",
  },
  transferContainer: {
    display: "flex",
    position: "relative",
    width: "100%",
    alignItems: "center",
  },
  transferInput: {},
  yesNoContainer: {
    display: "flex",
    position: "relative",
    width: "100%",
    justifyContent: "space-between",
  },
  button: {
    borderRadius: "8px",
  },
  disabledButton: {
    borderRadius: "8px",
    borderColor: "#525252",
    color: "#525252",
    background: "none",
    border: "1px solid",
    transition: "0.25s",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "1.1em",
    letterSpacing: "0em",
    textTransform: "none",
    "&:hover": {
      borderColor: "#525252",
      color: "#525252",
    },
    "&:active": {
      borderColor: "#525252",
      color: "#525252",
    },
    "&:disabled": {
      borderColor: "#525252",
      color: "#525252",
    },
  },
  loadingContainer: {
    display: "flex",
    position: "relative",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },

  tokenInfo: {
    display: "flex",
    justifyContent: "space-between",
    //position: 'absolute',
    //width: '100%',
    //height: '100%',
  },
  tokenInfoLeft: {
    display: "flex",
    //position: 'absolute',
    justifyContent: "center",
    alignItems: "flex-start",
    //justifySelf: 'flex-start',
  },
  tokenInfoHeader: {
    display: "flex",
    //position: 'absolute',
    //justifyContent: 'flex-start',
    color: theme.palette.text.primary,
  },
  tokenInfoRight: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  tokenLogo: {
    display: "flex",
    //position: 'absolute',
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
  },
  tokenInfoBody: {
    color: theme.palette.text.primary,
    //textTransform: 'none',
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: "bold",
    borderRadius: "16px",
    marginLeft: "8px",
  },
  divider: {
    background: "linear-gradient(91.6deg, #6D47D7 0%, #D7475A 100%)",
    opacity: "0.24",
    marginTop: "8px",
    marginBottom: "8px",
  },
  tradeInfoField: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    width: "100%",
  },
  tradeInfoHeader: {
    color: theme.palette.text.primary,
  },
  tradeInfoContent: {
    color: theme.palette.text.secondary,
  },
  cancelButton: {
    borderRadius: "8px",
    width: "100%",
    height: "100%",
  },
  submitButton: {
    //marginTop: '20px',
    borderRadius: "8px",
    width: "100%",
    height: "100%",
    //height: '56px',
  },
  claimButton: {
    borderRadius: "8px",
    width: "100%",
    height: "100%",
  },
  address: {
    fontSize: "1.25rem",
    fontWeight: 500,
    lineHeight: 1.6,
  },
  etherscanLink: {
    textTransform: "none",
    alignContent: "center",
    paddingLeft: "0px",
    "&:hover": {
      background: "none",
      textDecoration: "underline",
    },
  },
}));

const COMP_ADDRESS = "0xc00e94cb662c3520282e6f5717214004a7f26888";

function useTransfer() {
  // State variables use for inputting the deposit amount. We need an explicit
  // state variable because we need to do bignumber/decimal/string
  // conversions, and haveing an explicit state variable makes this much
  // easier.
  const [input, setInput] = useState("");
  const [rookBal, setRookBal] = useState(new BigNumber(0));
  const [compBal, setCompBal] = useState(new BigNumber(0));

  // The Wallet is needed so that we can sign and submit transactions to
  // Ethereum.
  const wallet = useWeb3React();
  const { t } = useTranslation();

  // transfer hook
  const transferC = TransferPosition.useContainer();

  //rates hook
  const exchange = ExchangeRates.useContainer();

  async function getRookBal() {
    if (!wallet.library || !wallet.chainId || !wallet.account || !transferC.rookRewards) {
      return new BigNumber(0);
    }
    const web3 = wallet.library;
    const networkID = networkIdFromChainId(wallet.chainId);
    const { position: vaultAddress } = transferC;

    //const kCompound = contract(web3, networkID, ContractType.KCompound, wallet.account, vaultAddress);
    const rook = contract(web3, networkID, ContractType.ROOK, wallet.account);
    //const rookAddress = contractAddress(networkID, ContractType.ROOK);
    const rookAmount = new BigNumber(await rook.methods.balanceOf(vaultAddress).call());

    return rookAmount;
  }

  async function getCompBal() {
    if (!wallet.library || !wallet.chainId || !wallet.account || !transferC.rookRewards) {
      return new BigNumber(0);
    }
    const web3 = wallet.library;
    const networkID = networkIdFromChainId(wallet.chainId);
    const { position: vaultAddress } = transferC;

    //const kCompound = contract(web3, networkID, ContractType.KCompound, wallet.account, vaultAddress);
    const comp = contract(web3, networkID, ContractType.COMP, wallet.account);
    //const rookAddress = contractAddress(networkID, ContractType.ROOK);
    const compAmount = new BigNumber(await comp.methods.balanceOf(vaultAddress).call());

    return compAmount;
  }

  async function getBalances() {
    const rookBal = await getRookBal();
    const compBal = await getCompBal();
    if (rookBal.gt(0)) {
      transferC.setRookState3(ClaimRookState.WAIT_FOR_RECOVER_INPUT);
      transferC.setRookState4(ClaimRookState.WAIT_FOR_RECOVER_INPUT);
      setRookBal(rookBal.dividedBy(new BigNumber(10).pow(18)));
    }
    if (compBal.gt(0)) {
      transferC.setCompState(ClaimCompState.WAIT_FOR_RECOVER_INPUT);
      setCompBal(compBal.dividedBy(new BigNumber(10).pow(18)));
    }
  }

  // If vault has rook and comp balance, set state to wait for transfer
  useEffect(() => {
    getBalances();
  }, [wallet, transferC.rookRewards, transferC.state]);

  //update input on modal state change
  useEffect(() => {
    setInput("");
  }, [transferC.state]);

  async function transfer() {
    transferC.setState(TransferState.WAIT_FOR_TRANSFER);
    if (!wallet.library || !wallet.chainId || !wallet.account) {
      return;
    }
    const web3 = wallet.library;
    const networkID = networkIdFromChainId(wallet.chainId);
    const { position, transferAddress } = transferC;
    const hidingVaultContract = contract(
      web3,
      networkID,
      ContractType.HidingVaultNFT,
      wallet.account
    );

    try {
      await hidingVaultContract.methods
        .safeTransferFrom(wallet.account, transferAddress, position)
        .send({ from: wallet.account })
        .on("receipt", async (receipt) => {
          transferC.setState(TransferState.SUCCESS);
        })
        .on("error", (error, receipt) => {
          transferC.setState(TransferState.CANCEL_TRANSFER);
          return;
        });
    } catch (err) {
      console.error(`cannot deposit: ${err}`);
      transferC.setState(TransferState.CANCEL_TRANSFER);
      return;
    }
  }

  async function claimComp() {
    if (!wallet.library || !wallet.chainId || !wallet.account || !transferC.vaultState) {
      return;
    }
    const web3 = wallet.library;
    const networkID = networkIdFromChainId(wallet.chainId);
    const { position: vaultAddress } = transferC;
    const comptroller = contract(web3, networkID, ContractType.Comptroller, wallet.account);

    try {
      transferC.setCompState(ClaimCompState.WAIT_FOR_CLAIM);
      await comptroller.methods
        .claimComp(vaultAddress, transferC.vaultState.assetsIn)
        .send({ from: wallet.account })
        .on("receipt", async (receipt) => {
          //transferC.setCompState(ClaimCompState.WAIT);
          //getBalances();
          //transferC.setCompState(ClaimCompState.WAIT_FOR_RECOVER);
          await transferComp();
        })
        .on("error", (error, receipt) => {
          transferC.setCompState(ClaimCompState.ERROR);
          return;
        });
    } catch (err) {
      console.error(`cannot caim comp: ${err}`);
      transferC.setCompState(ClaimCompState.ERROR);
      return;
    }
  }

  // THese need to be separate functions lol but we gotta save that for after shadow's vaults refactor is merged. Can't be messing with the state before then
  async function claimRook3() {
    if (!wallet.library || !wallet.chainId || !wallet.account || !transferC.rookRewards) {
      return;
    }
    const web3 = wallet.library;
    const networkID = networkIdFromChainId(wallet.chainId);
    const { position: vaultAddress } = transferC;
    const distributor = distibutorContract(
      web3,
      networkID,
      wallet.account,
      ContractType.HidingVaultDistributor
    );

    if (!distributor) {
      return;
    }

    try {
      transferC.setRookState3(ClaimRookState.WAIT_FOR_CLAIM);
      await distributor.methods
        .claim(
          vaultAddress,
          transferC.rookRewards.rewardsAct3.pendingRewards.toFixed(0),
          transferC.rookRewards.rewardsAct3.rewardsState.nonce,
          transferC.rookRewards.rewardsAct3.rewardsState.signature
        )
        .send({ from: wallet.account })
        .on("receipt", async (receipt) => {
          //transferC.setCompState(ClaimCompState.WAIT);
          //getBalances();
          //transferC.setRookState(ClaimRookState.WAIT_FOR_RECOVER);
          await transferRook();
        })
        .on("error", (error, receipt) => {
          transferC.setRookState3(ClaimRookState.ERROR);
          return;
        });
    } catch (err) {
      console.error(`cannot caim rook: ${err}`);
      transferC.setRookState3(ClaimRookState.ERROR);
      return;
    }
  }

  async function claimRook4() {
    if (!wallet.library || !wallet.chainId || !wallet.account || !transferC.rookRewards) {
      return;
    }
    const web3 = wallet.library;
    const networkID = networkIdFromChainId(wallet.chainId);
    const { position: vaultAddress } = transferC;
    const distributor = distibutorContract(
      web3,
      networkID,
      wallet.account,
      ContractType.HidingVaultDistributor2
    );

    if (!distributor) {
      return;
    }

    try {
      transferC.setRookState4(ClaimRookState.WAIT_FOR_CLAIM);
      await distributor.methods
        .claim(
          vaultAddress,
          transferC.rookRewards.rewardsAct4.pendingRewards.toFixed(0),
          transferC.rookRewards.rewardsAct4.rewardsState.nonce,
          transferC.rookRewards.rewardsAct4.rewardsState.signature
        )
        .send({ from: wallet.account })
        .on("receipt", async (receipt) => {
          //transferC.setCompState(ClaimCompState.WAIT);
          //getBalances();
          //transferC.setRookState(ClaimRookState.WAIT_FOR_RECOVER);
          await transferRook();
        })
        .on("error", (error, receipt) => {
          transferC.setRookState4(ClaimRookState.ERROR);
          return;
        });
    } catch (err) {
      console.error(`cannot caim rook: ${err}`);
      transferC.setRookState4(ClaimRookState.ERROR);
      return;
    }
  }

  async function claimRook4v2() {
    if (!wallet.library || !wallet.chainId || !wallet.account || !transferC.rookRewards) {
      return;
    }
    const web3 = wallet.library;
    const networkID = networkIdFromChainId(wallet.chainId);
    const { position: vaultAddress } = transferC;
    const distributor = distibutorContract(
      web3,
      networkID,
      wallet.account,
      ContractType.HidingVaultDistributor3
    );

    if (!distributor) {
      return;
    }

    try {
      transferC.setRookState4v2(ClaimRookState.WAIT_FOR_CLAIM);
      await distributor.methods
        .claim(
          vaultAddress,
          transferC.rookRewards.rewardsAct4v2.pendingRewards.toFixed(0),
          transferC.rookRewards.rewardsAct4v2.rewardsState.nonce,
          transferC.rookRewards.rewardsAct4v2.rewardsState.signature
        )
        .send({ from: wallet.account })
        .on("receipt", async (receipt) => {
          //transferC.setCompState(ClaimCompState.WAIT);
          //getBalances();
          //transferC.setRookState(ClaimRookState.WAIT_FOR_RECOVER);
          await transferRook();
        })
        .on("error", (error, receipt) => {
          transferC.setRookState4v2(ClaimRookState.ERROR);
          return;
        });
    } catch (err) {
      console.error(`cannot caim rook: ${err}`);
      transferC.setRookState4v2(ClaimRookState.ERROR);
      return;
    }
  }

  async function claimRook5() {
    if (!wallet.library || !wallet.chainId || !wallet.account || !transferC.rookRewards) {
      return;
    }
    const web3 = wallet.library;
    const networkID = networkIdFromChainId(wallet.chainId);
    const { position: vaultAddress } = transferC;
    const distributor = distibutorContract(
      web3,
      networkID,
      wallet.account,
      ContractType.HidingVaultDistributor4
    );

    if (!distributor) {
      return;
    }

    try {
      transferC.setRookState5(ClaimRookState.WAIT_FOR_CLAIM);
      await distributor.methods
        .claim(
          vaultAddress,
          transferC.rookRewards.rewardsAct5.pendingRewards.toFixed(0),
          transferC.rookRewards.rewardsAct5.rewardsState.nonce,
          transferC.rookRewards.rewardsAct5.rewardsState.signature
        )
        .send({ from: wallet.account })
        .on("receipt", async (receipt) => {
          //transferC.setCompState(ClaimCompState.WAIT);
          //getBalances();
          //transferC.setRookState(ClaimRookState.WAIT_FOR_RECOVER);
          await transferRook();
        })
        .on("error", (error, receipt) => {
          transferC.setRookState5(ClaimRookState.ERROR);
          return;
        });
    } catch (err) {
      console.error(`cannot caim rook: ${err}`);
      transferC.setRookState5(ClaimRookState.ERROR);
      return;
    }
  }

  async function transferComp() {
    if (!wallet.library || !wallet.chainId || !wallet.account || !transferC.vaultState) {
      return;
    }
    const web3 = wallet.library;
    const networkID = networkIdFromChainId(wallet.chainId);
    const { position: vaultAddress } = transferC;

    const kCompound = contract(
      web3,
      networkID,
      ContractType.KCompound,
      wallet.account,
      vaultAddress
    );
    const comp = contract(web3, networkID, ContractType.COMP, wallet.account);
    const compAddress = contractAddress(networkID, ContractType.COMP);
    const compAmount = new BigNumber(await comp.methods.balanceOf(vaultAddress).call());

    try {
      transferC.setCompState(ClaimCompState.WAIT_FOR_RECOVER);
      await kCompound.methods
        .recoverTokens(wallet.account, compAddress, compAmount.toFixed(0))
        .send({ from: wallet.account })
        .on("receipt", async (receipt) => {
          transferC.setCompState(ClaimCompState.SUCCESS);
        })
        .on("error", (error, receipt) => {
          transferC.setCompState(ClaimCompState.ERROR);
          return;
        });
    } catch (err) {
      console.error(`cannot recover comp: ${err}`);
      transferC.setCompState(ClaimCompState.ERROR);
      return;
    }
  }

  async function transferRook() {
    if (!wallet.library || !wallet.chainId || !wallet.account || !transferC.rookRewards) {
      return;
    }
    const web3 = wallet.library;
    const networkID = networkIdFromChainId(wallet.chainId);
    const { position: vaultAddress } = transferC;

    const kCompound = contract(
      web3,
      networkID,
      ContractType.KCompound,
      wallet.account,
      vaultAddress
    );
    const rook = contract(web3, networkID, ContractType.ROOK, wallet.account);
    const rookAddress = contractAddress(networkID, ContractType.ROOK);
    const rookAmount = new BigNumber(await rook.methods.balanceOf(vaultAddress).call());

    try {
      transferC.setRookState3(ClaimRookState.WAIT_FOR_RECOVER);
      transferC.setRookState4(ClaimRookState.WAIT_FOR_RECOVER);
      transferC.setRookState4v2(ClaimRookState.WAIT_FOR_RECOVER);
      await kCompound.methods
        .recoverTokens(wallet.account, rookAddress, rookAmount.toFixed(0))
        .send({ from: wallet.account })
        .on("receipt", async (receipt) => {
          transferC.setRookState3(ClaimRookState.SUCCESS);
          transferC.setRookState4(ClaimRookState.SUCCESS);
          transferC.setRookState4v2(ClaimRookState.SUCCESS);
        })
        .on("error", (error, receipt) => {
          transferC.setRookState3(ClaimRookState.ERROR);
          transferC.setRookState4(ClaimRookState.ERROR);
          transferC.setRookState4v2(ClaimRookState.ERROR);
          return;
        });
    } catch (err) {
      console.error(`cannot recover comp: ${err}`);
      transferC.setRookState3(ClaimRookState.ERROR);
      transferC.setRookState4(ClaimRookState.ERROR);
      transferC.setRookState4v2(ClaimRookState.ERROR);
      return;
    }
  }

  return {
    input,
    setInput,
    ...wallet,
    ...transferC,
    t,
    transfer,
    claimComp,
    transferComp,
    compBal,
    claimRook3,
    claimRook4,
    claimRook4v2,
    claimRook5,
    transferRook,
    rookBal,
    setRookBal,
    setCompBal,
    exchange,
  };
}

function Hidden() {
  // It is important that this component is explicitly rendered when the modal
  // is hidden, otherwise the order of the "useState" calls will change when
  // the model opens/closes, and this will cause all sorts of undefined
  // behaviour!
  const _ = useTransfer();
  return null;
}

function WaitForInput(valid?) {
  const c = useStyles();
  const {
    setState,
    compState,
    rookState3,
    rookState4,
    rookState4v2,
    rookState5,
    setCompState,
    t,
    input,
    setInput,
    setTransferAddress,
    transferAddress,
    rookRewards,
    compRewards,
    claimComp,
    claimRook3,
    claimRook4,
    claimRook4v2,
    claimRook5,
    transferComp,
    transferRook,
    rookBal,
    compBal,
    setRookBal,
    setCompBal,
  } = useTransfer();

  function validateAddress() {
    const validAddress = utils.isAddress(transferAddress);
    if (!validAddress) {
      setState(TransferState.WRONG_ADDRESS);
      return;
    }

    setState(TransferState.CONFIRM_TRANSFER);
  }

  const message = valid ? t("transfer-modal.input-popup") : t("transfer-modal.enter-valid-address");

  const ClaimCompField = (state) => {
    switch (state) {
      case ClaimCompState.WAIT_FOR_CLAIM_INPUT:
        return (
          <Grid container item className={c.claimContainer}>
            <Grid container item direction="column" alignItems="flex-start" xs={8}>
              <Grid item>
                <Typography variant="body1">
                  {t("vault-reward-claimable", { token: "COMP" })}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5">{compRewards.toFixed(9)}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              {/*<TooltipColored
                title={"Claiming COMP has temporarily been disabled"}
                color={theme.palette.action.disabled}
              >*/}
              <Button
                variant="outlined"
                fullWidth
                className={c.button}
                onClick={async () => await claimComp()}
              >
                Claim
              </Button>
              {/*<Button fullWidth className={c.disabledButton}>
                    Claim
                  </Button>
                
                </TooltipColored>*/}
            </Grid>
          </Grid>
        );
      case ClaimCompState.WAIT_FOR_CLAIM:
        return (
          <Grid container item className={c.claimContainer}>
            <Grid container item direction="row" alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">
                  {t("vault-reward-claiming", { token: "COMP" })}
                </Typography>
              </Grid>
              <Grid item>
                <MoonLoader color="#f5f5f5" loading={true} size={25} />
              </Grid>
            </Grid>
          </Grid>
        );
      case ClaimCompState.WAIT_FOR_RECOVER_INPUT:
        return (
          <Grid container item className={c.claimContainer}>
            <Grid container item direction="column" alignItems="flex-start" xs={8}>
              <Grid item>
                <Typography variant="body1">
                  {t("vault-reward-in-vault", { token: "COMP" })}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5">{compBal.toFixed(9)}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="outlined"
                fullWidth
                className={c.button}
                onClick={async () => await transferComp()}
              >
                Transfer
              </Button>
            </Grid>
          </Grid>
        );
      case ClaimCompState.WAIT_FOR_RECOVER:
        return (
          <Grid container item className={c.claimContainer}>
            <Grid container item direction="row" alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">
                  {t("vault-reward-transfer", { token: "COMP" })}
                </Typography>
              </Grid>
              <Grid item>
                <MoonLoader color="#f5f5f5" loading={true} size={25} />
              </Grid>
            </Grid>
          </Grid>
        );
      case ClaimCompState.SUCCESS:
        return (
          <Grid container item className={c.claimContainer}>
            <Grid container item direction="row" alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">{t("vault-reward-claimed", { token: "COMP" })}</Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      case ClaimCompState.ERROR:
        return (
          <Grid container item className={c.claimContainer}>
            <Grid container item direction="row" alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">{t("vault-reward-error", { token: "COMP" })}</Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  const ClaimRookField = (act, state) => {
    let amount = "0";
    if (act === 3) {
      amount = rookRewards
        ? rookRewards.rewardsAct3.rewardsDisplay.dividedBy(new BigNumber(10).pow(18)).toFixed(9)
        : "0";
    }
    if (act === 4) {
      amount = rookRewards
        ? rookRewards.rewardsAct4.rewardsDisplay.dividedBy(new BigNumber(10).pow(18)).toFixed(9)
        : "0";
    }
    if (act === 5) {
      amount = rookRewards
        ? rookRewards.rewardsAct4v2.rewardsDisplay.dividedBy(new BigNumber(10).pow(18)).toFixed(9)
        : "0";
    }
    if (act === 6) {
      amount = rookRewards
        ? rookRewards.rewardsAct5.rewardsDisplay.dividedBy(new BigNumber(10).pow(18)).toFixed(9)
        : "0";
    }
    const amountBN = new BigNumber(amount);
    switch (state) {
      case ClaimRookState.WAIT_FOR_CLAIM_INPUT:
        return (
          <Grid container item className={c.claimContainer}>
            <Grid container item direction="column" alignItems="flex-start" xs={8}>
              <Grid container item direction="row" spacing={1}>
                <Grid item>
                  <Typography variant="body1">
                    {t("vault-reward-claimable", { token: "ROOK" })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" style={{ fontWeight: 600 }}>
                    {act === 3
                      ? "  Act III"
                      : act === 4
                      ? "  Act IV"
                      : act === 5
                      ? " Act IV-2"
                      : " Act V"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="h5">{amount}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="outlined"
                fullWidth
                className={c.button}
                disabled={!amountBN.gt(0)}
                onClick={async () => {
                  if (act === 3) await claimRook3();
                  if (act === 4) await claimRook4();
                  if (act === 5) await claimRook4v2();
                  if (act === 6) await claimRook5();
                }}
              >
                Claim
              </Button>
            </Grid>
          </Grid>
        );
      case ClaimRookState.WAIT_FOR_CLAIM:
        return (
          <Grid container item className={c.claimContainer}>
            <Grid container item direction="row" alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">
                  {t("vault-reward-claiming", { token: "ROOK" })}
                </Typography>
              </Grid>
              <Grid item>
                <MoonLoader color="#f5f5f5" loading={true} size={25} />
              </Grid>
            </Grid>
          </Grid>
        );
      case ClaimRookState.WAIT_FOR_RECOVER_INPUT:
        return (
          <Grid container item className={c.claimContainer}>
            <Grid container item direction="column" alignItems="flex-start" xs={8}>
              <Grid item>
                <Typography variant="body1">
                  {t("vault-reward-in-vault", { token: "ROOK" })}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5">{rookBal.toFixed(9)}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="outlined"
                fullWidth
                className={c.button}
                onClick={async () => await transferRook()}
              >
                Transfer
              </Button>
            </Grid>
          </Grid>
        );
      case ClaimRookState.WAIT_FOR_RECOVER:
        return (
          <Grid container item className={c.claimContainer}>
            <Grid container item direction="row" alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">
                  {t("vault-reward-transfer", { token: "COMP" })}
                </Typography>
              </Grid>
              <Grid item>
                <MoonLoader color="#f5f5f5" loading={true} size={25} />
              </Grid>
            </Grid>
          </Grid>
        );
      case ClaimRookState.SUCCESS:
        return (
          <Grid container item className={c.claimContainer}>
            <Grid container item direction="row" alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">{t("vault-reward-claimed", { token: "ROOK" })}</Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      case ClaimRookState.ERROR:
        return (
          <Grid container item className={c.claimContainer}>
            <Grid container item direction="row" alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">{t("vault-reward-error", { token: "ROOK" })}</Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <Grid container item className={c.contentContainer} direction="column" spacing={1}>
      <Grid container item className={c.transferContainer} direction="column">
        <Grid item>
          <Typography variant="h6">{t("vault-transfer-header")}</Typography>
        </Grid>

        <Grid
          container
          item
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{
            padding: "16px",
            backgroundColor: "rgba(245,245,245,0.08)",
            borderRadius: "16px",
          }}
        >
          <Grid item xs={8}>
            <Input
              disableUnderline
              fullWidth
              placeholder={"0x0000...."}
              value={input}
              onChange={(evt) => {
                const address = evt.target.value;
                setInput(address);
                setTransferAddress(address);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              style={{ borderRadius: "8px", justifySelf: "flex-end" }}
              fullWidth
              variant="contained"
              disabled={!utils.isAddress(transferAddress)}
              onClick={validateAddress}
            >
              <ArrowForward />
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item className={c.transferContainer} direction="column">
        {ClaimCompField(compState)}
        {ClaimRookField(3, rookState3)}
        {ClaimRookField(4, rookState4)}
        {ClaimRookField(5, rookState4v2)}
        {ClaimRookField(6, rookState5)}
      </Grid>
      <Grid container item justifyContent="space-around">
        <Grid item xs={10}>
          <Button
            variant="outlined"
            fullWidth
            className={c.button}
            href={`https://etherscan.io/address/${rookRewards?.posAddress}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("vault-etherscan")}
          </Button>
        </Grid>
      </Grid>
      <Grid container item justifyContent="space-around">
        <Grid item xs={9}>
          <Button
            variant="outlined"
            fullWidth
            className={c.button}
            onClick={() => {
              setState(TransferState.HIDDEN);
            }}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

function ConfirmTransfer() {
  const c = useStyles();
  const { setState, t, transfer, transferAddress, position, exchange, account } = useTransfer();
  //const posistionB = allUserPositions.find((pos) => pos.posAddress === position);
  //const posIndex = allUserPositions.findIndex((pos) => pos.posAddress === position) + 1;
  const vaultAddressShort = `${position.slice(0, 14)}...${position.slice(-12)}`;
  const transferAddressShort = `${transferAddress.slice(0, 14)}...${transferAddress.slice(-12)}`;
  const accountAddressShort = `${account?.slice(0, 14)}...${account?.slice(-12)}`;
  // const collateralUSD = posistionB?.suppliedTokens.reduce((bal, tokenBal) => {
  //     const usdPrice = exchange[tokenBal.cToken.name];
  //     if (!usdPrice) {
  //         return bal;
  //     }
  //     const underlyingBalance = new BigNumber(tokenBal.cToken.balance).multipliedBy(new BigNumber(usdPrice));
  //     return bal.plus(underlyingBalance);
  // }, new BigNumber(0));

  // const borrowUSD = posistionB?.borrowedTokens.reduce((bal, tokenBal) => {
  //     const usdPrice = exchange[tokenBal.underlyingToken.name];
  //     if (!usdPrice) {
  //         return bal;
  //     }
  //     const underlyingBalance = new BigNumber(tokenBal.underlyingToken.balance).multipliedBy(new BigNumber(usdPrice));
  //     return bal.plus(underlyingBalance);
  // }, new BigNumber(0));

  // const assets = posistionB?.suppliedTokens
  //     .concat(posistionB?.suppliedTokens)
  //     .reduce((names, tokenBal) => {
  //         if (new BigNumber(tokenBal.cToken.balance).gt(0)) {
  //             return names.concat([tokenBal.cToken.name]);
  //         }
  //         return names;
  //     }, [] as string[])
  //     .filter((v, i, a) => a.indexOf(v) === i)
  //     .join(', ');

  return (
    <Grid container item className={c.contentContainer} direction="column">
      <Grid container item className={c.transferContainer} direction="column">
        <Grid item>
          <Typography variant="h6">{t("vault-transfer-header")}</Typography>
        </Grid>

        <Grid
          container
          item
          style={{
            position: "relative",
            width: "100%",
            margin: "none",
          }}
        >
          <Divider className={c.divider} />
        </Grid>

        <Grid
          item
          style={{
            textAlign: "center",
          }}
        >
          <Typography variant="body1">
            <Trans i18nKey="vault-transfer-confirm">
              Your Hiding Vault at <Typography variant="h6">{{ vaultAddressShort }}</Typography>
              will be transferred from
              <Typography variant="h6">{{ accountAddressShort }}</Typography> to
              <Typography variant="h6">{{ transferAddressShort }}</Typography>
            </Trans>
          </Typography>
        </Grid>

        <Grid
          container
          item
          style={{
            position: "relative",
            width: "100%",
            margin: "none",
          }}
        >
          <Divider className={c.divider} />
        </Grid>

        <Grid container item className={c.yesNoContainer}>
          <Grid item style={{ width: "48%" }}>
            <Button
              fullWidth
              variant="outlined"
              className={c.button}
              onClick={() => {
                setState(TransferState.WAIT_FOR_INPUT);
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item style={{ width: "48%" }}>
            <Button
              fullWidth
              variant="contained"
              className={c.button}
              onClick={async () => transfer()}
            >
              Transfer
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function WaitForTx(props: { message: string }) {
  const c = useStyles();
  const { message } = props;
  const { transferAddress, t, setState } = useTransfer();

  const transferAddressShort = `${transferAddress.slice(0, 12)}...${transferAddress.slice(-10)}`;
  return (
    <Grid container item className={c.contentContainer} direction="column">
      <Grid container item className={c.transferContainer} direction="column">
        <Grid item>
          <Typography variant="h6">{t("vault-transfer-header")}</Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <Typography variant="body1">Transferring to </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">{transferAddressShort}</Typography>
        </Grid>
        <Grid container item justifyContent="space-around">
          <MoonLoader color="#6D47D7" loading={true} size={50} />
        </Grid>
      </Grid>
    </Grid>
  );
}

interface SuccessProps {
  onClose: () => void;
}

function Success(props: SuccessProps) {
  const c = useStyles();
  const { transferAddress, t, setTransferAddress, setState } = useTransfer();
  const { onClose } = props;
  const transferAddressShort = `${transferAddress.slice(0, 6)}...${transferAddress.slice(-4)}`;

  return (
    <Grid container item className={c.contentContainer} direction="column">
      <Grid container item className={c.transferContainer} direction="column">
        <Grid item>
          <Typography variant="h6">{t("vault-transfer-header")}</Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <Typography variant="body1">Success! </Typography>
        </Grid>
        <Grid container item justifyContent="space-around">
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              className={c.button}
              onClick={() => {
                setTransferAddress("");
                setState(TransferState.HIDDEN);

                onClose();
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function Message(props: { message: string }) {
  const c = useStyles();
  const { message } = props;
  const { t, setState } = useTransfer();
  return (
    <Grid container item className={c.contentContainer} direction="column">
      <Grid container item className={c.transferContainer} direction="column">
        <Grid item>
          <Typography variant="h6">{t("vault-transfer-header")}</Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <Typography variant="body1">{message}</Typography>
        </Grid>
        <Grid container item justifyContent="space-around">
          <Grid item xs={6}>
            <Button
              variant="outlined"
              fullWidth
              className={c.button}
              onClick={() => {
                setState(TransferState.WAIT_FOR_INPUT);
              }}
            >
              Go Back
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

interface CreateModalProps {
  onClose: () => void;
}

function TransferModal(props: CreateModalProps) {
  const c = useStyles();
  const { state, setState, t, transferAddress, setCompState, setRookState3, setRookState4 } =
    useTransfer();
  const { onClose } = props;

  const modalContentState = (state) => {
    switch (state) {
      case TransferState.HIDDEN:
        return <Hidden />;
      case TransferState.WAIT_FOR_INPUT:
        return <WaitForInput />;
      case TransferState.WRONG_ADDRESS:
        return <WaitForInput valid={false} />;
      case TransferState.CONFIRM_TRANSFER:
        return <ConfirmTransfer />;
      case TransferState.WAIT_FOR_TRANSFER:
        return (
          <WaitForTx
            message={t("transfer-modal.transfer-wait", {
              address: transferAddress,
            })}
          />
        );
      case TransferState.SUCCESS:
        return <Success onClose={onClose} />;
      case TransferState.ERROR:
        return <Message message={t("transfer-modal.transfer-error")} />;

      case TransferState.CANCEL_TRANSFER:
        return <Message message={t("transfer-modal.transfer-cancel")} />;
      default:
        throw new TypeError(`non-exhaustive pattern: ${state}`);
    }
  };

  return (
    <Dialog
      onClose={() => {
        setState(TransferState.HIDDEN);
        setCompState(ClaimCompState.WAIT_FOR_CLAIM_INPUT);
        setRookState3(ClaimRookState.WAIT_FOR_CLAIM_INPUT);
        setRookState4(ClaimRookState.WAIT_FOR_CLAIM_INPUT);
      }}
      open={state !== TransferState.HIDDEN}
      classes={{ paper: c.root }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: { zIndex: -3 },
        timeout: 500,
      }}
    >
      <div className={c.modal}>
        <StyledModal>
          <Grid container className={c.modalContent}>
            <Grid container item direction="column" className={c.modalHeader}>
              <Grid item style={{ marginBottom: "20px" }}>
                <Typography variant="h4">Vault Settings</Typography>
              </Grid>
              <Grid item style={{ marginBottom: "8px" }}>
                <Typography variant="body1">{t("vault-settings-header")}</Typography>
              </Grid>
            </Grid>
            <Grid container item>
              {modalContentState(state)}
            </Grid>
          </Grid>
        </StyledModal>
      </div>
    </Dialog>
  );
}

export default TransferModal;
