import {
  AppBar,
  CssBaseline,
  Grid,
  Toolbar,
  IconButton,
  Button,
  Hidden,
  SvgIcon,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Page404 from "../pages/Page404";
import Poolinfo from "../pages/Poolinfo";
import Roadmap from "../pages/Roadmap";
import Trade from "../pages/Trade/Trade";
import Vaults from "../pages/Vaults/Vaults";
import Header from "./Header";
import Sidedrawer from "./Sidedrawer";
import { ReactComponent as Logo } from "../styles/images/KeeperDAO_Logo_Monogram_White.svg";
import { ReactComponent as LogoInline } from "../styles/images/KeeperDAO_Logo_Inline_White.svg";
import WalletStatus from "./WalletStatus";

const sideDrawerWidth = "18%";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    //zIndex: theme.zIndex.drawer - 1,
    [theme.breakpoints.up("md")]: {
      marginLeft: sideDrawerWidth,
      width: `calc(100% - ${sideDrawerWidth})`,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0%",
      width: "100%",
    },
    height: "60px",
    background: theme.palette.primary.main,
    position: "fixed",
    justifyContent: "center",
  },
  toolbar: {
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pageContentWrapper: {
    //paddingTop: '8%',
    top: "60px",
    [theme.breakpoints.up("md")]: {
      left: sideDrawerWidth,
      width: `calc(100% - ${sideDrawerWidth})`,
      height: `calc(100% - 60px)`,
    },
    [theme.breakpoints.down("sm")]: {
      left: "0%",
      width: "100%",
      bottom: "60px",
      height: `calc(100% - 120px)`,
    },
    display: "flex",
    justifyContent: "center",
    position: "fixed",
  },
  pageContent: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "start",
    height: "100%",
    width: "100%",
  },
  footer: {
    display: "flex",
    position: "fixed",
    bottom: "0",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "45px",
    },
    [theme.breakpoints.up("md")]: {
      height: "0px",
    },
    justifyContent: "center",
  },
  menuButton: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  topIconButton: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    height: "100%",
    marginRight: theme.spacing(2),

    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
}));

const AppWrapper: React.FC = () => {
  const c = useStyles();
  // const history = useHistory();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className="app">
      <div className={c.root}>
        <CssBaseline />
        <AppBar className={c.appBar} elevation={0}>
          <Toolbar className={c.toolbar}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={c.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              className={c.topIconButton}
              href="https://keeperdao.com/#/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SvgIcon component={Logo} viewBox="0 0 400 400" />
            </IconButton>
            <Hidden smUp>
              <Button>
                <Header />
              </Button>
            </Hidden>
            <Hidden only="xs">
              <Header />
            </Hidden>
          </Toolbar>
        </AppBar>

        <Sidedrawer buttonToggle={handleDrawerToggle} mobileOpen={mobileOpen} />

        <main className={c.pageContentWrapper}>
          <Grid item container className={c.pageContent}>
            <Switch>
              <Route path="/" exact render={() => <Redirect to="/trade" />} />
              <Route path="/pool" exact render={() => <Dashboard />} />
              <Route path="/pool/:id" exact render={(props) => <Poolinfo {...props} />} />
              <Route path="/trade" exact render={() => <Trade />} />
              <Route path="/vaults" exact render={() => <Vaults />} />
              <Route
                path="/roadmap"
                exact
                component={() => {
                  window.location.href = "https://www.keeperdao.com/files/roadmap.png";
                  return null;
                }}
              />
              <Route path="/404" exact render={() => <Page404 />} />
              <Redirect to="/404" />
            </Switch>
          </Grid>
        </main>
        <Grid container className={c.footer}>
          <Grid item>
            <Hidden mdUp>
              <WalletStatus />
            </Hidden>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AppWrapper;
