import { useState } from "react";
import { createContainer } from "unstated-next";
import { isMobile } from "react-device-detect";

function useAppSettings() {
  const [mobileMenuOpen, setmobileMenuOpen] = useState(false);
  return {
    mobileMenuOpen,
    setmobileMenuOpen,
    isMobile,
  };
}

const AppSettings = createContainer(useAppSettings);

export default AppSettings;
