import React, { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import wallet from "../containers/wallet";
import WalletModal from "./modals/WalletModal";
import BeatLoader from "react-spinners/BeatLoader";
import { useTranslation } from "react-i18next";
import { makeStyles, Grid, Button, Icon } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import { ReactComponent as RookIcon } from "../styles/images/Tokens/ROOK.svg";
import BigNumber from "bignumber.js";
import { RewardsBalances } from "../containers/rookBalances";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
  },
  button: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusIcon: {
    height: "10px",
    width: "10px",
    position: "relative",
    alignSelf: "flex-start",
    color: "#09AF73",
  },
}));

const WalletStatus = (): JSX.Element => {
  const c = useStyles();
  const { t } = useTranslation();

  const { account } = useWeb3React();
  const [walletString, setWalletString] = useState("");

  const { setModalOpen } = wallet.useContainer();

  const [claimableRook, setClaimableRook] = useState("");
  const { totalClaimable, loading: rewardsLoading } = RewardsBalances.useContainer();

  useEffect(() => {
    const tokenAmount = totalClaimable.dividedBy(new BigNumber(10).pow(18));
    if (tokenAmount.gt(0.0001)) {
      setClaimableRook(tokenAmount.toPrecision(4));
    } else if (tokenAmount.eq(0)) {
      setClaimableRook("0");
    } else {
      setClaimableRook("< 0.001");
    }
  }, [totalClaimable]);

  useEffect(() => {
    if (!account) return;
    const wallet = account.substring(0, 6) + "..." + account.slice(-4);
    setWalletString(wallet.toLowerCase());
  }, [account]);

  const openModal = () => {
    setModalOpen(true);
  };

  return (
    <Grid container item>
      {account ? (
        <Button
          className={c.button}
          variant="outlined"
          onClick={openModal}
          startIcon={<FiberManualRecord className={c.statusIcon} />}
        >
          {walletString}
          {!rewardsLoading ? (
            <>
              {
                <RookIcon
                  viewBox="0 0 24 24"
                  width={16}
                  height={16}
                  fill="currentColor"
                  stroke="currentColor"
                  style={{
                    marginLeft: "16px",
                    marginRight: "6px",
                  }}
                />
              }
              {claimableRook}
            </>
          ) : (
            <Grid container item direction="row" alignItems="center">
              <RookIcon
                viewBox="0 0 24 24"
                width={16}
                height={16}
                fill="currentColor"
                stroke="currentColor"
                style={{
                  marginLeft: "16px",
                  marginRight: "6px",
                }}
              />
              <Grid item>
                <BeatLoader size={8} color="currentColor" loading={true} />
              </Grid>
            </Grid>
          )}
        </Button>
      ) : (
        <Button className={c.button} variant="outlined" onClick={openModal}>
          {t("header.connect-wallet-btn")}
        </Button>
      )}
      <WalletModal />
    </Grid>
  );
};

export default WalletStatus;
