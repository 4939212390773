import { useState, useEffect } from "react";
import { createContainer } from "unstated-next";
import BigNumber from "bignumber.js";
import {
  CompoundToken,
  CompoundTokenInfo,
  MarketSupplyInfo,
  MarketBorrowInfo,
} from "../lib/compound";
import { useWeb3React } from "@web3-react/core";
import { networkIdFromChainId } from "../lib/network";
import MultiCall from "@indexed-finance/multicall";
import { ContractType, contract, contractABI, contractAddress } from "../lib/contracts";
import { cTokens } from "../lib/compound";
import { balanceOf } from "../containers/balances";
import { RewardBalance } from "./rookBalances";
import compoundTokens from "../contracts/compoundTokens.json";

export enum BorrowState {
  HIDDEN,
  WAIT_FOR_INPUT,
  WAIT_FOR_APPROVE,
  SET_UNLIMITED_APPROVAL,
  WAIT_FOR_BORROW,
  SUCCESS,
  ERROR,
  CANCEL_APPROVE,
  CANCEL_BORROW,
}

export enum SupplyState {
  HIDDEN,
  WAIT_FOR_INPUT,
  WAIT_FOR_APPROVE,
  SET_UNLIMITED_APPROVAL,
  WAIT_FOR_SUPPLY,
  SUCCESS,
  ERROR,
  CANCEL_APPROVE,
  CANCEL_SUPPLY,
}

export enum RepayState {
  HIDDEN,
  WAIT_FOR_INPUT,
  WAIT_FOR_APPROVE,
  SET_UNLIMITED_APPROVAL,
  WAIT_FOR_REPAY,
  SUCCESS,
  ERROR,
  CANCEL_APPROVE,
  CANCEL_REPAY,
}

export enum WithdrawState {
  HIDDEN,
  WAIT_FOR_INPUT,
  WAIT_FOR_APPROVE,
  SET_UNLIMITED_APPROVAL,
  WAIT_FOR_WITHDRAW,
  SUCCESS,
  ERROR,
  CANCEL_APPROVE,
  CANCEL_WITHDRAW,
}

export enum WalletCreateState {
  HIDDEN,
  CREATE_VAULT,
  MIGRATE_VAULT,
  MIGRATE_ONLY,
  CONFIRM_APPROVE,
  WAIT_FOR_APPROVE,
  CHECKING_ALLOWANCES,
  WAIT_FOR_WALLET_CREATE,
  WAIT_FOR_WALLET_MIGRATE,
  CREATE_SUCCESS,
  MIGRATE_SUCCESS,
  MIGRATE_NOTHING,
  MIGRATE_FAIL,
  CREATE_FAIL,
  ERROR,
  CANCEL_CREATE,
}

export type SuppliedBalances = {
  address: string;
  name: string;
  balance: string;
  decimals: number;
  interestAccrued: string;
};

export type SupplyPair = {
  underlyingToken: SuppliedBalances;
  cToken: SuppliedBalances;
};

export type PositionsState = {
  posAddress: string;
  suppliedTokens: SupplyPair[];
  borrowedTokens: SupplyPair[];
  unhealth: number;
  assetsIn: string[];
  isUnderwritten: boolean;
  totalCollateralValueETH: string;
  totalBorrowValueETH: string;
};

export type UsdPositionState = {
  posAddress: string;
  collateralUSD: BigNumber;
  collateralETH: BigNumber;
  borrowUSD: BigNumber;
  borrowETH: BigNumber;
  limitUSD: BigNumber;
  limitETH: BigNumber;
  leftToBorrowUSD: BigNumber;
  leftToBorrowETH: BigNumber;
  supplyETH: BigNumber;
  supplyUSD: BigNumber;
  supplyInterestAPY: BigNumber;
  borrowInterestAPY: BigNumber;
  netInterestAPY: BigNumber;
  supplyCompAPY: BigNumber;
  borrowCompAPY: BigNumber;
  netCompAPY: BigNumber;
  powerUsed: number;
  health: number;
};

export type RookRewards = {
  pendingRewards: BigNumber;
  rewardsState: RewardBalance;
  rewardsDisplay: BigNumber;
};

export type RookPositionState = {
  posAddress: string;
  rookAPY: BigNumber;
  rewardsAct3: RookRewards;
  rewardsAct4: RookRewards;
  rewardsAct4v2: RookRewards;
  rewardsAct5: RookRewards;
};

export type GlobalVaultsState = {
  numVaults: number;
  collateralUSD: BigNumber;
  borrowUSD: BigNumber;
};

function useBorrower() {
  const [amount, setAmount] = useState(null as BigNumber | null);
  const [token, setToken] = useState<CompoundTokenInfo>({
    underlyingToken: { name: "", address: "", decimals: 18 },
    cToken: { name: "", address: "", decimals: 18 },
  });
  const [state, setState] = useState(BorrowState.HIDDEN);
  const [positionAddress, setPositionAddress] = useState("");
  const [positionBalance, setPositionBalance] = useState<PositionsState>();
  const [positionBalanceUSD, setPositionBalanceUSD] = useState<UsdPositionState>();

  function openBorrowModal(
    pair: CompoundTokenInfo,
    position: string,
    balance: PositionsState,
    balanceUSD: UsdPositionState
  ) {
    setToken(pair);
    setPositionAddress(position);
    setPositionBalance(balance);
    setPositionBalanceUSD(balanceUSD);
    setState(BorrowState.WAIT_FOR_INPUT);
  }

  return {
    amount,
    setAmount,
    state,
    setState,
    token,
    setToken,
    openBorrowModal,
    positionAddress,
    setPositionAddress,
    positionBalance,
    positionBalanceUSD,
  };
}

function useSupplier() {
  const [amount, setAmount] = useState(null as BigNumber | null);
  const [token, setToken] = useState<CompoundTokenInfo>({
    underlyingToken: { name: "", address: "", decimals: 18 },
    cToken: { name: "", address: "", decimals: 18 },
  });
  const [positionAddress, setPositionAddress] = useState("");
  const [state, setState] = useState(SupplyState.HIDDEN);

  function openSupplyModal(pair: SupplyPair, position: string) {
    setToken(pair);
    setPositionAddress(position);
    setState(SupplyState.WAIT_FOR_INPUT);
  }

  return {
    amount,
    setAmount,
    state,
    setState,
    token,
    setToken,
    openSupplyModal,
    positionAddress,
    setPositionAddress,
  };
}

function useRepay() {
  const [amount, setAmount] = useState(null as BigNumber | null);
  const [token, setToken] = useState<CompoundToken>({
    name: "",
    address: "",
    decimals: 18,
  });
  const [positionAddress, setPositionAddress] = useState("");
  const [state, setState] = useState(RepayState.HIDDEN);

  function openRepayModal(token: CompoundToken, position: string) {
    setToken(token);
    setPositionAddress(position);
    setState(RepayState.WAIT_FOR_INPUT);
  }

  return {
    amount,
    setAmount,
    state,
    setState,
    token,
    setToken,
    openRepayModal,
    positionAddress,
    setPositionAddress,
  };
}

function useBorrowRepay() {
  const [borrowAmount, setBorrowAmount] = useState(null as BigNumber | null);
  const [repayAmount, setRepayAmount] = useState(null as BigNumber | null);
  const [token, setToken] = useState<CompoundTokenInfo>({
    underlyingToken: { name: "", address: "", decimals: 18 },
    cToken: { name: "", address: "", decimals: 18 },
  });
  const [positionAddress, setPositionAddress] = useState("");
  const [borrowState, setBorrowState] = useState(BorrowState.HIDDEN);
  const [repayState, setRepayState] = useState(RepayState.HIDDEN);
  const [vaultBalances, setVaultBalances] = useState<PositionsState>();
  const [vaultBalancesUSD, setVaultBalancesUSD] = useState<UsdPositionState>();
  const [borrowData, setBorrowData] = useState<MarketBorrowInfo[]>([]);

  function openBorrowRepayModal(
    pair: SupplyPair,
    vaultState: PositionsState,
    vaultStateUSD: UsdPositionState,
    borrowData: MarketBorrowInfo[]
  ) {
    setToken(pair);
    setVaultBalances(vaultState);
    setVaultBalancesUSD(vaultStateUSD);
    setPositionAddress(vaultState.posAddress);
    setBorrowData(borrowData);
    setBorrowState(BorrowState.WAIT_FOR_INPUT);
    setRepayState(RepayState.HIDDEN);
  }

  return {
    borrowAmount,
    setBorrowAmount,
    repayAmount,
    setRepayAmount,
    borrowState,
    setBorrowState,
    repayState,
    setRepayState,
    vaultBalances,
    setVaultBalances,
    vaultBalancesUSD,
    setVaultBalancesUSD,
    borrowData,
    setBorrowData,
    token,
    setToken,
    openBorrowRepayModal,
    positionAddress,
    setPositionAddress,
  };
}

function useSupplyWithdraw() {
  const [supplyAmount, setSupplyAmount] = useState(null as BigNumber | null);
  const [withdrawAmount, setWithdrawAmount] = useState(null as BigNumber | null);
  const [token, setToken] = useState<CompoundTokenInfo>({
    underlyingToken: { name: "", address: "", decimals: 18 },
    cToken: { name: "", address: "", decimals: 18 },
  });
  const [positionAddress, setPositionAddress] = useState("");
  const [supplyState, setSupplyState] = useState(SupplyState.HIDDEN);
  const [withdrawState, setWithdrawState] = useState(WithdrawState.HIDDEN);
  const [vaultBalances, setVaultBalances] = useState<PositionsState>();
  const [vaultBalancesUSD, setVaultBalancesUSD] = useState<UsdPositionState>();
  const [supplyData, setSupplyData] = useState<MarketSupplyInfo[]>([]);

  function openSupplyWithdrawModal(
    pair: SupplyPair,
    vaultState: PositionsState,
    vaultStateUSD: UsdPositionState,
    supplyData: MarketSupplyInfo[]
  ) {
    setToken(pair);
    setVaultBalances(vaultState);
    setVaultBalancesUSD(vaultStateUSD);
    setPositionAddress(vaultState.posAddress);
    setSupplyData(supplyData);
    setSupplyState(SupplyState.WAIT_FOR_INPUT);
    setWithdrawState(WithdrawState.HIDDEN);
  }

  return {
    supplyAmount,
    setSupplyAmount,
    withdrawAmount,
    setWithdrawAmount,
    supplyState,
    setSupplyState,
    withdrawState,
    setWithdrawState,
    vaultBalances,
    setVaultBalances,
    vaultBalancesUSD,
    setVaultBalancesUSD,
    supplyData,
    setSupplyData,
    token,
    setToken,
    openSupplyWithdrawModal,
    positionAddress,
    setPositionAddress,
  };
}

export enum TransferState {
  HIDDEN,
  WAIT_FOR_INPUT,
  WAIT_FOR_TRANSFER,
  WRONG_ADDRESS,
  CONFIRM_TRANSFER,
  SUCCESS,
  ERROR,
  CANCEL_TRANSFER,
}

export enum ClaimRookState {
  WAIT_FOR_CLAIM_INPUT,
  WAIT_FOR_CLAIM,
  WAIT_FOR_RECOVER_INPUT,
  WAIT_FOR_RECOVER,
  SUCCESS,
  ERROR,
}

export enum ClaimCompState {
  WAIT_FOR_CLAIM_INPUT,
  WAIT_FOR_CLAIM,
  WAIT_FOR_RECOVER_INPUT,
  WAIT_FOR_RECOVER,
  SUCCESS,
  ERROR,
}

function useTransferPosition() {
  const [position, setPositionAddress] = useState("");
  const [vaultState, setVaultState] = useState<PositionsState>();
  const [transferAddress, setTransferAddress] = useState("");
  const [state, setState] = useState(TransferState.HIDDEN);
  const [rookRewards, setRook] = useState<RookPositionState>();
  const [compRewards, setComp] = useState(new BigNumber(0));
  const [compState, setCompState] = useState(ClaimCompState.WAIT_FOR_CLAIM_INPUT);
  const [rookState3, setRookState3] = useState(ClaimRookState.WAIT_FOR_CLAIM_INPUT);
  const [rookState4, setRookState4] = useState(ClaimRookState.WAIT_FOR_CLAIM_INPUT);
  const [rookState4v2, setRookState4v2] = useState(ClaimRookState.WAIT_FOR_CLAIM_INPUT);
  const [rookState5, setRookState5] = useState(ClaimRookState.WAIT_FOR_CLAIM_INPUT);

  function openTransferModal(
    position: string,
    vaultState: PositionsState,
    vaultStateRook: RookPositionState,
    vaultStateComp: BigNumber
  ) {
    if (!position || !vaultStateRook || !vaultState) return;
    if (compState === ClaimCompState.ERROR) setCompState(ClaimCompState.WAIT_FOR_CLAIM_INPUT);
    if (rookState3 === ClaimRookState.ERROR) setRookState3(ClaimRookState.WAIT_FOR_CLAIM_INPUT);
    if (rookState4 === ClaimRookState.ERROR) setRookState4(ClaimRookState.WAIT_FOR_CLAIM_INPUT);
    if (rookState4v2 === ClaimRookState.ERROR) setRookState4v2(ClaimRookState.WAIT_FOR_CLAIM_INPUT);
    if (rookState5 === ClaimRookState.ERROR) setRookState5(ClaimRookState.WAIT_FOR_CLAIM_INPUT);
    setVaultState(vaultState);
    setPositionAddress(position);
    setRook(vaultStateRook);
    setComp(vaultStateComp);
    setState(TransferState.WAIT_FOR_INPUT);
  }

  return {
    vaultState,
    state,
    setState,
    compState,
    rookRewards,
    compRewards,
    setCompState,
    rookState3,
    setRookState3,
    rookState4,
    setRookState4,
    rookState4v2,
    setRookState4v2,
    rookState5,
    setRookState5,
    openTransferModal,
    position,
    setPositionAddress,
    transferAddress,
    setTransferAddress,
  };
}

function useWithdrawer() {
  const [amount, setAmount] = useState(null as BigNumber | null);
  const [token, setToken] = useState<CompoundTokenInfo>({
    underlyingToken: { name: "", address: "", decimals: 18 },
    cToken: { name: "", address: "", decimals: 18 },
  });
  const [state, setState] = useState(WithdrawState.HIDDEN);
  const [positionAddress, setPositionAddress] = useState("");
  const [vaultBalances, setVaultBalances] = useState<PositionsState>();

  function openWithdrawModal(pair: CompoundTokenInfo, position: string, balance: PositionsState) {
    setToken(pair);
    setPositionAddress(position);
    setVaultBalances(balance);
    setState(WithdrawState.WAIT_FOR_INPUT);
  }

  return {
    amount,
    setAmount,
    state,
    setState,
    token,
    setToken,
    openWithdrawModal,
    positionAddress,
    setPositionAddress,
    vaultBalances,
  };
}

type CompoundVars = {
  cTokens: string[];
  jituAddress: string;
};

export type cTokensBalance = {
  token: string;
  address: string;
  balance: BigNumber;
};

export type cTokensAllowance = {
  address: string;
  allowance: BigNumber;
};

export enum VaultCreateOptions {
  CREATE,
  MIGRATE,
}

function useWalletCreate() {
  const [state, setState] = useState(WalletCreateState.HIDDEN);
  const { library, chainId, account } = useWeb3React();
  const [compoundVars, setCompoundVars] = useState<CompoundVars>({
    cTokens: [],
    jituAddress: "",
  });
  const [jituBalance, setJituBalance] = useState(new BigNumber(0));
  const [userCTokens, setUserCtokens] = useState<cTokensBalance[]>([]);
  const [positionAddress, setPositionAddress] = useState("");
  const [migrateOnly, setMigrateOnly] = useState(false);
  const [vaultState, setVaultState] = useState<PositionsState>();
  // const [tokenAllowances, setTokenAllowances] = useState<cTokensAllowance[]>([]);
  function openWalletCreateModal(
    option: VaultCreateOptions,
    migrateOnly: boolean,
    posAddress?: string,
    posState?: PositionsState
  ) {
    option === VaultCreateOptions.CREATE
      ? setState(WalletCreateState.CREATE_VAULT)
      : setState(WalletCreateState.MIGRATE_VAULT);

    if (migrateOnly) {
      setMigrateOnly(migrateOnly);
      setState(WalletCreateState.MIGRATE_ONLY);
      if (posAddress) {
        setPositionAddress(posAddress);
      }
      if (posState) {
        setVaultState(posState);
      }
    }
  }

  async function hidingVaultNFT() {
    if (!account || !chainId) {
      return;
    }
    try {
      const network = networkIdFromChainId(chainId);
      const compGateway = contract(library, network, ContractType.CompoundGateway, account);
      const compoundVarsAddress = await compGateway.methods.compoundVars().call();
      const compoundVarsContract = contract(
        library,
        network,
        ContractType.CompoundVars,
        account,
        compoundVarsAddress
      );
      const JITUAddress = contractAddress(network, ContractType.JITUCompound);
      const cETH = contract(library, network, ContractType.cETH, account);
      const amount = await cETH.methods.balanceOf(JITUAddress).call();

      setJituBalance(amount);
      const CTokens = await compoundVarsContract.methods.cTokens().call();

      setCompoundVars({
        cTokens: CTokens,
        jituAddress: "0xcf2097340D8493b48c7Faf62D885358798b4DC70",
      });
    } catch (err) {
      console.log("ERROR: [hidingvault]", err);
      return;
    }
  }

  useEffect(() => {
    hidingVaultNFT();
  }, [library, chainId, account]);

  async function getJituBalance() {
    if (!library || !compoundVars.jituAddress || !chainId) {
      return;
    }
    try {
      //const amount = balanceOf(web3, networkID, cTokensByName['cETH'], cTokensByName['cETH'].address);
      const balance = await library.eth.getBalance(compoundVars.jituAddress);
      //setJituBalance(balance);
    } catch (err) {
      console.log("ERROR: [getJituBalance] ", err);
      return;
    }
  }

  // async function getPositionAllowance() {
  //     if (!library || !positionAddress || !compoundVars.cTokens.length || account) {
  //         return;
  //     }
  //     console.log('getPositionAllowance ==========')
  //     const multicall = new MultiCall(library);
  //     const network = networkIdFromChainId(chainId);
  //     const abi = contractABI(network, ContractType.DAI);

  //     const inputsA = compoundVars.cTokens.map((cTokenAddress) => ({
  //         target: cTokenAddress,
  //         function: 'allowance',
  //         args: [account, positionAddress],
  //     }));

  //     try {
  //         const [, allowances] = await multicall.multiCall(abi, inputsA);
  //         const mapedAllowances = compoundVars.cTokens.map((cAddress, index) => {
  //             return {
  //                 allowance: new BigNumber(allowances[index].toString()),
  //                 address: cAddress,
  //             };
  //         });
  //         setTokenAllowances(mapedAllowances);
  //     } catch (err) {
  //         console.log('ERROR: [getPositionAllowance] ', err);
  //     }
  // }

  async function getUserCTokensBalance() {
    if (!library || !account) {
      return;
    }
    try {
      const multicall = new MultiCall(library);
      const network = networkIdFromChainId(chainId);
      const abi = contractABI(network, ContractType.DAI);

      const ctokens = compoundTokens["mainnet"]["ctokens"].map((ctoken) => ctoken.address);

      const inputsB = ctokens.map((cTokenAddress) => ({
        target: cTokenAddress,
        function: "balanceOf",
        args: [account],
      }));
      const inputsN = ctokens.map((cTokenAddress) => ({
        target: cTokenAddress,
        function: "symbol",
        args: [],
      }));

      const [, bals] = await multicall.multiCall(abi, inputsB);
      const [, names] = await multicall.multiCall(abi, inputsN);

      const balances = ctokens.map((cAddress, index) => {
        return {
          balance: new BigNumber(bals[index].toString()),
          token: names[index],
          address: cAddress,
        };
      });
      const positiveBalances = balances.reduce((arr, bal) => {
        if (bal.balance.gt(0)) {
          return arr.concat([bal]);
        }
        return arr;
      }, [] as cTokensBalance[]);

      setUserCtokens(positiveBalances);
    } catch (err) {
      console.log("ERROR: [getUserCTokensBalance] ", err);
    }
  }

  // useEffect(() => {
  //     console.log('THIS EFFECT');
  //     if (!compoundVars.cTokens.length) return;
  //     getPositionAllowance();
  // }, [compoundVars.cTokens, positionAddress]);

  useEffect(() => {
    if (!compoundVars.cTokens.length) return;
    getUserCTokensBalance();
  }, [compoundVars.cTokens]);

  useEffect(() => {
    if (!compoundVars.jituAddress) return;
    getJituBalance();
  }, [compoundVars.jituAddress]);

  return {
    state,
    setState,
    openWalletCreateModal,
    jituBalance,
    compoundVars,
    userCTokens,
    positionAddress,
    setPositionAddress,
    migrateOnly,
    vaultState,
  };
}

export const TransferPosition = createContainer(useTransferPosition);
export const Repay = createContainer(useRepay);
export const Borrower = createContainer(useBorrower);
export const Supplier = createContainer(useSupplier);
export const Withdraw = createContainer(useWithdrawer);
export const WalletCreate = createContainer(useWalletCreate);
export const SupplyWithdraw = createContainer(useSupplyWithdraw);
export const BorrowRepay = createContainer(useBorrowRepay);
