/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import useSWR from "swr";
import { MakerOrder, OrderStatus } from "../pages/Trade/types";

const HIDING_BOOK_URL = "https://hidingbook.keeperdao.com/api/v1";

const axiosInstance = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Max-Age": 600,
    "Content-Type": "application/json; charset=utf-8",
  },
});

const fetcher = (url) => axiosInstance.get(url).then((res) => res.data);

const parseResponse = ({ data, error, mutate }) => {
  return {
    data,
    mutate,
    isLoading: !error && data === null,
    isError: !!error,
  };
};

const FILL_PERCENTAGE_WE_COUNT_AS_FILLED = 98;

/**
 * Make nearly filled orders appear as filled.
 * @param order Hiding Book order object
 * @returns modified order object
 */
const parseOrder = (order: MakerOrder) => {
  const filledAmount = order.metaData.filledAmount_takerToken;
  const totalAmount = parseFloat(order.order.takerAmount);
  const fillPercentage = (100 * filledAmount) / totalAmount;

  const parsedOrder = order;

  if (fillPercentage > FILL_PERCENTAGE_WE_COUNT_AS_FILLED) {
    parsedOrder.metaData.filledAmount_takerToken = totalAmount;
    parsedOrder.metaData.status = OrderStatus.FILLED;
  }

  return parsedOrder;
};

export function useUserOrders(address: string) {
  const { data, error, mutate } = useSWR(`${HIDING_BOOK_URL}/orders?maker=${address}`, fetcher);

  let orders: [] | null;

  if (data) {
    orders = data.orders.map((o: MakerOrder) => parseOrder(o));

    orders = data.orders.sort(
      (a: MakerOrder, b: MakerOrder) => Number(b.metaData.creation) - Number(a.metaData.creation)
    );
  } else {
    orders = null;
  }

  return parseResponse({ data: orders, error, mutate });
}
