import {
  Backdrop,
  Button,
  Divider,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { trackPageview } from "fathom-client";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";
import { StyledModal } from ".";
import ExchangeRates from "../../containers/exchangeRates";
import { ClaimRookState, RewardsBalances } from "../../containers/rookBalances";
import wallet, { WalletModalState, Web3ReactManagerReturn } from "../../containers/wallet";
import { usePrevious } from "../../hooks";
import {
  ConnectorNames,
  connectorsByName,
  fortmatic,
  injectedConnector,
  portis,
  walletconnect,
  walletlink,
} from "../../lib/connectors";
import { displayTokenBalance, Token } from "../../lib/tokens";
import { Pages } from "../../pages/Trade/analytics";
import { ReactComponent as EthIcon } from "../../styles/images/Tokens/ETH.svg";
import { ReactComponent as RookIcon } from "../../styles/images/Tokens/ROOK.svg";
import walletIcon, { CopyIcon, DisconnectIcon, TradeIcon } from "../Icons";
import ClaimRookModal from "./ClaimRookModal";
import OrderHistoryModal from "./dex/OrderHistoryModal";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    display: "flex",
    flex: "1 1 0",
    width: "311px",
    maxHeight: "409px",
    top: "60px",
    right: "10px",
    background: "none",
  },

  modalContent: {
    height: "100%",
    width: "100%",
    padding: "24px",
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  walletConnectorButton: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: "16px",
    background: "rgba(245, 245, 245, 0.04)",
    width: "100%",
    padding: "10px",
    color: theme.palette.text.primary,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "rgba(245, 245, 245, 0.16)",
    },
  },
  walletInfoContainer: {
    display: "flex",
    position: "relative",
    height: "100%",
    width: "100%",
    justifyContent: "flex-start",
    color: theme.palette.text.primary,
  },
  balanceRow: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  balanceUSD: {
    color: theme.palette.text.secondary,
  },
  divider: {
    position: "absolute",
    width: "100%",
    background: "linear-gradient(to right, #6C46D6 0%, #09A7F3 100%)",
    opacity: "0.24",
    marginBottom: "8px",
  },
  listItemText: {
    color: theme.palette.text.primary,
  },
  icon: {
    marginLeft: "7px",
    paddingRight: "3px",
  },
}));

enum BalanceType {
  claimable = "claimable",
  supply = "rookSupply",
  balance = "rookBalance",
}

interface IBalanceList {
  [BalanceType.supply]: BigNumber;
  [BalanceType.balance]: BigNumber;
  [BalanceType.claimable]: BigNumber;
}

function sliceAccount(account) {
  return `${account.slice(0, 6)}...${account.slice(-4)}`;
}

interface WalletInfoProps {
  rookBalance: number;
  ethBalance: number;
}

export default function WalletModal() {
  const c = useStyles();
  const { t } = useTranslation();
  const {
    connector,
    library,
    chainId,
    account,
    deactivate,
    active,
    activate,
    error,
  }: Web3ReactManagerReturn = useWeb3React();
  const {
    triedEager,
    library: walletLibrary,
    modalState,
    setModalState,
    modalOpen,
    setModalOpen,
  } = wallet.useContainer();
  const { ROOK, ETH } = ExchangeRates.useContainer();
  const [showWalletOptions, setShowWalletOptions] = useState(false);
  const [activatingConnector, setActivatingConnector] = React.useState<any>();
  const [pendingWallet, setPendingWallet] = useState(undefined);
  const [pendingError, setPendingError] = useState(false);
  const activePrevious = usePrevious(active);
  const connectorPrevious = usePrevious(connector);

  const [rookBalance, setRookBalance] = useState(0);
  const [ethBalance, setEthBalance] = useState(0);
  const [rewardsBalance, setRewardsBalance] = useState(0);

  const {
    setClaimModalState: setRewardsModalState,
    totalClaimable,
    rewardsState,
    loading: rewardsLoading,
  } = RewardsBalances.useContainer();

  const [openOrderHistoryModal, setOpenHistoryModal] = useState(false);

  const [rookBalanceList, setRookBalancesList] = useState<IBalanceList>({
    [BalanceType.supply]: new BigNumber(0),
    [BalanceType.balance]: new BigNumber(0),
    [BalanceType.claimable]: new BigNumber(0),
  });

  // Get user's ROOK and ETH balance
  useEffect(() => {
    if (account && active && walletLibrary) {
      setRookBalancesList({
        [BalanceType.supply]: rewardsState.rookSupply,
        [BalanceType.balance]: rewardsState.rookBalance,
        [BalanceType.claimable]: totalClaimable,
      });

      walletLibrary.eth
        .getBalance(account)
        .then((balance) =>
          setEthBalance(parseFloat(displayTokenBalance(Token.ETH, new BigNumber(balance))))
        );
    }
  }, [rewardsState, totalClaimable, account, active, walletLibrary]);

  useEffect(() => {
    if (rookBalanceList[BalanceType.balance]) {
      setRookBalance(
        parseFloat(displayTokenBalance(Token.ROOK, rookBalanceList[BalanceType.balance]))
      );
    }
    if (rookBalanceList[BalanceType.claimable]) {
      setRewardsBalance(
        parseFloat(
          parseFloat(
            displayTokenBalance(Token.ROOK, rookBalanceList[BalanceType.claimable])
          ).toPrecision(3)
        )
      );
    }
  }, [rookBalanceList]);

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // change modal state to show account info if a connection is successful
  useEffect(() => {
    if (
      modalOpen &&
      ((active && !activePrevious) || (connector && connector !== connectorPrevious && !error))
    ) {
      setModalState(WalletModalState.ACCOUNT);
      setShowWalletOptions(false);
    }
  }, [setModalState, active, error, connector, modalOpen, activePrevious, connectorPrevious]);

  //set modal to show wallet options if a wallet is not connected
  useEffect(() => {
    if (!account && !active && modalOpen) {
      setShowWalletOptions(true);
    }
  }, [modalOpen, account, pendingWallet, active, error]);

  useEffect(() => {
    console.log("WALLET OPTIONS", showWalletOptions);
  }, [showWalletOptions]);

  async function deactivateCurrentConnector() {
    if (connector && active) {
      switch (connector) {
        case walletlink:
        case walletconnect:
        case fortmatic:
        case portis:
          await connector.close();
          deactivate();
          return;
        default:
          deactivate();
          return;
      }
    }
  }

  const handleCopyAddress = () => {
    if (account) {
      navigator.clipboard?.writeText(account);
    }
  };

  const connectorName = (connector: AbstractConnector) => {
    switch (connector) {
      case injectedConnector:
        return ConnectorNames.Injected;
      case walletconnect:
        return ConnectorNames.WalletConnect;
      case walletlink:
        return ConnectorNames.WalletLink;
      case portis:
        return ConnectorNames.Portis;
      case fortmatic:
        return ConnectorNames.Fortmatic;
      default:
        return "Undefined Wallet";
    }
  };

  const onConnectWalletError = () => {
    setPendingError(false);
    setActivatingConnector(undefined);
    setModalState(WalletModalState.ACCOUNT);
    setShowWalletOptions(true);
  };

  const connectWallet = async (connector) => {
    setPendingWallet(connector);
    setModalState(WalletModalState.PENDING);
    activate(connector, undefined, true)
      .then(() => {
        setModalState(WalletModalState.ACCOUNT);
        setPendingWallet(undefined);
      })
      .catch((e) => {
        console.log(e);
        setModalState(WalletModalState.ERROR);
        setPendingError(true);
      });
  };

  function walletOptions() {
    if (showWalletOptions) {
      return (
        <Grid container item direction="column" justifyContent="flex-end" spacing={1}>
          {Object.keys(connectorsByName).map((name) => {
            const currentConnector = connectorsByName[name];

            //skip network connector
            if (name === ConnectorNames.Network) {
              return null;
            }

            //skip injected if not installed
            if (name === ConnectorNames.Injected && window.ethereum === undefined) {
              return null;
            }

            // do not show current connector
            if (connector === connectorsByName[name]) {
              return null;
            }

            //skip portis on mobile
            if (isMobile && name === ConnectorNames.Portis) {
              return null;
            }

            const connected = currentConnector === connector;
            const disabled = !triedEager || connected || !!error;

            return (
              <Grid key={name} container item justifyContent="center">
                <Button
                  variant="text"
                  className={c.walletConnectorButton}
                  disabled={disabled}
                  fullWidth
                  disableElevation
                  startIcon={walletIcon(name)}
                  endIcon={
                    currentConnector === activatingConnector && WalletModalState.PENDING ? (
                      <ClipLoader size={15} color="rgba(245, 245, 245, 0.6)" loading={true} />
                    ) : (
                      <></>
                    )
                  }
                  onClick={() => {
                    setActivatingConnector(currentConnector);
                    connectWallet(connectorsByName[name]);
                  }}
                >
                  {name}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      );
    }
    return null;
  }

  function pendingView() {
    return (
      <Grid
        container
        item
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
      >
        <Grid item style={{ marginTop: "20px" }}>
          <Typography variant="body1">Error encountered during connection attempt</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={onConnectWalletError}
            style={{ borderRadius: "8px" }}
          >
            Try Again
          </Button>
        </Grid>
      </Grid>
    );
  }

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleCloseHistory = () => {
    setOpenHistoryModal(false);
    setModalOpen(false);
  };

  function walletInfo() {
    return (
      <Grid container item className={c.walletInfoContainer} direction="column">
        <Grid item>
          <Typography variant="body2">{t("header-balances")}</Typography>
        </Grid>
        <Grid item style={{ paddingTop: "20px" }}>
          <Grid container item className={c.balanceRow} direction="row">
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              xs={7}
            >
              <EthIcon stroke="#FFFFFF" viewBox="0 0 36 36" />
              <Typography>{ethBalance}</Typography>
            </Grid>
            <Grid container item direction="row" xs={4}>
              <Typography align="right" className={c.balanceUSD}>
                ≈ ${Math.round(ethBalance * ETH.toNumber())}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item className={c.balanceRow} direction="row">
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              xs={7}
            >
              <RookIcon
                viewBox="0 0 32 32"
                fill="currentColor"
                stroke="currentColor"
                style={{
                  marginLeft: "6px",
                  marginRight: "2px",
                  marginTop: "7px",
                }}
              />
              <Typography>{rookBalance}</Typography>
            </Grid>
            <Grid container item direction="row" xs={4}>
              <Typography align="right" className={c.balanceUSD}>
                ≈ ${Math.round(rookBalance * ROOK.toNumber())}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Divider className={c.divider} />
        </Grid>

        <Grid item style={{ paddingTop: "20px" }}>
          <List dense disablePadding>
            <ListItem
              button
              onClick={() => setRewardsModalState(ClaimRookState.WAIT_FOR_PROCEED_TO_CLAIMING)}
            >
              <ListItemIcon style={{ color: "inherit" }}>
                <RookIcon
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  stroke="currentColor"
                  style={{
                    marginLeft: "4px",
                    marginRight: "2px",
                    marginTop: "5px",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("header-claim", { amount: rewardsBalance })} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                trackPageview({ url: Pages.Orders });
                setOpenHistoryModal(true);
              }}
            >
              <ListItemIcon style={{ color: "inherit" }}>
                <TradeIcon />
              </ListItemIcon>
              <ListItemText primary={t("header-transaction-history")} />
            </ListItem>
            <ListItem button onClick={handleCopyAddress}>
              <ListItemIcon style={{ color: "inherit" }}>
                <CopyIcon />
              </ListItemIcon>
              <ListItemText primary={t("header-copy-address")} />
            </ListItem>
            <ListItem button onClick={deactivateCurrentConnector}>
              <ListItemIcon style={{ color: "inherit" }}>
                <DisconnectIcon />
              </ListItemIcon>
              <ListItemText primary={t("header-disconnect")} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    );
  }

  if (!modalOpen) return null;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      //className={c.modal}
      open={modalOpen}
      closeAfterTransition
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: { zIndex: -5 },
        timeout: 500,
      }}
    >
      <Fade in={modalOpen}>
        <div className={c.modal}>
          <StyledModal>
            <div className={c.modalContent}>
              <Grid
                container
                item
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                style={{ paddingBottom: "24px" }}
              >
                {account && active ? (
                  <Typography variant="h6">
                    {t("header-your-wallet", { address: sliceAccount(account) })}
                  </Typography>
                ) : (
                  <Typography variant="h5">{t("header.connect-wallet-btn")}</Typography>
                )}
              </Grid>
              <Grid container item justifyContent="center">
                {account && active
                  ? walletInfo()
                  : modalState === WalletModalState.ERROR
                  ? pendingView()
                  : walletOptions()}
                {/*modalState === WalletModalState.PENDING ? pendingView() : walletOptions()*/}
              </Grid>
            </div>
          </StyledModal>
          <ClaimRookModal />
          <OrderHistoryModal
            address={account ? account : ""}
            closeModal={handleCloseHistory}
            active={openOrderHistoryModal}
          />
        </div>
      </Fade>
    </Modal>
  );
}
