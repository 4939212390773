import MultiCall from "@indexed-finance/multicall";
import { Grid, makeStyles } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import BigNumber from "bignumber.js";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Web3 from "web3";
import Balances from "../../containers/balances";
import {
  GlobalVaultsState,
  PositionsState,
  RookPositionState,
  UsdPositionState,
  WalletCreate,
  WalletCreateState,
} from "../../containers/borrow";
import CompoundData from "../../containers/compound";
import ExchangeRates from "../../containers/exchangeRates";
import { getClaimedROOKBalance, getRewards, rewardsCategory } from "../../containers/rookBalances";
import { contract, contractABI, contractAddress, ContractType } from "../../lib/contracts";
import { NetworkID, networkIdFromChainId } from "../../lib/network";
import { getRemainingBlocksInAct, getRemainingTimeInAct } from "../../lib/utils";
import CreateVault from "./CreateVault";
import VaultConnectWallet from "./VaultConnectWallet";
import VaultInfo from "./VaultInfo";
import VaultSelector from "./VaultSelector";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "absolute",
    width: "100%",
    height: "100%",
    paddingTop: "20px",
    flexDirection: "column",
    overflow: "auto",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flex: "0 1 auto",
    overflow: "auto",
    flexDirection: "column",
    position: "absolute",
    //minWidth: '298',
    width: "80%",
    maxWidth: "1082px",
    //minWidth: '460px',
    minHeight: "70%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  borrowContent: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "auto",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "24px",
    width: "100%",
    height: "100%",
  },
  borrowHeader: {
    display: "flex",
    flex: "1 1 auto",
    position: "relative",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "8px",
    maxHeight: "40%",
  },
  borrowActions: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "auto",
    position: "relative",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  borrowHeaderText: {
    color: theme.palette.text.primary,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    [theme.breakpoints.up("md")]: {
      fontSize: "32px",
      lineHeight: "39px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "30px",
    },
  },
  docsLink: {
    textTransform: "none",
    alignContent: "center",
    paddingLeft: "0px",
    "&:hover": {
      background: "none",
      textDecoration: "underline",
    },
  },
}));

const DEFAULT_POS_STATE = {
  posAddress: "",
  suppliedTokens: [],
  borrowedTokens: [],
  unhealth: 0,
  assetsIn: [],
  isUnderwritten: false,
  totalBorrowValueETH: "0",
  totalCollateralValueETH: "0",
};

const DEFAULT_ROOK_STATE = {
  posAddress: "",
  rookAPY: new BigNumber(0),
  rewardsAct3: {
    pendingRewards: new BigNumber(0),
    rewardsState: {
      earnings: new BigNumber(0),
      nonce: "",
      signature: "",
      type: 0,
    },
    rewardsDisplay: new BigNumber(0),
  },
  rewardsAct4: {
    pendingRewards: new BigNumber(0),
    rewardsState: {
      earnings: new BigNumber(0),
      nonce: "",
      signature: "",
      type: 0,
    },
    rewardsDisplay: new BigNumber(0),
  },
  rewardsAct4v2: {
    pendingRewards: new BigNumber(0),
    rewardsState: {
      earnings: new BigNumber(0),
      nonce: "",
      signature: "",
      type: 0,
    },
    rewardsDisplay: new BigNumber(0),
  },
  rewardsAct5: {
    pendingRewards: new BigNumber(0),
    rewardsState: {
      earnings: new BigNumber(0),
      nonce: "",
      signature: "",
      type: 0,
    },
    rewardsDisplay: new BigNumber(0),
  },
};

const DEFAULT_USD_STATE = {
  posAddress: "",
  collateralUSD: new BigNumber(0),
  collateralETH: new BigNumber(0),
  borrowUSD: new BigNumber(0),
  borrowETH: new BigNumber(0),
  limitUSD: new BigNumber(0),
  limitETH: new BigNumber(0),
  leftToBorrowUSD: new BigNumber(0),
  leftToBorrowETH: new BigNumber(0),
  supplyETH: new BigNumber(0),
  supplyUSD: new BigNumber(0),
  supplyInterestAPY: new BigNumber(0),
  borrowInterestAPY: new BigNumber(0),
  netInterestAPY: new BigNumber(0),
  supplyCompAPY: new BigNumber(0),
  borrowCompAPY: new BigNumber(0),
  netCompAPY: new BigNumber(0),
  powerUsed: 0,
  health: 0,
};

const DEFAULT_GLOBAL_VAULT_STATE = {
  numVaults: 0,
  collateralUSD: new BigNumber(0),
  borrowUSD: new BigNumber(0),
};

const ACT_IV_START_BLOCK = new BigNumber(13617668);
const ACT_IV_END_BLOCK = new BigNumber(13720196);
const ACT_IV_LP_REWARDS = new BigNumber(1293.6);

const ACT_IV_VAULT_REWARDS_PER_BLOCK = ACT_IV_LP_REWARDS.dividedBy(
  ACT_IV_END_BLOCK.minus(ACT_IV_START_BLOCK)
);

const DAILY_VAULT_ROOK_EMISSION = 260.55;
//const DAILY_VAULT_ROOK_EMISSION = 0;

type PositionCompClaim = {
  posAddress: string;
  claimBalance: BigNumber;
};

function Vaults(): React.ReactElement {
  const c = useStyles();
  const { t } = useTranslation();
  const { library, chainId, account } = useWeb3React();

  const [loadingVaults, setLoadingVaults] = useState(false);

  const [userVaultAddresses, setVaultAddresses] = useState<string[]>([]);
  const [userVaultBalances, setVaultBalances] = useState<PositionsState[]>([]);
  const [userVaultBalancesUSD, setVaultBalancesUSD] = useState<UsdPositionState[]>([]);
  const [userVaultBalancesROOK, setVaultBalancesROOK] = useState<RookPositionState[]>([]);

  const [selectedVaultAddress, setSelectedVaultAddress] = useState<string>("");
  const [selectedVaultBalances, setSelectedVaultBalances] =
    useState<PositionsState>(DEFAULT_POS_STATE);
  const [selectedVaultBalancesUSD, setSelectedVaultUSD] =
    useState<UsdPositionState>(DEFAULT_USD_STATE);
  const [selectedVaultBalancesROOK, setSelectedVaultROOK] =
    useState<RookPositionState>(DEFAULT_ROOK_STATE);

  const [globalVaultsState, setGlobalVaults] = useState<GlobalVaultsState>(
    DEFAULT_GLOBAL_VAULT_STATE
  );
  const [userTotalBorrowUSD, setTotalBorrowUSD] = useState("");
  const [globalTotalBorrowUSD, setglobalSupplyUSD] = useState("");

  const {
    compoundData,
    compoundTokenPairs: compoundTokens,
    loading: compDataLoading,
  } = CompoundData.useContainer();
  const { compoundBalances } = Balances.useContainer();
  const rates = ExchangeRates.useContainer();

  const { openWalletCreateModal, state: vaultCreateState } = WalletCreate.useContainer();

  const fetchBalancesInterval = useRef<number | null>(null);

  //On wallet connect, load vaults and compound token data
  useEffect(() => {
    setLoadingVaults(true);
    setVaultBalances([]);
    setSelectedVaultBalances(DEFAULT_POS_STATE);
    setVaultAddresses([]);

    if (account && chainId && library) {
      updateVaults();
    }
  }, [account, chainId, library]);

  //Update vaults on vault create or transfer
  useEffect(() => {
    if (!account || !library || !chainId) {
      return;
    }
    if (
      vaultCreateState === WalletCreateState.CREATE_SUCCESS ||
      vaultCreateState === WalletCreateState.MIGRATE_SUCCESS
    ) {
      setSelectedVaultAddress("");
      setLoadingVaults(true);
      updateVaults();
    }
  }, [vaultCreateState]);

  //Fetch vault token balances on 30 second interval after initial load
  useEffect(() => {
    if (!chainId || !library || !account || compDataLoading) return;

    fetchBalancesInterval.current = window.setInterval(() => {
      updateVaultBalances();
    }, 30000);

    updateVaultBalances();

    return () => {
      if (fetchBalancesInterval.current && !loadingVaults) {
        clearInterval(fetchBalancesInterval.current);
      }
    };
  }, [userVaultAddresses, compDataLoading]);

  //Update selected vault balances when selected vault changes and when vault balances are updated
  useEffect(() => {
    updateSelectedBalances();
  }, [userVaultBalances, userVaultBalancesUSD, userVaultBalancesROOK]);

  //Get balances of all Hiding Vaults to display TVL (this will be depracated by metrics server)
  async function getAllVaults() {
    if (!library || !account || !chainId)
      return {
        numVaults: 0,
        collateralUSD: new BigNumber(0),
        borrowUSD: new BigNumber(0),
      };

    const network = networkIdFromChainId(chainId);
    const hidingVaultContract = contract(
      library,
      network,
      ContractType.HidingVaultNFT,
      account ? account : "0"
    );

    let ethPrice;
    try {
      ethPrice = rates.ETH;
    } catch (err) {
      ethPrice = new BigNumber(0);
    }

    let totalSupply;
    try {
      totalSupply = await hidingVaultContract.methods.totalSupply().call();
      totalSupply = parseInt(totalSupply);
    } catch (err) {
      if (
        process.env.NODE_ENV === "development" ||
        process.env.NODE_ENV === "production" ||
        process.env.NODE_ENV === "test"
      ) {
        console.log("HV totalSupply error: ", err);
      }
      totalSupply = 0;
    }

    let allVaultAddresses: string[] = [];
    try {
      const multicall = new MultiCall(library);
      const hidingVaultABI = contractABI(network, ContractType.HidingVaultNFT);
      const hidingVaultContract = contractAddress(network, ContractType.HidingVaultNFT);

      const addresses: string[] = [];
      let addressesLeft = totalSupply;
      let idx = 0;

      const inputs: { target: string; function: string; args: number[] }[][] = [];

      while (addressesLeft > 0) {
        const numAddresses = addressesLeft < 50 ? addressesLeft : 50;
        const lengthArr = Array.from(Array(numAddresses), (_, i) => idx + i + 1);
        const input = lengthArr.map((n, i) => {
          return {
            target: hidingVaultContract,
            function: "tokenByIndex",
            args: [i + idx],
          };
        });

        inputs.push(input);

        addressesLeft = addressesLeft - numAddresses;
        idx = idx + numAddresses;
      }

      const calls = inputs.map((input) => multicall.multiCall(hidingVaultABI, input));
      const results = await Promise.all(calls);

      results.map((result) => {
        const [, positionAddresses] = result;
        positionAddresses.map((address, i) => {
          const kCompoundPosition = new BigNumber(address.toString());

          let hex = address._hex;
          if (hex.length !== 42) {
            const slice = hex.slice(2);
            const padded = slice.padStart(40, "0");
            hex = "0x" + padded;
          }
          addresses.push(hex);
        });
      });
      allVaultAddresses = addresses;
    } catch (err) {
      if (process.env.NODE_ENV === "development") {
        console.log("Error: [getHidingVaultAddresses]: ", err);
      }
    }

    let allVaultsData;
    const vaultBatchSize = 40;
    const allVaultsCollateralETH: number[] = [];
    const allVaultsBorrowedETH: number[] = [];
    const allVaultsSupplyUSD: number[] = [];

    try {
      // Batch compound API calls
      let idx = 0;
      const requests: Promise<any>[] = [];
      let responses: any[] = [];
      while (idx < allVaultAddresses.length) {
        let vaultsBatch;
        if (idx + vaultBatchSize >= allVaultAddresses.length) {
          vaultsBatch = allVaultAddresses.slice(idx);
        } else {
          vaultsBatch = allVaultAddresses.slice(idx, idx + vaultBatchSize);
        }

        const CompoundURL = `https://api.compound.finance/api/v2/account?network=${network}&page_size=${vaultBatchSize}&page_number=1&addresses[]=${vaultsBatch.join(
          "&addresses[]="
        )}`;

        requests.push(axios.get(CompoundURL));
        idx = idx + vaultBatchSize;
      }

      responses = await Promise.all(requests);

      for (let i = 0; i < responses.length; i++) {
        allVaultsData = responses[i];
        allVaultsData.data.accounts.map((account) => {
          allVaultsCollateralETH.push(parseFloat(account.total_collateral_value_in_eth.value));
          allVaultsBorrowedETH.push(parseFloat(account.total_borrow_value_in_eth.value));
          let vaultSupply = 0;
          account.tokens.map((token) => {
            const tokenInfo = compoundData.supply.find((tok) => tok.symbol === token.symbol);
            if (!tokenInfo) return;
            const tokenPrice = tokenInfo.priceUSD;
            vaultSupply =
              vaultSupply +
              parseFloat(token.supply_balance_underlying.value) * parseFloat(tokenPrice);
          });

          allVaultsSupplyUSD.push(vaultSupply);
        });
      }
    } catch (err) {
      console.log("compound api error: ", err);
    }

    let allVaultsSuppliedUSD = 0;
    let allVaultsBorrowedUSD = 0;

    allVaultsBorrowedETH.forEach(
      (bal) => (allVaultsBorrowedUSD = allVaultsBorrowedUSD + bal * ethPrice)
    );
    allVaultsSupplyUSD.forEach((bal) => (allVaultsSuppliedUSD = allVaultsSuppliedUSD + bal));

    // Display vaults sorted by USD supply amount
    if (process.env.NODE_ENV === "development") {
      const sortedSupply = allVaultsSupplyUSD.sort((a, b) => (a > b ? -1 : 1));
      const sortedBorrow = allVaultsBorrowedETH
        .sort((a, b) => (a > b ? -1 : 1))
        .map((x) => x * ethPrice);

      let top10 = 0;
      let top20 = 0;
      sortedSupply.map((x, i) => {
        if (i < 10) top10 = top10 + x;
        if (i < 20) top20 = top20 + x;
      });
      console.log("SORTED BY SUPPLY: ", sortedSupply);
      console.log("TOP 10 SUPPLY VOLUME: ", top10);
      console.log("TOP 20 SUPPLY VOLUME: ", top20);

      top10 = 0;
      sortedBorrow.map((x, i) => {
        if (i < 10) top10 = top10 + x;
      });
      console.log("SORTED BY BORROW: ", sortedBorrow);
      console.log("TOP 10 BORROW VOLUME: ", top10);
    }

    let colEth = 0;
    let borEth = 0;
    allVaultsCollateralETH.forEach((bal) => (colEth = colEth + bal));
    allVaultsBorrowedETH.forEach((bal) => (borEth = borEth + bal));

    const collateralUSD = new BigNumber(allVaultsSuppliedUSD);
    const borrowUSD = new BigNumber(allVaultsBorrowedUSD);

    return { numVaults: totalSupply, collateralUSD, borrowUSD };
  }

  //Refresh vault token and USD balances
  async function updateVaultBalances() {
    if (!library || !chainId || !account) return;

    if (process.env.NODE_ENV === "development") {
      console.log("Fetching global vault USD borrow balance...");
    }

    const globalVaults = await getAllVaults();

    if (process.env.NODE_ENV === "development") {
      console.log("Total number of Hiding Vaults: ", globalVaults.numVaults);
      console.log("Global USD borrow balance: ", globalVaults.borrowUSD.toFixed(2));
      console.log("Global USD collateral balance: ", globalVaults.collateralUSD.toFixed(2));
    }

    setGlobalVaults(globalVaults);

    if (!userVaultAddresses.length) return;

    if (process.env.NODE_ENV === "development") {
      console.log("User Vault Addresses: ", userVaultAddresses);
      console.log("Fetching vault token balances...");
    }
    const tokenBalances = await getVaultTokenBalances(
      userVaultAddresses,
      library,
      chainId,
      account
    );

    if (process.env.NODE_ENV === "development") {
      console.log("Vault States: ", tokenBalances);
      console.log("Fetching vault APYs and USD balances...");
    }
    const usdBalances = await getVaultUSDBalances(tokenBalances, library, chainId, account);
    let userTotal = new BigNumber(0);
    usdBalances.forEach((vault) => (userTotal = userTotal.plus(vault.borrowUSD)));

    if (process.env.NODE_ENV === "development") {
      console.log("Vault USD balances/APYs: ", usdBalances);
      console.log("Your total borrow balance: ", userTotal.toFixed(2));
      console.log("Fetching vault rewards balances");
    }
    const rewardsBalances = await getVaultRookRewards(
      tokenBalances,
      usdBalances,
      globalVaults.borrowUSD,
      userTotal,
      library,
      chainId,
      account
    );

    setVaultBalances(tokenBalances);
    setVaultBalancesUSD(usdBalances);
    setVaultBalancesROOK(rewardsBalances);
    setTotalBorrowUSD(userTotal.toFixed(2));
    setLoadingVaults(false);
  }

  //Update user's vaults (called after minting or transferring)
  async function updateVaults() {
    if (!account || !chainId || !library) {
      return;
    } else {
      //console.log('Running update vaults');
      try {
        // Fetch number of hiding vaults owned by user
        const network = networkIdFromChainId(chainId);
        const hidingVaultNFT = contract(library, network, ContractType.HidingVaultNFT, account);
        const numberOfHidingVaults = Number(await hidingVaultNFT.methods.balanceOf(account).call());

        if (!numberOfHidingVaults) {
          setVaultAddresses([]);
          setVaultBalances([]);
          setLoadingVaults(false);
          return;
        }

        // If new hiding vaults found, fetch vault addresses
        if (numberOfHidingVaults !== userVaultAddresses.length) {
          setLoadingVaults(true);
        }
        const compoundPositions = await getHidingVaultAddresses(
          numberOfHidingVaults,
          account,
          network
        );
        setVaultAddresses(compoundPositions);
      } catch (err) {
        if (
          process.env.NODE_ENV === "development" ||
          process.env.NODE_ENV === "production" ||
          process.env.NODE_ENV === "test"
        ) {
          console.log("[updateVaults] Error: ", err);
        }

        setLoadingVaults(false);
      }
    }
  }

  //Fetch addresses of all vaults owned by a given user address
  async function getHidingVaultAddresses(
    numberOfAddresses: number,
    userAddress: string,
    network: NetworkID
  ): Promise<string[]> {
    try {
      const multicall = new MultiCall(library);
      const hidingVaultABI = contractABI(network, ContractType.HidingVaultNFT);
      const hidingVaultContract = contractAddress(network, ContractType.HidingVaultNFT);

      const lengthArr = Array.from(Array(numberOfAddresses), (_, i) => i + 1);
      const inputs = lengthArr.map((n, i) => {
        return {
          target: hidingVaultContract,
          function: "tokenOfOwnerByIndex",
          args: [userAddress, i],
        };
      });

      const [, positionAddresses] = await multicall.multiCall(hidingVaultABI, inputs);

      const addresses = positionAddresses.map((address, i) => {
        const kCompoundPosition = new BigNumber(address.toString());
        let hex = address._hex;
        if (hex.length !== 42) {
          const slice = hex.slice(2);
          const padded = slice.padStart(40, "0");
          hex = "0x" + padded;
        }
        return hex;
      });
      return addresses;
    } catch (err) {
      if (
        process.env.NODE_ENV === "development" ||
        process.env.NODE_ENV === "production" ||
        process.env.NODE_ENV === "test"
      ) {
        console.log("Error: [getHidingVaultAddresses]: ", err);
      }
      return [];
    }
  }

  //Fetch total supply, collateral and borrow token balances of a given list of vaults
  async function getVaultTokenBalances(
    positions: string[],
    web3: Web3,
    chainId: number,
    account: string
  ) {
    const posBalances: PositionsState[] = [];
    const network = networkIdFromChainId(chainId);

    const kCompoundMulticall = new MultiCall(web3);
    const kCompoundABI = contractABI(networkIdFromChainId(chainId), ContractType.KCompound);

    // Fetch cToken and underlying token supply balances with KCompound contract utility functions
    async function fetchSupplyBalances(
      inputs: any[],
      multicall: MultiCall,
      abi: any,
      underlying = false
    ) {
      const [, tokenOutstanding] = await multicall.multiCall(abi, inputs);
      const balances = compoundTokens.map((t, index) => {
        const token = underlying ? t.underlyingToken : t.cToken;
        if (!tokenOutstanding[index])
          return {
            name: token.name,
            address: token.address.toLowerCase(),
            balance: "0",
            decimals: token.decimals,
          };

        return {
          name: token.name,
          address: token.address.toLowerCase(),
          balance: tokenOutstanding[index].toString(),
          //balance: new BigNumber(tokenOutstanding[index].toString())
          //.dividedBy(new BigNumber(10).pow(token.decimals))
          //.toString(),
          decimals: token.decimals,
        };
      });

      return balances;
    }

    // Fetch underlying token borrow balances from cToken contracts
    async function fetchBorrowBalances(position: string, assetsIn: string[]) {
      const balances = await Promise.all(
        compoundTokens.map(async (pair) => {
          const ctoken = pair.cToken;
          const underlying = pair.underlyingToken;

          if (assetsIn.includes(ctoken.address.toLowerCase())) {
            const cTokenContract = contract(web3, network, ContractType[ctoken.name], account);

            const borrowBalance = await cTokenContract.methods
              .borrowBalanceCurrent(position)
              .call();
            return {
              name: underlying.name,
              address: underlying.address.toLowerCase(),
              balance: borrowBalance.toString(),
              decimals: underlying.decimals,
            };
          } else {
            return {
              name: underlying.name,
              address: underlying.address.toLowerCase(),
              balance: "0",
              decimals: underlying.decimals,
            };
          }
        })
      );

      return balances;
    }

    try {
      // Fetch collateral and borrow value in ETH from Compound API
      const CompoundURL = `https://api.compound.finance/api/v2/account?network=${network}&addresses[]=${positions.join(
        "&addresses[]="
      )}`;
      const compoundAccountData = await axios.get(CompoundURL);
      //console.log('API RESULT USER', compoundAccountData);

      for (let i = 0; i < positions.length; i++) {
        const posAddress = positions[i];

        const accountData = compoundAccountData.data.accounts.find(
          (account) => account.address.toLowerCase() === posAddress.toLowerCase()
        );

        let totalSupply = "0";
        let totalBorrow = "0";
        if (accountData) {
          totalSupply = accountData.total_collateral_value_in_eth.value;
          totalBorrow = accountData.total_borrow_value_in_eth.value;
        }

        // Fetch markets vault has entered into from Compound Comptroller contract
        const comptroller = contract(web3, network, ContractType.Comptroller, account);
        let assetsIn = await comptroller.methods.getAssetsIn(posAddress).call();
        assetsIn = assetsIn.map((address) => address.toLowerCase());

        //const liq = await comptroller.methods.getAccountLiquidity(posAddress).call();
        //console.log('LIQUIDITY', liq);

        const KCompoundContract = contract(
          web3,
          network,
          ContractType.KCompound,
          account,
          posAddress
        );

        const supplyInputs = compoundTokens.map((tokenPair) => {
          const tok = tokenPair.cToken;
          return {
            target: posAddress,
            function: "compound_balanceOf",
            args: [tok.address],
          };
        });

        const supplyUnderlyingInputs = compoundTokens.map((tokenPair) => {
          const tok = tokenPair.cToken;
          return {
            target: posAddress,
            function: "compound_balanceOfUnderlying",
            args: [tok.address],
          };
        });
        const balances = await Promise.all([
          fetchSupplyBalances(supplyInputs, kCompoundMulticall, kCompoundABI),
          fetchSupplyBalances(supplyUnderlyingInputs, kCompoundMulticall, kCompoundABI, true),
          fetchBorrowBalances(posAddress, assetsIn),
        ]);

        const supply = balances[0];
        const supplyUnderlying = balances[1];

        const borrow = balances[2];

        let unhealth = 0;
        try {
          unhealth = parseFloat(await KCompoundContract.methods.compound_unhealth().call());
        } catch (err) {
          console.log("compound_unhealth error: ", err);
        }

        let isUnderwritten = false;
        try {
          isUnderwritten = await KCompoundContract.methods.compound_isUnderwritten().call();
        } catch (err) {
          console.log("compound_isUnderwritten error: ", err);
        }

        // Create cToken/underlying pair for supply balances
        const supplyPair = supply.map((bal, i) => {
          let supplyInterestAccrued = "0";
          if (accountData) {
            const userSupplyTokenData = accountData.tokens.find((tok) => tok.symbol === bal.name);
            if (userSupplyTokenData) {
              supplyInterestAccrued = userSupplyTokenData.lifetime_supply_interest_accrued.value;
            }
          }

          const cToken = {
            name: bal.name,
            address: bal.address,
            balance: bal.balance,
            decimals: bal.decimals,
            interestAccrued: "0",
          };

          const underlyingToken = {
            name: supplyUnderlying[i].name,
            address: supplyUnderlying[i].address,
            balance: supplyUnderlying[i].balance,
            decimals: supplyUnderlying[i].decimals,
            interestAccrued: supplyInterestAccrued ? supplyInterestAccrued : "0",
          };
          return { cToken: cToken, underlyingToken: underlyingToken };
        });

        // Crete cToken/underlying pair for borrow balances
        // const borrowPair = borrow.map((bal, i) => ({
        //     cToken: supply[i],
        //     underlyingToken: bal,
        // }));

        const borrowPair = borrow.map((bal, i) => {
          let borrowInterestAccrued = "0";
          if (accountData) {
            const userSupplyTokenData = accountData.tokens.find((tok) => tok.symbol === bal.name);
            if (userSupplyTokenData) {
              borrowInterestAccrued = userSupplyTokenData.lifetime_borrow_interest_accrued.value;
            }
          }

          const cToken = {
            name: supply[i].name,
            address: supply[i].address,
            balance: supply[i].balance,
            decimals: supply[i].decimals,
            interestAccrued: "0",
          };

          const underlyingToken = {
            name: bal.name,
            address: bal.address,
            balance: bal.balance,
            decimals: bal.decimals,
            interestAccrued: borrowInterestAccrued ? borrowInterestAccrued : "0",
          };
          return { cToken: cToken, underlyingToken: underlyingToken };
        });

        posBalances.push({
          posAddress,
          suppliedTokens: supplyPair,
          borrowedTokens: borrowPair,
          unhealth: unhealth,
          assetsIn: assetsIn,
          isUnderwritten: isUnderwritten,
          totalBorrowValueETH: totalBorrow ? totalBorrow : "0",
          totalCollateralValueETH: totalSupply ? totalSupply : "0",
        });
      }
      return posBalances;
    } catch (err) {
      if (process.env.NODE_ENV === "development") {
        console.log("Error: [getVaultTokenBalances]: ", err);
      }
      return [];
    }
  }

  //Calculate USD supply, collateral and borrow balances of a given list of vaults
  function getVaultUSDBalances(
    vaults: PositionsState[],
    web3: Web3,
    chainId: number,
    account: string
  ) {
    const statuses: UsdPositionState[] = [];

    //console.log('VAULT STATES: ', vaults);

    let ethPrice;
    try {
      ethPrice = rates.ETH;
    } catch (err) {
      ethPrice = new BigNumber(0);
    }

    // For each vault, calculate ssupply and borrow value in USD from token balances and current prices
    vaults.forEach((vault) => {
      const supplyBalance = vault.suppliedTokens.reduce(
        (bal, tokenBal) => {
          const tokInfo = compoundData.borrow.find(
            (token) => token.underlyingSymbol === tokenBal.underlyingToken.name
          );

          if (!tokInfo) {
            return bal;
          }
          const decimalScaling = new BigNumber(10).pow(tokenBal.underlyingToken.decimals);
          const underlyingBalanceETH = new BigNumber(tokenBal.underlyingToken.balance)
            .dividedBy(decimalScaling)
            .multipliedBy(new BigNumber(tokInfo.priceETH));
          const underlyingBalanceUSD = new BigNumber(tokenBal.underlyingToken.balance)
            .dividedBy(decimalScaling)
            .multipliedBy(new BigNumber(tokInfo.priceUSD));
          const collateralETH = vault.assetsIn.includes(tokenBal.cToken.address)
            ? underlyingBalanceETH
            : new BigNumber(0);
          const collateralUSD = vault.assetsIn.includes(tokenBal.cToken.address)
            ? underlyingBalanceUSD
            : new BigNumber(0);
          const limitETH = vault.assetsIn.includes(tokenBal.cToken.address)
            ? underlyingBalanceETH.multipliedBy(new BigNumber(tokInfo.collateralFactor))
            : new BigNumber(0);

          return {
            supplyBalanceETH: bal.supplyBalanceETH.plus(underlyingBalanceETH),
            supplyBalanceUSD: bal.supplyBalanceUSD.plus(underlyingBalanceUSD),
            collateralBalanceETH: bal.collateralBalanceETH.plus(collateralETH),
            collateralBalanceUSD: bal.collateralBalanceUSD.plus(collateralUSD),
            limitETH: bal.limitETH.plus(limitETH),
          };
        },
        {
          supplyBalanceETH: new BigNumber(0),
          supplyBalanceUSD: new BigNumber(0),
          collateralBalanceETH: new BigNumber(0),
          collateralBalanceUSD: new BigNumber(0),
          limitETH: new BigNumber(0),
        }
      );

      const borrowBalance = vault.borrowedTokens.reduce(
        (bal, tokenBal) => {
          const tokInfo = compoundData.borrow.find(
            (token) => token.underlyingSymbol === tokenBal.underlyingToken.name
          );
          if (!tokInfo) {
            return bal;
          }
          // const underlyingBalance = new BigNumber(tokenBal.underlyingToken.balance).multipliedBy(
          //     new BigNumber(tokInfo.priceETH),
          const decimalScaling = new BigNumber(10).pow(tokenBal.underlyingToken.decimals);
          const underlyingBalanceETH = new BigNumber(tokenBal.underlyingToken.balance)
            .dividedBy(decimalScaling)
            .multipliedBy(new BigNumber(tokInfo.priceETH));
          const underlyingBalanceUSD = new BigNumber(tokenBal.underlyingToken.balance)
            .dividedBy(decimalScaling)
            .multipliedBy(new BigNumber(tokInfo.priceUSD));
          return {
            borrowBalanceETH: bal.borrowBalanceETH.plus(underlyingBalanceETH),
            borrowBalanceUSD: bal.borrowBalanceUSD.plus(underlyingBalanceUSD),
          };
        },
        {
          borrowBalanceETH: new BigNumber(0),
          borrowBalanceUSD: new BigNumber(0),
        }
      );

      const adjustedCollateralValueETH = new BigNumber(vault.totalCollateralValueETH);
      const totalBorrowValueETH = new BigNumber(vault.totalBorrowValueETH);

      const supplyETH = supplyBalance.supplyBalanceETH;
      const supplyUSD = supplyBalance.supplyBalanceUSD;
      const collateralETH = supplyBalance.collateralBalanceETH;
      const collateralUSD = supplyBalance.collateralBalanceUSD;
      const borrowETH = totalBorrowValueETH.minus(borrowBalance.borrowBalanceETH).abs().gt(0.00001)
        ? borrowBalance.borrowBalanceETH
        : totalBorrowValueETH;
      const borrowUSD = borrowBalance.borrowBalanceUSD;
      const limitETH = adjustedCollateralValueETH.minus(supplyBalance.limitETH).abs().gt(0.00001)
        ? supplyBalance.limitETH
        : adjustedCollateralValueETH;
      const limitUSD = limitETH.multipliedBy(ethPrice);
      const leftToBorrowETH = limitETH.minus(borrowETH);
      const leftToBorrowUSD = limitUSD.minus(borrowUSD);
      const pUsed = borrowETH.dividedBy(limitETH).multipliedBy(100).toNumber();
      const powerUsed = pUsed >= 100 || isNaN(pUsed) ? 100 : Math.floor(pUsed);
      const health = 100 - vault.unhealth;

      // Calculate supply APYs
      let supplyCompAPY = new BigNumber(0);
      let supplyInterestAPY = new BigNumber(0);
      vault.suppliedTokens.forEach((token) => {
        const tokInfo = compoundData.supply.find(
          (tok) => tok.underlyingSymbol === token.underlyingToken.name
        );
        if (!tokInfo) {
          return;
        }
        const decimalScaling = new BigNumber(10).pow(token.underlyingToken.decimals);
        const underlyingBalance = new BigNumber(token.underlyingToken.balance)
          .dividedBy(decimalScaling)
          .multipliedBy(new BigNumber(tokInfo.priceUSD));

        if (!underlyingBalance.gt(0)) return;

        const multiplier = underlyingBalance.dividedBy(supplyUSD);
        const interestApyToAdd = new BigNumber(tokInfo.supplyRate).multipliedBy(multiplier);
        const compApyToAdd = new BigNumber(tokInfo.supplyCompAPY)
          .dividedBy(100)
          .multipliedBy(multiplier);
        supplyInterestAPY = supplyInterestAPY.plus(interestApyToAdd);
        supplyCompAPY = supplyCompAPY.plus(compApyToAdd);
      });

      // Calculate borrow APYs
      let borrowInterestAPY = new BigNumber(0);
      let borrowCompAPY = new BigNumber(0);
      vault.borrowedTokens.forEach((token) => {
        const tokInfo = compoundData.borrow.find(
          (tok) => tok.underlyingSymbol === token.underlyingToken.name
        );
        if (!tokInfo) {
          return;
        }

        // Calculate value in USD of each asset
        const decimalScaling = new BigNumber(10).pow(token.underlyingToken.decimals);
        const underlyingBalance = new BigNumber(token.underlyingToken.balance)
          .dividedBy(decimalScaling)
          .multipliedBy(new BigNumber(tokInfo.priceUSD));

        if (!underlyingBalance.gt(0)) return;

        const multiplier = underlyingBalance.dividedBy(supplyUSD);
        const interestApyToAdd = new BigNumber(tokInfo.borrowRate).multipliedBy(multiplier);
        const compApyToAdd = new BigNumber(tokInfo.borrowCompAPY)
          .dividedBy(100)
          .multipliedBy(multiplier);
        borrowInterestAPY = borrowInterestAPY.plus(interestApyToAdd);
        borrowCompAPY = borrowCompAPY.plus(compApyToAdd);
        // console.log(
        //     token.underlyingToken.name,
        //     'BORROW APY: ',
        //     'INTEREST: ',
        //     interestApyToAdd.toFixed(4),
        //     'COMP: ',
        //     compApyToAdd.toFixed(4),
        //     'USD BAL: ',
        //     underlyingBalance.toFixed(4),
        //     'TOTAL SUPPLY BAL: ',
        //     supplyUSD.toFixed(4),
        //     'MULTIPLIER: ',
        //     multiplier.toFixed(4),
        // );
      });

      let netInterestAPY = supplyInterestAPY;
      let netCompAPY = supplyCompAPY;
      if (borrowInterestAPY.gt(0)) {
        netInterestAPY = supplyInterestAPY.minus(borrowInterestAPY);
      }
      if (borrowCompAPY.gt(0)) {
        netCompAPY = supplyCompAPY.plus(borrowCompAPY);
      }

      const status = {
        posAddress: vault.posAddress,
        supplyETH: supplyETH,
        supplyUSD: supplyETH.multipliedBy(ethPrice),
        collateralETH: collateralETH,
        collateralUSD: collateralUSD,
        borrowETH: borrowETH,
        borrowUSD: borrowUSD,
        limitETH: limitETH,
        limitUSD: limitETH.multipliedBy(ethPrice),
        leftToBorrowETH: leftToBorrowETH,
        leftToBorrowUSD: leftToBorrowUSD,
        supplyInterestAPY: supplyInterestAPY,
        borrowInterestAPY: borrowInterestAPY,
        netInterestAPY: netInterestAPY,
        supplyCompAPY: supplyCompAPY,
        borrowCompAPY: borrowCompAPY,
        netCompAPY: netCompAPY,
        powerUsed: powerUsed,
        health: vault.unhealth,
      };

      //console.log(status);

      statuses.push(status);
    });

    //console.log('USD STATUSES: ', statuses);

    return statuses;
  }

  //Fetch and calculate ROOK rewards balances for a given list of vaults
  async function getVaultRookRewards(
    vaults: PositionsState[],
    vaultsUSD: UsdPositionState[],
    globalBorrow: BigNumber,
    userBorrow: BigNumber,
    web3: Web3,
    chainId: number,
    account: string
  ) {
    const network = networkIdFromChainId(chainId);

    const statuses: RookPositionState[] = [];

    const [currentBlock, secondsLeftInAct] = await Promise.all([
      web3.eth.getBlockNumber(),
      getRemainingTimeInAct(),
    ]);

    const blocksLeftInAct = getRemainingBlocksInAct(currentBlock);

    // Get total rewards and claimed rewards for each vault
    for (let i = 0; i < vaults.length; i++) {
      const vaultAddress = vaults[i].posAddress;
      const vault = vaults[i];
      const vaultUSD = vaultsUSD[i];

      let claimedBalance3 = new BigNumber(0);
      let totalBalance3 = new BigNumber(0);
      let earnings3 = {
        earnings: new BigNumber(0),
        nonce: "",
        signature: "",
      };
      let claimedBalance4 = new BigNumber(0);
      let totalBalance4 = new BigNumber(0);
      let earnings4 = {
        earnings: new BigNumber(0),
        nonce: "",
        signature: "",
      };
      let claimedBalance4v2 = new BigNumber(0);
      let totalBalance4v2 = new BigNumber(0);
      let earnings4v2 = {
        earnings: new BigNumber(0),
        nonce: "",
        signature: "",
      };
      let claimedBalance5 = new BigNumber(0);
      let totalBalance5 = new BigNumber(0);
      let earnings5 = {
        earnings: new BigNumber(0),
        nonce: "",
        signature: "",
      };

      try {
        const [
          claimedAct3,
          rewardsAct3,
          claimedAct4,
          rewardsAct4,
          claimedAct4v2,
          rewardsAct4v2,
          claimedAct5,
          rewardsAct5,
        ] = await Promise.all([
          getClaimedROOKBalance(web3, network, vaultAddress, ContractType.HidingVaultDistributor),
          getRewards(rewardsCategory.HidingVault, vaultAddress, network),
          getClaimedROOKBalance(web3, network, vaultAddress, ContractType.HidingVaultDistributor2),
          getRewards(rewardsCategory.HidingVault2, vaultAddress, network),
          getClaimedROOKBalance(web3, network, vaultAddress, ContractType.HidingVaultDistributor3),
          getRewards(rewardsCategory.HidingVault3, vaultAddress, network),
          getClaimedROOKBalance(web3, network, vaultAddress, ContractType.HidingVaultDistributor4),
          getRewards(rewardsCategory.HidingVault4, vaultAddress, network),
        ]);

        if (process.env.NODE_ENV === "development") {
          console.log(`Vault ${i} claimed ROOK act 3: ${claimedAct3?.toString()}`);
          console.log(`Vault ${i} total ROOK rewards act 3: ${rewardsAct3.earnings.toString()}: `);
          console.log(`Vault ${i} claimed ROOK act 4: ${claimedAct4?.toString()}`);
          console.log(`Vault ${i} total ROOK rewards act 4: ${rewardsAct4.earnings.toString()}: `);
          console.log(`Vault ${i} claimed ROOK act 4v2: ${claimedAct4v2?.toString()}`);
          console.log(
            `Vault ${i} total ROOK rewards act 4v2: ${rewardsAct4v2.earnings.toString()}: `
          );
        }

        claimedBalance3 = claimedAct3 ? new BigNumber(claimedAct3) : new BigNumber(0);
        totalBalance3 = rewardsAct3.earnings;
        earnings3 = rewardsAct3;

        claimedBalance4 = claimedAct4 ? new BigNumber(claimedAct4) : new BigNumber(0);
        totalBalance4 = rewardsAct4.earnings;
        earnings4 = rewardsAct4;

        claimedBalance4v2 = claimedAct4v2 ? new BigNumber(claimedAct4v2) : new BigNumber(0);
        totalBalance4v2 = rewardsAct4v2.earnings;
        earnings4v2 = rewardsAct4v2;

        claimedBalance5 = claimedAct5 ? new BigNumber(claimedAct5) : new BigNumber(0);
        totalBalance5 = rewardsAct5.earnings;
        earnings5 = rewardsAct5;
      } catch (err) {
        console.log(err);
      }

      if (!globalBorrow || !userBorrow || !vaultUSD) {
        statuses.push({
          posAddress: vaultAddress,
          rookAPY: new BigNumber(0),
          rewardsAct3: {
            pendingRewards: new BigNumber(0),
            rewardsState: earnings3,
            rewardsDisplay: new BigNumber(0),
          },
          rewardsAct4: {
            pendingRewards: new BigNumber(0),
            rewardsState: earnings4,
            rewardsDisplay: new BigNumber(0),
          },
          rewardsAct4v2: {
            pendingRewards: new BigNumber(0),
            rewardsState: earnings4v2,
            rewardsDisplay: new BigNumber(0),
          },
          rewardsAct5: {
            pendingRewards: new BigNumber(0),
            rewardsState: earnings4,
            rewardsDisplay: new BigNumber(0),
          },
        });
      } else {
        const pendingRewards3 = totalBalance3; //.minus(claimedBalance);
        const displayRewards3 = totalBalance3.minus(claimedBalance3);
        const pendingRewards4 = totalBalance4; //.minus(claimedBalance);
        const displayRewards4 = totalBalance4.minus(claimedBalance4);
        const pendingRewards4v2 = totalBalance4v2; //.minus(claimedBalance);
        const displayRewards4v2 = totalBalance4v2.minus(claimedBalance4v2);
        const pendingRewards5 = totalBalance5; //.minus(claimedBalance);
        const displayRewards5 = totalBalance5.minus(claimedBalance5);

        // Calculate ROOK APY from current price of ROOK
        let rewardsAPY = new BigNumber(0);
        if (vaultUSD.supplyUSD.gt(0)) {
          // Vault rewards (in ROOK) remaining in current act
          const rewardsRemaining = blocksLeftInAct.multipliedBy(ACT_IV_VAULT_REWARDS_PER_BLOCK);

          // Annual vault rewards (in USD) at current emissions rate
          const annualRewardsUSD = rewardsRemaining
            .dividedBy(secondsLeftInAct)
            .multipliedBy(60 * 60 * 24 * 365)
            .multipliedBy(rates.ROOK);

          // This vault's share of the rewards
          const rewardsShare = vaultUSD.borrowUSD.dividedBy(globalBorrow);

          // This vault's annual rewards (in USD) at current emissions rate
          const vaultAnnualReturn = rewardsShare.multipliedBy(annualRewardsUSD);

          // This vault's ROOK rewards APY in USD
          rewardsAPY = vaultAnnualReturn.dividedBy(vaultUSD.supplyUSD);
        }

        if (process.env.NODE_ENV === "development") {
          console.log(`Vault ${i} ROOK APY: ${rewardsAPY.toFixed(4)}% `);
        }

        statuses.push({
          posAddress: vaultAddress,
          rookAPY: rewardsAPY,
          rewardsAct3: {
            pendingRewards: pendingRewards3,
            rewardsState: earnings3,
            rewardsDisplay: displayRewards3,
          },
          rewardsAct4: {
            pendingRewards: pendingRewards4,
            rewardsState: earnings4,
            rewardsDisplay: displayRewards4,
          },
          rewardsAct4v2: {
            pendingRewards: pendingRewards4v2,
            rewardsState: earnings4v2,
            rewardsDisplay: displayRewards4v2,
          },
          rewardsAct5: {
            pendingRewards: pendingRewards5,
            rewardsState: earnings5,
            rewardsDisplay: displayRewards5,
          },
        });
      }
    }

    return statuses;
  }

  const onPositionChange = (address: string) => {
    if (address !== "") {
      const selectedBalance = userVaultBalances.find((pos) => pos.posAddress === address);
      if (selectedBalance) setSelectedVaultBalances(selectedBalance);

      const selectedBalanceUSD = userVaultBalancesUSD.find((u) => u.posAddress === address);
      if (selectedBalanceUSD) setSelectedVaultUSD(selectedBalanceUSD);

      const selectedBalanceRook = userVaultBalancesROOK.find((u) => u.posAddress === address);
      if (selectedBalanceRook) setSelectedVaultROOK(selectedBalanceRook);

      setSelectedVaultAddress(address);
    } else {
      setSelectedVaultAddress("");
      setSelectedVaultBalances(DEFAULT_POS_STATE);
      setSelectedVaultUSD(DEFAULT_USD_STATE);
      setSelectedVaultROOK(DEFAULT_ROOK_STATE);
    }
  };

  const updateSelectedBalances = () => {
    if (selectedVaultAddress !== "") {
      const selectedBalance = userVaultBalances.find(
        (pos) => pos.posAddress === selectedVaultAddress
      );
      if (selectedBalance) setSelectedVaultBalances(selectedBalance);

      const selectedBalanceUSD = userVaultBalancesUSD.find(
        (u) => u.posAddress === selectedVaultAddress
      );
      if (selectedBalanceUSD) setSelectedVaultUSD(selectedBalanceUSD);

      const selectedBalanceRook = userVaultBalancesROOK.find(
        (u) => u.posAddress === selectedVaultAddress
      );
      if (selectedBalanceRook) setSelectedVaultROOK(selectedBalanceRook);
    }
  };

  return (
    <Grid container item className={c.root} justifyContent="center">
      <div className={c.container}>
        {!account ? (
          <VaultConnectWallet />
        ) : !userVaultAddresses.length ? (
          <CreateVault
            loading={loadingVaults}
            globalVaults={globalVaultsState}
            openWalletCreateModal={openWalletCreateModal}
            updateVault={updateVaultBalances}
          />
        ) : !selectedVaultAddress || !selectedVaultBalancesUSD ? (
          <VaultSelector
            positions={userVaultBalances}
            usdStatuses={userVaultBalancesUSD}
            rookStatuses={userVaultBalancesROOK}
            totalBorrowUSD={userTotalBorrowUSD}
            globalVaults={globalVaultsState}
            selectedPosition={selectedVaultAddress}
            setPosition={onPositionChange}
            loading={loadingVaults}
            openWalletCreateModal={openWalletCreateModal}
            updateVault={updateVaultBalances}
          />
        ) : (
          <VaultInfo
            vaultAddress={selectedVaultAddress}
            vaultState={selectedVaultBalances}
            vaultStateUSD={selectedVaultBalancesUSD}
            vaultStateROOK={selectedVaultBalancesROOK}
            data={compoundData}
            walletBalances={compoundBalances}
            updateVault={updateVaultBalances}
            refreshVaults={updateVaults}
            setPosition={onPositionChange}
          />
        )}
      </div>
    </Grid>
  );
}

export default Vaults;
