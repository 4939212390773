import React from "react";
import { ConnectorNames } from "../lib/connectors";
import { SvgIcon, makeStyles } from "@material-ui/core";
import { ReactComponent as Exchange } from "../styles/images/Menu Icons/Exchange.svg";
import { ReactComponent as Coins } from "../styles/images/Menu Icons/Coins.svg";
import { ReactComponent as PlusSquare } from "../styles/images/Menu Icons/PlusSquare.svg";
import { ReactComponent as Vote } from "../styles/images/Menu Icons/Vote.svg";
import { ReactComponent as Copy } from "../styles/images/Menu Icons/Copy.svg";
import { ReactComponent as Ohm } from "../styles/images/Menu Icons/Ohm.svg";
import { ReactComponent as Disconnect } from "../styles/images/Menu Icons/Disconnect.svg";
import { ReactComponent as CaretDown } from "../styles/images/Menu Icons/Caret Down.svg";
import { ReactComponent as Search } from "../styles/images/Menu Icons/Search.svg";
import { ReactComponent as ChessRook } from "../styles/images/Menu Icons/chess-rook.svg";
import { ReactComponent as Vault } from "../styles/images/Menu Icons/Vault.svg";
import { ReactComponent as ExternalLink } from "../styles/images/Menu Icons/external-link.svg";
import { ReactComponent as Logo } from "../styles/images/KeeperDAO_Logo_Inline_White.svg";
import { ReactComponent as Github } from "../styles/images/Brands/GitHub.svg";
import { ReactComponent as Discord } from "../styles/images/Brands/Discord.svg";
import { ReactComponent as Ghost } from "../styles/images/Brands/Ghost.svg";
import { ReactComponent as Medium } from "../styles/images/Brands/Medium.svg";
import { ReactComponent as Twitter } from "../styles/images/Brands/Twitter.svg";
import { ReactComponent as Coinbase } from "../styles/images/Wallets/Coinbase Wallet.svg";
import { ReactComponent as Fortmatic } from "../styles/images/Wallets/Fortmatic.svg";
import { ReactComponent as MetaMask } from "../styles/images/Wallets/MetaMask.svg";
import { ReactComponent as Portis } from "../styles/images/Wallets/Portis.svg";
import { ReactComponent as WalletConnect } from "../styles/images/Wallets/WalletConnect.svg";
import { ReactComponent as Rook } from "../styles/images/Tokens/ROOK.svg";

const useStyles = makeStyles((theme) => ({
  svgIcon: {
    //stroke: theme.palette.text.primary,
    fill: "inherit",
    padding: "0px",
  },
}));

export function LogoInline(): any {
  return <SvgIcon component={Logo} />;
}

export function TradeIcon({ height = "32", width = "32", viewBox = "0 0 32 32" }): any {
  const c = useStyles();
  return (
    <SvgIcon
      /*className={c.svgIcon}*/ component={Exchange}
      height={height}
      width={width}
      viewBox={viewBox ? viewBox : "0 0 32 32"}
    />
  );
}

export function BorrowIcon({ height = "32", width = "32", viewBox = "0 0 32 32" }): any {
  const c = useStyles();
  return (
    <SvgIcon
      /*className={c.svgIcon}*/ component={Coins}
      height={height}
      width={width}
      viewBox={viewBox ? viewBox : "0 0 32 32"}
    />
  );
}

export function PoolIcon({ height = "32", width = "32", viewBox = "0 0 32 32" }): any {
  const c = useStyles();
  return (
    <SvgIcon
      /*className={c.svgIcon}*/ component={PlusSquare}
      height={height}
      width={width}
      viewBox={viewBox ? viewBox : "0 0 32 32"}
    />
  );
}

export function VoteIcon({ height = "32", width = "32", viewBox = "0 0 32 32" }): any {
  const c = useStyles();
  return (
    <SvgIcon
      /*className={c.svgIcon}*/ component={Vote}
      height={height}
      width={width}
      viewBox={viewBox ? viewBox : "0 0 32 32"}
    />
  );
}

export function VaultIcon({ height = "32", width = "32", viewBox = "0 0 32 32" }): any {
  const c = useStyles();
  return (
    <SvgIcon
      /*className={c.svgIcon}*/ component={Vault}
      height={height}
      width={width}
      viewBox={viewBox ? viewBox : "0 0 32 32"}
    />
  );
}

export function OhmIcon({ height = "32", width = "32" }): any {
  const c = useStyles();
  return (
    <SvgIcon
      /*className={c.svgIcon}*/ component={Ohm}
      height={height}
      width={width}
      viewBox={"-250 -250 1500 1500"}
    />
  );
}

export function CopyIcon(): any {
  const c = useStyles();
  return <SvgIcon /*className={c.svgIcon}*/ component={Copy} viewBox="0 0 32 32" />;
}

export function DisconnectIcon(): any {
  const c = useStyles();
  return <SvgIcon /*className={c.svgIcon}*/ component={Disconnect} viewBox="0 0 32 32" />;
}

export function CaretDownIcon(): any {
  const c = useStyles();
  return <SvgIcon /*className={c.svgIcon}*/ component={CaretDown} viewBox="0 0 32 32" />;
}

export function ChessRookIcon(): any {
  const c = useStyles();
  return <SvgIcon /*className={c.svgIcon}*/ component={ChessRook} viewBox="0 0 24 24" />;
}

export function ExternalLinkIcon(): any {
  const c = useStyles();
  return <SvgIcon /*className={c.svgIcon}*/ component={ExternalLink} />;
}

export function SearchIcon(fill: any): any {
  return <SvgIcon component={Search} fill={fill} viewBox="0 0 32 32" />;
}

export function GitHubIcon(): any {
  //const c = useStyles();
  return <SvgIcon component={Github} viewBox="0 0 32 32" />;
}

export function DiscordIcon(): any {
  //const c = useStyles();
  return <SvgIcon component={Discord} viewBox="0 0 32 32" />;
}

export function GhostIcon(): any {
  //const c = useStyles();
  return <SvgIcon component={Ghost} />;
}

export function MediumIcon(): any {
  //const c = useStyles();
  return <SvgIcon component={Medium} viewBox="0 0 32 32" />;
}

export function TwitterIcon(): any {
  //const c = useStyles();
  return <SvgIcon component={Twitter} viewBox="0 0 32 32" />;
}

export function CoinbaseIcon(): any {
  return <SvgIcon component={Coinbase} viewBox="0 0 32 32" />;
}

export function FortmaticIcon(): any {
  return <SvgIcon component={Fortmatic} viewBox="0 0 32 32" />;
}

export function MetaMaskIcon(): any {
  return <SvgIcon component={MetaMask} viewBox="0 0 32 32" />;
}

export function PortisIcon(): any {
  return <SvgIcon component={Portis} viewBox="0 0 32 32" />;
}

export function WalletConnectIcon(): any {
  return <SvgIcon component={WalletConnect} viewBox="0 0 32 32" />;
}

const WALLET_ICONS = {
  [ConnectorNames.Injected]: <MetaMaskIcon />,
  [ConnectorNames.WalletConnect]: <WalletConnectIcon />,
  [ConnectorNames.Portis]: <PortisIcon />,
  [ConnectorNames.Fortmatic]: <FortmaticIcon />,
  [ConnectorNames.WalletLink]: <CoinbaseIcon />,
};

export default function walletIcon(connector) {
  return WALLET_ICONS[connector];
}

export function RookIcon(): any {
  return <SvgIcon component={Rook} viewBox="0 0 32 32" />;
}
