/* eslint-disable react/jsx-key */
import React from "react";
import { useTable } from "react-table";

import {
  makeStyles,
  withStyles,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Typography,
  Paper,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: "rgba(0,0,0,0)",
    padding: 0,
    //backgroundColor: '#123456',
  },
  tablePaper: {
    backgroundColor: "rgba(0,0,0,0)",
  },
  tableRow: {
    border: "none",
    //backgroundColor: 'rgba(0,0,0,0)',
    "&:hover": {
      background: "rgba(245,245,245,0.12)",
      backgroundColor: "rgba(0,0,0,0)",
      color: theme.palette.action.hover,
      cursor: "pointer",
    },
  },
  tableRowHover: {
    border: "none",
    backgroundColor: "rgba(0,0,0,0)",
    "&:hover": {
      backgroundColor: "rgba(245,245,245,0.12)",
      cursor: "pointer",
    },
  },
  tableHead: {
    backgroundColor: "rgba(0,0,0,0)",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: 0,
  },
}));

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      border: "none",
      backgroundColor: "none",
      "&:hover": {
        backgroundColor: "rgba(245,245,245,0.04)",
      },
    },
  })
)(TableRow);

interface Props {
  columns: any;
  data: any;
  onRowClick: (row: any) => void;
  supply: boolean;
  opacity?: boolean;
  history?: boolean;
  striped?: boolean;
  headerColor?: string;
  rowHighlighted?: boolean;
  pointer?: boolean;
  control?: boolean;
}

const AssetTable: React.FC<Props> = ({ columns, data, onRowClick, supply }: Props) => {
  const c = useStyles();

  const rowClickHandle = (row) => {
    onRowClick(row);
  };

  const onSupplyRowClick = (event) => {
    event?.stopPropagation();
  };

  return (
    <TableContainer style={{ padding: "0px", backgroundColor: "rgba(0,0,0,0)" }}>
      <Table className={c.table} style={{ backgroundColor: "rgba(0,0,0,0)" }} aria-label="tx table">
        <TableHead classes={{ root: c.tableHead }}>
          <TableRow
            style={{
              backgroundColor: "rgba(0,0,0,0)",
              alignItems: "flex-start",
              padding: 0,
            }}
          >
            {columns.map((column, i) => {
              if (i === 0) {
                return (
                  <TableCell align="left" style={{ width: "50%" }} key={i}>
                    {column.Header}
                  </TableCell>
                );
              } else {
                return (
                  <TableCell align="left" style={{ width: "16%" }} key={i}>
                    {column.Header}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableHead>
        <TableBody style={{ backgroundColor: "inherit" }}>
          {data.map((row, i) => {
            return (
              <TableRow
                classes={{
                  root: c.tableRow,
                  hover: c.tableRow,
                  selected: c.tableRow,
                  head: c.tableRow,
                  footer: c.tableRow,
                }}
                style={{ backgroundColor: "inherit" }}
                key={row.symbol.toString() + i.toString()}
                onClick={() => rowClickHandle(row)}
              >
                {columns.map((column, i) => {
                  if (i === 0) {
                    return (
                      <TableCell
                        key={row[column.accessor] + i.toString()}
                        align="left"
                        style={{
                          color: "inherit",
                          background: "inherit",
                          backgroundColor: "inherit",
                          width: "50%",
                        }}
                        onClick={(event) => {
                          return supply && i === 3 ? onSupplyRowClick(event) : null;
                        }}
                      >
                        {row[column.accessor]}
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        key={row[column.accessor] + i.toString()}
                        align="left"
                        style={{
                          color: "inherit",
                          background: "inherit",
                          backgroundColor: "inherit",
                        }}
                        onClick={(event) => {
                          return supply && i === 3 ? onSupplyRowClick(event) : null;
                        }}
                      >
                        {row[column.accessor]}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AssetTable;
