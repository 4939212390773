import { Button, Grid, Icon, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { ExternalLinkIcon } from "../components/Icons";
import PoolsGridWrapper from "../components/PoolsGridWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    // // position: "absolute",
    // width: "100%",
    // maxWidth: "100%",
    // maxHeight: "80vh",
    // paddingTop: "20px",
    // flexDirection: "column",
    // justifyContent: "flex-start",
    // alignItems: "center",
  },
  dashboardContainer: {
    // display: "flex",
    // flex: "1 1 0",
    overflow: "scroll",
    // flexDirection: "column",
    // // position: "absolute",
    maxWidth: "87%",
    // justifyContent: "flex-start",
    // alignItems: "center",
  },
  rowHeader: {
    display: "flex",
    position: "relative",
    justifyContent: "flex-start",
    marginTop: "20px",
  },
  rowContainer: {
    // display: "flex",
    // position: "relative",
    overflow: "scroll",
    justifyContent: "center",
    alignItems: "center",
  },
  contentContainer: {
    display: "flex",
  },
  item: {
    display: "flex",
    justifyContent: "center",
  },
  poolHeader: {
    maxWidth: "87%",
    alignItems: "center",
    padding: "8px",
    maxHeight: "40%",
  },
  poolHeaderText: {
    color: theme.palette.text.primary,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    [theme.breakpoints.up("md")]: {
      fontSize: "32px",
      lineHeight: "39px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "30px",
    },
  },
  docsLink: {
    textTransform: "none",
    alignContent: "center",
    paddingLeft: "0px",
    "&:hover": {
      background: "none",
      textDecoration: "underline",
    },
  },
}));

function Dashboard(): React.ReactElement {
  const c = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container item className={c.root} justifyContent="center">
      <Grid container item className={c.poolHeader}>
        <Grid container item direction="row">
          <Typography
            variant="h4"
            classes={{ h4: c.poolHeaderText }}
            style={{ marginRight: "7px" }}
          >
            {t("pools-header")}
          </Typography>
        </Grid>
        <Button
          className={c.docsLink}
          endIcon={
            <Icon fontSize="large">
              <ExternalLinkIcon />
            </Icon>
          }
          href="https://snapshot.org/#/rook.eth/proposal/0x5715c13cfec5218696171f5d2356a01194ffd9d2e86a73f865d95645cae86a21"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("sunset-link")}
        </Button>
      </Grid>
      <Grid container item className={c.dashboardContainer} spacing={2}>
        <Grid container item className={c.rowContainer} direction="row">
          <PoolsGridWrapper />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Dashboard;
