import {
  Button,
  Divider,
  Grid,
  Input,
  InputAdornment,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BarLoader from "react-spinners/BarLoader";
import BorrowChart from "../components/charts/BorrowChart";
// import ProfitChart from '../components/charts/ProfitChart';
import DepositChart from "../components/charts/DepositChart";
import HistoryTable from "../components/HistoryTable";
import { ChessRookIcon } from "../components/Icons";
import ClaimRookModal from "../components/modals/ClaimRookModal";
import DepositModal from "../components/modals/DepositModal";
import WithdrawModal from "../components/modals/WithdrawModal";
import TokenSVG from "../components/TokenSVG";
import APYs from "../containers/apys";
import Balances from "../containers/balances";
import Depositor, { DepositorState } from "../containers/depositor";
import { ClaimRookState, RewardsBalances, rewardsCategory } from "../containers/rookBalances";
import {
  getUserTransactionsVariables,
  Tx,
  useLazyUserTransactions,
} from "../containers/subscriptions/transactions";
import {
  calculateUserBalance,
  getLazyOptions,
  // useUnderlyingBalance,
  useEarnings,
  useLazyUnderlyingBalance,
} from "../containers/subscriptions/user";
import Withdrawer, { WithdrawerState } from "../containers/withdrawer";
import { usePrevious } from "../hooks";
import config from "../lib/config";
import {
  displayTokenBalance,
  tokenDecimals,
  UnderlyingToken,
  UnderlyingTokens,
  underlyingTokenSymbol,
  wrapUnderlyingToken,
} from "../lib/tokens";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "absolute",
    width: "100%",
    height: "100%",
    paddingTop: "20px",
    flexDirection: "column",
    overflow: "auto",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  poolInfoContainer: {
    display: "flex",
    flex: "1 1 0",
    overflow: "auto",
    flexDirection: "column",
    position: "absolute",
    maxWidth: "87%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  rowHeader: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "20px",
  },
  rowHeaderText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
      lineHeight: "24px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      lineHeight: "18px",
    },
  },
  rowContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  },
  chartsContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "2%",
  },
  chart: {
    padding: "2%",
  },
  poolActionsContainer: {
    display: "flex",
    justifyContent: "space-around",
    borderRadius: "24px",
    background: "rgba(245, 245, 245, 0.04)",
    padding: "3%",
  },
  divider: {
    background: "#8F8F8F",
  },
  poolActionColumn: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    padding: "0px 10px 0px 10px",
  },
  poolInputContainer: {
    justifySelf: "center",
    borderRadius: "16px",
    background: "rgba(245, 245, 245, 0.08)",
    alignContent: "center",
    justifyContent: "space-between",
    padding: "8px",
  },
  maxButton: {
    position: "relative",
    width: "15%",
    color: theme.palette.text.primary,
    border: "none",
    textTransform: "none",
    textSize: "8%",
    justifySelf: "right",
    right: "0%",
    left: "0%",
  },
  poolInput: {
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "75%",
  },
  poolActionButton: {
    borderRadius: "8px",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    position: "relative",
  },
  claimButton: {
    borderRadius: "8px",
    width: "100%",
    color: "white",
    border: "1px solid white",
  },
}));

function Poolinfo(props: { match: any }): React.ReactElement {
  const c = useStyles();
  const { t } = useTranslation();
  const [selected, setSelected] = useState<null | { value: UnderlyingToken }>(null);
  const [tokenLabel, setTokenLabel] = useState<string>("");
  const { library, chainId, account } = useWeb3React();
  const { library: networkWeb3, chainId: networkChainId } = useWeb3React("infura");
  const [depositAmount, setDepositAmount] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [deposit, setDeposit] = useState<BigNumber>(new BigNumber(0));
  const [withdraw, setWithdraw] = useState<BigNumber>(new BigNumber(0));
  const [underlyingTokenBalance, setUnderlyingTokenBalance] = useState<BigNumber>(new BigNumber(0));
  const [decimalScaling, setDecimalScaling] = useState<BigNumber>(new BigNumber(0));
  const depositor = Depositor.useContainer();
  const withdrawer = Withdrawer.useContainer();
  const balances = Balances.useContainer();
  const [getBalance, { data: kBalanceData, loading: loadingKBalance, error: errorKBalance }] =
    useLazyUnderlyingBalance();
  const [kBalance, setKBalance] = useState(new BigNumber(0));
  const [
    getTransactions,
    { data: transactions, loading: transactionsLoading, error: transactionsError },
  ] = useLazyUserTransactions();
  const [collectedFees, setCollectedFees] = useState<BigNumber>(new BigNumber(0));
  const prevFees = usePrevious<BigNumber>(collectedFees);
  const { earnings } = useEarnings(selected ? selected.value : UnderlyingToken.ETH);

  const {
    rewardsState,
    setClaimModalState,
    loading: rewardsLoading,
  } = RewardsBalances.useContainer();
  const [lpRewards, setLpRewards] = useState(0);
  const apys = APYs.useContainer();

  useEffect(() => {
    if (account && !rewardsLoading) {
      const lp = rewardsState.rewardsData.find(
        (reward) => reward.reward_type === rewardsCategory.LiquidityProvider4
      );
      if (lp) {
        setLpRewards(lp.claimable_amount.dividedBy(new BigNumber(10).pow(18)).toNumber());
      }
    }
  }, [rewardsState, account, rewardsLoading]);

  const onDepositClick = () => {
    if (library && chainId && account && deposit.gt(0) && deposit.lte(underlyingTokenBalance)) {
      depositor.setState(DepositorState.ACCEPT_FEE);
    }
  };

  const onWithdrawClick = () => {
    if (library && chainId && account && withdraw.gt(0) && withdraw.lte(kBalance)) {
      withdrawer.setState(WithdrawerState.APPROVE);
    }
  };

  const handleDepositChange = (amount: string) => {
    const amt = new BigNumber(amount).multipliedBy(decimalScaling);
    setDeposit(amt);
    setDepositAmount(amount);
  };

  const handleDepositChangeBN = (amount: BigNumber) => {
    if (selected) {
      const decimals = tokenDecimals(wrapUnderlyingToken(selected.value));
      const depositAmount = amount.dividedBy(decimalScaling).toPrecision(decimals);
      setDepositAmount(depositAmount);
      setDeposit(amount.dp(decimals, 1));
    }
  };

  const handleWithdrawChange = (amount: string) => {
    const amt = new BigNumber(amount).multipliedBy(decimalScaling);
    setWithdraw(amt);
    setWithdrawAmount(amount);
  };

  const handleWithdrawChangeBN = (amount: BigNumber) => {
    if (selected) {
      const decimals = tokenDecimals(wrapUnderlyingToken(selected.value));
      const withdrawAmount = amount.dividedBy(decimalScaling).toPrecision(decimals);
      setWithdrawAmount(withdrawAmount);
      setWithdraw(amount.dp(decimals, 1));
    }
  };

  useEffect(() => {
    if (transactions && prevFees) {
      //check if balances actually changed to avoid unncessary re-render
      const { deposits, withdrawals } = transactions;
      const sumOfDeposits = sumAmounts(deposits);
      const fee = chainId === 1 ? config.MAINNET_DEPOSIT_FEE : 0;
      const depositsLessFees = sumOfDeposits.minus(sumOfDeposits.multipliedBy(fee));
      const sumOfWithdrawals = sumAmounts(withdrawals);
      const balanceDelta = depositsLessFees.minus(sumOfWithdrawals);
      const feesCollected = kBalance.minus(balanceDelta).minus(earnings);
      if (!prevFees.eq(feesCollected)) {
        setCollectedFees(feesCollected);
      }
    }
  }, [transactions, kBalance, earnings, chainId, prevFees]);

  // Get user's underlying token balance and kToken balance
  useEffect(() => {
    if (selected && library && chainId && account) {
      const tokenBal = balances.balances.get(wrapUnderlyingToken(selected.value));
      setUnderlyingTokenBalance(tokenBal ? tokenBal : new BigNumber(0));
      setDecimalScaling(new BigNumber(10).pow(tokenDecimals(wrapUnderlyingToken(selected.value))));
      getBalance(getLazyOptions(selected.value, account, chainId));
    }
  }, [selected, library, chainId, account, balances]);

  useEffect(() => {
    if (kBalanceData && kBalanceData.kTokenBalance) {
      const calcBalance = calculateUserBalance(
        new BigNumber(kBalanceData.kTokenBalance.balance),
        new BigNumber(kBalanceData.kTokenSupply.totalSupply),
        new BigNumber(kBalanceData.poolSupply[0].supply)
      );
      setKBalance(calcBalance);
    }
  }, [kBalanceData]);

  useEffect(() => {
    if (selected && account && chainId) {
      getTransactions(getUserTransactionsVariables(account, selected.value, chainId));
      //setKBalance(new BigNumber(0));
    }
  }, [selected, library, chainId, account]);

  useEffect(() => {
    let token = underlyingTokenOptions().find(
      (t) => UnderlyingToken[t.value] === props.match.params.id
    );
    token = token ? token : underlyingTokenOptions()[0];
    if (selected?.value !== token.value) {
      setSelected(token);
      setTokenLabel(underlyingTokenSymbol(token.value));
    }
  }, [props, selected]);

  return (
    <Grid container item className={c.root} justifyContent="center">
      <Grid
        container
        item
        className={c.poolInfoContainer}
        direction="column"
        justifyContent="center"
        spacing={4}
      >
        <Grid container item className={c.rowHeader} direction="row">
          <Typography variant="h5" classes={{ h5: c.rowHeaderText }}>
            {t("poolinfo-page-stats")}
          </Typography>
        </Grid>
        <Grid container item className={c.rowContainer} direction="row">
          <Grid item className={c.chart} xs={12} md={6}>
            <DepositChart underlyingToken={selected} />
          </Grid>
          <Grid item className={c.chart} xs={12} md={6}>
            <BorrowChart underlyingToken={selected} />
          </Grid>
        </Grid>
        <Grid container item className={c.rowHeader} direction="row">
          <Typography variant="h5" classes={{ h5: c.rowHeaderText }}>
            {t("repay-modal.repay-wait-approval", {
              token: tokenLabel,
            })}
          </Typography>
        </Grid>
        <Grid container item className={c.rowContainer} direction="row">
          <Grid container item className={c.poolActionsContainer} direction="row" spacing={4}>
            <Grid
              container
              item
              className={c.poolActionColumn}
              direction="column"
              spacing={3}
              xs={12}
              sm={6}
              md={4}
            >
              <Typography variant="h6">{t("user-actions.holdings-user-deposit")}</Typography>

              <Divider className={c.divider} />

              <Grid item>
                <Typography variant="body1">
                  {t("pool-detailed-wallet")}
                  {": "}
                  {underlyingTokenBalance.gt(0)
                    ? `${underlyingTokenBalance
                        .dividedBy(decimalScaling)
                        .toNumber()
                        .toPrecision(9)}...`
                    : "0"}
                </Typography>
              </Grid>
              <Grid container item className={c.poolInputContainer}>
                <Input
                  id="withdraw-input"
                  className={c.poolInput}
                  placeholder="0"
                  disableUnderline
                  onChange={(event) => {
                    const amt = parsePriceInput(event);
                    handleDepositChange(amt);
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <TokenSVG underlyingToken={selected ? selected.value : 0} /> {tokenLabel}
                    </InputAdornment>
                  }
                  value={depositAmount}
                />
                <Button
                  variant="text"
                  className={c.maxButton}
                  disabled
                  size="small"
                  onClick={() => {
                    handleDepositChangeBN(underlyingTokenBalance);
                  }}
                >
                  Max
                </Button>
              </Grid>
              <Grid item style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Button
                  variant="contained"
                  className={c.poolActionButton}
                  disabled
                  onClick={onDepositClick}
                  startIcon={<ChessRookIcon />}
                >
                  {account
                    ? deposit.gt(underlyingTokenBalance)
                      ? `Max: ${underlyingTokenBalance
                          .dividedBy(decimalScaling)
                          .toNumber()
                          .toPrecision(9)}`
                      : t("user-actions.holdings-user-deposit")
                    : t("header.connect-wallet-btn")}
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              item
              className={c.poolActionColumn}
              direction="column"
              spacing={3}
              xs={12}
              sm={6}
              md={4}
            >
              <Typography variant="h6">{t("user-actions.holdings-user-withdraw")}</Typography>

              <Divider className={c.divider} />

              <Grid item>
                <Typography variant="body1">
                  {t("summary.holdings-user-balance")}
                  {": "}
                  {kBalance.gt(0)
                    ? `${kBalance.dividedBy(decimalScaling).toNumber().toPrecision(9)}...`
                    : "0"}
                </Typography>
              </Grid>
              <Grid container item className={c.poolInputContainer}>
                <Input
                  id="withdraw-input"
                  className={c.poolInput}
                  placeholder="0"
                  disableUnderline
                  onChange={(event) => {
                    const amt = parsePriceInput(event);
                    handleWithdrawChange(amt);
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <TokenSVG underlyingToken={selected ? selected.value : 0} /> {tokenLabel}
                    </InputAdornment>
                  }
                  value={withdrawAmount}
                />
                <Button
                  variant="text"
                  className={c.maxButton}
                  size="small"
                  onClick={() => {
                    handleWithdrawChangeBN(kBalance);
                  }}
                >
                  Max
                </Button>
              </Grid>
              <Grid item style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Button
                  variant="contained"
                  className={c.poolActionButton}
                  disabled={!withdraw.gt(0) || !account || withdraw.gt(kBalance)}
                  onClick={onWithdrawClick}
                  startIcon={<ChessRookIcon />}
                >
                  {account
                    ? withdraw.gt(kBalance)
                      ? `Max: ${kBalance.dividedBy(decimalScaling).toNumber().toPrecision(9)}`
                      : t("user-actions.holdings-user-withdraw")
                    : t("header.connect-wallet-btn")}
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              item
              className={c.poolActionColumn}
              direction="column"
              spacing={2}
              xs={12}
              sm={6}
              md={4}
            >
              <Typography variant="h6">{t("poolinfo-page-performance")}</Typography>

              <Divider className={c.divider} />

              <Grid item>
                <Typography variant="body1">
                  APY:{" "}
                  {selected ? (apys ? (apys[selected.value] ? apys[selected.value] : "") : "") : ""}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {t("user-actions.your-fees-collected")}:{" "}
                  {displayTokenBalance(
                    wrapUnderlyingToken(selected ? selected.value : 0),
                    collectedFees
                  )}{" "}
                  {tokenLabel}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {t("pool-q3")}{" "}
                  {rewardsLoading ? (
                    <BarLoader color="#354468" loading={true} width={40} />
                  ) : (
                    lpRewards.toPrecision(4)
                  )}{" "}
                  ROOK
                </Typography>
              </Grid>
              <Grid item style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Button
                  className={c.claimButton}
                  variant="outlined"
                  disabled={!account || !lpRewards}
                  onClick={() => setClaimModalState(ClaimRookState.WAIT_FOR_PROCEED_TO_CLAIMING)}
                >
                  {account ? "Claim" : t("header.connect-wallet-btn")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item className={c.rowHeader} direction="row">
          <Typography variant="h5" classes={{ h5: c.rowHeaderText }}>
            {t("header-transaction-history")}
          </Typography>
        </Grid>
        <Grid container item className={c.rowContainer} direction="row">
          {selected && (
            <HistoryTable
              underlyingToken={selected.value}
              transactions={transactions}
              loading={transactionsLoading}
            />
          )}
        </Grid>

        <DepositModal underlyingToken={selected ? selected.value : 0} depositAmount={deposit} />
        <WithdrawModal underlyingToken={selected ? selected.value : 0} withdrawAmount={withdraw} />

        {/*</div>*/}
      </Grid>
      <ClaimRookModal />
    </Grid>
  );
}

const parsePriceInput = (priceString: any) => {
  // Remove spaces.
  let v = priceString.currentTarget.value.replace(/\s/g, "");
  // Remove second occurrences of periods.
  v = v
    .split("")
    .filter((x, n, self) => self.indexOf(x) === n || x !== ".")
    .join("");
  // Remove non-digits (unless it is a period).
  v = v.replace(/[^\d\.]/g, "");

  // replace 0 if continued by digit
  v = v.length > 1 && v[1] !== "." ? v.replace(/^0+/, "") : v;

  return v;
};

// Custom options/components/styles for react-select.

function underlyingTokenOptions() {
  return [
    ...UnderlyingTokens.map((uToken) => ({
      value: uToken,
      label: dropDownlabel(uToken),
      disabled: false,
      icon: <TokenSVG underlyingToken={uToken} />,
    })),
    // { value: "", label: "More coming soon!", disabled: true }
  ];
}

const dropDownlabel = (t: UnderlyingToken) => {
  return (
    <div className="label-drob-list">
      {<TokenSVG underlyingToken={t} />}
      <span>{underlyingTokenSymbol(t)}</span>
    </div>
  );
};

function sumAmounts(txs: Tx[]): BigNumber {
  const sum = txs.reduce((sum, tx) => {
    return sum.plus(tx.amount);
  }, new BigNumber(0));

  return sum;
}

export default Poolinfo;
