import Web3 from "web3";
import { HttpProvider } from "web3-providers-http";
import BigNumber from "bignumber.js";
import { NetworkID } from "./network";
import { contractAddress, ContractType, contract } from "./contracts";
import Web3ProviderEngine from "web3-provider-engine";
import sigUtil from "eth-sig-util";
import { Web3JsProvider } from "@0x/subproviders";

// interface InjectedEthereum extends HttpProvider {
//     isMetaMask: InjectedEthereum | undefined;
//     enable: () => Promise<void>;
//     on: any;
// }

declare global {
  interface Window {
    ethereum?: any;
    web3?: Web3;
  }
}

/**
 * Wait transactions to be mined.
 * Based on https://raw.githubusercontent.com/Kaisle/await-transaction-mined/master/index.js
 */

const INTERVAL_TO_WAIT = 1000;
const BLOCKS_TO_WAIT = 0;

export function emptyAddress(address: string): boolean {
  return address === "0x0000000000000000000000000000000000000000";
}

export function waitForTxReceipt(web3: Web3, txHash: string): Promise<any> {
  async function txReceipt(txHash: any, resolve: any, reject: any) {
    try {
      const receipt = web3.eth.getTransactionReceipt(txHash);
      if (!receipt) {
        setTimeout(() => txReceipt(txHash, resolve, reject), INTERVAL_TO_WAIT);
        return;
      }

      const resolvedReceipt = await receipt;
      if (!resolvedReceipt || !resolvedReceipt.blockNumber) {
        setTimeout(() => txReceipt(txHash, resolve, reject), INTERVAL_TO_WAIT);
        return;
      }
      try {
        const block = await web3.eth.getBlock(resolvedReceipt.blockNumber);
        const current = await web3.eth.getBlock("latest");
        if (current.number - block.number >= BLOCKS_TO_WAIT) {
          resolve(resolvedReceipt);
        } else {
          setTimeout(() => txReceipt(txHash, resolve, reject), INTERVAL_TO_WAIT);
          return;
        }
      } catch {
        setTimeout(() => txReceipt(txHash, resolve, reject), INTERVAL_TO_WAIT);
        return;
      }
    } catch (err) {
      reject(err);
    }
  }
  return new Promise((resolve, reject) => {
    txReceipt(txHash, resolve, reject);
  });
}

export function wrapETH(
  web3: Web3,
  network: NetworkID,
  amount: BigNumber,
  account: string
): Promise<any> {
  return new Promise(async (resolve, reject) => {
    // const wethAddress = contractAddress(network, ContractType.WETH);
    const wethContact = contract(web3, network, ContractType.WETH, account);
    await wethContact.methods
      .deposit()
      .send({ from: account, value: amount.toFixed() })
      .on("receipt", async (receipt) => {
        // console.log('Tx receipt came => ', receipt);
        resolve(receipt);
      })
      .on("error", (error) => {
        reject(error);
      });
  });
}

export function unwrapETH(
  web3: Web3,
  network: NetworkID,
  amount: BigNumber,
  account: string
): Promise<any> {
  return new Promise(async (resolve, reject) => {
    // const wethAddress = contractAddress(network, ContractType.WETH);
    const wethContact = contract(web3, network, ContractType.WETH, account);

    await wethContact.methods
      .withdraw(amount.toFixed())
      .send({ from: account })
      .on("receipt", async (receipt) => {
        // console.log('Tx receipt came => ', receipt);
        resolve(receipt);
      })
      .on("error", (error) => {
        reject(error);
      });
  });
}

export function createCancelOrderSig(
  orderHash: string,
  web3: any,
  account: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    const msg = `soft cancel order: '${orderHash.toLowerCase()}`;
    const hash = Web3.utils.sha3(msg);
    const msgParams = [
      {
        type: "string",
        name: "Hash message",
        value: hash,
      },
    ];

    web3.currentProvider.sendAsync(
      {
        method: "eth_signTypedData",
        params: [msgParams, account],
        from: account,
      },
      (err, result) => {
        if (err) reject(err);
        resolve(result.result);
      }
    );
  });
}
