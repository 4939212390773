import * as React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import loadingGif from "../styles/images/load.gif";

export default function PageLoader(): JSX.Element {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#141118",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={loadingGif} alt="loading..." />
    </div>
  );
}
