import { useState } from "react";
import { createContainer } from "unstated-next";
import BigNumber from "bignumber.js";

export enum DepositorState {
  HIDDEN,
  ACCEPT_FEE,
  APPROVE,
  WAIT_FOR_APPROVE,
  SET_UNLIMITED_APPROVAL,
  WAIT_FOR_DEPOSIT,
  SUCCESS,
  ERROR,
  CANCEL_APPROVE,
  CANCEL_DEPOSIT,
}

function useDepositor() {
  const [amount, setAmount] = useState(null as BigNumber | null);
  const [state, setState] = useState(DepositorState.HIDDEN);
  return {
    amount,
    setAmount,
    state,
    setState,
  };
}

const Depositor = createContainer(useDepositor);

export default Depositor;
