import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import GraphInfo from "../../containers/graphInfo";
import {
  calculateRequiredBlocksHistory,
  ChartDataType,
  ChartLength,
  getIntervalForLength,
  makeChartData,
} from "../../lib/charts";
import { liquidityPoolTracesListRequest, tokenID } from "../../lib/graphql";
import { networkIdFromChainId } from "../../lib/network";
import { UnderlyingToken, wrapUnderlyingToken } from "../../lib/tokens";
import TokenSVG from "../TokenSVG";
import TokenAmountTooltip from "./TokenAmountTooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  chartContainer: {
    display: "flex",
    position: "relative",
    borderRadius: "4px",
    padding: "0px",
    width: "100%",
  },
  chartHeader: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    width: "100%",
  },
  chartContent: {
    display: "flex",
    position: "relative",
    width: "100%",
  },
  historySelector: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    position: "relative",
    minHeight: 0,
    minWidth: 0,
    padding: "0px",
    margin: "2px",
    textTransform: "none",
    "&:hover": {
      background: "none",
      textDecoration: "underline",
    },
  },
}));

interface Props {
  underlyingToken: {
    value: UnderlyingToken;
  } | null;
}

export default function BorrowChart({ underlyingToken }: Props) {
  const c = useStyles();
  const { t } = useTranslation();
  const { library, chainId } = useWeb3React();
  const { library: networkWeb3, chainId: networkChainId } = useWeb3React("infura");
  const [chartHistoryLength, setChartHistoryLength] = useState<ChartLength>(ChartLength.all);
  const [dataHistory, setDataHistory] = useState<any[]>([]);
  const [chartData, setChartData] = useState<any[] | undefined>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { graphLatestBlock } = GraphInfo.useContainer();

  useEffect(() => {
    async function getBLocksForChartLength() {
      if ((!library && !networkWeb3) || (!chainId && !networkChainId)) {
        return;
      }
      if (!underlyingToken || !graphLatestBlock) {
        return;
      }
      setLoading(() => true);
      const web3 = !!library ? library : networkWeb3;
      const chain = library && chainId ? chainId : networkChainId;
      const net = networkIdFromChainId(chain);
      const requiredBLocks = calculateRequiredBlocksHistory(
        graphLatestBlock,
        chartHistoryLength,
        net
      );
      const { data, error } = await liquidityPoolTracesListRequest(
        requiredBLocks,
        tokenID(wrapUnderlyingToken(underlyingToken.value), net),
        net
      );
      if (error) {
        setLoading(() => false);
        return;
      }
      setChartData(data);
    }
    getBLocksForChartLength();
  }, [
    chartHistoryLength,
    library,
    networkWeb3,
    chainId,
    networkChainId,
    graphLatestBlock,
    underlyingToken,
  ]);

  useEffect(() => {
    if (!underlyingToken) return;
    if (chartData && Object.keys(chartData).length) {
      const filtered = Object.keys(chartData)
        .reduce((acc: any[], item: string) => {
          if (chartData[item].length > 0) {
            const info = chartData[item][0];
            return [...acc, info];
          }
          return acc;
        }, [])
        .sort((a, b) => Number(a.updatedAt) - Number(b.updatedAt));
      setLoading(() => false);
      setDataHistory(
        makeChartData(
          filtered,
          wrapUnderlyingToken(underlyingToken.value),
          ChartDataType.Borrow,
          chartHistoryLength
        )
      );
    }
  }, [chartData]);

  const lastValue =
    dataHistory && dataHistory.length > 0
      ? dataHistory[dataHistory.length - 1].value.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        })
      : 0;

  const renderShape =
    (key, pixel = 10) =>
    ({ height, width, fill, x, y, ...rest }) => {
      const xpercent = Math.trunc((pixel * 100) / Math.trunc(height || 1));
      return (
        <svg x={x} y={y} fill="none" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="colorUv" gradientTransform="rotate(1.6)">
              <stop offset="0%" stopColor="#0993DC" />
              <stop offset="100%" stopColor="#552BC8" />
            </linearGradient>
          </defs>
          <rect fill={`url(#${key})`} width={width} height={height} />
        </svg>
      );
    };

  return (
    <Grid container item className={c.chartContainer} direction="column">
      <Grid container item className={c.chartHeader}>
        <Grid container item xs={12} alignItems="center">
          <Grid container item xs={6} justifyContent="flex-start">
            <Typography variant="body1">{t("summary.pool-borrow-volume")}</Typography>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <Button
              classes={{ root: c.historySelector }}
              onClick={() => setChartHistoryLength(ChartLength.day)}
            >
              1d
            </Button>
            <Button
              classes={{ root: c.historySelector }}
              onClick={() => setChartHistoryLength(ChartLength.week)}
            >
              7d
            </Button>
            <Button
              classes={{ root: c.historySelector }}
              onClick={() => setChartHistoryLength(ChartLength.all)}
            >
              All
            </Button>
          </Grid>
        </Grid>
        <Grid container item justifyContent="flex-start" alignItems="center">
          {underlyingToken && <TokenSVG underlyingToken={underlyingToken.value} />}
          <Typography variant="h6">{loading ? "0" : lastValue}</Typography>
        </Grid>
      </Grid>
      <Grid container item className={c.chartContent}>
        {loading ? (
          <div className="spinner-wrapper">
            <ClipLoader size={60} color="#552BC8" loading={loading} />
          </div>
        ) : (
          <ResponsiveContainer width={"100%"} height={200}>
            <BarChart barCategoryGap={1} data={dataHistory} margin={{ right: 0, left: 0 }}>
              <defs>
                <linearGradient id="colorUv" gradientTransform="rotate(1.6)">
                  <stop offset="0%" stopColor="#0993DC" />
                  <stop offset="80%" stopColor="#0993DC" />
                  <stop offset="100%" stopColor="#552BC8" />
                </linearGradient>
              </defs>
              <Tooltip
                content={<TokenAmountTooltip />}
                cursor={{ fill: "rgba(245,245,245,0.04)" }}
              />
              <XAxis
                dataKey="name"
                hide={false}
                tickSize={7}
                tick={{ fill: "white" }}
                stroke="white"
                interval={getIntervalForLength(chartHistoryLength, dataHistory?.length)}
                width={30}
                dy={5}
              />
              <YAxis
                dataKey="value"
                hide={true}
                domain={[
                  (dataMin) => 0 - Math.abs(dataMin * 0.01),
                  (dataMax) => dataMax + Math.abs(dataMax * 0.3),
                ]}
              />
              <Bar dataKey="value" fill="url(#colorUv)" opacity={0.4} />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Grid>
    </Grid>
  );
}
