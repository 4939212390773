import * as React from "react";
// import PuffLoader from 'react-spinners/PuffLoader';

export default function Page404(): JSX.Element {
  return (
    <div className="page-loader">
      <div className="page-missing">404 ERROR</div>
    </div>
  );
}
