import ApolloClient from "apollo-boost";
import Configuration from "./config";
import { NetworkID } from "./network";
import { request } from "graphql-request";
import gql from "graphql-tag";
import { Token, UnderlyingTokens, underlyingTokenSymbol, wrapUnderlyingToken } from "./tokens";
import { tokenAddress, contractAddress, ContractType } from "./contracts";

export const kovanClient = new ApolloClient({
  uri: Configuration.THEGRAPH_KOVAN_URL,
});

export const mainnetClient = new ApolloClient({
  uri: Configuration.THEGRAPH_MAINNET_URL,
});

export function getGraphUrl(network: NetworkID) {
  switch (network) {
    case NetworkID.Mainnet:
      return Configuration["THEGRAPH_MAINNET_URL"];
    case NetworkID.Kovan:
      return Configuration["THEGRAPH_KOVAN_URL"];
    default:
      return Configuration["THEGRAPH_MAINNET_URL"];
  }
}

export async function getUniswapLatestBlockNumber(): Promise<any> {
  try {
    const variables = { order: "blockNumber", first: 1 };
    const data = await request(
      Configuration["UNISWAP_MAINNET_URL"],
      gql`
        query getBlockNumber($order: Bytes!, $first: Int!) {
          transactions(orderBy: $order, first: $first, orderDirection: desc) {
            blockNumber
            timestamp
          }
        }
      `,
      variables
    );
    return data && data.transactions ? parseInt(data.transactions[0].blockNumber) : 0;
  } catch (err) {
    return 0;
  }
}

export async function getCompoundMarketData(): Promise<any> {
  try {
    const variables = { first: 20 };
    const data = await request(
      Configuration["COMPOUND_MAINNET_URL"],
      gql`
        query getMarketData($first: Int!) {
          markets(first: $first) {
            borrowRate
            cash
            collateralFactor
            exchangeRate
            interestRateModelAddress
            name
            reserves
            supplyRate
            symbol
            id
            totalBorrows
            totalSupply
            underlyingAddress
            underlyingName
            underlyingPrice
            underlyingSymbol
            reserveFactor
            underlyingPriceUSD
          }
        }
      `,
      variables
    );
    return data.markets ? data.markets : null;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function getAPYRequest(
  block: number,
  tokenID: string,
  network: NetworkID
): Promise<any> {
  try {
    const variables = { id: tokenID, block };

    const data = await request(
      getGraphUrl(network),
      gql`
        query getAPY($id: ID!, $block: Int!) {
          last: liquidityPoolTraces(where: { underlyingToken: $id }) {
            id
            updatedAt
            earned
            deposited
            withdrawn
          }
          weekAgo: liquidityPoolTraces(where: { underlyingToken: $id }, block: { number: $block }) {
            id
            updatedAt
            earned
            deposited
            withdrawn
          }
        }
      `,
      variables
    );
    return { data };
  } catch (err) {
    return { error: err };
  }
}

export async function liquidityPoolTracesListRequest(
  blocks: number[],
  tokenID: string,
  network: NetworkID
): Promise<any> {
  try {
    const blockQueries = blocks
      .map(
        (blockNumber, i) => `
            trace${i}: liquidityPoolTraces(where: { underlyingToken: $id }, block: { number: ${blockNumber} }) {
                id
                updatedAt 
                earned
                deposited
                borrowed
                withdrawn
            }`
      )
      .join(`\n`);

    const variables = { id: tokenID };

    const data = await request(
      getGraphUrl(network),
      gql`
                query getLiquidityPoolTraces($id: Bytes!) {
                    ${blockQueries}
                }

            `,
      variables
    );
    return { data };
  } catch (err) {
    return { error: err };
  }
}

export async function getTopEarners(network: NetworkID): Promise<any> {
  try {
    const tokenQueries = UnderlyingTokens.map(
      (token, i) => `
                ${underlyingTokenSymbol(
                  token
                )}: userProfits(where: { underlyingToken: $${underlyingTokenSymbol(
        token
      )} }, orderBy: profit) {
                        user {
                            id
                        }
                        updatedAt
                        profit
                    }`
    ).join(`\n`);

    const variables = UnderlyingTokens.reduce((acc, tok) => {
      return {
        ...acc,
        [underlyingTokenSymbol(tok)]: tokenID(wrapUnderlyingToken(tok), network),
      };
    }, {});

    const data = await request(
      getGraphUrl(network),
      gql`
                query getTopEarners($ETH: Bytes!, $renBTC: Bytes!, $WETH: Bytes!, $USDC: Bytes!, $DAI: Bytes) { 
                    ${tokenQueries}
            }`,
      variables
    );
    return { data };
  } catch (err) {
    console.log("getTopEarners error: ", err);
    return { error: err };
  }
}

export async function getTopKeepers(network: NetworkID): Promise<any> {
  try {
    const tokenQueries = UnderlyingTokens.map(
      (token, i) => `
                ${underlyingTokenSymbol(
                  token
                )}: keeperProfits(where: { underlyingToken: $${underlyingTokenSymbol(
        token
      )} }, orderBy: profit) {
                        id
                        updatedAt
                        profit
                    }`
    ).join(`\n`);

    const variables = UnderlyingTokens.reduce((acc, tok) => {
      return {
        ...acc,
        [underlyingTokenSymbol(tok)]: tokenID(wrapUnderlyingToken(tok), network),
      };
    }, {});

    const data = await request(
      getGraphUrl(network),
      gql`
                query getTopKeepers($ETH: Bytes!, $renBTC: Bytes!, $WETH: Bytes!, $USDC: Bytes!, $DAI: Bytes) { 
                    ${tokenQueries}
            }`,
      variables
    );
    return { data };
  } catch (err) {
    console.log("getTopKeepers error: ", err);
    return { error: err };
  }
}

export function tokenID(selectedToken: Token, network: NetworkID): string {
  return network && network !== NetworkID.Unsupported
    ? tokenAddress(network, selectedToken).toLowerCase()
    : "";
}

export function poolID(selectedToken: Token, network: NetworkID): string {
  return network && network !== NetworkID.Unsupported
    ? contractAddress(network, ContractType.LiquidityPool).toLowerCase() +
        tokenAddress(network, selectedToken).toLowerCase()
    : "";
}
