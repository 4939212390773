import { useEffect, useState, useCallback } from "react";
import { createContainer } from "unstated-next";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import { Token, Tokens } from "../lib/tokens";
import { contractAddress, contractTypeFromToken } from "../lib/contracts";
import { NetworkID, networkIdFromChainId } from "../lib/network";
import { useWeb3React } from "@web3-react/core";
import MultiCall from "@indexed-finance/multicall";
import compoundTokens from "../contracts/compoundTokens.json";

export async function balanceOf(web3: Web3, network: NetworkID, token: Token, address: string) {
  try {
    if (token === Token.ETH) {
      // console.log("eth balance update")
      return new BigNumber(await web3.eth.getBalance(address));
    } else {
      // console.log("erc-20 balance update")
      try {
        const bal = await web3.eth.call({
          to: contractAddress(network, contractTypeFromToken(token)),
          data: `0x70a08231000000000000000000000000${address.slice(-40)}`,
        });
        return new BigNumber(bal);
      } catch (err) {
        console.log("balanceOf error for ", token, ": ", err);
        return new BigNumber(0);
      }
    }
  } catch (err) {
    console.error("ERC20 balanceOf error", err);
    return new BigNumber(0);
  }
}

function useBalances() {
  const { connector, library, chainId, account } = useWeb3React();
  const [balances, setBalances] = useState(new Map<Token, BigNumber>());
  const [compoundBalances, setCompoundBalances] = useState(new Map<string, BigNumber>());

  const updateCompoundBalances = useCallback(async () => {
    if (!library || !chainId) {
      return;
    }
    const { tokens } = compoundTokens[networkIdFromChainId(chainId)];
    const cBalances = new Map<string, BigNumber>();
    if (!account) {
      for (const token of tokens) {
        const name = token.name; //=== 'ETH' ? 'WETH' : token.name;
        cBalances.set(name, new BigNumber(0));
      }
      setCompoundBalances(cBalances);

      return;
    }

    const multi = new MultiCall(library);

    const [_, userBalances] = await multi.getBalances(
      tokens.map((t) => t.address),
      account
    );

    const ethBalance = new BigNumber(await library.eth.getBalance(account));

    for (const t of tokens) {
      if (t.name === "ETH") {
        cBalances.set("ETH", ethBalance);
      } else {
        const tAddress = t.address;
        const tSymbol = t.name; // === 'ETH' ? 'WETH' : t.name;
        cBalances.set(tSymbol, new BigNumber(userBalances[tAddress].toString()));
      }
    }
    setCompoundBalances(cBalances);
  }, [library, chainId, account]);

  const updateBalances = useCallback(async () => {
    if (!library || !chainId) {
      return;
    }

    const balances = new Map<Token, BigNumber>();
    if (!account) {
      for (const token of Tokens) {
        balances.set(token, new BigNumber(0));
      }
      setBalances(balances);
      return;
    }

    for (const token of Tokens) {
      balances.set(token, await balanceOf(library, networkIdFromChainId(chainId), token, account));
    }
    setBalances(balances);
  }, [library, chainId, account]);

  useEffect(() => {
    updateCompoundBalances();
    updateBalances();
    const interval = setInterval(() => {
      updateCompoundBalances();
      updateBalances();
    }, 20000);
    return () => interval && clearInterval(interval);
  }, [connector, library, chainId, account, updateBalances, updateCompoundBalances]);

  return { balances, updateBalances, compoundBalances, updateCompoundBalances };
}

const Balances = createContainer(useBalances);

export default Balances;
