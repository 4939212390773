import { Trans, useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Icon,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ArrowRightAlt } from "@material-ui/icons";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import React from "react";
import MoonLoader from "react-spinners/MoonLoader";
import { ExternalLinkIcon } from "../../components/Icons";
import WalletCreateModal from "../../components/modals/compound/CompoundWalletCreateModal";
import { GradientBox } from "../../components/ui-components/StyledBox";
import {
  GlobalVaultsState,
  PositionsState,
  RookPositionState,
  UsdPositionState,
  VaultCreateOptions,
} from "../../containers/borrow";
import { ReactComponent as VaultIcon } from "../../styles/images/Menu Icons/Vault.svg";

const useStyles = makeStyles((theme) => ({
  vaultContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "auto",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "24px",
    width: "100%",
    height: "100%",
  },
  vaultHeader: {
    display: "flex",
    flex: "1 1 auto",
    position: "relative",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "8px",
    maxHeight: "40%",
    //backgroundColor: '#123456',
  },
  vaultContent: {
    display: "flex",
    position: "relative",
    flex: "1 1 0",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
    //height: '100%',
    //width: '100%',
  },
  totalFundsContainer: {
    display: "flex",
    flex: "0 1 0",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "10px",
    borderRadius: "16px",
    //background: 'linear-gradient(to right, #6C46D6 0%, #09A7F3 100%)',
  },
  totalFundsContent: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    width: "100%",
    height: "100%",
    borderRadius: "16px",
    backgroundColor: "rgba(245,245,245,0.08)",
    //background: 'linear-gradient(to right, #6C46D6 0%, #09A7F3 100%)',
  },
  totalFundsText: {
    color: theme.palette.text.primary,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    [theme.breakpoints.up("md")]: {
      fontSize: "30px",
      lineHeight: "32px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "23px",
      lineHeight: "24px",
    },
  },
  totalFundsSubtext: {
    color: theme.palette.text.primary,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 300,
    [theme.breakpoints.up("md")]: {
      fontSize: "28px",
      lineHeight: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "21px",
      lineHeight: "23px",
    },
  },
  vaultHeaderText: {
    color: theme.palette.text.primary,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    [theme.breakpoints.up("md")]: {
      fontSize: "32px",
      lineHeight: "39px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "30px",
    },
  },
  docsLink: {
    textTransform: "none",
    alignItems: "center",
    paddingTop: "8px",
    paddingLeft: "0px",
    "&:hover": {
      background: "none",
      textDecoration: "underline",
    },
  },
  noVaults: {
    display: "flex",
    position: "relative",
    justifyContent: "space-beteen",
    borderRadius: "16px",
    border: "1px dashed #525252",
    alignItems: "center",
    color: theme.palette.text.secondary,
    paddingLeft: "30%",
    paddingRight: "30%",
    paddingTop: "5%",
    paddingBottom: "5%",
    height: "100%",
    width: "100%",
  },
  noVaultHeaderText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "2em",
    textAlign: "center",
    color: "#525252",
  },
  noVaultSubtext: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "1.1vw",
    textAlign: "center",
    color: "#525252",
  },
  createVaultButton: {
    borderRadius: "8px",
    fontSize: "1.1vw",
    marginTop: "8px",
  },
  importButton: {
    textTransform: "none",
    alignContent: "center",
    paddingLeft: "0px",
    fontSize: "1vw",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    "&:hover": {
      background: "none",
      textDecoration: "underline",
    },
  },
  positionSelectorContainer: {
    display: "flex",
    position: "relative",
    flex: "1 1 auto",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
    height: "100%",
    width: "100%",
  },
  vaultGrid: {
    display: "flex",
    position: "relative",
    flex: "1 1 0",
    alignItems: "center",
    //justifyContent: 'center',
    justifyContent: "flex-start",
    //height: '100%',
    //width: '100%',
    //backgroundColor: '#123456',
  },
  cardContainer: {
    display: "flex",
    position: "relative",
    flex: "0 1 0",
    maxHeight: "252px",
    maxWidth: "252px",
  },
  newVaultCard: {
    display: "flex",
    position: "relative",
    background: "none",
    height: "252px",
    width: "252px",
    justifyContent: "center",
    alignItems: "center",
  },
  newVaultCardAction: {
    position: "relative",
    border: "1px dashed #525252",
    borderRadius: "16px",
    padding: "10px",
    height: "232px",
    width: "232px",
  },
  vaultCard: {
    display: "flex",
    position: "relative",
    background: "none",
    height: "252px",
    width: "252px",
    justifyContent: "center",
    alignItems: "center",
  },
  vaultCardAction: {
    position: "relative",
    background: "rgba(245, 245, 245, 0.08)",
    borderRadius: "16px",
    padding: "10px",
    height: "232px",
    width: "232px",
  },
  vaultCardContent: {
    display: "flex",
    position: "relative",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  vaultSymbol: {
    display: "flex",
    position: "absolute",
    left: "0%",
    right: "0%",
    top: "0%",
    bottom: "0%",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    zIndex: -1,
    opacity: 0.07,
  },
  divider: {
    position: "absolute",
    width: "100%",
    background: "linear-gradient(to right, #6C46D6 0%, #09A7F3 100%)",
    opacity: "0.36",
  },
}));

interface VaultSelectorProps {
  positions: PositionsState[];
  usdStatuses: UsdPositionState[];
  rookStatuses: RookPositionState[];
  totalBorrowUSD: string;
  globalVaults: GlobalVaultsState;
  selectedPosition: string;
  setPosition: (position: string) => void;
  loading: boolean;
  openWalletCreateModal: (
    option: VaultCreateOptions,
    migrateOnly: boolean,
    posAddress?: string
  ) => void;
  updateVault: () => void;
}

type PositionCompClaim = {
  posAddress: string;
  claimBalance: BigNumber;
};

const VaultSelector = (props: VaultSelectorProps): React.ReactElement => {
  const c = useStyles();
  const { t } = useTranslation();
  const { library, chainId, account } = useWeb3React();
  const {
    selectedPosition,
    setPosition,
    positions,
    usdStatuses,
    rookStatuses,
    totalBorrowUSD,
    globalVaults,
    loading,
    openWalletCreateModal,
    updateVault,
  } = props;
  const supplyAmount = globalVaults.collateralUSD.toFormat(0);
  const numVaults = globalVaults.numVaults;

  /*
    async function fetchCompToClaim(positionAddresses: string[]): Promise<PositionCompClaim[]> {
        try {
            if (!library || !chainId) return [];
            const multicall = new MultiCall(library);
            const abi = contractABI(networkIdFromChainId(chainId), ContractType.COMP);
            const compAddress = contractAddress(networkIdFromChainId(chainId), ContractType.COMP, true);
            const inputs = positionAddresses.map((pos) => ({
                target: compAddress,
                function: 'balanceOf',
                args: [pos],
            }));
            const [_, bals] = await multicall.multiCall(abi, inputs);
            const balances: PositionCompClaim[] = positionAddresses.map((pos, index) => {
                return { posAddress: pos, claimBalance: new BigNumber(bals[index].toString()) };
            });
            return balances;
        } catch (err) {
            if (process.env.NODE_ENV === 'development') {
                console.log('Error: [fetchCompToClaim] ', err);
            }

            return [];
        }
    }

    useEffect(() => {
        if (!positions.length) return;
        const positionsAddress = positions.map((p) => p.posAddress);
        fetchCompToClaim(positionsAddress).then((balances) => {
            if (balances) setCompToClaim(balances);
        });
    }, [positions, filtered]);
    */

  const onPosSelect = (pos: string) => {
    if (selectedPosition !== pos) {
      setPosition(pos);
    }
  };

  return (
    <GradientBox>
      <Grid container className={c.vaultContainer} direction="column">
        <Grid container item className={c.vaultHeader}>
          <Grid container item direction="row" xs={12}>
            <Typography
              variant="h4"
              classes={{ h4: c.vaultHeaderText }}
              style={{ marginRight: "7px" }}
            >
              <Trans i18nKey="vaults-header">
                Manage assets in a vault, protect funds against liquidation, and earn
                <span
                  className={c.vaultHeaderText}
                  style={{
                    color: "#6C46D6",
                  }}
                >
                  ROOK
                </span>
                in the process.
              </Trans>
            </Typography>
          </Grid>
          <Button
            classes={{ root: c.docsLink }}
            endIcon={
              <Icon fontSize="large" style={{ alignItems: "center", marginTop: "8px" }}>
                <ExternalLinkIcon />
              </Icon>
            }
            href="https://medium.com/keeperdao/hiding-vault-x-compound-a09ad6c2db80"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("sunset-link")}
          </Button>
        </Grid>
        {loading ? null : (
          <Grid container item className={c.totalFundsContainer} xs={11}>
            <Grid container item className={c.totalFundsContent} direction="row">
              <Grid item style={{ marginRight: "12px" }}>
                <Typography variant="h5" align="center" classes={{ h5: c.totalFundsSubtext }}>
                  <Trans i18nKey="vault-supply">
                    $<span className={c.totalFundsText}>{{ supplyAmount }}</span> in total assets
                    protected across
                    <span className={c.totalFundsText}>{{ numVaults }}</span> Vaults
                  </Trans>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container item className={c.vaultContent}>
          {loading ? (
            <Grid
              container
              item
              className={c.noVaults}
              direction="column"
              justifyContent="space-between"
            >
              <Grid item>
                <MoonLoader color="#6D47D7" loading={true} size={50} />
              </Grid>
              <Grid item>
                <Typography classes={{ root: c.noVaultHeaderText }}>Loading Vaults</Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container item className={c.vaultGrid} direction="row">
              {usdStatuses.map((pos, i) => {
                const posRook = rookStatuses[i]
                  ? rookStatuses[i]
                  : {
                      posAddress: "",
                      rookAPY: new BigNumber(0),
                      pendingRewards: new BigNumber(0),
                    };
                return (
                  <Grid
                    container
                    item
                    className={c.cardContainer}
                    key={i}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                  >
                    <Card elevation={0} className={c.vaultCard}>
                      <CardActionArea
                        className={c.vaultCardAction}
                        onClick={() => onPosSelect(pos.posAddress)}
                      >
                        <CardContent className={c.vaultCardContent}>
                          <div className={c.vaultSymbol}>
                            <VaultIcon fill="#F9A606" width="85%" height="85%" />
                          </div>
                          <Typography variant="h6">
                            {pos.posAddress.substr(0, 5)}...
                            {pos.posAddress.slice(-3)}
                          </Typography>
                          <Grid container direction="column">
                            <Typography variant="body2">Supply Balance </Typography>
                            <Typography variant="h6">${pos.supplyUSD.toFormat(2)}</Typography>
                          </Grid>
                          <Grid container direction="column">
                            <Typography variant="body2">Borrow Balance </Typography>
                            <Typography variant="h6">${pos.borrowUSD.toFormat(2)}</Typography>
                          </Grid>
                          <Grid container direction="column">
                            <Typography variant="body2">{t("vault-net-apy")} </Typography>
                            <Typography variant="h6">
                              {pos.netInterestAPY
                                .plus(pos.netCompAPY)
                                .plus(posRook.rookAPY)
                                .multipliedBy(100)
                                .toFormat(2)}
                              %
                            </Typography>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
              {/*<Grid container item className={c.cardContainer} xs={12} sm={6} md={4} lg={3} xl={2}>
                <Card elevation={0} className={c.newVaultCard}>
                  <Grid className={c.newVaultCardAction}>
                    <CardContent className={c.vaultCardContent}>
                      <Grid
                        container
                        item
                        direction="column"
                        justifyContent="space-around"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item>
                          <VaultIcon fill="#525252" height="65px" width="72px" />
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            className={c.createVaultButton}
                            style={{ width: "152px", height: "40px" }}
                            endIcon={<ArrowRightAlt />}
                            onClick={() => openWalletCreateModal(VaultCreateOptions.CREATE, false)}
                          >
                            {t("borrow-new-vault")}
                          </Button>
                        </Grid>
                        <Grid container item justifyContent="center">
                          <Button
                            variant="text"
                            className={c.importButton}
                            style={{
                              paddingLeft: "6px",
                              width: "100%",
                              fontSize: "15px",
                              fontWeight: 200,
                              textAlign: "center",
                            }}
                            onClick={() => openWalletCreateModal(VaultCreateOptions.MIGRATE, false)}
                          >
                            {t("borrow-import-from-compound")}
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Card>
                          </Grid>*/}
            </Grid>
          )}
        </Grid>
      </Grid>
      <WalletCreateModal onClose={updateVault} />
    </GradientBox>
  );
};

export default VaultSelector;
