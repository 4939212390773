import {
  Backdrop,
  Button,
  Dialog,
  Divider,
  Grid,
  Input,
  InputAdornment,
  LinearProgress,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MoonLoader from "react-spinners/MoonLoader";
// import { EVENT_CATEGORIES, ACTIONS } from '../../../lib/analytics';
import Balances from "../../../containers/balances";
import { BorrowRepay, BorrowState, RepayState } from "../../../containers/borrow";
import CompoundData from "../../../containers/compound";
import { MarketBorrowInfo } from "../../../lib/compound";
import { contract, ContractType, ERC20Contract } from "../../../lib/contracts";
import { networkIdFromChainId } from "../../../lib/network";
import TokenLogo from "../../TokenLogo";
import { GradientBox } from "../../ui-components/StyledBox";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "none",
    border: "none",
    position: "absolute",
  },
  modal: {
    position: "relative",
    display: "flex",
    flex: "1 1 0",
    width: "375px",
    maxHeight: "625px",
  },
  modalContent: {
    display: "flex",
    flex: "1 1 0",
    position: "relative",
    height: "100%",
    width: "100%",
    padding: "24px",
    justifyContent: "flex-start",
    alignItems: "center",
    alignSelf: "flex-start",
    overflow: "auto",
  },
  modalMessage: {
    marginTop: "8px",
    alignItems: "center",
  },
  tabContainer: {
    display: "flex",
    position: "relative",
    marginTop: "16px",
    justifyContent: "center",
  },
  inputContainer: {
    display: "flex",
    position: "relative",
    backgroundColor: "rgba(245,245,245,0.08)",
    padding: "24px",
    borderRadius: "16px",
  },
  inputHeader: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
  },
  inputField: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
  },
  supplyContent: {
    display: "flex",
    position: "relative",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px",
    marginTop: "16px",
  },
  supplyLimits: {
    marginTop: "20px",
    display: "flex",
    position: "relative",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px",
  },
  yesNoContainer: {
    display: "flex",
    position: "relative",
    width: "100%",
    justifyContent: "space-between",
  },
  button: {
    borderRadius: "8px",
  },
  loadingContainer: {
    display: "flex",
    position: "relative",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  divider: {
    position: "relative",
    width: "100%",
    background: "linear-gradient(to right, #6C46D6 0%, #09A7F3 100%)",
    opacity: "0.24",
    marginTop: "8px",
    marginBottom: "8px",
  },
  cancelButton: {
    borderRadius: "8px",
    width: "100%",
    height: "100%",
  },
  submitButton: {
    borderRadius: "8px",
    width: "100%",
    height: "100%",
  },
  maxButton: {
    color: theme.palette.text.primary,
    padding: 2,
    margin: 0,
    border: "1px solid",
    textTransform: "none",
    textSize: "10px",
    borderColor: "rgba(245,245,245,0.5)",
    justifySelf: "right",
  },
  limitBar: {
    backgroundColor: "#09A7F3",
  },
  tabItemRoot: {
    "&:hover": {
      opacity: 1,
    },
    minHeight: 44,
    minWidth: 96,
    [theme.breakpoints.up("md")]: {
      minWidth: 120,
    },
  },
  tabItemWrapper: {
    // zIndex: 2,
    // marginTop: spacing(0.5),
    color: theme.palette.text.primary,
    textTransform: "initial",
  },
  tabRoot: {
    backgroundColor: "rgba(245, 245, 245, 0.08)",
    borderRadius: 16,
    minHeight: 44,
  },
  tabFlexContainer: {
    display: "inline-flex",
    position: "relative",
    zIndex: 1,
  },
  tabScroller: {
    [theme.breakpoints.up("md")]: {
      padding: "0px 8px",
    },
  },
  tabIndicator: {
    top: 3,
    bottom: 3,
    right: 3,
    height: "auto",
    background: "none",
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 4,
      left: 4,
      right: 4,
      bottom: 4,
      borderRadius: 8,
      backgroundColor: theme.palette.action.hover,
      boxShadow: "0 4px 12px 0 rgba(0,0,0,0.16)",
    },
  },
}));

function useBorrowRepayModal() {
  // State variables use for inputting the deposit amount. We need an explicit
  // state variable because we need to do bignumber/decimal/string
  // conversions, and haveing an explicit state variable makes this much
  // easier.
  const [borrowInput, setBorrowInput] = useState("");
  const [repayInput, setRepayInput] = useState("");

  // The Wallet is needed so that we can sign and submit transactions to
  // Ethereum.
  const wallet = useWeb3React();

  // Balances is needed so that we know the maximum amount that can be
  // deposited. There is no point letting the user attempt to deposit more
  // than the maximum, since it would just revert.
  const { compoundBalances } = Balances.useContainer();
  const { compoundData } = CompoundData.useContainer();
  //const exchangeRates = ExchangeRates.useContainer();

  //console.log('COMPOUND DATA', compoundTokenInfo, compoundData);

  const { t } = useTranslation();

  // The depositor is needed so that we can know the current state of the
  // depositor.
  const borrower = BorrowRepay.useContainer();
  const token = borrower.token;

  //update input on modal state change
  useEffect(() => {
    setBorrowInput("");
  }, [borrower.borrowState]);

  useEffect(() => {
    setRepayInput("");
  }, [borrower.repayState]);

  function calculateNewLimit(borrow: boolean, borrowData: MarketBorrowInfo | undefined) {
    if (!borrower.vaultBalancesUSD || !borrower.vaultBalances || !borrowData)
      return { oldLimit: "0", oldBorrow: "0", newLimit: "0", newBorrow: "0" };
    const currentLimit = borrower.vaultBalancesUSD.limitUSD;
    const currentLeftToBorrow = borrower.vaultBalancesUSD.leftToBorrowUSD;

    if (borrow && borrowInput) {
      const price = borrowData.priceUSD;
      const decimalScaling = new BigNumber(10).pow(borrower.token.underlyingToken.decimals);
      const limitToSubtract = borrower.borrowAmount
        ? borrower.borrowAmount.dividedBy(decimalScaling).multipliedBy(price)
        : new BigNumber(0);
      const newLimit = currentLimit.toFixed(2);
      const newBorrow = currentLeftToBorrow.minus(limitToSubtract).toFixed(2);
      return {
        oldLimit: currentLimit.toFixed(2),
        oldBorrow: currentLeftToBorrow.toFixed(2),
        newLimit: newLimit,
        newBorrow: newBorrow,
      };
    }

    if (!borrow && repayInput) {
      const price = borrowData.priceUSD;
      const decimalScaling = new BigNumber(10).pow(borrower.token.underlyingToken.decimals);
      const limitToAdd = borrower.repayAmount
        ? borrower.repayAmount.dividedBy(decimalScaling).multipliedBy(price)
        : new BigNumber(0);
      const newLimit = currentLimit.toFixed(2);
      const newBorrow = currentLeftToBorrow.plus(limitToAdd).toFixed(2);
      return {
        oldLimit: currentLimit.toFixed(2),
        oldBorrow: currentLeftToBorrow.toFixed(2),
        newLimit: newLimit,
        newBorrow: newBorrow,
      };
    }

    return {
      oldLimit: currentLimit.toFixed(2),
      oldBorrow: currentLeftToBorrow.toFixed(2),
      newLimit: currentLimit.toFixed(2),
      newBorrow: currentLeftToBorrow.toFixed(2),
    };
  }

  async function borrow() {
    borrower.setBorrowState(BorrowState.WAIT_FOR_BORROW);
    if (!wallet.library || !wallet.chainId || !wallet.account) {
      return;
    }
    const web3 = wallet.library;
    const networkID = networkIdFromChainId(wallet.chainId);
    const positionContract = contract(
      web3,
      networkID,
      ContractType.KCompound,
      wallet.account,
      borrower.positionAddress
    );

    const amount = borrower.borrowAmount || new BigNumber(0);

    //const amount = new BigNumber('1000000000000000000000');
    const address = token.cToken.address;
    //const address = '0xF0d0EB522cfa50B716B3b1604C4F0fA6f04376AD';

    try {
      await positionContract.methods
        .compound_borrow(wallet.account, address, amount?.toFixed(0))
        .send({ from: wallet.account })
        .on("transactionHash", (hash) => {
          console.log("Tx hash came => ", hash);
        })
        .on("receipt", async (receipt) => {
          console.log("Tx receipt came => ", receipt);
          borrower.setBorrowState(BorrowState.SUCCESS);
        })
        .on("error", (error, receipt) => {
          borrower.setBorrowState(BorrowState.CANCEL_BORROW);
          return;
        });
    } catch (err) {
      console.error(`cannot deposit: ${err}`);
      borrower.setBorrowState(BorrowState.CANCEL_BORROW);
      return;
    }
  }

  async function repay() {
    borrower.setRepayState(RepayState.WAIT_FOR_REPAY);
    if (!wallet.library || !wallet.chainId || !wallet.account) {
      return;
    }
    const web3 = wallet.library;
    const networkID = networkIdFromChainId(wallet.chainId);
    const positionContract = contract(
      web3,
      networkID,
      ContractType.KCompound,
      wallet.account,
      borrower.positionAddress
    );
    const amount = borrower.repayAmount || new BigNumber(0);
    const value = token.underlyingToken.name === "ETH" ? amount : new BigNumber(0);
    try {
      await positionContract.methods
        .compound_repay(token.cToken.address, amount?.toFixed(0))
        .send({ from: wallet.account, value: value })
        .on("transactionHash", (hash) => {
          // console.log('Tx hash came => ', hash);
        })
        .on("receipt", async (receipt) => {
          // console.log('Tx receipt came => ', receipt);
          borrower.setRepayState(RepayState.SUCCESS);
        })
        .on("error", (error, receipt) => {
          borrower.setRepayState(RepayState.CANCEL_REPAY);
          return;
        });
    } catch (err) {
      console.error(`cannot deposit: ${err}`);
      borrower.setRepayState(RepayState.CANCEL_REPAY);
      return;
    }
  }

  return {
    borrowInput,
    setBorrowInput,
    repayInput,
    setRepayInput,
    ...wallet,
    borrower,
    t,
    borrow,
    repay,
    compoundBalances,
    compoundData,
    token,
    calculateNewLimit,
  };
}

function Hidden() {
  // It is important that this component is explicitly rendered when the modal
  // is hidden, otherwise the order of the "useState" calls will change when
  // the model opens/closes, and this will cause all sorts of undefined
  // behaviour!
  const _ = useBorrowRepayModal();
  return null;
}

function SetUnlimtedApproval() {
  const borrowModal = useBorrowRepayModal();
  const c = useStyles();

  async function depositWithApproval(unlimited = false) {
    borrowModal.borrower.setRepayState(RepayState.WAIT_FOR_APPROVE);

    if (!borrowModal.library || !borrowModal.chainId || !borrowModal.account) {
      return;
    }
    const web3 = borrowModal.library;
    const networkID = networkIdFromChainId(borrowModal.chainId);
    const underlyingTokenContract = ERC20Contract(
      web3,
      networkID,
      borrowModal.token.underlyingToken.address,
      borrowModal.account
    );

    const amount = borrowModal.borrower.borrowAmount
      ? new BigNumber(borrowModal.borrower.borrowAmount)
      : new BigNumber(0);

    const maxAllowance = unlimited ? new BigNumber(2).pow(256).minus(1) : amount;

    try {
      if (!maxAllowance || maxAllowance.isNaN()) {
        borrowModal.borrower.setRepayState(RepayState.CANCEL_APPROVE);
        return;
      }
      await underlyingTokenContract.methods
        .approve(borrowModal.borrower.positionAddress, maxAllowance.toFixed(0))
        .send({ from: borrowModal.account })
        .on("transactionHash", (hash) => {
          // console.log('Tx hash came => ', hash);
        })
        .on("receipt", async (receipt) => {
          console.log("Tx receipt came => ", receipt);
          await borrowModal.repay();
        })
        .on("error", () => {
          borrowModal.borrower.setRepayState(RepayState.CANCEL_APPROVE);
          return;
        });
    } catch (err) {
      console.error(`cannot approve: ${err}`);
      borrowModal.borrower.setRepayState(RepayState.CANCEL_APPROVE);
      return;
    }
  }

  return (
    <Grid container item className={c.supplyContent} direction="column" spacing={2}>
      <Grid container item>
        <Typography variant="h4">
          {borrowModal.t("deposit-modal.unlimited-approval-header")}
        </Typography>
      </Grid>
      <Grid container item>
        <Typography variant="body2">
          {borrowModal.t("deposit-modal.unlimited-approval-popup", {
            underlyingTokenSymbol: borrowModal.token.underlyingToken.name,
          })}
        </Typography>
      </Grid>
      <Grid container item className={c.yesNoContainer}>
        <Grid item style={{ width: "48%" }}>
          <Button
            fullWidth
            variant="outlined"
            className={c.button}
            onClick={async () => await depositWithApproval()}
          >
            No
          </Button>
        </Grid>
        <Grid item style={{ width: "48%" }}>
          <Button
            fullWidth
            variant="contained"
            className={c.button}
            onClick={async () => await depositWithApproval(true)}
          >
            Approve
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

function RepayWaitForInput() {
  const {
    token,
    borrower,
    t,
    repayInput,
    account,
    library,
    chainId,
    setRepayInput,
    compoundBalances,
    repay,
    calculateNewLimit,
    compoundData,
  } = useBorrowRepayModal();
  const c = useStyles();

  const decimalScaling = new BigNumber(10).pow(token.underlyingToken.decimals);

  const [underlyingTokenBalance, setUnderlyingTokenBalance] = useState(new BigNumber(0));
  const [borrowedBalance, setBorrowedBalance] = useState(new BigNumber(0));
  const [isUnderwritten, setUnderwritten] = useState(false);

  useEffect(() => {
    if (borrower.vaultBalances) {
      setUnderwritten(borrower.vaultBalances.isUnderwritten);
      const borrowTokenPair = borrower.vaultBalances.borrowedTokens.find(
        (x) => x.underlyingToken.name === token.underlyingToken.name
      );
      if (borrowTokenPair)
        setBorrowedBalance(new BigNumber(borrowTokenPair.underlyingToken.balance)); //.multipliedBy(decimalScaling));
    }
    if (compoundBalances) {
      const bal = compoundBalances.get(token.underlyingToken.name);
      if (bal) setUnderlyingTokenBalance(bal);
    }
  }, [borrower.vaultBalances, compoundBalances]);

  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    setDisabled(
      !repayInput ||
        !(parseFloat(repayInput) > 0) ||
        (borrowedBalance ? borrower.repayAmount?.gt(borrowedBalance) : false) ||
        (underlyingTokenBalance && borrower.repayAmount
          ? underlyingTokenBalance.lt(borrower.repayAmount)
          : false)
    );
  }, [repayInput, borrowedBalance, underlyingTokenBalance, borrower.repayAmount]);

  const borrowData = borrower.borrowData;
  const tokenData = borrowData.find((x) => x.underlyingSymbol === token.underlyingToken.name);
  const tokenRate = tokenData ? new BigNumber(tokenData.priceUSD) : new BigNumber(0);
  const tokenRateString =
    borrower.repayAmount && repayInput
      ? `≈ $${tokenRate.multipliedBy(borrower.repayAmount.dividedBy(decimalScaling)).toFixed(2)}`
      : "";

  const [limits, setLimits] = useState({
    oldLimit: "0",
    oldBorrow: "0",
    newLimit: "0",
    newBorrow: "0",
  });
  useEffect(() => {
    const newLimits = calculateNewLimit(false, tokenData);
    setLimits(newLimits);
  }, [borrower.borrowData, borrower.vaultBalancesUSD, borrower.repayAmount]);

  async function checkApproval() {
    // ETH does not need to be approved before depositing.
    if (token.underlyingToken.name === "ETH") {
      await repay();
      return;
    }
    if (!library || !chainId || !account) {
      return;
    }
    const web3 = library;
    const networkID = networkIdFromChainId(chainId);
    // const compoundGatewayAddress = contractAddress(networkID, ContractType.CompoundGateway);

    const underlyingTokenContract = ERC20Contract(
      web3,
      networkID,
      borrower.token.underlyingToken.address,
      account
    );
    const allowance = await underlyingTokenContract.methods
      .allowance(account, borrower.positionAddress)
      .call();
    const value = borrower.repayAmount ? new BigNumber(borrower.repayAmount) : new BigNumber(0);
    // console.log('ALLOWance =>', new BigNumber(allowance).toFormat(), value.toFormat());
    // if allowance is set to unlimited we skip to deposit
    if (new BigNumber(allowance).lt(value)) {
      //promt message to set allowance to unlimited
      borrower.setRepayState(RepayState.SET_UNLIMITED_APPROVAL);
    } else {
      await repay();
      return;
    }
  }

  return (
    <Grid container item className={c.supplyContent} direction="column">
      <Grid container item className={c.inputContainer}>
        <Grid container item className={c.inputHeader} direction="row">
          <Typography variant="body2">Repaying {token.underlyingToken.name}</Typography>
          <Typography variant="body2">{tokenRateString}</Typography>
        </Grid>
        <Grid container item className={c.inputField} direction="row">
          <Grid container item alignItems="center" xs={9}>
            <Input
              id="borrow-input"
              placeholder="0"
              disableUnderline
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Inter",
                fontSize: "20px",
                fontWeight: "bold",
              }}
              onChange={(evt) => {
                // Remove spaces.
                let v = evt.currentTarget.value.replace(/\s/g, "");
                // Remove second occurrences of periods.
                v = v
                  .split("")
                  .filter((x, n, self) => self.indexOf(x) === n || x !== ".")
                  .join("");
                // Remove non-digits (unless it is a period).
                v = v.replace(/[^\d\.]/g, "");
                // Set the withdraw amount as a big number. (This is
                // the shared state value.)
                borrower.setRepayAmount(new BigNumber(v).multipliedBy(decimalScaling));
                // Set the amount as a string so that input is not
                // messed up when typing "." characters.
                setRepayInput(v);
              }}
              startAdornment={
                <InputAdornment position="start" style={{ alignItems: "center" }}>
                  <TokenLogo chainId={chainId} address={token.underlyingToken.address} size={28} />
                </InputAdornment>
              }
              value={repayInput}
            />
          </Grid>
          <Grid container item alignItems="center" xs={3}>
            <Button
              onClick={() => {
                // Set the withdraw amount as a big number. (This is the
                // shared state value.)
                if (borrowedBalance.gt(underlyingTokenBalance)) {
                  borrower.setRepayAmount(underlyingTokenBalance);
                  setRepayInput(underlyingTokenBalance.dividedBy(decimalScaling).toPrecision(9));
                } else {
                  borrower.setRepayAmount(borrowedBalance);
                  setRepayInput(borrowedBalance.dividedBy(decimalScaling).toPrecision(9));
                }
              }}
              classes={{ root: c.maxButton }}
            >
              Max
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item className={c.supplyLimits}>
        <Grid container item justifyContent="flex-start" style={{ marginBottom: "16px" }}>
          <Typography variant="body2" style={{ color: "#8F8F8F" }}>
            Supply Rates
          </Typography>
        </Grid>
        <Grid container item direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">{t("borrow-modal.borrow-header")} APY</Typography>
          <Typography variant="body1" style={{ color: "#8F8F8F" }}>
            {tokenData ? new BigNumber(tokenData.borrowRate).multipliedBy(100).toFixed(2) : ""}%
          </Typography>
        </Grid>
        <Grid
          container
          item
          style={{
            position: "relative",
            width: "100%",
            margin: "none",
          }}
        >
          <Divider className={c.divider} />
        </Grid>
        <Grid container item direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">{t("vault-comp-apy")}</Typography>
          <Typography variant="body1" style={{ color: "#8F8F8F" }}>
            {tokenData ? new BigNumber(tokenData.borrowCompAPY).toFixed(2) : ""}%
          </Typography>
        </Grid>
        <Grid
          container
          item
          justifyContent="flex-start"
          style={{ marginTop: "24px", marginBottom: "16px" }}
        >
          <Typography variant="body2" style={{ color: "#8F8F8F" }}>
            Limits
          </Typography>
        </Grid>
        <Grid container item direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">{t("vault-borrow-limit")}</Typography>
          <Typography variant="body1" style={{ color: "#8F8F8F" }}>
            ${limits.oldLimit} → ${limits.newLimit}
          </Typography>
        </Grid>
        <Grid
          container
          item
          style={{
            position: "relative",
            width: "100%",
            margin: "none",
          }}
        >
          <Divider className={c.divider} />
        </Grid>
        <Grid container item direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">{t("vault-left-to-borrow")}</Typography>
          <Typography variant="body1" style={{ color: "#8F8F8F" }}>
            ${limits.oldBorrow} → ${limits.newBorrow}
          </Typography>
        </Grid>
        <LinearProgress
          classes={{ barColorPrimary: c.limitBar }}
          style={{
            position: "relative",
            width: "100%",
            marginTop: "12px",
            marginBottom: "24px",
            borderRadius: "2px",
          }}
          variant="determinate"
          value={
            (100 * (parseFloat(limits.newLimit) - parseFloat(limits.newBorrow))) /
            parseFloat(limits.newLimit)
          }
        />
      </Grid>
      <Grid container item className={c.yesNoContainer}>
        <Grid item style={{ width: "48%" }}>
          <Button
            fullWidth
            variant="outlined"
            className={c.button}
            onClick={() => {
              borrower.setRepayState(RepayState.HIDDEN);
              borrower.setBorrowState(BorrowState.HIDDEN);
            }}
          >
            Go Back
          </Button>
        </Grid>
        <Grid item style={{ width: "48%" }}>
          <Button
            fullWidth
            variant="contained"
            disabled={disabled}
            className={c.button}
            onClick={async () => await checkApproval()}
          >
            Repay
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

function BorrowWaitForInput() {
  const {
    token,
    borrower,
    t,
    borrowInput,
    account,
    library,
    chainId,
    setBorrowInput,
    compoundBalances,
    borrow,
    calculateNewLimit,
  } = useBorrowRepayModal();
  const c = useStyles();

  const decimalScaling = new BigNumber(10).pow(token.underlyingToken.decimals);

  const borrowData = borrower.borrowData;
  const tokenData = borrowData.find((x) => x.underlyingSymbol === token.underlyingToken.name);
  const tokenRate = tokenData ? new BigNumber(tokenData.priceETH) : new BigNumber(0);
  const tokenRateUSD = tokenData ? new BigNumber(tokenData.priceUSD) : new BigNumber(0);

  console.log("TOKEN PRICE: ", tokenRate.toString());

  const letfToBorrowETH = borrower.vaultBalancesUSD
    ? borrower.vaultBalancesUSD.leftToBorrowETH
    : new BigNumber(0);
  const leftToBorrowToken =
    letfToBorrowETH && tokenRate
      ? letfToBorrowETH.dividedBy(tokenRate).multipliedBy(decimalScaling).multipliedBy(0.9999)
      : new BigNumber(0);

  const [isUnderwritten, setUnderwritten] = useState(false);

  const disabled =
    !borrowInput ||
    !(parseFloat(borrowInput) > 0) ||
    (leftToBorrowToken ? borrower.borrowAmount?.gt(leftToBorrowToken) : false);

  const tokenRateString =
    borrower.borrowAmount && borrowInput
      ? `≈ $${tokenRateUSD
          .multipliedBy(borrower.borrowAmount.dividedBy(decimalScaling))
          .toFixed(2)}`
      : "";

  const [limits, setLimits] = useState({
    oldLimit: "0",
    oldBorrow: "0",
    newLimit: "0",
    newBorrow: "0",
  });
  useEffect(() => {
    if (borrower.vaultBalances) setUnderwritten(borrower.vaultBalances.isUnderwritten);
    const newLimits = calculateNewLimit(true, tokenData);
    setLimits(newLimits);
  }, [borrower.borrowData, borrower.vaultBalancesUSD, borrower.borrowAmount, tokenData]);

  return (
    <Grid container item className={c.supplyContent} direction="column">
      <Grid container item className={c.inputContainer}>
        <Grid container item className={c.inputHeader} direction="row">
          <Typography variant="body2">Borrowing {token.underlyingToken.name}</Typography>
          <Typography variant="body2">{tokenRateString}</Typography>
        </Grid>
        <Grid container item className={c.inputField} direction="row">
          <Grid container item alignItems="center" xs={9}>
            <Input
              id="borrow-input"
              placeholder="0"
              disableUnderline
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Inter",
                fontSize: "20px",
                fontWeight: "bold",
              }}
              onChange={(evt) => {
                // Remove spaces.
                let v = evt.currentTarget.value.replace(/\s/g, "");
                // Remove second occurrences of periods.
                v = v
                  .split("")
                  .filter((x, n, self) => self.indexOf(x) === n || x !== ".")
                  .join("");
                // Remove non-digits (unless it is a period).
                v = v.replace(/[^\d\.]/g, "");
                // Set the withdraw amount as a big number. (This is
                // the shared state value.)
                borrower.setBorrowAmount(new BigNumber(v).multipliedBy(decimalScaling));
                // Set the amount as a string so that input is not
                // messed up when typing "." characters.
                setBorrowInput(v);
              }}
              startAdornment={
                <InputAdornment position="start" style={{ alignItems: "center" }}>
                  <TokenLogo chainId={chainId} address={token.underlyingToken.address} size={28} />
                </InputAdornment>
              }
              value={borrowInput}
            />
          </Grid>
          <Grid container item alignItems="center" xs={3}>
            <Button
              onClick={() => {
                // Set the withdraw amount as a big number. (This is the
                // shared state value.)
                borrower.setBorrowAmount(
                  leftToBorrowToken
                    ? leftToBorrowToken.multipliedBy(0.8).integerValue()
                    : new BigNumber(0)
                );
                // Set the amount as a string so that input is not
                // messed up when typing "." characters.
                setBorrowInput(
                  leftToBorrowToken
                    ? leftToBorrowToken.multipliedBy(0.8).dividedBy(decimalScaling).toPrecision(9)
                    : "0"
                );
              }}
              classes={{ root: c.maxButton }}
            >
              80%
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item className={c.supplyLimits}>
        <Grid container item justifyContent="flex-start" style={{ marginBottom: "16px" }}>
          <Typography variant="body2" style={{ color: "#8F8F8F" }}>
            Supply Rates
          </Typography>
        </Grid>
        <Grid container item direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">{t("borrow-modal.borrow-header")} APY</Typography>
          <Typography variant="body1" style={{ color: "#8F8F8F" }}>
            {tokenData ? new BigNumber(tokenData.borrowRate).multipliedBy(100).toFixed(2) : ""}%
          </Typography>
        </Grid>
        <Grid
          container
          item
          style={{
            position: "relative",
            width: "100%",
            margin: "none",
          }}
        >
          <Divider className={c.divider} />
        </Grid>
        <Grid container item direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">{t("vault-comp-apy")}</Typography>
          <Typography variant="body1" style={{ color: "#8F8F8F" }}>
            {tokenData ? new BigNumber(tokenData.borrowCompAPY).toFixed(2) : ""}%
          </Typography>
        </Grid>
        <Grid
          container
          item
          justifyContent="flex-start"
          style={{ marginTop: "24px", marginBottom: "16px" }}
        >
          <Typography variant="body2" style={{ color: "#8F8F8F" }}>
            Limits
          </Typography>
        </Grid>
        <Grid container item direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">{t("vault-borrow-limit")}</Typography>
          <Typography variant="body1" style={{ color: "#8F8F8F" }}>
            ${limits.oldLimit} → ${limits.newLimit}
          </Typography>
        </Grid>
        <Grid
          container
          item
          style={{
            position: "relative",
            width: "100%",
            margin: "none",
          }}
        >
          <Divider className={c.divider} />
        </Grid>
        <Grid container item direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">{t("vault-left-to-borrow")}</Typography>
          <Typography variant="body1" style={{ color: "#8F8F8F" }}>
            ${limits.oldBorrow} → ${limits.newBorrow}
          </Typography>
        </Grid>
        <LinearProgress
          classes={{ barColorPrimary: c.limitBar }}
          style={{
            position: "relative",
            width: "100%",
            marginTop: "12px",
            marginBottom: "24px",
            borderRadius: "2px",
          }}
          variant="determinate"
          value={
            (100 * (parseFloat(limits.newLimit) - parseFloat(limits.newBorrow))) /
            parseFloat(limits.newLimit)
          }
        />
      </Grid>
      <Grid container item className={c.yesNoContainer}>
        <Grid item style={{ width: "48%" }}>
          <Button
            fullWidth
            variant="outlined"
            className={c.button}
            onClick={() => {
              borrower.setBorrowState(BorrowState.HIDDEN);
              borrower.setRepayState(RepayState.HIDDEN);
            }}
          >
            Go Back
          </Button>
        </Grid>
        <Grid item style={{ width: "48%" }}>
          <Button
            fullWidth
            variant="contained"
            disabled={disabled}
            className={c.button}
            onClick={async () => await borrow()}
          >
            {t("borrow-modal.borrow-header")}
          </Button>
        </Grid>
      </Grid>
      {isUnderwritten ? (
        <Grid>
          <Typography variant="body2">Your position is currently underwritten by JITU</Typography>
        </Grid>
      ) : null}
    </Grid>
  );
}

function WaitForTx(props: { header: string; message: string }) {
  const c = useStyles();
  const { header, message } = props;
  const { t } = useTranslation();

  return (
    <Grid container item className={c.supplyContent} direction="column" spacing={2}>
      <Grid container item justifyContent="center">
        <Typography variant="h4">{t("deposit-modal.unlimited-approval-header")}</Typography>
      </Grid>
      <Grid container item justifyContent="center">
        <Typography variant="body1">{message}</Typography>
      </Grid>
      <Grid container item justifyContent="space-around">
        <MoonLoader color="#6D47D7" loading={true} size={50} />
      </Grid>
    </Grid>
  );
}

function Success(props: { message: string }) {
  const borrowModal = useBorrowRepayModal();
  const c = useStyles();
  const { message } = props;

  // Decimal scaling is used to scale between the string based amount (input
  // by the user) and the shared state value (a big number with no decimals
  // that will be handed to the contracts).
  const decimalScaling = new BigNumber(10).pow(borrowModal.token.underlyingToken.decimals);

  return (
    <Grid container item className={c.supplyContent} direction="column" spacing={2}>
      <Grid container item justifyContent="space-around">
        <Typography variant="body1">{message}</Typography>
      </Grid>
      <Grid container item justifyContent="space-around" xs={8}>
        <Button
          variant="outlined"
          className={c.button}
          fullWidth
          onClick={() => {
            borrowModal.setBorrowInput("");
            borrowModal.borrower.setBorrowAmount(null);
            borrowModal.borrower.setBorrowState(BorrowState.WAIT_FOR_INPUT);
            borrowModal.setRepayInput("");
            borrowModal.borrower.setRepayAmount(null);
            borrowModal.borrower.setRepayState(RepayState.WAIT_FOR_INPUT);
          }}
        >
          Go Back
        </Button>
      </Grid>
    </Grid>
  );
}

function Message(props: { message: string }) {
  const borrowModal = useBorrowRepayModal();
  const c = useStyles();
  const { message } = props;
  return (
    <Grid container item className={c.supplyContent} direction="column" spacing={2}>
      <Grid container item justifyContent="space-around">
        <Typography variant="body1">{message}</Typography>
      </Grid>
      <Grid container item justifyContent="space-around" xs={8}>
        <Button
          variant="outlined"
          className={c.button}
          fullWidth
          onClick={() => {
            borrowModal.setBorrowInput("");
            borrowModal.borrower.setBorrowAmount(null);
            borrowModal.borrower.setBorrowState(BorrowState.WAIT_FOR_INPUT);
            borrowModal.setRepayInput("");
            borrowModal.borrower.setRepayAmount(null);
            borrowModal.borrower.setRepayState(RepayState.WAIT_FOR_INPUT);
          }}
        >
          Go Back
        </Button>
      </Grid>
    </Grid>
  );
}

interface BorrowModalProps {
  onClose: () => void;
}

function KCompBorrowModal(props: BorrowModalProps) {
  const c = useStyles();
  const { borrower, token, t } = useBorrowRepayModal();
  const { onClose } = props;

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, value: any) => {
    borrower.setRepayState(RepayState.WAIT_FOR_INPUT);
    borrower.setBorrowState(BorrowState.WAIT_FOR_INPUT);

    setTabIndex(value);
  };

  // Decimal scaling is used to scale between the string based amount (input
  // by the user) and the shared state value (a big number with no decimals
  // that will be handed to the contracts).
  const decimalScaling = new BigNumber(10).pow(token.underlyingToken.decimals);

  const repayState = (state: RepayState) => {
    switch (state) {
      case RepayState.HIDDEN:
        setTabIndex(0);
        return <Hidden />;
      case RepayState.WAIT_FOR_INPUT:
        return <RepayWaitForInput />;
      case RepayState.WAIT_FOR_REPAY:
        return (
          <WaitForTx
            header={t("repay-modal.repay-header")}
            message={t("repay-modal.repay-wait", {
              amount: borrower.repayAmount?.dividedBy(decimalScaling).toFixed(6),
              token: token.underlyingToken.name,
            })}
          />
        );
      case RepayState.SET_UNLIMITED_APPROVAL:
        return <SetUnlimtedApproval />;
      case RepayState.WAIT_FOR_APPROVE:
        return (
          <WaitForTx
            header={t("repay-modal.repay-header")}
            message={t("repay-modal.repay-wait-approval", {
              token: token.underlyingToken.name,
            })}
          />
        );
      case RepayState.SUCCESS:
        return (
          <Success
            message={t("repay-modal.successful-repay", {
              amount: borrower.repayAmount?.dividedBy(decimalScaling).toFixed(6),
              token: token.underlyingToken.name,
            })}
          />
        );
      case RepayState.ERROR:
        return <Message message={t("repay-modal.repay-error")} />;
      case RepayState.CANCEL_APPROVE:
        return <Message message={t("repay-modal.repay-cancel")} />;
      case RepayState.CANCEL_REPAY:
        return <Message message={t("repay-modal.repay-cancel")} />;
      default:
        throw new TypeError(`non-exhaustive pattern: ${state}`);
    }
  };

  const borrowState = (state: BorrowState) => {
    switch (state) {
      case BorrowState.HIDDEN:
        return <Hidden />;
      case BorrowState.WAIT_FOR_INPUT:
        return <BorrowWaitForInput />;
      case BorrowState.SET_UNLIMITED_APPROVAL:
        return <SetUnlimtedApproval />;
      case BorrowState.WAIT_FOR_APPROVE:
        return (
          <WaitForTx
            header={t("borrow-modal.borrow-header")}
            message={t("borrow-modal.borrow-wait-approval", {
              token: token.underlyingToken.name,
            })}
          />
        );
      case BorrowState.WAIT_FOR_BORROW:
        return (
          <WaitForTx
            header={t("borrow-modal.borrow-header")}
            message={t("borrow-modal.borrow-wait", {
              amount: borrower.borrowAmount?.dividedBy(decimalScaling).toFixed(6),
              token: token.underlyingToken.name,
            })}
          />
        );
      case BorrowState.SUCCESS:
        return (
          <Success
            message={t("borrow-modal.successful-borrow", {
              amount: borrower.borrowAmount?.dividedBy(decimalScaling).toFixed(6),
              token: token.underlyingToken.name,
            })}
          />
        );
      case BorrowState.ERROR:
        return <Message message={t("borrow-modal.borrow-error")} />;
      case BorrowState.CANCEL_APPROVE:
        return <Message message={t("borrow-modal.borrow-cancel")} />;
      case BorrowState.CANCEL_BORROW:
        return <Message message={t("borrow-modal.borrow-cancel")} />;
      default:
        throw new TypeError(`non-exhaustive pattern: ${state}`);
    }
  };

  return (
    <Dialog
      classes={{ paper: c.root }}
      open={
        borrower.borrowState !== BorrowState.HIDDEN || borrower.repayState !== RepayState.HIDDEN
      }
      onClose={() => {
        onClose();
        setTabIndex(0);
        borrower.setBorrowState(BorrowState.HIDDEN);
        borrower.setRepayState(RepayState.HIDDEN);
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: { zIndex: -3 },
        timeout: 500,
      }}
    >
      <div className={c.modal}>
        <GradientBox>
          <Grid container className={c.modalContent} direction="column">
            <Grid container item className={c.tabContainer}>
              <Tabs
                classes={{
                  root: c.tabRoot,
                  indicator: c.tabIndicator,
                  flexContainer: c.tabFlexContainer,
                  scroller: c.tabScroller,
                }}
                value={tabIndex}
                onChange={handleTabChange}
              >
                <Tab
                  classes={{ root: c.tabItemRoot, wrapper: c.tabItemWrapper }}
                  disableRipple
                  label={t("borrow-modal.borrow-header")}
                />
                <Tab
                  classes={{ root: c.tabItemRoot, wrapper: c.tabItemWrapper }}
                  disableRipple
                  label={"Repay"}
                />
              </Tabs>
            </Grid>
            <Grid container className={c.modalMessage} item>
              {tabIndex === 0 ? borrowState(borrower.borrowState) : repayState(borrower.repayState)}
            </Grid>
          </Grid>
        </GradientBox>
      </div>
    </Dialog>
  );
}

export default KCompBorrowModal;
