import React from "react";
import { SvgIcon } from "@material-ui/core";
import { UnderlyingToken } from "../lib/tokens";
import { ReactComponent as UsdcIcon } from "../styles/images/Tokens/USDC.svg";
import { ReactComponent as EthIcon } from "../styles/images/Tokens/ETH.svg";
import { ReactComponent as DaiIcon } from "../styles/images/Tokens/DAI.svg";
import { ReactComponent as WethIcon } from "../styles/images/Tokens/WETH_2.svg";
import { ReactComponent as BtcIcon } from "../styles/images/Tokens/BTC.svg";

import { ReactComponent as GradUsdcIcon } from "../styles/images/Tokens/USDC_grad.svg";
import { ReactComponent as GradEthIcon } from "../styles/images/Tokens/ETH_grad.svg";
import { ReactComponent as GradDaiIcon } from "../styles/images/Tokens/DAI_grad.svg";
import { ReactComponent as GradWethIcon } from "../styles/images/Tokens/WETH_grad.svg";
import { ReactComponent as GradBtcIcon } from "../styles/images/Tokens/BTC_grad.svg";

const TOKEN_ICONS = {
  [UnderlyingToken.ETH]: <EthIcon stroke="#FFFFFF" stroke-opacity="50%" viewBox="0 0 36 36" />,
  [UnderlyingToken.WETH]: <WethIcon stroke="#FFFFFF" stroke-opacity="50%" viewBox="0 0 36 36" />,
  [UnderlyingToken.USDC]: <UsdcIcon stroke="#FFFFFF" stroke-opacity="50%" viewBox="0 0 36 36" />,
  [UnderlyingToken.DAI]: <DaiIcon stroke="#FFFFFF" stroke-opacity="50%" viewBox="0 0 36 36" />,
  [UnderlyingToken.BTC]: <BtcIcon stroke="#FFFFFF" stroke-opacity="50%" viewBox="0 0 36 36" />,
};

export default function tokenSVG({ underlyingToken, viewBox = "0 0 36 36" }): JSX.Element {
  switch (underlyingToken) {
    case UnderlyingToken.ETH:
      return (
        <EthIcon stroke="#FFFFFF" strokeOpacity="50%" viewBox={viewBox ? viewBox : "0 0 36 36"} />
      );
    case UnderlyingToken.WETH:
      return (
        <WethIcon stroke="#FFFFFF" strokeOpacity="50%" viewBox={viewBox ? viewBox : "0 0 36 36"} />
      );
    case UnderlyingToken.USDC:
      return (
        <UsdcIcon stroke="#FFFFFF" strokeOpacity="50%" viewBox={viewBox ? viewBox : "0 0 36 36"} />
      );
    case UnderlyingToken.DAI:
      return (
        <DaiIcon stroke="#FFFFFF" strokeOpacity="50%" viewBox={viewBox ? viewBox : "0 0 36 36"} />
      );
    case UnderlyingToken.BTC:
      return (
        <BtcIcon stroke="#FFFFFF" strokeOpacity="50%" viewBox={viewBox ? viewBox : "0 0 36 36"} />
      );
    default:
      return <></>;
  }
  //return TOKEN_ICONS[underlyingToken];
}

const GRADIENT_TOKEN_ICONS = {
  [UnderlyingToken.ETH]: <GradEthIcon />,
  [UnderlyingToken.WETH]: <GradWethIcon />,
  [UnderlyingToken.USDC]: <GradUsdcIcon />,
  [UnderlyingToken.DAI]: <GradDaiIcon />,
  [UnderlyingToken.BTC]: <GradBtcIcon />,
};

export function TokenGradientSVG({ underlyingToken }: { underlyingToken: UnderlyingToken }) {
  return GRADIENT_TOKEN_ICONS[underlyingToken];
}
