import compoundTokens from "../contracts/compoundTokens.json";
import { networkIdFromChainId } from "./network";

export type CompoundToken = {
  name: string;
  address: string;
  decimals: number;
};

export type CompoundTokenInfo = {
  underlyingToken: CompoundToken;
  cToken: CompoundToken;
};

const FILTER_TOKENS_IDS = [
  "0xf5dce57282a584d2746faf1593d3121fcac444dc", // SAI
  "0xc11b1268c1a384e55c48c2391d8d480264a3a7f4", // WBTC
];

export function tokenDecimals(symbol: string): number {
  switch (symbol) {
    case "AAVE":
    case "BAT":
    case "COMP":
    case "DAI":
    case "LINK":
    case "MKR":
    case "REP":
    case "SAI":
    case "SUSHI":
    case "ETH":
    case "TUSD":
    case "UNI":
    case "YFI":
    case "ZRX":
    case "FEI":
      return 18;
    case "USDC":
    case "USDT":
    case "USDP":
      return 6;
    case "WBTC":
      return 8;
    default:
      throw new TypeError("non-exhaustive pattern");
  }
}

export interface MarketSupplyInfo {
  name: string;
  symbol: string;
  id: string;
  supplyRate: string;
  supplyCompAPY: string;
  totalSupply: string;
  underlyingSymbol: string;
  address: string;
  collateralFactor: string;
  priceUSD: string;
  priceETH: string;
}

export interface CompData {
  borrow: MarketBorrowInfo[];
  supply: MarketSupplyInfo[];
}

export interface MarketBorrowInfo {
  name: string;
  symbol: string;
  id: string;
  borrowRate: string;
  borrowCompAPY: string;
  totalBorrows: string;
  underlyingSymbol: string;
  address: string;
  collateralFactor: string;
  priceUSD: string;
  priceETH: string;
  liquidity: string;
}
export interface ICompoundMarketData {
  borrowRate: string;
  cash: string;
  collateralFactor: string;
  exchangeRate: string;
  id: string;
  interestRateModelAddress: string;
  name: string;
  reserveFactor: string;
  reserves: string;
  supplyRate: string;
  symbol: string;
  totalBorrows: string;
  totalSupply: string;
  underlyingAddress: string;
  underlyingName: string;
  underlyingPrice: string;
  underlyingPriceUSD: string;
  underlyingSymbol: string;
}

export function compoundUnderlyingTokens(chain: number): CompoundToken[] {
  const network = networkIdFromChainId(chain);
  return compoundTokens[network.toString()].tokens;
}

export function cTokens(chain: number): CompoundToken[] {
  const network = networkIdFromChainId(chain);
  return compoundTokens[network.toString()].ctokens;
}

export function compoundTokenPairs(chain: number): CompoundTokenInfo[] {
  const network = networkIdFromChainId(chain);
  const tokens = compoundTokens[network.toString()].tokens;
  const cTokens = compoundTokens[network.toString()].ctokens;
  const tokenPairs = tokens.map((token) => {
    const ctoken = cTokens.find((x) => x.name === "c" + token.name);
    return { underlyingToken: token, cToken: ctoken };
  });
  return tokenPairs;
}

export function compoundChainToken(name: string, chaind: number): CompoundToken {
  const network = networkIdFromChainId(chaind);
  const tokenName = name === "ETH" ? "WETH" : name;
  const token = compoundTokens[network.toString()].tokens.find((t) => t.name === tokenName);
  if (!token) {
    throw new Error("unsupported token");
  }
  return token;
}

export function mapCompoundMarketData(data: ICompoundMarketData[]): {
  supply: MarketSupplyInfo[];
  borrow: MarketBorrowInfo[];
} {
  const supply: MarketSupplyInfo[] = [];
  const borrow: MarketBorrowInfo[] = [];

  for (let i = 0; i < data.length; i += 1) {
    const asset = data[i];

    // filter deprecated
    if (FILTER_TOKENS_IDS.includes(asset.id)) continue;

    const supplyData = {
      name: asset.underlyingName,
      symbol: asset.symbol,
      id: asset.id,
      supplyRate: asset.supplyRate,
      supplyCompAPY: "0",
      totalSupply: asset.totalSupply,
      underlyingSymbol: asset.underlyingSymbol,
      address: asset.underlyingAddress,
      collateralFactor: asset.collateralFactor,
      priceUSD: asset.underlyingPriceUSD,
      priceETH: "0",
    };

    const borrowData = {
      name: asset.underlyingName,
      symbol: asset.symbol,
      id: asset.id,
      borrowRate: asset.borrowRate,
      borrowCompAPY: "0",
      totalBorrows: asset.totalBorrows,
      underlyingSymbol: asset.underlyingSymbol,
      address: asset.underlyingAddress,
      collateralFactor: asset.collateralFactor,
      priceUSD: asset.underlyingPriceUSD,
      priceETH: "0",
      liquidity: "0",
    };

    supply.push(supplyData);
    borrow.push(borrowData);
  }

  const supplySorted = supply.sort((a, b) => (a.underlyingSymbol > b.underlyingSymbol ? 1 : -1));
  const borrowSorted = borrow.sort((a, b) => (a.underlyingSymbol > b.underlyingSymbol ? 1 : -1));

  return {
    supply: supplySorted,
    borrow: borrowSorted,
  };
}
