import { createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: "#141118",
    },
    secondary: {
      main: "#17171C",
    },
    action: {
      hover: "#6C46D6",
      selected: "#562CC9",
      focus: "#552BC8",
      disabled: "#D5D5D5",
      active: "#09AF73",
    },
    background: { default: "#141118" },
    success: { main: "#09AF73" },
    warning: { main: "#F9A606" },
    error: { main: "#E62B0F" },
    text: {
      primary: "#F5F5F5",
      secondary: "#8F8F8F",
    },
  },
  typography: {
    fontFamily: ["Roboto", "Helvetica", "Arial", "Aleo", "Inter", "sans-serif"].join(","),
    // body2: {
    //     fontFamily: 'Inter',
    //     fontStyle: 'normal',
    //     fontWeight: 'normal',
    //     fontSize: '16px',
    //     lineHeight: '19px',
    //     color: '#8F8F8F',
    // },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

export default theme;
