import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  LinearProgress,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MoonLoader from "react-spinners/MoonLoader";
import { StyledModal } from "..";
import { contract, ContractType } from "../../../lib/contracts";
import { networkIdFromChainId } from "../../../lib/network";
import {
  ETH,
  getHidingBookInfo,
  HidingBookToken,
  MakerOrderV4,
  OrderDetails,
  OrderStatus,
  submitSoftCancelOrder,
} from "../../../lib/trade";
import { createCancelOrderSig } from "../../../lib/web3";
import { useUserOrders } from "../../../services/hidingBook";
import TokenLogo from "../../TokenLogo";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "none",
    border: "none",
    position: "absolute",
  },
  modal: {
    position: "relative",
    display: "flex",
    flex: "1 1 0",
    width: "375px",
    height: "600px",
  },
  modalContent: {
    display: "flex",
    flex: "1 1 0",
    position: "relative",
    height: "100%",
    width: "100%",
    padding: "24px",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-start",
    overflow: "auto",
  },
  reviewContainer: {
    display: "flex",
    position: "relative",
    height: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  loadingContainer: {
    display: "flex",
    flex: "1 1 0",
    position: "relative",
    height: "100%",
    width: "100%",
    padding: "8px",
    justifyContent: "center",
    alignItems: "center",
  },
  noOrders: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    justifyContent: "center",
    borderRadius: "16px",
    border: "1px dashed #525252",
    alignItems: "center",
    marginTop: "24px",
    color: theme.palette.text.secondary,
    padding: "16px",
    height: "343px",
    width: "100%",
  },
  messageRow: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "8px",
    marginBottom: "8px",
  },
  buttonRow: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "8px",
    marginBottom: "8px",
  },
  cancelButton: {
    borderRadius: "8px",
    //width: '100%',
    //height: '100%',
  },
  submitButton: {
    //marginTop: '20px',
    borderRadius: "8px",
    width: "100%",
    height: "100%",
    //height: '56px',
  },
  ordersList: {
    display: "flex",
    flex: "1 1 0",
    position: "relative",
    width: "100%",
    minHeight: "200px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "16px",
    //overflow: 'auto',
    paddingTop: "20px",
  },
  accordionRoot: {
    background: "none",
    border: "none",
    borderRadius: "16px",
    boxShadow: "none",
    padding: 0,
    margin: 0,
    width: "100%",
  },
  accordionExpanded: {
    backgroundColor: "rgba(245,245,245,0.08)",
    borderRadius: "16px !important",
  },
  orderSummary: {
    boxShadow: "none",
    borderRadius: "16px",
    paddingLeft: "12px",
    paddingRight: "12px",
    margin: 0,
    border: "none",
    "&:hover": {
      backgroundColor: "rgba(245,245,245,0.08)",
    },
  },
  tokenAmountText: {
    color: theme.palette.text.secondary,
  },
  divider: {
    background: "linear-gradient(to right, #6C46D6 0%, #09A7F3 100%)",
    opacity: "0.24",
    marginTop: "8px",
    marginBottom: "8px",
  },
  filledBar: {
    backgroundColor: "#09A7F3",
  },
  tabItemRoot: {
    "&:hover": {
      opacity: 1,
    },
    minHeight: 44,
    minWidth: 96,
    [theme.breakpoints.up("md")]: {
      minWidth: 120,
    },
  },
  tabItemWrapper: {
    // zIndex: 2,
    // marginTop: spacing(0.5),
    color: theme.palette.text.primary,
    textTransform: "initial",
  },
  tabRoot: {
    backgroundColor: "rgba(245, 245, 245, 0.08)",
    borderRadius: 16,
    minHeight: 44,
  },
  tabFlexContainer: {
    display: "inline-flex",
    position: "relative",
    zIndex: 1,
  },
  tabScroller: {
    [theme.breakpoints.up("md")]: {
      padding: "0px 8px",
    },
  },
  tabIndicator: {
    top: 3,
    bottom: 3,
    right: 3,
    height: "auto",
    background: "none",
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 4,
      left: 4,
      right: 4,
      bottom: 4,
      borderRadius: 8,
      backgroundColor: theme.palette.action.hover,
      boxShadow: "0 4px 12px 0 rgba(0,0,0,0.16)",
    },
  },
}));

const printOrderStatus = {
  [OrderStatus.CANCELLED]: "Cancelled",
  [OrderStatus.EXPIRED]: "Expired",
  [OrderStatus.FILLABLE]: "Open",
  [OrderStatus.FILLED]: "Filled",
  [OrderStatus.INVALID]: "Invalid",
};

enum CancelState {
  Hidden,
  WarningHard,
  WarningSoft,
  Process,
  Success,
  Failure,
}

interface Props {
  address: string;
  closeModal: () => void;
  active: boolean;
}

const RDQ_ORDER_KEYS = [
  "makerToken",
  "takerToken",
  "makerAmount",
  "takerAmount",
  "maker",
  "taker",
  "txOrigin",
  "pool",
  "expiry",
  "salt",
];

function createExchangeOrderTuple(order: MakerOrderV4) {
  const orderTuple = RDQ_ORDER_KEYS.map((k) => {
    if (["salt", "makerAmount", "takerAmount"].includes(k)) {
      return new BigNumber(order.order[k]).toString();
      // return BigInt(order.order[k]).toString();
    }
    return order.order[k];
  });
  return orderTuple;
}

export default function OrderHistoryModal({ address, closeModal, active }: Props) {
  const c = useStyles();
  const { t } = useTranslation();
  const { account, library, chainId, connector } = useWeb3React();
  const { data: orders, isLoading, isError } = useUserOrders(address);

  const [orderDetails, setOrderdetails] = useState<OrderDetails | undefined>(undefined);
  const [tokenList, setTokenList] = useState<HidingBookToken[]>([]);
  const [tokensByAddress, setTokensByAddress] = useState({});
  const [expanded, setExpanded] = React.useState<number | false>(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [cancelState, setCancelState] = useState(CancelState.Hidden);
  const [order, setOrder] = useState<MakerOrderV4>();
  const [errorMsg, setErrorMsg] = useState("");

  const handleTabChange = (event, value) => {
    setTabIndex(value);
    setExpanded(false);
  };

  const onCancelError = (errMsg: string) => {
    setErrorMsg(errMsg);
    setCancelState(CancelState.Failure);
  };

  const handleChange =
    (order: MakerOrderV4, panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      setOrder(order);
      setCancelState(CancelState.Hidden);
    };

  const displayTokenAmount = (token: HidingBookToken, amount: string) => {
    const decimalScaling = new BigNumber(10).pow(token.decimals);
    let balance = new BigNumber(amount).dividedBy(decimalScaling).toFixed(6);
    balance = balance.length > 9 ? balance.substr(0, 9) + ".." : balance;
    return balance;
  };

  const getLimitPrice = (order) => {
    const makerToken = tokensByAddress[order.makerToken];
    const takerToken = tokensByAddress[order.takerToken];

    const makerDecimals = new BigNumber(10).pow(makerToken.decimals);
    const takerDecimals = new BigNumber(10).pow(takerToken.decimals);

    const maker = new BigNumber(order.makerAmount).dividedBy(makerDecimals);
    const taker = new BigNumber(order.takerAmount).dividedBy(takerDecimals);

    return taker.dividedBy(maker).toPrecision(4);
  };

  const printExpiry = (expiry) => {
    const now = Date.now() / 1000;

    let seconds = Math.abs(expiry - now);
    //console.log('seconds', seconds);
    const dateString = new Date(expiry).toDateString().split(" ").slice(0, 3).join(" ");

    const days = Math.floor(seconds / 86400);
    seconds -= days * 86400;
    const hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    const minutes = Math.round(seconds / 60);

    const expiryString = `${days ? `${days} day` : ""}${days > 1 ? "s" : ""}${days ? " " : ""}${
      hours ? `${hours} hour` : ""
    }${hours > 1 ? "s" : ""}${hours ? " " : ""}${minutes ? `${minutes} minute` : ""}${
      minutes > 1 ? "s" : ""
    }${!minutes && seconds < 60 ? "< 1 minute" : ""}${expiry < now ? " ago" : ""}`;
    //seconds = (expiry - Date.now()) / 1000;
    //console.log('expirystring', expiryString);
    return seconds < 86400 ? `${expiryString}` : `${dateString} (${expiryString})`;
  };

  useEffect(() => {
    getHidingBookInfo().then(({ orderDetails, tokenList }) => {
      setOrderdetails(orderDetails);
      setTokenList([ETH, ...tokenList.tokens]);
      const tokens = tokenList.tokens.reduce((acc, elem) => {
        acc[elem.address] = elem;
        return acc;
      }, {});
      setTokensByAddress(tokens);
    });
  }, [account]);

  const processSoftCancel = async () => {
    try {
      if (!chainId || !library || !account || !order) {
        return;
      }

      setCancelState(CancelState.Process);
      const { orderHash } = order.metaData;
      const msgSig = await createCancelOrderSig(orderHash, library, account);
      const message = await submitSoftCancelOrder({
        orderHash: orderHash,
        signature: msgSig,
      });
      setCancelState(CancelState.Success);
    } catch (err) {
      console.log(err);
      onCancelError(JSON.stringify(err));
      return;
    }
  };

  const processHardCancel = async () => {
    try {
      if (!chainId || !library || !account || !order) {
        onCancelError("Wallet is not connected or order does not exist");
        return;
      }

      setCancelState(CancelState.Process);
      const orderTuple = createExchangeOrderTuple(order);
      if (process.env.NODE_ENV === "development") {
        console.log(orderTuple);
      }
      const exchangeContract = contract(
        library,
        networkIdFromChainId(chainId),
        ContractType.ZrxV4Exchange,
        account
      );
      await exchangeContract.methods
        .cancelRfqOrder(orderTuple)
        .send({ from: account })
        .on("receipt", async (receipt) => {
          setCancelState(CancelState.Success);
          return;
        })
        .on("error", (error, receipt) => {
          console.log(error);
          onCancelError(JSON.stringify(error));
          return;
        });
    } catch (err) {
      console.log(err);
      onCancelError(JSON.stringify(err));
      return;
    }
  };

  const WarningHard = () => {
    return (
      <Grid container item className={c.loadingContainer}>
        <Grid item className={c.messageRow}>
          <Typography align="center" variant="h5">
            {t("tx-history-modal-cancel")}
          </Typography>
        </Grid>

        <Grid item className={c.messageRow}>
          <Typography variant="body1">{t("tx-history-modal-sure")}</Typography>
        </Grid>

        <Grid container item className={c.buttonRow} justifyContent="space-between">
          <Grid item style={{ position: "relative", width: "49%" }}>
            <Button
              fullWidth
              variant="outlined"
              style={{ borderRadius: "8px" }}
              onClick={() => {
                setCancelState(CancelState.Hidden);
                setExpanded(false);
              }}
            >
              Close
            </Button>
          </Grid>
          <Grid item style={{ position: "relative", width: "49%" }}>
            <Button
              fullWidth
              variant="contained"
              style={{ borderRadius: "8px" }}
              onClick={processHardCancel}
            >
              {t("tx-history-modal-cancel")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const WarningSoft = () => {
    return (
      <div className="inner">
        <h2>{"Soft Cancel Order"}</h2>
        <p>
          'Cancel an order without paying gas. This actions informs all keepers that you wish for
          this order to not be filled.'
        </p>

        <Grid container item>
          <Button variant="text" onClick={() => setCancelState(CancelState.Hidden)}>
            CLOSE
          </Button>
          <Button variant="contained" onClick={processSoftCancel}>
            CANCEL ORDER
          </Button>
        </Grid>
      </div>
    );
  };

  const Process = () => {
    return (
      <Grid container item className={c.loadingContainer} direction="column">
        <Grid item className={c.messageRow}>
          <Typography align="center" variant="h5">
            {"Canceling Order.."}
          </Typography>
        </Grid>
        <Grid container item className={c.messageRow} justifyContent="space-around">
          <MoonLoader color="#6D47D7" loading={true} size={50} />
        </Grid>
      </Grid>
    );
  };

  const Failure = () => {
    return (
      <Grid container item className={c.loadingContainer}>
        <Grid item className={c.messageRow}>
          <Typography align="center" variant="h5">
            {"Error"}
          </Typography>
        </Grid>

        <Grid item className={c.messageRow}>
          <Typography align="center" variant="body1">
            {errorMsg}
          </Typography>
        </Grid>

        <Grid container item className={c.messageRow} justifyContent="space-between">
          <Button
            className={c.cancelButton}
            variant="contained"
            fullWidth
            onClick={() => setCancelState(CancelState.Hidden)}
          >
            Go Back
          </Button>
        </Grid>
      </Grid>
    );
  };

  const Success = () => {
    return (
      <Grid container item className={c.loadingContainer}>
        <Grid item className={c.messageRow}>
          <Typography align="center" variant="h5">
            {"Success!"}
          </Typography>
        </Grid>

        <Grid item className={c.messageRow}>
          <Typography align="center" variant="body1">
            {"The order is canceled"}
          </Typography>
        </Grid>

        <Grid container item className={c.messageRow} justifyContent="space-between">
          <Button
            className={c.cancelButton}
            variant="contained"
            fullWidth
            onClick={() => setCancelState(CancelState.Hidden)}
          >
            Go Back
          </Button>
        </Grid>
      </Grid>
    );
  };

  const modalContentState = (state: CancelState) => {
    switch (state) {
      case CancelState.WarningHard:
        return WarningHard();
      case CancelState.WarningSoft:
        return WarningSoft();
      case CancelState.Process:
        return Process();
      case CancelState.Success:
        return Success();
      case CancelState.Failure:
        return Failure();
      default:
        return Failure();
    }
  };

  const OrderAccordian = ({ order, idx, open }) => {
    const makerToken = tokensByAddress[order.order.makerToken];
    const takerToken = tokensByAddress[order.order.takerToken];

    if (!makerToken || !takerToken) {
      return null;
    }

    const fillPercentage = (
      (100 * order.metaData.filledAmount_takerToken) /
      parseFloat(order.order.takerAmount)
    ).toPrecision(4);

    return (
      <Accordion
        classes={{ root: c.accordionRoot, expanded: c.accordionExpanded }}
        key={idx}
        id={idx.toString()}
        expanded={expanded === idx}
        onChange={handleChange(order, idx)}
      >
        <AccordionSummary className={c.orderSummary}>
          <Grid container item direction="row" alignItems="center" justifyContent="space-between">
            <Grid item container xs={1} alignItems="center">
              <TokenLogo chainId={chainId} address={order.order.makerToken} size={24} />
            </Grid>

            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              xs={4}
              style={{ paddingLeft: "8px", paddingRight: "8px" }}
            >
              <Typography variant="h6">{makerToken.symbol}</Typography>
              <Typography variant="body2" classes={{ root: c.tokenAmountText }}>
                {displayTokenAmount(makerToken, order.order.makerAmount)}
              </Typography>
            </Grid>

            <Grid container item xs={2} justifyContent="center">
              <ArrowForward />
            </Grid>

            <Grid container item xs={1} alignItems="center">
              <TokenLogo chainId={chainId} address={order.order.takerToken} size={24} />
            </Grid>

            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              xs={4}
              style={{ paddingLeft: "8px", paddingRight: "8px" }}
            >
              <Typography variant="h6">{takerToken.symbol}</Typography>
              <Typography variant="body2" classes={{ root: c.tokenAmountText }}>
                {displayTokenAmount(takerToken, order.order.takerAmount)}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          {cancelState === CancelState.Hidden ? (
            <Grid container item direction="column" spacing={1}>
              <Grid container item direction="row" justifyContent="space-between">
                <Typography variant="body1">{t("tx-history-modal-rate")}</Typography>
                <Typography variant="body1" classes={{ root: c.tokenAmountText }}>
                  {`1 ${makerToken.symbol} = ${getLimitPrice(order.order)} ${takerToken.symbol}`}
                </Typography>
              </Grid>
              <Divider className={c.divider} />
              <Grid container item direction="row" justifyContent="space-between">
                <Typography variant="body1">{t("tx-history-modal-expiry")}</Typography>
                <Typography variant="body1" classes={{ root: c.tokenAmountText }}>
                  {printExpiry(order.order.expiry)}
                </Typography>
              </Grid>
              <Divider className={c.divider} />
              <Grid container item direction="row" justifyContent="space-between">
                <Typography variant="body1">Filled</Typography>
                <Typography variant="body1" classes={{ root: c.tokenAmountText }}>
                  {fillPercentage}%
                </Typography>
              </Grid>
              <LinearProgress
                classes={{ barColorPrimary: c.filledBar }}
                style={{
                  marginTop: "4px",
                  marginBottom: "12px",
                  borderRadius: "2px",
                }}
                variant="determinate"
                value={
                  (order.metaData.filledAmount_takerToken / parseInt(order.order.takerAmount)) * 100
                }
              />
              {open && (
                <Grid container item justifyContent="space-around" style={{ paddingTop: "8px" }}>
                  {/* <Button
                  variant="outlined"
                  style={{ borderRadius: "8px" }}
                  onClick={() => setCancelState(CancelState.WarningSoft)}
                >
                  Soft Cancel
                </Button> */}
                  <Button
                    variant="contained"
                    style={{ borderRadius: "8px" }}
                    onClick={() => setCancelState(CancelState.WarningHard)}
                  >
                    {t("tx-history-modal-cancel")}
                  </Button>
                </Grid>
              )}
            </Grid>
          ) : (
            modalContentState(cancelState)
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  function OpenOrdersList() {
    if (isLoading) {
      return (
        <Grid container item className={c.ordersList}>
          <CircularProgress />
        </Grid>
      );
    }
    const filtered = orders.filter((order) => order.metaData.status === OrderStatus.FILLABLE);
    return filtered.length > 0 ? (
      <Grid container item className={c.ordersList}>
        {filtered.map((order, idx) => (
          <OrderAccordian order={order} idx={idx} open={true} key={idx} />
        ))}
      </Grid>
    ) : (
      <Grid container item className={c.noOrders}>
        <Typography variant="h4" align="center">
          You have no open orders
        </Typography>
      </Grid>
    );
  }

  function OrderHistoryList() {
    const filtered = orders.filter((order) => order.metaData.status !== OrderStatus.FILLABLE);
    return filtered.length > 0 ? (
      <Grid container item className={c.ordersList}>
        {filtered.map((order, idx) => (
          <OrderAccordian order={order} idx={idx} open={false} key={idx} />
        ))}
      </Grid>
    ) : (
      <Grid container item className={c.noOrders}>
        <Typography align="center" variant="h4">
          You have no closed orders
        </Typography>
      </Grid>
    );
  }

  if (!active || !address) {
    return null;
  }

  return (
    <Dialog
      classes={{ paper: c.root }}
      open={active}
      onClose={closeModal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: { zIndex: -3 },
        timeout: 500,
      }}
    >
      <div className={c.modal}>
        <StyledModal>
          <Grid container className={c.modalContent}>
            <Tabs
              classes={{
                root: c.tabRoot,
                indicator: c.tabIndicator,
                flexContainer: c.tabFlexContainer,
                scroller: c.tabScroller,
              }}
              value={tabIndex}
              onChange={handleTabChange}
            >
              <Tab
                classes={{ root: c.tabItemRoot, wrapper: c.tabItemWrapper }}
                disableRipple
                label={t("tx-history-modal-open")}
              />
              <Tab
                classes={{ root: c.tabItemRoot, wrapper: c.tabItemWrapper }}
                disableRipple
                label={t("tx-history-modal-history")}
              />
            </Tabs>
            <Grid container item>
              {tabIndex === 0 ? OpenOrdersList() : OrderHistoryList()}
            </Grid>
          </Grid>
        </StyledModal>
      </div>
    </Dialog>
  );
}
