/* eslint-disable react/jsx-key */
import React, { useMemo, useEffect, useState } from "react";
import { UnderlyingToken, UnderlyingTokens, Token, tokenBalanceWithDecimals } from "../lib/tokens";
import BarLoader from "react-spinners/BarLoader";
import Liquidity from "../containers/liquidity";
import ExchangeRates from "../containers/exchangeRates";
import BigNumber from "bignumber.js";
import { calculateUserBalance } from "../lib/utils";
import { useTranslation } from "react-i18next";
import { useWeb3React } from "@web3-react/core";
import PoolsGrid from "./PoolsGrid";
import APYs from "../containers/apys";
import millify from "millify";

type IBalances<T> = { [key in keyof typeof UnderlyingToken | string]: T };

function makeFieldsForPoolsGrid(
  exchange?: IBalances<BigNumber> | null,
  tokenLiquidity?: IBalances<BigNumber> | null,
  balances?: IBalances<BigNumber> | null,
  apys?: IBalances<string> | null
) {
  return [
    ...UnderlyingTokens.map((token) => {
      const liquidity =
        tokenLiquidity &&
        tokenLiquidity[UnderlyingToken[token]] &&
        exchange &&
        exchange[UnderlyingToken[token]] ? (
          "$" +
          millify(
            tokenLiquidity[UnderlyingToken[token]]
              .multipliedBy(exchange[UnderlyingToken[token]])
              .toNumber(),
            {
              precision: 0,
            }
          ).toString()
        ) : (
          <BarLoader color="#354468" loading={true} width={40} />
        );
      const balance =
        balances && balances[UnderlyingToken[token]] ? (
          balances[UnderlyingToken[token]].toFormat() // balances[UnderlyingToken[token]].toFormat(4, BigNumber.ROUND_DOWN)
        ) : (
          <BarLoader color="#354468" loading={true} width={40} />
        );

      const apy =
        apys && apys[token] ? apys[token] : <BarLoader color="#354468" loading={true} width={40} />;
      return {
        apy: apy,
        liquidity: liquidity,
        balance: balance,
        token,
      };
    }),
  ];
}

const PoolsGridWrapper: React.FC = () => {
  const { t } = useTranslation();

  const fields = useMemo(() => {
    return [
      {
        fieldName: t("table-pool.apy").toUpperCase(),
        accessor: "apy",
      },
      {
        fieldName: t("table-pool.liquidity").toUpperCase(),
        accessor: "liquidity",
      },
      {
        fieldName: t("table-pool.balance").toUpperCase(),
        accessor: "balance",
      },
    ];
  }, [t]);
  const [data, setData] = useState(() => makeFieldsForPoolsGrid());
  const liquidity = Liquidity.useContainer();
  const exchange = ExchangeRates.useContainer();
  const poolAPYs = APYs.useContainer();

  useEffect(() => {
    if (!(exchange[UnderlyingToken[UnderlyingToken.ETH]].toNumber() > 0)) {
      return;
    }

    if (poolAPYs[UnderlyingToken.ETH] === "0") {
      return;
    }

    const poolLiq: IBalances<BigNumber> = {
      ETH: tokenBalanceWithDecimals(Token.ETH, liquidity.ethPoolBalance),
      WETH: tokenBalanceWithDecimals(Token.WETH, liquidity.wethPoolBalance),
      DAI: tokenBalanceWithDecimals(Token.DAI, liquidity.daiPoolBalance),
      USDC: tokenBalanceWithDecimals(Token.USDC, liquidity.usdcPoolBalance),
      BTC: tokenBalanceWithDecimals(Token.BTC, liquidity.btcPoolBalance),
    };

    const balances: IBalances<BigNumber> = {
      ETH: calculateUserBalance(
        Token.ETH,
        liquidity.kethBalance,
        liquidity.ethPoolBalance,
        liquidity.kethSupply
      ),
      WETH: calculateUserBalance(
        Token.WETH,
        liquidity.kwethBalance,
        liquidity.wethPoolBalance,
        liquidity.kwethSupply
      ),
      DAI: calculateUserBalance(
        Token.DAI,
        liquidity.kdaiBalance,
        liquidity.daiPoolBalance,
        liquidity.kdaiSupply
      ),
      USDC: calculateUserBalance(
        Token.USDC,
        liquidity.kusdcBalance,
        liquidity.usdcPoolBalance,
        liquidity.kusdcSupply
      ),
      BTC: calculateUserBalance(
        Token.BTC,
        liquidity.kbtcBalance,
        liquidity.btcPoolBalance,
        liquidity.kbtcSupply
      ),
    };

    const data = makeFieldsForPoolsGrid(exchange, poolLiq, balances, poolAPYs);
    setData(data);
  }, [exchange, poolAPYs, liquidity]);

  return <PoolsGrid data={data} fields={fields} />;
};

export default PoolsGridWrapper;
