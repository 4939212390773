import { Button, Grid, Icon, makeStyles, Typography } from "@material-ui/core";
import { ArrowRightAlt } from "@material-ui/icons";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import MoonLoader from "react-spinners/MoonLoader";
import { ExternalLinkIcon } from "../../components/Icons";
import WalletCreateModal from "../../components/modals/compound/CompoundWalletCreateModal";
import { GradientBox } from "../../components/ui-components/StyledBox";
import { GlobalVaultsState, VaultCreateOptions } from "../../containers/borrow";
import { ReactComponent as VaultIcon } from "../../styles/images/Menu Icons/Vault.svg";

const useStyles = makeStyles((theme) => ({
  vaultContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "auto",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "24px",
    width: "100%",
    height: "100%",
  },
  vaultHeader: {
    display: "flex",
    flex: "1 1 auto",
    position: "relative",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "8px",
    maxHeight: "40%",
  },
  vaultContent: {
    display: "flex",
    position: "relative",
    flex: "1 1 auto",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
    height: "100%",
    width: "100%",
  },
  vaultHeaderText: {
    color: theme.palette.text.primary,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    [theme.breakpoints.up("md")]: {
      fontSize: "32px",
      lineHeight: "39px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "30px",
    },
  },
  docsLink: {
    textTransform: "none",
    alignContent: "center",
    paddingLeft: "0px",
    "&:hover": {
      background: "none",
      textDecoration: "underline",
    },
  },
  noVaults: {
    display: "flex",
    position: "relative",
    justifyContent: "space-beteen",
    borderRadius: "16px",
    border: "1px dashed #525252",
    alignItems: "center",
    color: theme.palette.text.secondary,
    paddingLeft: "30%",
    paddingRight: "30%",
    paddingTop: "5%",
    paddingBottom: "5%",
    height: "100%",
    width: "100%",
  },
  noVaultHeaderText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "2em",
    textAlign: "center",
    color: "#525252",
  },
  noVaultSubtext: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "1.1vw",
    textAlign: "center",
    color: "#525252",
  },
  totalFundsContainer: {
    display: "flex",
    flex: "0 1 0",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "10px",
    borderRadius: "16px",
    //background: 'linear-gradient(to right, #6C46D6 0%, #09A7F3 100%)',
  },
  totalFundsContent: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    width: "100%",
    height: "100%",
    borderRadius: "16px",
    backgroundColor: "rgba(245,245,245,0.08)",
    //background: 'linear-gradient(to right, #6C46D6 0%, #09A7F3 100%)',
  },
  totalFundsText: {
    color: theme.palette.text.primary,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    [theme.breakpoints.up("md")]: {
      fontSize: "30px",
      lineHeight: "32px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "23px",
      lineHeight: "24px",
    },
  },
  totalFundsSubtext: {
    color: theme.palette.text.primary,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 300,
    [theme.breakpoints.up("md")]: {
      fontSize: "28px",
      lineHeight: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "21px",
      lineHeight: "23px",
    },
  },
  createVaultButton: {
    borderRadius: "8px",
    fontSize: "1.1vw",
    marginTop: "8px",
  },
  importButton: {
    textTransform: "none",
    alignContent: "center",
    paddingLeft: "0px",
    fontSize: "1vw",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    "&:hover": {
      background: "none",
      textDecoration: "underline",
    },
  },
}));

interface CreateVaultProps {
  loading: boolean;
  globalVaults: GlobalVaultsState;
  openWalletCreateModal: (
    option: VaultCreateOptions,
    migrateOnly: boolean,
    posAddress?: string
  ) => void;
  updateVault: () => void;
}

function CreateVault(props: CreateVaultProps): React.ReactElement {
  const c = useStyles();
  const { t } = useTranslation();
  const { loading, globalVaults, openWalletCreateModal, updateVault } = props;
  const supplyAmount = globalVaults.collateralUSD.toFormat(0);
  const numVaults = globalVaults.numVaults;

  return (
    <GradientBox>
      <Grid container item className={c.vaultContainer} direction="column">
        <Grid container item className={c.vaultHeader}>
          <Grid container item direction="row" xs={12}>
            <Typography
              variant="h4"
              classes={{ h4: c.vaultHeaderText }}
              style={{ marginRight: "7px" }}
            >
              {t("vaults-header")}
            </Typography>
          </Grid>
          <Button
            className={c.docsLink}
            endIcon={
              <Icon fontSize="large">
                <ExternalLinkIcon />
              </Icon>
            }
            href="https://snapshot.org/#/rook.eth/proposal/0xfd05c73beb9b02f4840604fd12f91411c11004658fea74919ee0251288951116"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("sunset-link")}
          </Button>
        </Grid>
        {loading ? null : (
          <Grid container item className={c.totalFundsContainer}>
            <Grid container item className={c.totalFundsContent} direction="row">
              <Grid item style={{ marginRight: "12px" }}>
                <Typography variant="h5" align="center" classes={{ h5: c.totalFundsSubtext }}>
                  <Trans i18nKey="vault-supply">
                    $<span className={c.totalFundsText}>{{ supplyAmount }}</span> in total assets
                    protected across
                    <span className={c.totalFundsText}>{{ numVaults }}</span> Vaults
                  </Trans>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container item className={c.vaultContent}>
          {loading ? (
            <Grid
              container
              item
              className={c.noVaults}
              direction="column"
              justifyContent="space-between"
            >
              <Grid item>
                <MoonLoader color="#6D47D7" loading={true} size={50} />
              </Grid>
              <Grid item>
                <Typography classes={{ root: c.noVaultHeaderText }}>Loading Vaults</Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              item
              className={c.noVaults}
              direction="column"
              justifyContent="space-between"
            >
              <Grid item>
                <VaultIcon fill="#525252" height="6vh" width="4.5vw" />
              </Grid>
              <Grid item>
                <Typography classes={{ root: c.noVaultHeaderText }}>
                  {t("vault-no-vaults")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography classes={{ root: c.noVaultSubtext }}>
                  {t("vault-create-import")}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  disabled
                  variant="contained"
                  fullWidth
                  className={c.createVaultButton}
                  endIcon={<ArrowRightAlt />}
                  onClick={() => openWalletCreateModal(VaultCreateOptions.CREATE, false)}
                >
                  Create Vault
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled
                  variant="text"
                  className={c.importButton}
                  onClick={() => openWalletCreateModal(VaultCreateOptions.MIGRATE, false)}
                >
                  {t("borrow-import-from-compound")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <WalletCreateModal onClose={updateVault} />
    </GradientBox>
  );
}

export default CreateVault;
