import { ApolloProvider } from "@apollo/react-hooks";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import { useWeb3React } from "@web3-react/core";
import { Compose } from "provider-compose";
import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import AppSettings from "../containers/appSettings";
import APYs from "../containers/apys";
import Balances from "../containers/balances";
import {
  Borrower,
  BorrowRepay,
  Repay,
  Supplier,
  SupplyWithdraw,
  TransferPosition,
  WalletCreate,
  Withdraw,
} from "../containers/borrow";
import CompoundData from "../containers/compound";
import Depositor from "../containers/depositor";
import ExchangeRates from "../containers/exchangeRates";
import GraphInfo from "../containers/graphInfo";
import Liquidity from "../containers/liquidity";
import { RewardsBalances } from "../containers/rookBalances";
import Wallet from "../containers/wallet";
import Withdrawer from "../containers/withdrawer";
import { kovanClient, mainnetClient } from "../lib/graphql";
import { NetworkID, networkIdFromChainId } from "../lib/network";
import { IProps } from "../lib/types";
import theme from "../styles/theme";
import AppWrapper from "./AppWrapper";
import { Web3Provider, Web3ProviderNetwork } from "./Web3Provider";

const GlobalCss = withStyles({
  "@global": {
    ".MuiButton-contained": {
      color: theme.palette.text.primary,
      // rgb(109, 71, 215), rgb(215, 71, 90)
      //backgroundImage: 'linear-gradient(to right, #6D47D7 0%, #D7475A 50%, #6D47D7 100%)',
      // rgb(108, 70, 214), rgb(9, 167, 243)
      backgroundImage: "linear-gradient(to right, #6C46D6 0%, #09A7F3 50%, #6C46D6 100%)",
      backgroundSize: "200%",
      transition: "0.25s",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "1.1em",
      letterSpacing: "0em",
      textTransform: "none",
      "&:hover": {
        backgroundPosition: "right center",
      },
      "&:active": {
        //rgb(86, 44, 201), rgb(201, 44, 65)
        //backgroundImage: 'linear-gradient(to right, #562CC9 0%, #C92C41 50%, #562CC9 100%)',
        //rgb(85, 43, 200), rgb(9, 147, 220)
        backgroundImage: "linear-gradient(to right, #552BC8 0%, #0993DC 50%, #552BC8 100%)",
      },
      "&:disabled": {
        background: "#525252",
      },
    },
    ".MuiButton-outlined": {
      color: theme.palette.text.primary,
      background: "none",
      border: "1px solid",
      borderColor: theme.palette.text.primary,
      transition: "0.25s",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "1.1em",
      letterSpacing: "0em",
      textTransform: "none",
      "&:hover": {
        borderColor: theme.palette.action.hover,
        color: theme.palette.action.hover,
      },
      "&:active": {
        backgroundColor: theme.palette.action.selected,
        borderColor: theme.palette.action.selected,
        color: theme.palette.text.primary,
      },
      "&:disabled": {
        borderColor: "#525252",
        color: "#525252",
      },
    },
    ".MuiBackdrop-root": {
      background: "rgb(15,15,15,0.75)",
    },
    ".MuiListItem-button": {
      "&:hover": {
        color: theme.palette.action.hover,
        background: "none",
      },
      "&:active": {
        color: theme.palette.action.hover,
        background: "none",
      },
      "&:selected": {
        color: theme.palette.action.hover,
        background: "none",
      },
    },
    ".MuiTableRow-root": {
      border: "none",
      backgroundColor: theme.palette.background.default,
    },
    ".MuiTableCell-root": {
      borderBottom: "none",
    },
  },
})(() => null);

function GraphProvider({ children }: IProps) {
  const { chainId } = useWeb3React();
  const networkId = networkIdFromChainId(chainId);

  const client = (() => {
    switch (networkId) {
      case NetworkID.Mainnet:
        return mainnetClient;
      case NetworkID.Kovan:
        return kovanClient;
      default:
        return mainnetClient;
    }
  })();

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

const store = [
  GraphInfo.Provider,
  AppSettings.Provider,
  Wallet.Provider,
  Balances.Provider,
  RewardsBalances.Provider,
  Depositor.Provider,
  Withdrawer.Provider,
  Borrower.Provider,
  Supplier.Provider,
  Repay.Provider,
  Withdraw.Provider,
  SupplyWithdraw.Provider,
  BorrowRepay.Provider,
  CompoundData.Provider,
  WalletCreate.Provider,
  TransferPosition.Provider,
  Liquidity.Provider,
  ExchangeRates.Provider,
  APYs.Provider,
];

function ContextProviders({ children }: IProps) {
  return (
    <Web3Provider>
      <Web3ProviderNetwork>
        <GraphProvider>
          <Compose providers={store}>{children}</Compose>
        </GraphProvider>
      </Web3ProviderNetwork>
    </Web3Provider>
  );
}

export default function App(): JSX.Element {
  return (
    <ContextProviders>
      <BrowserRouter basename="/">
        <ThemeProvider theme={theme}>
          <GlobalCss />
          <AppWrapper />
        </ThemeProvider>
      </BrowserRouter>
    </ContextProviders>
  );
}
