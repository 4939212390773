import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { isMobile } from "react-device-detect";
import { Token, UnderlyingToken } from "../lib/tokens";
import ExchangeRates, { useRates } from "../containers/exchangeRates";
import { getAPYRequest, tokenID } from "../lib/graphql";
import BigNumber from "bignumber.js";
import { calculateRequiredBlocksHistory } from "../lib/charts";
import { networkIdFromChainId } from "../lib/network";
import { useWeb3React } from "@web3-react/core";
import { calculateRookAPY, getRemainingTimeInAct, getRemainingBlocksInAct } from "../lib/utils";

type IBalances<T> = { [key in keyof typeof UnderlyingToken | string]: T };

function useAPYs() {
  const { library, chainId } = useWeb3React();
  const { library: networkWeb3, chainId: networkChainId } = useWeb3React("infura");
  const exchangeRates = useRates();
  const [APYs, setAPYs] = useState<IBalances<string>>({
    [UnderlyingToken.ETH]: "0",
    [UnderlyingToken.WETH]: "0",
    [UnderlyingToken.DAI]: "0",
    [UnderlyingToken.USDC]: "0",
    [UnderlyingToken.BTC]: "0",
  });

  useEffect(() => {
    async function getAPYs() {
      if (
        (!library && !networkWeb3) ||
        (!chainId && !networkChainId) ||
        !exchangeRates["ROOK"].gt(0)
      ) {
        return;
      }
      const web3 = !!library ? library : networkWeb3;
      const chain = library && chainId ? chainId : networkChainId;
      const net = networkIdFromChainId(chain);

      const latestBlock = await web3.eth.getBlockNumber();
      const blockWeekAgo = calculateRequiredBlocksHistory(latestBlock, 7, net)[0];

      const blocksLeftInAct = getRemainingBlocksInAct(latestBlock);
      const secondsLeftInAct = await getRemainingTimeInAct();

      const apysData = (
        await Promise.all(
          [Token.ETH, Token.WETH, Token.DAI, Token.USDC, Token.BTC].map(async (token) => {
            const { data, error } = await getAPYRequest(blockWeekAgo, tokenID(token, net), net);

            if (error || !data.last[0]) {
              console.error("APY error", error);
              return {
                [Token[token]]: "0.0%",
              };
            }

            const tokenSupply = new BigNumber(data.last[0].deposited).minus(
              new BigNumber(data.last[0].withdrawn)
            );

            const { number: rookAPY } = calculateRookAPY(
              net,
              blocksLeftInAct,
              secondsLeftInAct,
              tokenSupply,
              exchangeRates[Token[token]],
              exchangeRates["ROOK"],
              token
            );

            return {
              [Token[token]]: `${rookAPY.toFormat(2)}%`,
            };
          })
        )
      ).reduce((acc, t) => {
        const key = Object.keys(t)[0];
        return { ...acc, [key]: t[key] };
      }, {});

      setAPYs({
        [UnderlyingToken.ETH]: apysData["ETH"],
        [UnderlyingToken.WETH]: apysData["WETH"],
        [UnderlyingToken.DAI]: apysData["DAI"],
        [UnderlyingToken.USDC]: apysData["USDC"],
        [UnderlyingToken.BTC]: apysData["BTC"],
      });
    }
    getAPYs();
  }, [library, chainId, networkChainId, networkWeb3, exchangeRates]);

  return APYs;
}

const APYs = createContainer(useAPYs);

export default APYs;
