import { BigNumber } from "bignumber.js";

/**
 * Enumerates all kTokens and underlying tokens. This is useful for accessing
 * functions that work on both types of tokens.
 */
export enum Token {
  KETH,
  KWETH,
  KUSDC,
  KDAI,
  KBTC,
  ETH,
  WETH,
  USDC,
  DAI,
  ROOK,
  BTC,
  REP,
  UNI,
  BAT,
  COMP,
  WBTC,
  USDT,
  ZRX,
  KNC,
  LINK,
}

/**
 * Enumerates all of the kTokens that this frontend supports. A kToken
 * represents a share in the liquidity and profits of the liquidity pool. It is
 * not the "real" ETH/ERC20 token that is typically used in other platforms.
 */
export enum KToken {
  KDAI,
  KETH,
  KWETH,
  KUSDC,
  KBTC,
}

// /**
//  * Enumerates all of the cTokens that this frontend supports. A cToken
//  * represents an interest bearing token by Compound protocol
//  */
// export enum cToken {
//     CREP,
//     CUNI,
//     CUSDC,
//     CETH,
//     CDAI,
//     CBAT,
//     CCOMP,
//     CZRX,
//     CWBTC,
//     CUSDT,
// }

/**
 * Enumerates all tokens supported by dex aggregator
 */
export const dexTokens = [
  Token.WETH,
  Token.REP,
  Token.UNI,
  Token.BAT,
  Token.ETH,
  Token.DAI,
  Token.WBTC,
  Token.USDC,
  Token.ZRX,
  Token.USDT,
  Token.COMP,
];

/**
 * Enumerates all of the underlying tokens that this frontend supports for
 * depositing/withdrawing to/from the liquidity pool. An underlying token is the
 * "real" ETH/ERC20 token that is typically used in other platforms.
 */
export enum UnderlyingToken {
  DAI,
  ETH,
  WETH,
  USDC,
  BTC,
}

/**
 * Enumerates all tokens as values that can be iterated over. This is needed
 * because an enumerated type cannot be iterated over, and sometimes we want to
 * run some logic for each supported token.
 */
export const Tokens = [
  Token.ETH,
  Token.KETH,
  Token.WETH,
  Token.KWETH,
  Token.USDC,
  Token.KUSDC,
  Token.DAI,
  Token.KDAI,
  Token.ROOK,
  Token.BTC,
  Token.KBTC,
  Token.REP,
  Token.UNI,
  Token.BAT,
  Token.WBTC,
  Token.ZRX,
  Token.USDT,
  Token.COMP,
];

/**
 * Enumerates all kTokens as values that can be iterated over. This is needed
 * because an enumerated type cannot be iterated over, and sometimes we want to
 * run some logic for each supported kToken.
 */
export const KTokens = [KToken.KETH, KToken.KWETH, KToken.KUSDC, KToken.KDAI, KToken.KBTC];

/**
 * Enumerates all underlying tokens as values that can be iterated over. This is
 * needed because an enumerated type cannot be iterated over, and sometimes we
 * want to run some logic for each supported underlying token.
 */
export const UnderlyingTokens = [
  UnderlyingToken.ETH,
  UnderlyingToken.WETH,
  UnderlyingToken.USDC,
  UnderlyingToken.BTC,
  UnderlyingToken.DAI,
];

/**
 *
 * @param token
 *
 * @returns the number of decimals in this token.
 */
export function tokenDecimals(token: Token): number {
  switch (token) {
    case Token.KETH:
    case Token.KWETH:
    case Token.KDAI:
    case Token.ETH:
    case Token.WETH:
    case Token.DAI:
    case Token.ROOK:
    case Token.REP:
    case Token.UNI:
    case Token.BAT:
    case Token.COMP:
    case Token.USDT:
    case Token.ZRX:
    case Token.KNC:
    case Token.LINK:
      return 18;
    case Token.KUSDC:
    case Token.USDC:
      return 6;
    case Token.KBTC:
    case Token.BTC:
    case Token.WBTC:
      return 8;
    default:
      throw new TypeError("non-exhaustive pattern");
  }
}

/**
 *
 * @param token
 * @param balance
 *
 * @returns the token balance with correct decimals.
 */
export function tokenBalanceWithDecimals(token: Token, balance: BigNumber): BigNumber {
  try {
    const decimals = tokenDecimals(token);
    const value = balance.div(new BigNumber(10).pow(decimals));
    if (!isFinite(value.toNumber())) return new BigNumber(0);
    if (token === Token.BTC || token === Token.ROOK) {
      if (value.gt(new BigNumber(1000))) {
        return new BigNumber(value.toFixed(2, BigNumber.ROUND_HALF_UP));
      }
      return new BigNumber(value.toFixed(8, BigNumber.ROUND_HALF_UP));
    }
    if (value.gt(new BigNumber(0.1))) {
      return new BigNumber(value.toFixed(2, BigNumber.ROUND_HALF_UP));
    }
    if (value.gt(new BigNumber(0.0001))) {
      return new BigNumber(value.toFixed(4, BigNumber.ROUND_HALF_UP));
    }
    if (value.gt(new BigNumber(0.00000001))) {
      return new BigNumber(value.toFixed(8, BigNumber.ROUND_HALF_UP));
    }

    return new BigNumber(0);
  } catch (err) {
    console.error(err);
    return new BigNumber(0);
  }
}

/**
 * BigNumber isn't adjusted to international formats.
 * So, override groupSeparator config to '' from it's default value ','.
 * This is to avoid any complications while parsing the BigNumber to float/int.
 */
const bigNumberFormatConfig = {
  decimalSeparator: ".",
  groupSeparator: "",
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: " ",
  fractionGroupSize: 0,
};

/**
 *
 * @param token
 * @param balance
 *
 * @returns the human-readable string for the token balance.
 */
export function displayTokenBalance(token: Token, balance: BigNumber): string {
  const value = tokenBalanceWithDecimals(token, balance);
  return value.toFormat(bigNumberFormatConfig);
}

/**
 *
 * @param token
 * @param balance
 * @param decimals
 *
 * @returns the human-readable string for the token balance with decimals.
 */
export function displayTokenBalanceWithFormat(
  token: Token,
  balance: BigNumber,
  decimals = 0
): string {
  const value = tokenBalanceWithDecimals(token, balance);
  return value.toFormat(decimals, bigNumberFormatConfig);
}

/**
 *
 * @param token
 *
 * @returns the human-readable string for the token.
 */
export function tokenSymbol(token: Token): string {
  switch (token) {
    case Token.KETH:
      return kTokenSymbol(KToken.KETH);
    case Token.KWETH:
      return kTokenSymbol(KToken.KWETH);
    case Token.KUSDC:
      return kTokenSymbol(KToken.KUSDC);
    case Token.KDAI:
      return kTokenSymbol(KToken.KDAI);
    case Token.ETH:
      return underlyingTokenSymbol(UnderlyingToken.ETH);
    case Token.WETH:
      return underlyingTokenSymbol(UnderlyingToken.WETH);
    case Token.USDC:
      return underlyingTokenSymbol(UnderlyingToken.USDC);
    case Token.DAI:
      return underlyingTokenSymbol(UnderlyingToken.DAI);
    case Token.BTC:
      return underlyingTokenSymbol(UnderlyingToken.BTC);
    case Token.KBTC:
      return kTokenSymbol(KToken.KBTC);
    default:
      throw new TypeError("non-exhaustive pattern");
  }
}

/**
 *
 * @param kToken
 *
 * @returns a human-readable string that represents the kToken.
 */
export function kTokenSymbol(kToken: KToken): string {
  switch (kToken) {
    case KToken.KETH:
      return "kETH";
    case KToken.KWETH:
      return "kWETH";
    case KToken.KUSDC:
      return "kUSDC";
    case KToken.KDAI:
      return "kDAI";
    case KToken.KBTC:
      return "kBTC";
    default:
      throw new TypeError("non-exhaustive pattern");
  }
}

/**
 *
 * @param underlyingToken
 *
 * @returns a human-readable string that represents the underlying token.
 */
export function underlyingTokenSymbol(underlyingToken: UnderlyingToken): string {
  switch (underlyingToken) {
    case UnderlyingToken.ETH:
      return "ETH";
    case UnderlyingToken.WETH:
      return "WETH";
    case UnderlyingToken.USDC:
      return "USDC";
    case UnderlyingToken.DAI:
      return "DAI";
    case UnderlyingToken.BTC:
      return "renBTC";
    default:
      throw new TypeError("non-exhaustive pattern");
  }
}

/**
 *
 * @param symbol
 *
 * @returns symbol to Token conversion.
 */
export function symbolToToken(symbol: string): UnderlyingToken {
  switch (symbol) {
    case "ETH":
      return UnderlyingToken.ETH;
    case "WETH":
      return UnderlyingToken.WETH;
    case "USDC":
      return UnderlyingToken.USDC;
    case "DAI":
      return UnderlyingToken.DAI;
    case "renBTC":
    case "BTC":
      return UnderlyingToken.BTC;
    default:
      throw new TypeError("non-exhaustive pattern");
  }
}

/**
 * Unwrap a token into a kToken.
 *
 * @param token that will be unwrapped.
 *
 * @returns the token represented as a kToken. It throws a TypeError if the
 * token is not actually a kToken.
 */
export function unwrapKToken(token: Token): KToken {
  switch (token) {
    case Token.KETH:
      return KToken.KETH;
    case Token.KWETH:
      return KToken.KWETH;
    case Token.KUSDC:
      return KToken.KUSDC;
    case Token.KDAI:
      return KToken.KDAI;
    case Token.KBTC:
      return KToken.KBTC;
    default:
      throw new TypeError("non-exhaustive pattern");
  }
}

/**
 * Wrap a kToken into a token.
 *
 * @param kToken that will be wrapped.
 *
 * @returns the equivalent token.
 */
export function wrapKToken(kToken: KToken): Token {
  switch (kToken) {
    case KToken.KETH:
      return Token.KETH;
    case KToken.KWETH:
      return Token.KWETH;
    case KToken.KUSDC:
      return Token.KUSDC;
    case KToken.KDAI:
      return Token.KDAI;
    case KToken.KBTC:
      return Token.KBTC;
    default:
      throw new TypeError("non-exhaustive pattern");
  }
}

/**
 * Unwrap a token into an underlying token.
 *
 * @param token that will be unwrapped.
 *
 * @returns the token represented as an underlying token. It throws a TypeError
 * if the token is not actually an underlying token.
 */
export function unwrapUnderlyingToken(token: Token): UnderlyingToken {
  switch (token) {
    case Token.ETH:
      return UnderlyingToken.ETH;
    case Token.WETH:
      return UnderlyingToken.WETH;
    case Token.USDC:
      return UnderlyingToken.USDC;
    case Token.DAI:
      return UnderlyingToken.DAI;
    case Token.BTC:
      return UnderlyingToken.BTC;
    default:
      throw new TypeError("non-exhaustive pattern");
  }
}

/**
 * Wrap an underlying token into a token.
 *
 * @param underlyingToken that will be wrapped.
 *
 * @returns the equivalent token.
 */
export function wrapUnderlyingToken(underlyingToken: UnderlyingToken): Token {
  switch (underlyingToken) {
    case UnderlyingToken.ETH:
      return Token.ETH;
    case UnderlyingToken.USDC:
      return Token.USDC;
    case UnderlyingToken.WETH:
      return Token.WETH;
    case UnderlyingToken.DAI:
      return Token.DAI;
    case UnderlyingToken.BTC:
      return Token.BTC;
    default:
      throw new TypeError("non-exhaustive pattern");
  }
}

/**
 * Map an underlying token to the kToken that is minted when the underlying
 * token is deposited into the liquidity pool. This is the same kToken that must
 * be burned in order to withdraw the underlying token from the liquidity pool.
 *
 * @param underlyingToken that will be mapped into the kToken.
 *
 * @returns the kToken.
 */
export function underlyingTokenToKToken(underlyingToken: UnderlyingToken): KToken {
  switch (underlyingToken) {
    case UnderlyingToken.ETH:
      return KToken.KETH;
    case UnderlyingToken.USDC:
      return KToken.KUSDC;
    case UnderlyingToken.WETH:
      return KToken.KWETH;
    case UnderlyingToken.DAI:
      return KToken.KDAI;
    case UnderlyingToken.BTC:
      return KToken.KBTC;
    default:
      throw new TypeError("non-exhaustive pattern");
  }
}

/**
 * Map a kToken to the underlying token that must be deposited into the
 * liquidity pool in order to mint the kToken. This is the same underlying token
 * that will be withdrawn from the liquidity pool when burning the kToken.
 *
 * @param kToken that will be mapped into the underlying token.
 *
 * @returns the underlying token.
 */
export function kTokenToUnderlyingToken(kToken: KToken): UnderlyingToken {
  switch (kToken) {
    case KToken.KETH:
      return UnderlyingToken.ETH;
    case KToken.KUSDC:
      return UnderlyingToken.USDC;
    case KToken.KWETH:
      return UnderlyingToken.WETH;
    case KToken.KDAI:
      return UnderlyingToken.DAI;
    case KToken.KBTC:
      return UnderlyingToken.BTC;
    default:
      throw new TypeError("non-exhaustive pattern");
  }
}

/**
 * Convert an amount of kTokens to an amount of underlying tokens. The
 * conversion is done in the context of withdrawing underlying tokens from the
 * liquidity pool. This function returns the number of underlying tokens that
 * can be withdrawn by burning the given number of kTokens.
 *
 * @param kAmount The amount of kTokens that will be burned.
 * @param kTokenTotalSupply The total supply of kTokens.
 * @param underlyingTokenBorrowableBalance The maximum amount of underlying
 * tokens that can be borrowed from the liquidity pool.
 */
export function kAmountToUnderlyingAmount(
  kAmount: BigNumber,
  kTokenTotalSupply: BigNumber,
  underlyingTokenBorrowableBalance: BigNumber
): BigNumber {
  return kAmount
    .multipliedBy(underlyingTokenBorrowableBalance)
    .dividedBy(kTokenTotalSupply)
    .integerValue(BigNumber.ROUND_DOWN);
}
