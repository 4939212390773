import {
  Button,
  Divider,
  Grid,
  LinearProgress,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import BigNumber from "bignumber.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CompoundTransferPositionModal from "../../components/modals/compound/CompoundTransferPositionModal";
import WalletCreateModal from "../../components/modals/compound/CompoundWalletCreateModal";
import KCompBorrowModal from "../../components/modals/compound/KCompBorrowModal";
import KCompCollateralModal from "../../components/modals/compound/KCompCollateralModal";
import KCompSupplyModal from "../../components/modals/compound/KCompSupplyModal";
import TokenLogo from "../../components/TokenLogo";
import { IOSSwitch } from "../../components/ui-components/IOSSwitch";
import { BorderBox, GradientBox } from "../../components/ui-components/StyledBox";
import {
  BorrowRepay,
  PositionsState,
  RookPositionState,
  SupplyPair,
  SupplyWithdraw,
  TransferPosition,
  UsdPositionState,
  VaultCreateOptions,
  WalletCreate,
} from "../../containers/borrow";
import {
  CompoundToken,
  CompoundTokenInfo,
  MarketBorrowInfo,
  MarketSupplyInfo,
} from "../../lib/compound";
import { contract, contractAddress, ContractType } from "../../lib/contracts";
import { networkIdFromChainId } from "../../lib/network";
import AssetTable from "./AssetTable";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    position: "relative",
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  VaultBalancesContainer: {
    display: "flex",
    position: "relative",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    borderRadius: "16px",
    marginBottom: "24px",
    backgroundColor: "rgba(245,245,245,0.08)",
  },
  vaultDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  vaultDetailsHeader: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "24px",
  },
  assetTableContainer: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "0px 24px 24px 24px",
  },
  vaultContainer: {
    display: "flex",
    //flex: '1 1 auto',
    //overflow: 'auto',
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    //padding: '24px',
    width: "100%",
    height: "100%",
    //marginBottom: '24px',
  },
  divider: {
    background: "rgba(245,245,245,0.08)",
    //opacity: '0.5',
    //position: 'relative',
    //height: '50%',
  },
  apyText: {
    fontWeight: 600,
    fontStyle: "Inter",
  },
  vaultDetailsDivider: {
    position: "absolute",
    width: "100%",
    background: "linear-gradient(to right, #6C46D6 0%, #09A7F3 100%)",
    opacity: "0.24",
  },
  vaultBalancesHeaderText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "15px",
    letterSpacing: "0.02em",
    marginBottom: "6px",
  },
  vaultBalancesText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "17px",
    lineHeight: "19px",
  },
  tabItemRoot: {
    "&:hover": {
      opacity: 1,
    },
    minHeight: 44,
    minWidth: 96,
    [theme.breakpoints.up("md")]: {
      minWidth: 120,
    },
  },
  tabItemWrapper: {
    // zIndex: 2,
    // marginTop: spacing(0.5),
    color: theme.palette.text.primary,
    textTransform: "initial",
  },
  tabRoot: {
    backgroundColor: "rgba(245, 245, 245, 0.08)",
    borderRadius: 16,
    minHeight: 44,
  },
  tabFlexContainer: {
    display: "inline-flex",
    position: "relative",
    zIndex: 1,
  },
  tabScroller: {
    [theme.breakpoints.up("md")]: {
      padding: "0px 8px",
    },
  },
  tabIndicator: {
    top: 3,
    bottom: 3,
    right: 3,
    height: "auto",
    background: "none",
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 4,
      left: 4,
      right: 4,
      bottom: 4,
      borderRadius: 8,
      backgroundColor: theme.palette.action.hover,
      boxShadow: "0 4px 12px 0 rgba(0,0,0,0.16)",
    },
  },
  limitBar: {
    backgroundColor: "#09A7F3",
  },
  barRoot: {
    height: 8,
    borderRadius: 5,
  },
  bar: {
    borderRadius: 5,
  },
}));

const displayBalance = (tokenBal: BigNumber) => {
  if (tokenBal.lt(0.00001)) {
    return "0";
  }
  if (tokenBal.lt(0.001)) {
    return tokenBal.toPrecision(3);
  }
  if (tokenBal.lt(0)) {
    return tokenBal.toPrecision(5);
  }
  if (tokenBal.lt(100)) {
    return tokenBal.toFixed(3);
  }
  return tokenBal.integerValue().toFixed(0);
};

interface EmptyVaultProps {
  vaultState: PositionsState;
  updateVault: () => void;
}

const EmptyVault = (props: EmptyVaultProps) => {
  const c = useStyles();
  const { t } = useTranslation();
  const { updateVault, vaultState } = props;
  const { openWalletCreateModal } = WalletCreate.useContainer();

  return (
    <Grid container className={c.VaultBalancesContainer}>
      <Grid
        container
        item
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        direction="row"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h5">{t("vault-empty")}</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            style={{ borderRadius: "8px" }}
            onClick={() =>
              openWalletCreateModal(
                VaultCreateOptions.MIGRATE,
                true,
                vaultState.posAddress,
                vaultState
              )
            }
          >
            {t("borrow-import-from-compound")}
          </Button>
        </Grid>
      </Grid>
      <WalletCreateModal onClose={updateVault} />
    </Grid>
  );
};

interface VaultBalancesProps {
  vaultState: PositionsState;
  vaultStateUSD: UsdPositionState;
  vaultStateROOK: RookPositionState;
  vaultStateCOMP: BigNumber;
  onTransferClose: () => void;
}

const VaultBalances = (props: VaultBalancesProps) => {
  const c = useStyles();
  const { t } = useTranslation();
  const { vaultState, vaultStateUSD, vaultStateROOK, vaultStateCOMP, onTransferClose } = props;

  const { openTransferModal } = TransferPosition.useContainer();

  const openSettings = (vaultAddress: string) => {
    if (!vaultAddress) return;

    openTransferModal(vaultAddress, vaultState, vaultStateROOK, vaultStateCOMP);
  };

  return (
    <Grid container className={c.VaultBalancesContainer} direction="row">
      <Grid container item direction="column" sm={2}>
        <Typography classes={{ root: c.vaultBalancesHeaderText }}>
          {t("vault-collateral")}
        </Typography>
        <Typography classes={{ root: c.vaultBalancesText }}>
          ${vaultStateUSD.collateralUSD.toFormat(2)}
        </Typography>
      </Grid>
      <Divider className={c.divider} orientation="vertical" flexItem />
      <Grid container item direction="column" sm={2}>
        <Typography classes={{ root: c.vaultBalancesHeaderText }}>Borrow Limit</Typography>
        <Typography classes={{ root: c.vaultBalancesText }}>
          ${vaultStateUSD.limitUSD.toFormat(2)}
        </Typography>
      </Grid>
      <Divider className={c.divider} orientation="vertical" flexItem />
      <Grid container item direction="column" sm={2}>
        <Typography classes={{ root: c.vaultBalancesHeaderText }}>{t("vault-borrowed")}</Typography>
        <Typography classes={{ root: c.vaultBalancesText }}>
          ${vaultStateUSD.borrowUSD.toFormat(2)}
        </Typography>
      </Grid>
      <Divider className={c.divider} orientation="vertical" flexItem />
      <Grid container item direction="column" sm={2}>
        <Typography classes={{ root: c.vaultBalancesHeaderText }}>
          {t("vault-left-to-borrow")}
        </Typography>
        <Typography classes={{ root: c.vaultBalancesText }}>
          ${vaultStateUSD.leftToBorrowUSD.toFormat(2)}
        </Typography>
      </Grid>
      <Grid container item sm={2} justifyContent="flex-end">
        <Button
          variant="contained"
          startIcon={<Settings />}
          style={{ borderRadius: "8px" }}
          onClick={() => openSettings(vaultStateUSD.posAddress)}
        >
          Settings
        </Button>
      </Grid>
      <CompoundTransferPositionModal onClose={onTransferClose} />
    </Grid>
  );
};

interface VaultAPYsProps {
  vaultStateUSD: UsdPositionState;
  vaultStateROOK: RookPositionState;
  handleBackClick: () => void;
}

const VaultAPYs = (props: VaultAPYsProps) => {
  const c = useStyles();
  const { t } = useTranslation();
  const { vaultStateUSD, vaultStateROOK, handleBackClick } = props;

  const [netAPY, setNetAPY] = useState("0");
  const [interestAPY, setInterest] = useState("0");
  const [compAPY, setCOMP] = useState("0");
  const [rookAPY, setROOK] = useState("0");

  useEffect(() => {
    const net = vaultStateUSD.netInterestAPY
      .plus(vaultStateUSD.netCompAPY.plus(vaultStateROOK.rookAPY))
      .multipliedBy(100)
      .toFormat(2);
    const interest = vaultStateUSD.netInterestAPY.multipliedBy(100).toFormat(2);
    const comp = vaultStateUSD.netCompAPY.multipliedBy(100).toFormat(2);
    const rook = vaultStateROOK.rookAPY.multipliedBy(100).toFormat(2);

    setNetAPY(net);
    setInterest(interest);
    setCOMP(comp);
    setROOK(rook);
  }, [vaultStateUSD, vaultStateROOK]);

  return (
    <Grid container className={c.VaultBalancesContainer} direction="row">
      <Grid container item direction="column" sm={2}>
        <Typography classes={{ root: c.vaultBalancesHeaderText }}>{t("vault-net-apy")}:</Typography>
        <Typography classes={{ root: c.vaultBalancesText }}>{netAPY}%</Typography>
      </Grid>
      <Divider className={c.divider} orientation="vertical" flexItem />
      <Grid container item direction="column" sm={2}>
        <Typography classes={{ root: c.vaultBalancesHeaderText }}>
          {t("vault-interest")}:
        </Typography>
        <Typography classes={{ root: c.vaultBalancesText }}>{interestAPY}%</Typography>
      </Grid>
      <Divider className={c.divider} orientation="vertical" flexItem />
      <Grid container item direction="column" sm={2}>
        <Typography classes={{ root: c.vaultBalancesHeaderText }}>
          {t("vault-comp-apy")}:
        </Typography>
        <Typography classes={{ root: c.vaultBalancesText }}>{compAPY}%</Typography>
      </Grid>
      <Divider className={c.divider} orientation="vertical" flexItem />
      <Grid container item direction="column" sm={2}>
        <Typography classes={{ root: c.vaultBalancesHeaderText }}>
          {t("vault-rook-apy")}:
        </Typography>
        <Typography classes={{ root: c.vaultBalancesText }}>{rookAPY}%</Typography>
      </Grid>
      <Grid container item sm={2} justifyContent="flex-end">
        <Button variant="outlined" onClick={handleBackClick} style={{ borderRadius: "8px" }}>
          {t("vault-back")}
        </Button>
      </Grid>
    </Grid>
  );
};

function makeRowsForBorrowTable(
  chainId: number | undefined,
  balances: SupplyPair[],
  position: string,
  borrows?: MarketBorrowInfo[],
  walletBalances?: Map<string, BigNumber>
) {
  if (borrows) {
    const rows: Record<string, unknown>[] = [];

    borrows.forEach((borrow) => {
      const pair = balances.find((pair) => pair.underlyingToken.name === borrow.underlyingSymbol);

      const liquidity = new BigNumber(borrow.liquidity).multipliedBy(
        new BigNumber(borrow.priceUSD)
      );

      const displayLiquidity = () => {
        if (liquidity.gt(1000000)) {
          return "$" + liquidity.dividedBy(1000000).toFormat(2) + "M";
        } else return "$" + liquidity.dividedBy(1000).toFormat(2) + "K";
      };

      // If pair exists in both market data and balances objects
      if (pair) {
        const underlying = pair.underlyingToken;
        const ctoken = pair.cToken;

        const decimalScaling = new BigNumber(10).pow(underlying.decimals);
        const balance = new BigNumber(underlying.balance).toFormat(2);
        const token: CompoundToken | null = {
          name: ctoken.name,
          address: ctoken.address,
          decimals: ctoken.decimals,
        };

        let walletBalanceBN = walletBalances
          ? walletBalances.get(underlying.name)
          : new BigNumber(0);
        walletBalanceBN = walletBalanceBN
          ? walletBalanceBN.dividedBy(decimalScaling)
          : new BigNumber(0);
        const walletBalance = walletBalanceBN.gt(0) ? displayBalance(walletBalanceBN) : "0";

        const underlyingBalance = new BigNumber(underlying.balance).dividedBy(decimalScaling);
        const underlyingBalanceUSD = underlyingBalance.multipliedBy(borrow.priceUSD);

        const interestAccrued = new BigNumber(underlying.interestAccrued);

        const posSupplied = parseFloat(balance) > 0;
        const TokenCell = () => (
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
          >
            <Grid container item justifyContent="flex-start" alignItems="center" xs={2}>
              <TokenLogo chainId={chainId} address={underlying.address} size={40} />
            </Grid>
            <Grid
              container
              item
              direction="column"
              justifyContent="space-around"
              alignItems="flex-start"
              xs={8}
            >
              <Grid item style={{ padding: 0, margin: 0 }}>
                <Typography style={{ padding: 0 }} variant="h6">
                  {borrow.name}
                </Typography>
              </Grid>
              <Grid item style={{ padding: 0, margin: 0, color: "#8F8F8F" }}>
                <Typography style={{ padding: 0 }} variant="body2">
                  {underlying.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        );

        const SupplyWithdrawCell = () => <div className="borrow-control-wrap">{null}</div>;

        rows.push({
          col1: <TokenCell />,
          col2: (
            <Grid item>
              {walletBalances ? (
                <Typography variant="h6">{`${new BigNumber(borrow.borrowRate)
                  .multipliedBy(100)
                  .toFormat(2)}%`}</Typography>
              ) : (
                <Grid container item direction="column" justifyContent="space-around">
                  <Grid item style={{ padding: 0, margin: 0 }}>
                    <Typography style={{ padding: 0 }} variant="h6">
                      {`${new BigNumber(borrow.borrowRate).multipliedBy(100).toFormat(2)}%`}
                    </Typography>
                  </Grid>
                  <Grid item style={{ padding: 0, margin: 0, color: "#8F8F8F" }}>
                    <Typography style={{ padding: 0 }} variant="body2">
                      {`${displayBalance(interestAccrued)} ${underlying.name}`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ),
          col3: (
            <Grid item>
              {walletBalances ? (
                <Typography variant="h6">{displayBalance(new BigNumber(walletBalance))}</Typography>
              ) : (
                <Grid container item direction="column" justifyContent="space-around">
                  <Grid item style={{ padding: 0, margin: 0 }}>
                    <Typography style={{ padding: 0 }} variant="h6">
                      ${underlyingBalanceUSD.toFormat(2)}
                    </Typography>
                  </Grid>
                  <Grid item style={{ padding: 0, margin: 0, color: "#8F8F8F" }}>
                    <Typography style={{ padding: 0 }} variant="body2">
                      {`${displayBalance(underlyingBalance)} ${underlying.name}`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ),
          col4: (
            <Grid item>
              <Typography variant="h6">{displayLiquidity()}</Typography>
            </Grid>
          ),
          symbol: borrow.underlyingSymbol,
        });
      }
    });
    return rows;
  }
  return [];
}

function makeRowsForSupplyTable(
  chainId: number | undefined,
  balances: SupplyPair[],
  position: string,
  vaultState: PositionsState,
  handleTick: (token: CompoundTokenInfo) => void,
  supplies?: MarketSupplyInfo[],
  walletBalances?: Map<string, BigNumber>
) {
  if (supplies) {
    const rows: Record<string, unknown>[] = [];

    supplies.forEach((supply) => {
      const pair = balances.find((pair) => pair.underlyingToken.name === supply.underlyingSymbol);

      // If pair exists in both market data and balances objects
      if (pair) {
        const underlying = pair.underlyingToken;
        const ctoken = pair.cToken;

        const balance = new BigNumber(underlying.balance).toFormat(2);
        const token: CompoundToken | null = {
          name: ctoken.name,
          address: ctoken.address,
          decimals: ctoken.decimals,
        };

        const decimalScaling = new BigNumber(10).pow(underlying.decimals);
        let walletBalanceBN = walletBalances
          ? walletBalances.get(underlying.name)
          : new BigNumber(0);
        walletBalanceBN = walletBalanceBN
          ? walletBalanceBN.dividedBy(decimalScaling)
          : new BigNumber(0);
        const walletBalance = walletBalanceBN.gt(0) ? displayBalance(walletBalanceBN) : "0";

        const underlyingBalance = new BigNumber(underlying.balance).dividedBy(decimalScaling);
        const underlyingBalanceUSD = underlyingBalance.multipliedBy(supply.priceUSD);

        const interestAccrued = new BigNumber(underlying.interestAccrued);

        const posSupplied = parseFloat(balance) > 0;
        const TokenCell = () => (
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
          >
            <Grid container item justifyContent="flex-start" alignItems="center" xs={2}>
              {walletBalances ? (
                <TokenLogo chainId={chainId} address={underlying.address} size={40} />
              ) : (
                <TokenLogo chainId={chainId} address={ctoken.address} size={40} />
              )}
            </Grid>
            <Grid
              container
              item
              direction="column"
              justifyContent="space-around"
              alignItems="flex-start"
              xs={8}
            >
              <Grid item style={{ padding: 0, margin: 0 }}>
                <Typography style={{ padding: 0 }} variant="h6">
                  {supply.name}
                </Typography>
              </Grid>
              <Grid item style={{ padding: 0, margin: 0, color: "#8F8F8F" }}>
                <Typography style={{ padding: 0 }} variant="body2">
                  {underlying.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        );

        const EnableCollateral = () => (
          <Grid item>
            {parseFloat(supply.collateralFactor) > 0 ? (
              <IOSSwitch
                checked={vaultState.assetsIn.includes(ctoken.address)}
                onChange={(event) => {
                  event.stopPropagation();
                  handleTick(pair);
                }}
              />
            ) : null}
          </Grid>
        );

        rows.push({
          col1: <TokenCell />,
          col2: (
            <Grid item>
              {walletBalances ? (
                <Typography variant="h6">{`${new BigNumber(supply.supplyRate)
                  .multipliedBy(100)
                  .toFormat(2)}%`}</Typography>
              ) : (
                <Grid container item direction="column" justifyContent="space-around">
                  <Grid item style={{ padding: 0, margin: 0 }}>
                    <Typography style={{ padding: 0 }} variant="h6">
                      {`${new BigNumber(supply.supplyRate).multipliedBy(100).toFormat(2)}%`}
                    </Typography>
                  </Grid>
                  <Grid item style={{ padding: 0, margin: 0, color: "#8F8F8F" }}>
                    <Typography style={{ padding: 0 }} variant="body2">
                      {`${displayBalance(interestAccrued)} ${underlying.name}`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ),
          col3: (
            <Grid item>
              {walletBalances ? (
                <Typography variant="h6">{displayBalance(new BigNumber(walletBalance))}</Typography>
              ) : (
                <Grid container item direction="column" justifyContent="space-around">
                  <Grid item style={{ padding: 0, margin: 0 }}>
                    <Typography style={{ padding: 0 }} variant="h6">
                      ${underlyingBalanceUSD.toFormat(2)}
                    </Typography>
                  </Grid>
                  <Grid item style={{ padding: 0, margin: 0, color: "#8F8F8F" }}>
                    <Typography style={{ padding: 0 }} variant="body2">
                      {`${displayBalance(underlyingBalance)} ${underlying.name}`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ),
          col4: <EnableCollateral />,
          symbol: supply.underlyingSymbol,
        });
      }
    });
    return rows;
  }
  return [];
}

interface VaultInfoProps {
  vaultAddress: string;
  vaultState: PositionsState;
  vaultStateUSD: UsdPositionState;
  vaultStateROOK: RookPositionState;
  data: {
    borrow: MarketBorrowInfo[];
    supply: MarketSupplyInfo[];
  };
  walletBalances: Map<string, BigNumber>;

  updateVault: () => void;
  refreshVaults: () => void;
  setPosition: (position: string) => void;
}

const VaultInfo = (props: VaultInfoProps): React.ReactElement => {
  const { t } = useTranslation();
  const c = useStyles();
  const { library, chainId, account } = useWeb3React();

  const {
    vaultAddress,
    vaultState,
    vaultStateUSD,
    vaultStateROOK,
    data,
    walletBalances,
    updateVault,
    refreshVaults,
    setPosition,
  } = props;

  const { openSupplyWithdrawModal } = SupplyWithdraw.useContainer();
  const { openBorrowRepayModal } = BorrowRepay.useContainer();

  const [tabIndex, setTabIndex] = useState(0);
  const [borrowedBalances, setBorrowedBalances] = useState<SupplyPair[]>([]);
  const [suppliedBalances, setSuppliedBalances] = useState<SupplyPair[]>([]);
  const [borrowTokens, setBorrowTokens] = useState<SupplyPair[]>([]);
  const [supplyTokens, setSupplyTokens] = useState<SupplyPair[]>([]);
  const [collateralModalActive, setCollateralModalActive] = useState(false);
  const [collateralToken, setCollateralToken] = useState<CompoundTokenInfo | undefined>(undefined);
  const [compToClaim, setCompToClaim] = useState(new BigNumber(0));
  const [vaultIsEmpty, setEmpty] = useState(false);

  useEffect(() => {
    const supplied = vaultState.suppliedTokens.filter((pair) => {
      const bal = new BigNumber(pair.underlyingToken.balance);
      return bal.gt(0);
    });
    const borrowed = vaultState.borrowedTokens.filter((pair) => {
      const bal = new BigNumber(pair.underlyingToken.balance);
      return bal.gt(0);
    });
    const supply = vaultState.suppliedTokens.filter((pair) => {
      const bal = new BigNumber(pair.underlyingToken.balance);
      return !bal.gt(0);
    });
    const borrow = vaultState.borrowedTokens.filter((pair) => {
      const bal = new BigNumber(pair.underlyingToken.balance);
      return !bal.gt(0);
    });
    setBorrowedBalances(borrowed);
    setSuppliedBalances(supplied);
    setBorrowTokens(borrow);
    setSupplyTokens(supply);

    async function getComp() {
      //const compToClaim = await fetchCompToClaim(vaultAddress);

      const compoundLens = contract(
        library,
        networkIdFromChainId(chainId),
        ContractType.CompoundLens,
        account ? account : "0"
      );

      const result = await compoundLens.methods
        .getCompBalanceMetadataExt(
          contractAddress(networkIdFromChainId(chainId), ContractType.COMP),
          contractAddress(networkIdFromChainId(chainId), ContractType.Comptroller),
          vaultAddress
        )
        .call();
      const compEarned = new BigNumber(result.allocated).dividedBy(new BigNumber(10).pow(18));
      setCompToClaim(compEarned);
    }

    getComp();
  }, [vaultState]);

  useEffect(() => {
    if (!vaultStateUSD.supplyETH.gt(0)) {
      setEmpty(true);
    }
  }, [vaultStateUSD]);

  async function fetchCompToClaim(positionAddress: string): Promise<BigNumber> {
    if (!account) return new BigNumber(0);
    try {
      const result = await axios.get(
        `https://api.compound.finance/api/v2/governance/comp/account?address=${positionAddress}`
      );
      if (result.data.markets) {
        let compAccrued = new BigNumber(0);
        let compClaimed = new BigNumber(0);
        result.data.markets.forEach((market) => {
          compAccrued = compAccrued.plus(new BigNumber(market.comp_allocated));
          compClaimed = compClaimed.plus(new BigNumber(market.comp_distributed));
        });
        return compAccrued.minus(compClaimed);
      } else return new BigNumber(0);
    } catch (err) {
      if (process.env.NODE_ENV === "development") {
        console.log("Error: [fetchCompToClaim] ", err);
      }

      return new BigNumber(0);
    }
  }

  const onTransferClose = () => {
    refreshVaults();
    setPosition("");
  };

  const handleBackClick = () => {
    setPosition("");
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, value: any) => {
    setTabIndex(value);
  };

  const handleCollateralTick = (token: CompoundTokenInfo) => {
    setCollateralToken(token);
    setCollateralModalActive(true);
  };

  const [borrowData, setBorrowData] = useState(() =>
    makeRowsForBorrowTable(chainId, borrowTokens, vaultAddress)
  );

  const [supplyData, setSupplyData] = useState(() =>
    makeRowsForSupplyTable(chainId, supplyTokens, vaultAddress, vaultState, handleCollateralTick)
  );

  const [borrowedData, setBorrowedData] = useState(() =>
    makeRowsForBorrowTable(chainId, borrowedBalances, vaultAddress)
  );

  const [suppliedData, setSuppliedData] = useState(() =>
    makeRowsForSupplyTable(
      chainId,
      suppliedBalances,
      vaultAddress,
      vaultState,
      handleCollateralTick
    )
  );

  useEffect(() => {
    setBorrowData(
      makeRowsForBorrowTable(chainId, borrowTokens, vaultAddress, data.borrow, walletBalances)
    );
    setSupplyData(
      makeRowsForSupplyTable(
        chainId,
        supplyTokens,
        vaultAddress,
        vaultState,
        handleCollateralTick,
        data.supply,
        walletBalances
      )
    );
    setBorrowedData(makeRowsForBorrowTable(chainId, borrowedBalances, vaultAddress, data.borrow));
    setSuppliedData(
      makeRowsForSupplyTable(
        chainId,
        suppliedBalances,
        vaultAddress,
        vaultState,
        handleCollateralTick,
        data.supply
      )
    );
  }, [
    data,
    suppliedBalances,
    borrowedBalances,
    vaultAddress,
    borrowTokens,
    supplyTokens,
    walletBalances,
  ]);

  const handleBorrowRowClick = (row) => {
    const clickedToken = vaultState.borrowedTokens.find(
      (pair) => pair.underlyingToken.name === row.symbol
    );
    if (clickedToken) openBorrowRepayModal(clickedToken, vaultState, vaultStateUSD, data.borrow);
  };

  const handleSupplyRowClick = (row) => {
    const clickedToken = vaultState.suppliedTokens.find(
      (pair) => pair.underlyingToken.name === row.symbol
    );
    if (clickedToken) openSupplyWithdrawModal(clickedToken, vaultState, vaultStateUSD, data.supply);
  };

  const borrowTable = () => {
    const borrowColumns = [
      {
        Header: "Asset",
        accessor: "col1",
      },
      {
        Header: "APY",
        accessor: "col2",
        sortType: "basic",
      },
      {
        Header: "In Wallet",
        accessor: "col3",
        sortType: "basic",
      },
      {
        Header: "Liquidity",
        accessor: "col4",
        sortType: "basic",
      },
    ];

    return (
      <AssetTable
        data={borrowData}
        columns={borrowColumns}
        onRowClick={handleBorrowRowClick}
        supply={false}
      />
    );
  };

  const supplyTable = () => {
    const supplyColumns = [
      {
        Header: "Asset",
        accessor: "col1",
      },
      {
        Header: "APY",
        accessor: "col2",
        sortType: "basic",
      },
      {
        Header: "In Wallet",
        accessor: "col3",
        sortType: "basic",
      },
      {
        Header: "Collateral",
        accessor: "col4",
        sortType: "basic",
      },
    ];

    return (
      <AssetTable
        data={supplyData}
        columns={supplyColumns}
        onRowClick={handleSupplyRowClick}
        supply={true}
      />
    );
  };

  const borrowedTable = () => {
    const borrowColumns = [
      {
        Header: "Asset",
        accessor: "col1",
      },
      {
        Header: "APY / Accrued",
        accessor: "col2",
        sortType: "basic",
      },
      {
        Header: "Borrowed",
        accessor: "col3",
        sortType: "basic",
      },
      {
        Header: "Liquidity",
        accessor: "col4",
        sortType: "basic",
      },
    ];

    return (
      <AssetTable
        data={borrowedData}
        columns={borrowColumns}
        onRowClick={handleBorrowRowClick}
        supply={false}
      />
    );
  };

  const suppliedTable = () => {
    const supplyColumns = [
      {
        Header: "Asset",
        accessor: "col1",
      },
      {
        Header: "APY / Earned",
        accessor: "col2",
        sortType: "basic",
      },
      {
        Header: "Supplied",
        accessor: "col3",
        sortType: "basic",
      },
      {
        Header: "Collateral",
        accessor: "col4",
        sortType: "basic",
      },
    ];

    return (
      <AssetTable
        data={suppliedData}
        columns={supplyColumns}
        onRowClick={handleSupplyRowClick}
        supply={true}
      />
    );
  };

  return (
    <div className={c.container}>
      {vaultIsEmpty ? <EmptyVault updateVault={updateVault} vaultState={vaultState} /> : null}
      <VaultAPYs
        vaultStateUSD={vaultStateUSD}
        vaultStateROOK={vaultStateROOK}
        handleBackClick={handleBackClick}
      />
      <VaultBalances
        vaultState={vaultState}
        vaultStateUSD={vaultStateUSD}
        vaultStateROOK={vaultStateROOK}
        vaultStateCOMP={compToClaim}
        onTransferClose={onTransferClose}
      />

      <div className={c.vaultDetailsContainer}>
        <div
          style={{
            marginBottom: "24px",
            width: "100%",
            height: "100%",
            display: "flex",
            position: "relative",
          }}
        >
          <GradientBox>
            <Grid container className={c.vaultContainer} direction="column">
              <Grid container item className={c.vaultDetailsHeader}>
                <Grid item>
                  <Tabs
                    classes={{
                      root: c.tabRoot,
                      indicator: c.tabIndicator,
                      flexContainer: c.tabFlexContainer,
                      scroller: c.tabScroller,
                    }}
                    value={tabIndex}
                    onChange={handleTabChange}
                  >
                    <Tab
                      classes={{
                        root: c.tabItemRoot,
                        wrapper: c.tabItemWrapper,
                      }}
                      disableRipple
                      label={"Supply"}
                    />
                    <Tab
                      classes={{
                        root: c.tabItemRoot,
                        wrapper: c.tabItemWrapper,
                      }}
                      disableRipple
                      label={t("borrow-modal.borrow-header")}
                    />
                  </Tabs>
                </Grid>

                <Grid
                  container
                  item
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  xs={12}
                  lg={8}
                  style={{ padding: "12px" }}
                >
                  <Grid item style={{ marginRight: "10px" }}>
                    <Typography classes={{ root: c.vaultBalancesText }}>
                      {t("vault-borrow-used", {
                        amount: vaultStateUSD.health,
                      })}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    style={{
                      marginLeft: 0,
                      position: "relative",
                      width: "60%",
                    }}
                  >
                    <LinearProgress
                      classes={{
                        root: c.barRoot,
                        barColorPrimary: c.limitBar,
                        bar: c.bar,
                      }}
                      style={{
                        position: "relative",
                        borderRadius: "5px",
                      }}
                      variant="determinate"
                      value={vaultStateUSD.health}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                item
                style={{
                  position: "relative",
                  width: "100%",
                  margin: "none",
                }}
              >
                <Divider className={c.vaultDetailsDivider} variant="fullWidth" />
              </Grid>

              <Grid container item className={c.assetTableContainer} direction="column">
                {tabIndex
                  ? borrowedBalances.length
                    ? borrowedTable()
                    : borrowTable()
                  : suppliedBalances.length
                  ? suppliedTable()
                  : supplyTable()}
              </Grid>
            </Grid>
          </GradientBox>
        </div>
        {tabIndex ? (
          borrowedBalances.length ? (
            <div
              style={{
                marginBottom: "24px",
                width: "100%",
                height: "100%",
                display: "flex",
                position: "relative",
              }}
            >
              <BorderBox>
                <Grid container className={c.vaultContainer} direction="column">
                  <Grid
                    container
                    item
                    className={c.assetTableContainer}
                    style={{ marginTop: "24px" }}
                    direction="column"
                  >
                    {borrowTable()}
                  </Grid>
                </Grid>
              </BorderBox>
            </div>
          ) : null
        ) : suppliedBalances.length ? (
          <div
            style={{
              marginBottom: "24px",
              width: "100%",
              height: "100%",
              display: "flex",
              position: "relative",
            }}
          >
            <BorderBox>
              <Grid container className={c.vaultContainer} direction="column">
                <Grid
                  container
                  item
                  className={c.assetTableContainer}
                  style={{ marginTop: "24px" }}
                  direction="column"
                >
                  {supplyTable()}
                </Grid>
              </Grid>
            </BorderBox>
          </div>
        ) : null}
      </div>
      <KCompCollateralModal
        vaultAddress={vaultAddress}
        vaultState={vaultState}
        vaultStateUSD={vaultStateUSD}
        active={collateralModalActive}
        setActive={setCollateralModalActive}
        onClose={updateVault}
        token={collateralToken}
      />
      <KCompSupplyModal onClose={updateVault} />
      <KCompBorrowModal onClose={updateVault} />
    </div>
  );
};

export default VaultInfo;
