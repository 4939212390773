import { Button, Grid, Icon, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { ExternalLinkIcon } from "../../components/Icons";
import { GradientBox } from "../../components/ui-components/StyledBox";
import { ReactComponent as VaultIcon } from "../../styles/images/Menu Icons/Vault.svg";

const useStyles = makeStyles((theme) => ({
  vaultContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "auto",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "24px",
    width: "100%",
    height: "100%",
  },
  vaultHeader: {
    display: "flex",
    flex: "1 1 auto",
    position: "relative",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "8px",
    maxHeight: "40%",
  },
  vaultContent: {
    display: "flex",
    position: "relative",
    flex: "1 1 auto",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
    height: "100%",
    width: "100%",
  },
  vaultHeaderText: {
    color: theme.palette.text.primary,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    [theme.breakpoints.up("md")]: {
      fontSize: "32px",
      lineHeight: "39px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "30px",
    },
  },
  docsLink: {
    textTransform: "none",
    alignContent: "center",
    paddingLeft: "0px",
    "&:hover": {
      background: "none",
      textDecoration: "underline",
    },
  },
  noVaults: {
    display: "flex",
    position: "relative",
    justifyContent: "space-beteen",
    borderRadius: "16px",
    border: "1px dashed #525252",
    alignItems: "center",
    color: theme.palette.text.secondary,
    paddingLeft: "30%",
    paddingRight: "30%",
    paddingTop: "5%",
    paddingBottom: "5%",
    height: "100%",
    width: "100%",
  },
  noVaultHeaderText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "2em",
    textAlign: "center",
    color: "#525252",
  },
  noVaultSubtext: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "1.1vw",
    textAlign: "center",
    color: "#525252",
  },
}));

const VaultConnectWallet = () => {
  const c = useStyles();
  const { t } = useTranslation();

  return (
    <GradientBox>
      <Grid container item className={c.vaultContainer} direction="column">
        <Grid container item className={c.vaultHeader}>
          <Grid container item direction="row" xs={12}>
            <Typography
              variant="h4"
              classes={{ h4: c.vaultHeaderText }}
              style={{ marginRight: "7px" }}
            >
              {t("vaults-header")}
            </Typography>
          </Grid>
          <Button
            className={c.docsLink}
            endIcon={
              <Icon fontSize="large">
                <ExternalLinkIcon />
              </Icon>
            }
            href="https://snapshot.org/#/rook.eth/proposal/0xfd05c73beb9b02f4840604fd12f91411c11004658fea74919ee0251288951116"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("sunset-link")}
          </Button>
        </Grid>
        <Grid container item className={c.vaultContent}>
          <Grid container item className={c.noVaults} direction="column" justifyContent="center">
            <Grid item>
              <VaultIcon fill="#525252" height="6vh" width="4.5vw" />
            </Grid>
            <Grid item>
              <Typography classes={{ root: c.noVaultHeaderText }}>
                Please connect your wallet
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </GradientBox>
  );
};

export default VaultConnectWallet;
